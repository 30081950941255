const { connect } = require('react-redux');
const Component = require('../components/SaveManager.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  type: state.type,
  stages: state.stages,
  isValueAsUnit: state.isValueAsUnit,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

const mapDispatchToProps = (dispatch) => ({
  saveItem: (id, changes, metadataToCreate, metadataToUpdate,
    metadataToDelete, shouldBeMoved) => dispatch(actions.saveItem(id, changes, metadataToCreate,
    metadataToUpdate, metadataToDelete, shouldBeMoved)),
  createItem: (item, changes, metadataToCreate,
    metadataToUpdate, metadataToDelete) => dispatch(actions
    .createItem(item, changes, metadataToCreate, metadataToUpdate, metadataToDelete)),
  showEditFeedbackModal: (item, handleFeedbackSave) => dispatch(actions
    .showEditFeedbackModal(item, handleFeedbackSave)),
  hideModal: () => dispatch(actions.hideModal()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
