const ChartConfigFilterProbability = require('./ChartConfigFilterProbability');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterProbabilityRange extends ChartConfigFilterProbability {
  /**
   * @param {number} min
   * @param {number} max
   */
  constructor(min, max) {
    super(ChartConfigFilter.TYPE_RANGE);
    this.min = min;
    this.max = max;
  }

  /**
   * @param data
   * @return {ChartConfigFilterProbabilityRange}
   */
  static fromJSON(data) {
    return new ChartConfigFilterProbabilityRange(data.min, data.max);
  }

  isValid() {
    const validMin = this.min !== null && this.min !== undefined && !Number.isNaN(this.min);
    const validMax = this.max !== null && this.max !== undefined && !Number.isNaN(this.max);
    return validMin || validMax;
  }
};
