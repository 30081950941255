const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Timesheet = {
  getAll(options = {}, filters = {}) {
    let url = '/timetracking/timesheet-report';
    const filtersQueryString = HTTPService.buildQueryString(filters);
    const queryString = HTTPService.buildQueryString(options);
    if (queryString) {
      url += `?${queryString}`;
      if (filtersQueryString) {
        url += `&${filtersQueryString}`;
      }
    }

    return request({
      method: 'get',
      url,
    });
  },
  getTotalHours(filters = {}) {
    let url = '/timetracking/timesheet-report-total-hours';
    const filtersQueryString = HTTPService.buildQueryString(filters);
    if (filtersQueryString) {
      url += `?${filtersQueryString}`;
    }

    return request({ method: 'get', url });
  },
  transferHours(selectAll = false, project, date, timesheets = [], filters) {
    let url = '/timetracking/transfer';
    const filtersQueryString = HTTPService.buildQueryString(filters);

    url += `?flagAll=${selectAll}`;
    if (filtersQueryString) {
      url += `&${filtersQueryString}`;
    }

    return request({
      method: 'post',
      url,
      data: {
        targetProjectId: project,
        targetDate: date,
        timetrackings: timesheets,
      },
    });
  },
};

module.exports = Timesheet;
