const React = require('react');
const PropTypes = require('prop-types');
const Button = require('../../Button/RoundedButton.react');
const ShowIf = require('../../ShowIf/ShowIf.react');

const InsightLevelActions = ({ onAddChartClick, canCreate }) => (
  <div className="wethod-insight-level__actions">
    <ShowIf condition={canCreate}>
      <Button onClick={onAddChartClick}>Add chart</Button>
    </ShowIf>
  </div>
);

InsightLevelActions.defaultProps = {
  canCreate: false,
};

InsightLevelActions.propTypes = {
  onAddChartClick: PropTypes.func.isRequired,
  canCreate: PropTypes.bool,
};

module.exports = InsightLevelActions;
