const React = require('react');
const NumberFormatter = require('../../../../../../../common/react/formatters/NumberFormatter.react');
const MoneyCell = require('./MoneyCell.react');

const PriceCell = ({ children, className, label = '' }) => (
  <MoneyCell className={className} label={label}>
    <NumberFormatter
      precision={2}
      minPrecision={2}
      hideZero>
      {children}
    </NumberFormatter>
  </MoneyCell>
);

module.exports = PriceCell;
