const React = require('react');
const Table = require('../../../../../../../../common/react/Table2/Table2.react');
const TableBody = require('../../../../../../../../common/react/Table2/TableBody.react');
const Loader = require('../../../../../../../../common/react/Loader/Loader.react');
const TableRow = require('../../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../../common/react/Table2/TableHead.react');
const Checkbox = require('../../../../../../../../common/react/inputs/Checkbox/Checkbox.react');
const TableSearch = require('./TransferHoursSearch.react');
const TimesheetRow = require('../../../containers/modal/TransferHours/TransferHoursRow');
const TableSummaryRow = require('../../../../../../../../common/react/Table2/TableSummaryRow.react');
const Typography = require('../../../../../../../../common/react/Typography/Typography.react.js');

module.exports = class TransferHoursTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return 350;
  }

  componentDidMount() {
    this.updateTableHeight();
    this.props.getTotalHours(this.getDefaultOptions().filters);
  }

  /**
   * Returns the given timesheet with updated data
   * @param timesheet
   * @returns {{[p: string]: *}|*}
   */
  getTimesheetWithUpdatedData(timesheet) {
    const updatedTimesheet = this.props.updatedTimesheets
      .find((updatedItem) => `${updatedItem.id_timetracking}-${updatedItem.date}` === `${timesheet.id_timetracking}-${timesheet.date}`);
    return updatedTimesheet ? {
      ...timesheet,
      ...updatedTimesheet,
    }
      : timesheet;
  }

  getList() {
    return this.props.timesheets.map((timesheet) => (
      <TimesheetRow
        key={`${timesheet.id_timetracking}-${timesheet.date}`}
        selectAll={this.props.selectAll}
        timesheet={this.getTimesheetWithUpdatedData(timesheet)}
        updateErrors={this.props.updateErrors} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <TableSearch placeholder="Search"
        keyword={this.props.search}
        filter={this.search.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.props.pageOffset,
      limit: this.props.pageLimit,
      search: this.props.search,
      order: this.props.order,
      sort: this.props.sort,
      filters: this.props.filters,
    };
  }

  getTimesheets(options) {
    if (!this.props.isWaiting) {
      this.props.getTimesheets(
        options.offset,
        options.limit,
        options.search,
        options.order,
        options.sort,
        options.filters,
      );
    }
  }

  getSort(order) {
    return this.props.order === order ? this.props.sort : null;
  }

  getSelectedHours() {
    if (this.props.selectAll) {
      // If the select-all-checkbox is checked, the total hours are the amount returned by the
      // backend minus the hours of all unchecked rows
      return this.props.updatedTimesheets.reduce((total, timesheet) => (
        timesheet.selected ? total : total - timesheet.hours
      ), this.props.totalHours);
    }

    // If the select-all-checkbox is not checked, the total hours are the sum of the hours for all
    // checked rows
    return this.props.updatedTimesheets.reduce((total, timesheet) => (
      timesheet.selected ? total + timesheet.hours : total
    ), 0.0);
  }

  getSelectedTransfer() {
    if (this.props.selectAll) {
      // If the select-all-checkbox is checked, the total hours to transfer are the amount returned
      // by the backend minus the hours of all unchecked rows minus the delta between the hours and
      // the hours to transfer for all checked rows
      return this.props.updatedTimesheets.reduce((total, timesheet) => (
        timesheet.selected
          ? total - (timesheet.hours - Number(timesheet.hours_to_transfer ?? timesheet.hours))
          : total - timesheet.hours
      ), this.props.totalHours);
    }

    // If the select-all-checkbox is not checked, the total hours to transfer are the sum of the
    // hours to transfer, or the hours if no hours to transfer are set, for all checked rows
    return this.props.updatedTimesheets.reduce((total, timesheet) => (
      timesheet.selected
        ? (total + Number(timesheet.hours_to_transfer ?? timesheet.hours))
        : total
    ), 0.0);
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = (page - 1) * size;
      options.limit = size;
      options.order = this.props.order;
      options.sort = this.props.sort;

      this.getTimesheets(options);
    }
  }

  hasMorePages() {
    return this.props.hasMorePages;
  }

  updateTableHeight() {
    this.setState({ tableHeight: TransferHoursTable.getTableMaxHeight() });
  }

  search(search) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.search = search;

      this.getTimesheets(options);
    }
  }

  sort(order, sort) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.order = order;
      options.sort = sort;
      this.getTimesheets(options);
    }
  }

  /**
   * Returns true if the select-all-checkbox should be partially checked
   *
   * @returns boolean
   */
  isSelectAllPartiallyChecked() {
    return (
      // At least one but not all rows are selected
      this.props.updatedTimesheets.some((timesheet) => timesheet.selected)
    ) || (
      // The select-all-checkbox has been pressed but, not all the edited rows are selected but at
      // least one is selected
      this.props.selectAll
      && this.props.updatedTimesheets.some((timesheet) => !timesheet.selected)
      && !this.props.updatedTimesheets.every((timesheet) => !timesheet.selected)
    );
  }

  /**
   * Returns true if the select-all-checkbox should be checked
   *
   * @returns boolean
   */
  isSelectAllChecked() {
    return (
      // The select-all-checkbox has been pressed and all the edited rows are selected
      this.props.selectAll
      && this.props.updatedTimesheets.every((timesheet) => timesheet.selected)
    ) || (
      // The select-all-checkbox has not been pressed but all the rows has been loaded, manually
      // updated and are selected
      !this.hasMorePages()
      && this.props.updatedTimesheets.length === this.props.timesheets.length
      && this.props.updatedTimesheets.every((timesheet) => timesheet.selected)
    );
  }

  /**
   * Returns true if the select all checkbox is disabled
   * When the page is loading, the checkbox is disabled
   * When the search is active, the checkbox is disabled
   *
   * @returns {boolean}
   */
  isSelectAllDisabled() {
    return this.props.isWaiting || this.props.search !== '';
  }

  render() {
    return (
      <Table columns={6} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="enable-transfer"
              className="timesheets-transfer-hours__column-checkbox">
              <Checkbox name="enable-transfer"
                id="transfer-all-checkbox"
                value="transfer-all"
                checked={this.isSelectAllChecked()}
                disabled={this.isSelectAllDisabled()}
                indeterminate={this.isSelectAllPartiallyChecked()}
                label=""
                onChange={this.props.onSelectAllChange} />
            </TableCell>
            <TableCell name="date"
              className="timesheets-transfer-hours__column-date"
              onSort={this.sort.bind(this)}
              sort={this.getSort('date')}>
              Date
            </TableCell>
            <TableCell name="employeeFullName"
              className="timesheets-transfer-hours__column-employee"
              onSort={this.sort.bind(this)}
              sort={this.getSort('employeeFullName')}>
              Employee
            </TableCell>
            <TableCell name="jobOrder"
              className="timesheets-transfer-hours__column-job-order"
              onSort={this.sort.bind(this)}
              sort={this.getSort('jobOrder')}>
              Job Order
            </TableCell>
            <TableCell name="hours"
              className="timesheets-transfer-hours__column-hours"
              onSort={this.sort.bind(this)}
              sort={this.getSort('hours')}>
              Hours
            </TableCell>
            <TableCell name="transfer"
              className="timesheets-transfer-hours__column-transfer">
              Transfer
            </TableCell>
          </TableRow>
          <TableSummaryRow>
            <TableCell className="timesheets-transfer-hours__column-selected-hours">
              <Typography
                uppercase
                weight={Typography.WEIGHTS.BOLD}
                size={Typography.SIZES.PX12}
                color={Typography.COLORS.DUSTY_GRAY}>
                Selected Hours
              </Typography>
            </TableCell>
            <TableCell className="timesheets-transfer-hours__column-hours">
              <span aria-label="timesheet-selected-hours">{this.getSelectedHours()}</span>
            </TableCell>
            <TableCell className="timesheets-transfer-hours__column-transfer">
              <span aria-label="timesheet-transfer-hours">{this.getSelectedTransfer()}</span>
            </TableCell>
          </TableSummaryRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
