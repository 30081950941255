/**
 * This service offers functions that can be used by Models to connect to the server. Be aware that
 * all models MUST handle their server responses by using this service or some other similar logic,
 * don't assume that the server responses will be automatically managed.
 */

/**
 * Common success logic. Check for response code and resolve data, or redirect if something wrong.
 *
 * @param code
 * @param model
 * @param defer
 */
const onSuccess = function (code, model, defer) {
  switch (code) {
    case 402:
      Wethod.navigate('block', {
        trigger: true,
        replace: true,
      });
      break;
    case 403:
      Wethod.navigate('forbidden', {
        trigger: true,
        replace: true,
      });
      break;
    case 412:
      Wethod.setCookie('entrypoint', window.location.pathname + window.location.hash);
      Wethod.navigate(TEAMSELECTION, {
        trigger: true,
        replace: true,
      });
      break;
    default:
      defer.resolve(model);
      break;
  }
};

/**
 * Common error logic.
 *
 */
const onError = function () {
};

/**
 *  Offers common 'server communication' functions for Backbone Models (only for Model because it
 * uses Models function like this.fetch ecc...).
 *
 *  Use:
 *
 *  var HTTP = require('this file');
 *  var Model = Backbone.Model.extend({
 *      load: HTTP.load
 *  });
 *  var model = new Model();
 *  $.when(model.load(), function() { ... });
 */
const HTTPService = {

  /**
   * Fetches data from the servers giving custom 'success' and 'error' callbacks. It automatically
   * redirect the app when: the company is blocked, the request is forbidden or the user must
   * select the company.
   */
  load() {
    const defer = $.Deferred();

    this.fetch({
      success(model, response) {
        onSuccess(response.code, model, defer);
      },
      error(model, response) {
        onError(response.statusText, response.status);
      },
    });

    return defer.promise();
  },

  /**
   * Persist a Model and check the response with custom 'success' and 'error' callbacks.
   */
  persist() {
    const defer = $.Deferred();

    this.save(null, {
      success(model, response) {
        onSuccess(response.code, model, defer);
      },
      error(model, response) {
        onError(response.statusText, response.status);
      },
    });

    return defer.promise();
  },

  /**
   * Destroy a Model and check the response with custom 'success' and 'error' callbacks.
   */
  erase() {
    const defer = $.Deferred();

    this.destroy({
      success(model, response) {
        onSuccess(response.code, model, defer);
      },
      error(model, response) {
        onError(response.statusText, response.status);
      },
    });

    return defer.promise();
  },
};

module.exports = HTTPService;

/**
 * Returns a map of the query params contained in queryString or null if queryString is empty.
 * "+" sign in values is treated as blank space and encoded in "%20", values are then decoded using
 * decodeURIComponent.
 * Values separated by comma are decoded into array.
 *
 * @param string
 * @return {*}
 */
const getQueryParams = function (string) {
  string = string || '';
  const cleanQueryString = string.replace('?', '');
  if (cleanQueryString === '') {
    return {};
  }
  const list = cleanQueryString.split('&');
  const params = {};
  for (let i = 0; i < list.length; i++) {
    const tokens = list[i].split('=');
    // A valid param must be a key-value pair, where key and value are separated by "="
    if (tokens.length > 1) {
      const values = tokens[1]
        .split(',')
        .map((val) => val.replace(/\+/g, '%20'))
        .map((val) => decodeURIComponent(val));

      if (values.length === 1) {
        params[tokens[0]] = values[0];
      } else {
        params[tokens[0]] = values;
      }
    }
  }
  return params;
};

/**
 * Returns the param with the given key in string if present, null otherwise.
 * @param string
 * @param key
 * @return {null}
 */
module.exports.getQueryParamByKey = function (string = '', key) {
  const queryParams = getQueryParams(string);
  if (queryParams === null) {
    return null;
  }
  const param = queryParams[key];

  return param || null;
};

/**
 * Transforms the given parameters map into a query string ready to be appended to URL.
 *
 * @param parameters {{}} represents parameters to encode: a key for each filter, value can be
 * numeric or string
 * @returns {string} URL safe queryString
 */
module.exports.buildQueryString = function (parameters) {
  let queryString = '';

  Object.keys(parameters).forEach((key) => {
    let values = parameters[key];
    // Normalize value to array
    if (!Array.isArray(values)) {
      values = [values];
    }

    if (queryString !== '') {
      queryString += '&';
    }

    // We need to replace spaces with "+" to prevent Backbone.history.navigate re-route due to
    // misunderstanding of spaces in segment.
    // See https://stackoverflow.com/questions/36646005/prevent-backbone-history-navigate-from-decoding-url-fragment
    values = values.map((val) => encodeURIComponent(val)
      .replace(/%20/g, '+'));

    const encodedValue = values.join(',');
    const encodedKey = encodeURIComponent(key)
      .replace(/%20/g, '+');

    queryString += `${encodedKey}=${encodedValue}`;
  });

  return queryString;
};

module.exports.getQueryParams = getQueryParams;
