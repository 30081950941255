const React = require('react');
const InsightsSection = require('../../../../../../common/react/InsightsSection/components/InsightsSection.react');
const TimeDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const ProbabilityDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimension');
const ClientDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigClientDimension/ChartConfigClientDimension');
const RevenuesDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigRevenuesDimension/ChartConfigRevenuesDimension');
const AccountDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimension');
const ProjectTypeDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimension');
const JocDimension = require('../../../../../../common/react/Chart/Chart/models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimension');
const DimensionSelectItem = require('../../../../../../common/react/Chart/ChartSidebar/models/DimensionSelectItem');

const availableDimensions = [
  new DimensionSelectItem(RevenuesDimension.KEY, 'Revenues'),
  new DimensionSelectItem(ProbabilityDimension.KEY, 'Probability'),
  new DimensionSelectItem(ClientDimension.KEY, 'Client'),
  new DimensionSelectItem(TimeDimension.KEY, 'Time'),
  new DimensionSelectItem(AccountDimension.KEY, 'Account'),
  new DimensionSelectItem(ProjectTypeDimension.KEY, 'Project type'),
  new DimensionSelectItem(JocDimension.KEY, 'Job order category'),
];

const RevenuesInsights = () => (
  <InsightsSection title="Revenues" helperUrl="reports/index/#revenues" dataSource="revenues" availableDimensions={availableDimensions} />
);

module.exports = RevenuesInsights;
