const ChartConfig = require('./ChartConfig');

class Chart {
  /**
   * @param {number} id
   * @param {?number} parentId
   * @param {string} dataSource
   * @param {ChartConfig} config
   * @param isDefault
   */
  constructor(id, parentId, dataSource, config, isDefault) {
    this.id = id;
    this.parentId = parentId;
    this.dataSource = dataSource;
    this.config = config;
    this.isDefault = isDefault;
  }

  /**
   * @return {ChartConfigTable}
   */
  getTableConfig() {
    return this.config.table;
  }

  /**
   * Return attributes to serialize when calling JSON.stringify on this object.
   */
  toJSON() {
    return {
      id: this.id !== null ? this.id : undefined,
      parent_id: this.parentId,
      data_source: this.dataSource,
      config: this.config,
    };
  }

  /**
   * Return a copy of this instance.
   * @return {Chart}
   */
  clone() {
    return new Chart(this.id, this.parentId, this.dataSource, this.config.clone(), this.isDefault);
  }

  /**
   * @param data
   * @return {Chart}
   */
  static fromJSON(data) {
    return new Chart(data.id, data.parent_id, data.data_source, ChartConfig.fromJSON(data.config),
      data.is_default);
  }
}

Chart.DATA_SOURCE_REVENUES = 'revenues';
Chart.DATA_SOURCE_GROSS_MARGIN = 'gross-margin';

Chart.AVAILABLE_DATA_SOURCES = [
  Chart.DATA_SOURCE_REVENUES,
  Chart.DATA_SOURCE_GROSS_MARGIN,
];

module.exports = Chart;
