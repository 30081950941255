const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../../inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../inputs/Select/SelectItem.react');
const ChartConfigDimension = require('../Chart/models/ChartConfigDimension/ChartConfigDimension');
const InputValidator = require('../../InputValidator/InputValidator.react');
const DimensionSelectItem = require('./models/DimensionSelectItem');
const ChartConfigTimeDimension = require('../Chart/models/ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const ChartConfigProbabilityDimension = require('../Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimension');
const ChartConfigClientDimension = require('../Chart/models/ChartConfigDimension/ChartConfigClientDimension/ChartConfigClientDimension');
const ChartConfigRevenuesDimension = require('../Chart/models/ChartConfigDimension/ChartConfigRevenuesDimension/ChartConfigRevenuesDimension');
const ChartConfigAccountDimension = require('../Chart/models/ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimension');
const ChartConfigProjectTypeDimension = require('../Chart/models/ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimension');
const ChartConfigJocDimension = require('../Chart/models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimension');
const TimeOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionTimeOptions.react');
const ClientOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionClientOptions.react');
const AccountOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionAccountOptions.react');
const ProjectTypeOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionProjectTypeOptions.react');
const ProbabilityOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionProbabilityOptions.react');
const JocOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionJobOrderCategoryOptions.react');
const ChartConfigGrossMarginDimension = require('../Chart/models/ChartConfigDimension/ChartConfigGrossMarginDimension/ChartConfigGrossMarginDimension');

/**
 *
 * @param {string} axisLabel
 * @param {string} axisKey
 * @param {ChartConfigDimension} dimension
 * @param error
 * @param onChange
 * @param {DimensionSelectItem[]} availableDimensions
 * @return {JSX.Element}
 * @constructor
 */
const ChartSidebarDimension = ({
  axisLabel,
  axisKey,
  dimension,
  getError,
  onChange,
  availableDimensions,
  updateErrors,
  isDataSourceAxis,
}) => {
  function handleChange(e, item) {
    let updatedDimension = null;
    switch (item.value) {
      case ChartConfigTimeDimension.KEY:
        updatedDimension = new ChartConfigTimeDimension();
        break;
      case ChartConfigProbabilityDimension.KEY:
        updatedDimension = new ChartConfigProbabilityDimension();
        break;
      case ChartConfigClientDimension.KEY:
        updatedDimension = new ChartConfigClientDimension();
        break;
      case ChartConfigRevenuesDimension.KEY:
        updatedDimension = new ChartConfigRevenuesDimension();
        break;
      case ChartConfigAccountDimension.KEY:
        updatedDimension = new ChartConfigAccountDimension();
        break;
      case ChartConfigProjectTypeDimension.KEY:
        updatedDimension = new ChartConfigProjectTypeDimension();
        break;
      case ChartConfigJocDimension.KEY:
        updatedDimension = new ChartConfigJocDimension();
        break;
      case ChartConfigGrossMarginDimension.KEY:
        updatedDimension = new ChartConfigGrossMarginDimension();
        break;
      default:
        throw new Error(`'${item.value}' is not a valid chart config dimension key`);
    }
    onChange(axisKey, updatedDimension);
  }

  function handleOptionsValueChange(e) {
    const { value } = e.target;
    const updatedDimension = dimension.clone();
    updatedDimension.grouping.value = value;
    onChange(axisKey, updatedDimension);
  }

  function handleClusterOptionValuesChange(values) {
    const updatedDimension = dimension.clone();
    updatedDimension.grouping.values = values;
    onChange(axisKey, updatedDimension);
  }

  function handleOptionsTypeChange(grouping) {
    const updatedDimension = dimension.clone();
    updatedDimension.grouping = grouping;
    onChange(axisKey, updatedDimension);
  }

  const getItems = () => availableDimensions.map((availableDimension) => (
    <SelectItem key={availableDimension.key} value={availableDimension.key}>
      {availableDimension.label}
    </SelectItem>
  ));

  const getOptions = () => {
    if (dimension instanceof ChartConfigTimeDimension) {
      return <TimeOptions value={dimension.grouping.value} onChange={handleOptionsValueChange} />;
    }
    if (dimension instanceof ChartConfigProbabilityDimension) {
      return (
        <ProbabilityOptions
          getError={getError}
          updateErrors={updateErrors}
          onChangeType={handleOptionsTypeChange}
          type={dimension.grouping.type}
          onChangeValues={handleClusterOptionValuesChange}
          values={dimension.grouping.values} />
      );
    }
    if (dimension instanceof ChartConfigJocDimension) {
      return (
        <JocOptions
          getError={getError}
          updateErrors={updateErrors}
          onChangeType={handleOptionsTypeChange}
          type={dimension.grouping.type}
          onChangeValues={handleClusterOptionValuesChange}
          values={dimension.grouping.values} />
      );
    }
    if (dimension instanceof ChartConfigClientDimension) {
      return (
        <ClientOptions type={dimension.grouping.type}
          getError={getError}
          value={dimension.grouping.value}
          onChangeValue={handleOptionsValueChange}
          onChangeType={handleOptionsTypeChange}
          updateErrors={updateErrors} />
      );
    }
    if (dimension instanceof ChartConfigAccountDimension) {
      return (
        <AccountOptions type={dimension.grouping.type}
          getError={getError}
          value={dimension.grouping.value}
          onChangeValue={handleOptionsValueChange}
          onChangeType={handleOptionsTypeChange}
          updateErrors={updateErrors} />
      );
    }
    if (dimension instanceof ChartConfigProjectTypeDimension) {
      return (
        <ProjectTypeOptions type={dimension.grouping.type}
          getError={getError}
          value={dimension.grouping.value}
          onChangeValue={handleOptionsValueChange}
          onChangeType={handleOptionsTypeChange}
          updateErrors={updateErrors} />
      );
    }
    return null;
  };

  return (
    <div className="wethod-chart-sidebar__dimension">
      <InputValidator updateErrors={updateErrors}
        constraints={['required']}>
        <Select errorText={getError(axisKey)}
          data-testid={`${axisKey}-dimension-input`}
          readOnly={isDataSourceAxis}
          id={`${axisKey}-dimension`}
          className="wethod-chart-sidebar__dimension-select"
          onChange={handleChange}
          label={axisLabel}
          value={dimension ? dimension.key : ''}
          name={axisKey}>
          <SelectItem key="empty" value="" />
          {getItems()}
        </Select>
      </InputValidator>
      <div>
        {getOptions()}
      </div>
    </div>
  );
};

ChartSidebarDimension.defaultProps = {
  isDataSourceAxis: false,
  dimension: null,
};

ChartSidebarDimension.propTypes = {
  axisLabel: PropTypes.string.isRequired,
  axisKey: PropTypes.string.isRequired,
  getError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dimension: PropTypes.instanceOf(ChartConfigDimension),
  availableDimensions: PropTypes.arrayOf(PropTypes.instanceOf(DimensionSelectItem)).isRequired,
  updateErrors: PropTypes.func.isRequired,
  isDataSourceAxis: PropTypes.bool,
};

module.exports = ChartSidebarDimension;
