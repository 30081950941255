const { connect } = require('react-redux');
const Component = require('../components/Projects.react');

const mapStateToProps = (state) => ({
  projects: state.filteredProjects.filter((project) => project.probability > 0
    && project.probability < state.winLossProbabilityThreshold.winProbabilityThreshold),
  isWaiting: state.isWaitingForProjects,
  timeFilter: state.timeFilter,
});

module.exports = connect(mapStateToProps)(Component);
