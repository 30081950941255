const React = require('react');
const PropTypes = require('prop-types');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const ProbabilityDimensionDistinctGrouping = require('../../Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionDistinctGrouping');
const ClusterOption = require('./ChartSidebarDimensionClusterGroupingOption.react');
const ProbabilityDimensionClusterGrouping = require('../../Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionClusterGrouping');
const Options = require('./ChartSidebarDimensionOptions.react');
const GroupingValue = require('../../Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionGroupingValue');

const ChartSidebarDimensionProbabilityOptions = ({
  type,
  updateErrors,
  values,
  getError,
  onChangeValues,
  onChangeType,
}) => {
  const availableProbabilities = [0, 10, 25, 50, 75, 90, 100];

  function handleTypeChange(e) {
    const selectedType = e.target.value;
    switch (selectedType) {
      case ProbabilityDimensionClusterGrouping.TYPE:
        onChangeType(new ProbabilityDimensionClusterGrouping([]));
        break;
      case ProbabilityDimensionDistinctGrouping.TYPE:
        onChangeType(new ProbabilityDimensionDistinctGrouping());
        break;
      default:
        throw new Error(`${selectedType} is not a valid probability dimension type`);
    }
  }

  return (
    <Options>
      <RadioGroup name="probability-dimension-grouping" onChange={handleTypeChange} value={type}>
        <Radio value={ProbabilityDimensionDistinctGrouping.TYPE} label="Show all" />
        <ClusterOption value={ProbabilityDimensionClusterGrouping.TYPE}
          updateErrors={updateErrors}
          getError={getError}
          availableValues={availableProbabilities}
          clusters={values}
          dimensionLabel="Probability"
          onChange={onChangeValues} />
      </RadioGroup>
    </Options>
  );
};

ChartSidebarDimensionProbabilityOptions.defaultProps = {};

ChartSidebarDimensionProbabilityOptions.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.instanceOf(GroupingValue)).isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeValues: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionProbabilityOptions;
