/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key */
const React = require('react');

const PlanBlock = ({
  projects, project, zoom, selectedProject, first, top, bordersLeft,
  winProbabilityThreshold,
}) => {
  const type = projects[project.project_id].type
    ? projects[project.project_id].type : projects[project.project_id].job_order_category;
  const { color } = type;
  const showName = (zoom === 'hour' && first) || (zoom === 'day' && project.amount > 3 && bordersLeft.length === 1);
  const projectName = showName
    ? (
      <div className="planning-calendar__plan-block">
        {_.unescape(projects[project.project_id].name)}
      </div>
    ) : '';

  const getClassName = () => {
    let name = 'planning-calendar__hour';
    if (selectedProject && selectedProject.id !== project.project_id) {
      name += ' planning-calendar__hour--disabled';
    }
    return name;
  };

  const uniqueBorderLeftNeeded = () => bordersLeft.length === 1 && bordersLeft[0].height === 'all';

  const getBackground = () => {
    if (project.is_pending) {
      const lightColor = color.replace('rgb', 'rgba').replace(')', ',0.5)');
      const darkColor = color;

      return `repeating-linear-gradient( 45deg, ${lightColor}, ${lightColor} 2px, ${darkColor} 1px, ${darkColor} 6px )`;
    }
    return color;
  };

  const style = {
    background: getBackground(),
    height: `${project.amount * (54 / 8)}px`,
    position: 'absolute',
    top,
    left: 0,
    borderTop: top > 0 ? '1px solid white' : 'none',
    borderLeft: uniqueBorderLeftNeeded() ? '1px solid white' : 'none',
    opacity: projects[project.project_id].probability >= winProbabilityThreshold ? 1 : 0.6,
  };

  const getBordersMaker = () => bordersLeft.map((style, index) => {
    const validStyle = {
      top: `${top > 0 ? style.top - 1 : style.top}px`, // correction due to top border width
      height: `${style.height}px`,
      borderLeft: '1px solid white',
      position: 'absolute',
    };
    return (
      <div key={index}
        className="planning-calendar__plan-block-border-maker"
        style={validStyle} />
    );
  });

  return (
    <div className={getClassName()}
      style={style}>
      {projectName}
      {getBordersMaker()}
    </div>
  );
};

module.exports = PlanBlock;
