const React = require('react');
const CurrencyCell = require('./CurrencyCell.react');
const CostCell = require('./CostCell.react');
const Cell = require('./Cell.react');
const ShowIf = require('../../../../../../../common/react/ShowIf/ShowIf.react');

/**
 * Cell used in header rows (summary header, area header) to display external cost.
 * The right amount of space to keep currency select aligned is reserved if multicurrency enabled.
 * @param className
 * @param isCurrencyEnabled
 * @param value
 * @param previousValue
 * @return {JSX.Element}
 * @constructor
 */
const ExternalCostHeaderCell = ({ className, isCurrencyEnabled, value, previousValue = null, label = '' }) => (
  <Cell className={`wethod-budget-calculator__cell-external-cost ${className}`} label={label}>
    <CostCell previousValue={previousValue}
      className="wethod-budget-calculator__cell-external-cost-value">{value}
    </CostCell>
    <ShowIf condition={isCurrencyEnabled}>
      <CurrencyCell />
    </ShowIf>
  </Cell>
);

module.exports = ExternalCostHeaderCell;
