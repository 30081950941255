const React = require('react');
const PropTypes = require('prop-types');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const Cluster = require('./ChartSidebarDimensionCluster.react');
const Button = require('../../../Button/RoundedButton.react');
const GroupingValue = require('../../Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionGroupingValue');
const T = require('../../../Typography/Typography.react');

const ChartSidebarDimensionClusterGroupingOption = ({
  id, name, checked, clusters, onClick, value, availableValues, dimensionLabel, getError,
  updateErrors, onChange,
}) => {
  function onAddClusterClick() {
    const clusterIndex = clusters.length + 1;
    const clusterName = `Cluster ${clusterIndex}`;
    const cluster = new GroupingValue(clusterName, []);

    onChange(clusters.concat(cluster));
  }

  /**
   * @param {number} index Position of the cluster to delete
   */
  function onDeleteCluster(index) {
    const updatedClusters = clusters.filter((cluster, clusterIndex) => clusterIndex !== index);

    onChange(updatedClusters);
  }

  /**
   * @param {number} index Position of the cluster to update
   * @param {GroupingValue} updatedCluster
   */
  function onUpdateCluster(index, updatedCluster) {
    const updatedClusters = clusters.map((cluster, clusterIndex) => {
      if (clusterIndex === index) {
        return updatedCluster;
      }
      return cluster;
    });

    onChange(updatedClusters);
  }

  const getClusters = () => clusters.map((cluster, index) => {
    const clusterId = `${name}-cluster-${index}`;
    return (
      <Cluster key={clusterId}
        index={index}
        getError={getError}
        updateErrors={updateErrors}
        onUpdate={onUpdateCluster}
        onDelete={onDeleteCluster}
        dimensionLabel={dimensionLabel}
        values={cluster.values}
        name={cluster.name}
        availableValues={availableValues}
        id={clusterId} />
    );
  });

  const getAttributes = () => {
    if (checked) {
      return (
        <div className="wethod-chart-sidebar__dimension-option-attributes">
          <div>
            <Button onClick={onAddClusterClick}
              border={false}
              className="wethod-chart-sidebar__dimension-option-attribute-cluster-add-button">
              <T size={T.SIZES.PX14} weight={T.WEIGHTS.SEMIBOLD}>+ Add cluster</T>
            </Button>
          </div>
          <div>
            {getClusters()}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="wethod-chart-sidebar__dimension-option">
      <Radio id={id}
        name={name}
        checked={checked}
        onClick={onClick}
        value={value}
        label="Show all distinct values and cluster this values" />
      {getAttributes()}
    </div>
  );
};

ChartSidebarDimensionClusterGroupingOption.defaultProps = {
  id: '',
  name: '',
  checked: false,
  onClick: null,
};

ChartSidebarDimensionClusterGroupingOption.propTypes = {
  /**
   * List of cluster to show.
   */
  clusters: PropTypes.arrayOf(PropTypes.instanceOf(GroupingValue)).isRequired,
  /**
   * Function to call when clusters are updated.
   */
  onChange: PropTypes.func.isRequired,
  availableValues: PropTypes.arrayOf(PropTypes
    .oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  dimensionLabel: PropTypes.string.isRequired,
  /**
   * Radio ID.
   */
  id: PropTypes.string,
  /**
   * Radio button name.
   */
  name: PropTypes.string,
  /**
   * It says whether or no this radio button is checked.
   */
  checked: PropTypes.bool,
  /**
   * Function to call when radio is clicked.
   * @param event {{}}
   */
  onClick: PropTypes.func,
  /**
   * Function to call when an input is validated.
   */
  updateErrors: PropTypes.func.isRequired,
  /**
   * Dimension group type.
   */
  value: PropTypes.string.isRequired,
  /**
   * Function that returns errors related to given input name.
   */
  getError: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionClusterGroupingOption;
