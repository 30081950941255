const React = require('react');
const PropTypes = require('prop-types');
const CheckboxFilter = require('../../../../../../../common/react/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');
const JobOrderCategoryModel = require('../../../../../../../models/JobOrderCategory');

class JobOrderCategoryFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    JobOrderCategoryModel
      .list()
      .done((categories) => this.setState({ categories }));
  }

  /**
   * @return {{label: string, value: string}[]}
   */
  getAvailableOptions() {
    return this.state.categories.map((projectType) => ({
      label: projectType.name,
      value: projectType.name,
    }));
  }

  render() {
    return (
      <CheckboxFilter name={this.props.name}
        onChange={this.props.onChange}
        value={this.props.value}
        label={this.props.label}
        availableOptions={this.getAvailableOptions()} />
    );
  }
}

JobOrderCategoryFilter.defaultProps = {
  value: [],
};

JobOrderCategoryFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param values {array}
   */
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

module.exports = JobOrderCategoryFilter;
