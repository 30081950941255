const ChartConfigFilterProjectType = require('./ChartConfigFilterProjectType');
const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterProjectTypeIncluded extends ChartConfigFilterProjectType {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterProjectTypeIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterProjectTypeIncluded(data.values);
  }

  isValid() {
    return Array.isArray(this.values) && this.values.length > 0;
  }
}

module.exports = ChartConfigFilterProjectTypeIncluded;
