const React = require('react');

const ProjectSidebarDescription = ({ winProbabilityThreshold }) => (
  <span>
    A project is a won opportunity, and its probability is { winProbabilityThreshold }% or higher.{' '}
    Otherwise, create a new project opportunity from <a href="/#pipeline/opportunities">Pipeline Opportunities</a>
  </span>
);

module.exports = ProjectSidebarDescription;
