const React = require('react');
const Header = require('../SubsectionHeader.react');
// const Modal = require('../../containers/ModalController');
const Actions = require('../../containers/work-hour-capacity/WorkHourCapacityActions');
const List = require('../../containers/work-hour-capacity/TableWorkHourCapacity');

const WorkHourCapacity = () => (
  <div className="bottomSpace">
    <Header subtitle="Employee Capacity Groups"
      id="work-hour-capacity"
      description={
        'Define a capacity group for team members, specifying the maximum number of working hours for each day of the week.\n'
        + '8 hours of capacity correspond to 1 Full-Time Equivalent (FTE). '
        + 'Currently, this is the value used by wethod for calculations in both budget and project report.'
      } />
    <Actions />
    <List />
    {/* <Modal /> */}
  </div>
);

module.exports = WorkHourCapacity;
