const request = require('./Request');

const WinLossEmailAddress = {
  get() {
    return request({
      method: 'get',
      url: '/settings/pipeline/win-loss-email',
    });
  },
  updateWinEmail(email) {
    const data = {
      address: email,
    };
    return request({
      method: 'patch',
      url: '/settings/pipeline/win-email',
      data,
    });
  },
  updateLossEmail(email) {
    const data = {
      address: email,
    };
    return request({
      method: 'patch',
      url: '/settings/pipeline/loss-email',
      data,
    });
  },
};

module.exports = WinLossEmailAddress;
