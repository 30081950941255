const React = require('react');
const PropTypes = require('prop-types');
const TopNGrouping = require('../../Chart/models/ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimensionTopNGrouping');
const DistinctGrouping = require('../../Chart/models/ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimensionDistinctGrouping');
const Options = require('./ChartSidebarDimensionOptions.react');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const TopNOption = require('./ChartSidebarDimensionTopNGroupingOption.react');

const ChartSidebarDimensionProjectTypeOptions = ({
  type, value, onChangeType, onChangeValue,
  updateErrors, getError,
}) => {
  function handleTypeChange(e) {
    const selectedType = e.target.value;
    switch (selectedType) {
      case TopNGrouping.TYPE:
        onChangeType(new TopNGrouping(3));
        break;
      case DistinctGrouping.TYPE:
        onChangeType(new DistinctGrouping());
        break;
      default:
        throw new Error(`${value} is not a valid project type dimension type`);
    }
  }

  return (
    <Options>
      <RadioGroup name="project-type-dimension-grouping" onChange={handleTypeChange} value={type}>
        <Radio value={DistinctGrouping.TYPE} label="Show distinct values" />
        <TopNOption getError={getError}
          updateErrors={updateErrors}
          topNValue={value}
          value={TopNGrouping.TYPE}
          onChangeTopNValue={onChangeValue} />
      </RadioGroup>
    </Options>
  );
};

ChartSidebarDimensionProjectTypeOptions.defaultProps = {
  value: 3,
};

ChartSidebarDimensionProjectTypeOptions.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChangeType: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionProjectTypeOptions;
