const { connect } = require('react-redux');
const Component = require('../components/BusinessUnitFilter.react');
const actions = require('../actions');
const RequestService = require('../services/RequestService');

const mapStateToProps = (state) => ({
  type: state.type,
  search: state.search,
  businessUnits: state.buFilter,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
  isWaiting: RequestService.isWaitingForItems(state.waitingFor),
  filters: state.filters,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

const mapDispatchToProps = (dispatch) => ({
  getItems: (type, offset, limit, filters, search, orderBy, sort, bu,
    winProbabilityThreshold) => dispatch(actions
    .getItems(type, offset, limit, filters, search, orderBy, sort, bu, winProbabilityThreshold)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
