const React = require('react');
const PropTypes = require('prop-types');
const IconLink = require('../../TooltipFixed/IconLinkTooltip.react');
const Icon = require('../../Icon/Icon.react');

const PipelineLink = ({ projectId }) => (
  <IconLink label="Pipeline"
    borderless
    href={`#pipeline/projects?id=${projectId}`}
    target="_blank">
    <Icon icon="pipeline" />
  </IconLink>
);

PipelineLink.propTypes = {
  projectId: PropTypes.number.isRequired,
};

module.exports = PipelineLink;
