const React = require('react');
const TableCell = require('../../../../../../../common/react/Table2/TableCell.react');
const NumericInput = require('../../../../../../../common/react/NumericInput.react');

module.exports = class ProjectHoursCell extends React.Component {
  handleInputBlur(value) {
    const formattedValue = parseFloat(value) || 0;
    this.props.updateHours(formattedValue, this.props.name);
  }

  handleInputChange(name, value) {
    const formattedValue = parseFloat(value) || 0;
    this.props.onChange(name, formattedValue);
  }

  getCellStyle() {
    let style = 'timesheet-weekly__cell-hours ';
    if (this.props.className) style += this.props.className;
    if (this.props.disabled) style += ' disabled';
    if (this.props.isHoliday) style += ' timesheet-weekly__cell-hours--holiday';
    return style;
  }

  getContent() {
    if (this.props.editable) {
      return (
        <div className="timesheet-weekly__cell-total--content" data-testid="timesheet-weekly-cell">
          <NumericInput
            value={this.props.value}
            name={this.props.name}
            ref={(input) => {
              this.input = input;
            }}
            formatOptions={{ numeralDecimalScale: 2 }}
            onChange={this.handleInputChange.bind(this)}
            onBlur={this.handleInputBlur.bind(this)}
            disabled={this.props.isWaiting}
            hideZero />
          <div
            className="timesheet-weekly__feedback timesheet-weekly__cell-total--feedback">
            {this.props.feedback}
          </div>
        </div>
      );
    }
    if (this.props.feedback) {
      return (
        <div className="timesheet-weekly__feedback">{this.props.feedback}</div>
      );
    }
    if (this.props.reasonCannotEdit === 'lines' && !this.props.totalHours) {
      return <p>--</p>;
    }
    return (
      <div data-testid="timesheet-weekly-cell">
        <div>{this.props.value ? this.props.value : '--'}</div>
      </div>
    );
  }

  getTooltipMessage() {
    return !this.props.isInWhitelist ? 'You need to be a whitelisted team member to update this timesheet.' : '';
  }

  render() {
    return (
      <TableCell className={this.getCellStyle()}>
        {this.getContent()}
      </TableCell>
    );
  }
};
