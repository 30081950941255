const React = require('react');
const PropTypes = require('prop-types');
const FormatService = require('../../../services/FormatService');

const NumberFormatter = ({
  children,
  explicitSign,
  className,
  hideZero,
  precision,
  minPrecision,
}) => {
  if (Number.isNaN(children)) {
    throw new TypeError('children must be number');
  }

  let formattedNumber = FormatService
    .formatDecimalNumber(children, explicitSign, precision, minPrecision);

  if (children === 0 && hideZero) {
    formattedNumber = '';
  }

  return (
    <span className={className}>{formattedNumber}</span>
  );
};

NumberFormatter.defaultProps = {
  className: '',
  explicitSign: false,
  hideZero: false,
  precision: 0,
  minPrecision: 0,
};

NumberFormatter.propTypes = {
  /**
   * The number to format.
   */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  explicitSign: PropTypes.bool,
  /**
   * Display empty when value is 0.
   */
  hideZero: PropTypes.bool,
  precision: PropTypes.number,
  minPrecision: PropTypes.number,
};

module.exports = NumberFormatter;
