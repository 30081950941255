/**
 * @abstract
 */
class ChartConfigFilter {
  /**
   * @param {string} type
   * @param {string} target
   */
  constructor(target, type) {
    this.setType(type);
    this.target = target;
  }

  /**
   * @param {string} type
   */
  setType(type) {
    const isTypeAvailable = ChartConfigFilter.AVAILABLE_TYPES.includes(type);
    if (!isTypeAvailable) {
      throw new Error(`'${type}' is not valid as ChartConfigFilter type.`);
    }
    this.type = type;
  }

  /**
   * Return true if filter is valid.
   * @abstract
   * @return boolean
   */
  // eslint-disable-next-line class-methods-use-this
  isValid() {
    throw new Error('isValid() must be implemented by the subclass');
  }

  /**
   * Build and return filter based on given data.
   * @abstract
   * @param data
   * @return {ChartConfigFilter}
   */
  // eslint-disable-next-line no-unused-vars
  static fromJSON(data) {
    throw new Error('fromJSON() must be implemented by the subclass');
  }
}

ChartConfigFilter.TYPE_IN = 'in';
ChartConfigFilter.TYPE_NOT_IN = 'not-in';
ChartConfigFilter.TYPE_RANGE = 'range';
ChartConfigFilter.TYPE_RELATIVE = 'relative';

ChartConfigFilter.AVAILABLE_TYPES = [
  ChartConfigFilter.TYPE_IN,
  ChartConfigFilter.TYPE_NOT_IN,
  ChartConfigFilter.TYPE_RANGE,
  ChartConfigFilter.TYPE_RELATIVE,
];

module.exports = ChartConfigFilter;
