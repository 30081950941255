const React = require('react');
const PropTypes = require('prop-types');
const TableCell = require('../../Table2/TableCell.react');

const InsightLevelTableViewMoneyCell = ({ children, className, ...rest }) => {
  const actualClassName = `${className} wethod-table__cell--money`;

  return (
    <TableCell className={actualClassName} {...rest}>
      {children}
    </TableCell>
  );
};

InsightLevelTableViewMoneyCell.defaultProps = {
  children: '',
  className: null,
};

InsightLevelTableViewMoneyCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

module.exports = InsightLevelTableViewMoneyCell;
