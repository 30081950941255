const React = require('react');
const PropTypes = require('prop-types');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const TimeRangeFilter = require('../../Chart/models/ChartConfigFilter/ChartConfigFilterTimeRange');

const ChartSidebarTimeRangeFilterRelativeOption = ({
  id, name, onClick, checked, value, label,
}) => {
  function handleClick() {
    onClick(TimeRangeFilter.TYPE_RELATIVE, value);
  }

  return (
    <Radio id={id}
      name={name}
      checked={checked}
      onClick={handleClick}
      label={label}
      value={value} />
  );
};

ChartSidebarTimeRangeFilterRelativeOption.defaultProps = {
  name: undefined,
  checked: false,
  onClick: null,
  id: null,
};

ChartSidebarTimeRangeFilterRelativeOption.propTypes = {
  id: PropTypes.string,
  /**
   * Used to group radio buttons. All buttons in the same group must have the same name.
   */
  name: PropTypes.string,
  checked: PropTypes.bool,
  /**
   * Function to call when radio is clicked.
   * @param event {{}}
   */
  onClick: PropTypes.func,
  /**
   * The time filter id.
   */
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

module.exports = ChartSidebarTimeRangeFilterRelativeOption;
