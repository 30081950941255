const { connect } = require('react-redux');
const Component = require('../components/PlanBlock.react');

const mapStateToProps = (state) => ({
  projects: state.planned_projects,
  zoom: state.zoom,
  selectedProject: state.selected_project,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

module.exports = connect(mapStateToProps)(Component);
