const React = require('react');
const ForProjects = require('../../containers/ForFocusedProject');
const ForOpportunities = require('../../containers/ForFocusedOpportunity');
const OpportunityDescription = require('../opportunities/sidebar/OpportunitySidebarDescription.react');
const ProjectDescription = require('../projects/sidebar/ProjectSidebarDescription.react');

const ItemDescription = ({ creation, winProbabilityThreshold }) => (creation ? (
  <div className="pipeline__sidebar-description pipeline__sidebar-description--spaced">
    <ForProjects>
      <ProjectDescription creation={creation} winProbabilityThreshold={winProbabilityThreshold} />
    </ForProjects>
    <ForOpportunities>
      <OpportunityDescription creation={creation}
        winProbabilityThreshold={winProbabilityThreshold} />
    </ForOpportunities>
  </div>
) : null);

module.exports = ItemDescription;
