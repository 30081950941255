const React = require('react');
const PropTypes = require('prop-types');
const TimeRelativeFilter = require('../../Chart/models/ChartConfigFilter/ChartConfigFilterTimeRelative');
const TimeRangeFilter = require('../../Chart/models/ChartConfigFilter/ChartConfigFilterTimeRange');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Accordion = require('../../../AdvancedSearch/FilterAccordion/FilterAccordion.react');
const AbsoluteOption = require('./ChartSidebarTimeRangeFilterAbsoluteOption.react');
const RelativeOption = require('./ChartSidebarTimeFilterRangeRelativeOption.react');

const ChartSidebarTimeRangeFilter = ({
  name,
  onChange,
  range,
  label,
  type,
}) => {
  const formattedType = type || '';

  function handleChange(newType, newValue) {
    onChange(name, newValue, newType);
  }

  return (
    <Accordion label={label} value={formattedType}>
      <RadioGroup name={name} onChange={handleChange} value={formattedType}>
        <RelativeOption key={TimeRelativeFilter.VALUE_YEAR_TO_DATE}
          label="Year to date"
          value={TimeRelativeFilter.VALUE_YEAR_TO_DATE} />
        <RelativeOption key={TimeRelativeFilter.VALUE_THIS_YEAR}
          label="This year"
          value={TimeRelativeFilter.VALUE_THIS_YEAR} />
        <RelativeOption key={TimeRelativeFilter.VALUE_LAST_YEAR}
          label="Last year"
          value={TimeRelativeFilter.VALUE_LAST_YEAR} />
        <RelativeOption key={TimeRelativeFilter.VALUE_LAST_3_YEARS}
          label="Last 3 years"
          value={TimeRelativeFilter.VALUE_LAST_3_YEARS} />
        <RelativeOption key={TimeRelativeFilter.VALUE_LAST_5_YEARS}
          label="Last 5 years"
          value={TimeRelativeFilter.VALUE_LAST_5_YEARS} />
        <AbsoluteOption key={TimeRangeFilter.TYPE_RANGE}
          onChange={handleChange}
          value={TimeRangeFilter.TYPE_RANGE}
          range={range} />
      </RadioGroup>
    </Accordion>
  );
};

ChartSidebarTimeRangeFilter.defaultProps = {
  type: '',
  range: {
    min: new Date(),
    max: new Date(),
  },
};

ChartSidebarTimeRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  range: PropTypes.shape({
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
  }),
};

module.exports = ChartSidebarTimeRangeFilter;
