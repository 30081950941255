const ClientDimension = require('./ChartConfigDimension/ChartConfigClientDimension/ChartConfigClientDimension');
const ProbabilityDimension = require('./ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimension');
const RevenuesDimension = require('./ChartConfigDimension/ChartConfigRevenuesDimension/ChartConfigRevenuesDimension');
const TimeDimension = require('./ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const AccountDimension = require('./ChartConfigDimension/ChartConfigAccountDimension/ChartConfigAccountDimension');
const ProjectTypeDimension = require('./ChartConfigDimension/ChartConfigProjectTypeDimension/ChartConfigProjectTypeDimension');
const JocDimension = require('./ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimension');
const GrossMarginDimension = require('./ChartConfigDimension/ChartConfigGrossMarginDimension/ChartConfigGrossMarginDimension');

/**
 * @abstract
 * @type {ChartConfigDimensions}
 */
module.exports = class ChartConfigDimensions {
  /**
   * Return attributes to serialize when calling JSON.stringify on this object.
   * @abstract
   * @return {*}
   */
  // eslint-disable-next-line class-methods-use-this
  toJSON() {
    throw new Error('toJSON method must be implemented by class extending ChartConfigDimensions');
  }

  /**
   * @param data
   * @return {ChartConfigDimension}
   */
  static dimensionFromJSON(data) {
    switch (data.key) {
      case ClientDimension.KEY:
        return ClientDimension.fromJSON(data);
      case ProbabilityDimension.KEY:
        return ProbabilityDimension.fromJSON(data);
      case RevenuesDimension.KEY:
        return RevenuesDimension.fromJSON();
      case TimeDimension.KEY:
        return TimeDimension.fromJSON(data);
      case AccountDimension.KEY:
        return AccountDimension.fromJSON(data);
      case ProjectTypeDimension.KEY:
        return ProjectTypeDimension.fromJSON(data);
      case JocDimension.KEY:
        return JocDimension.fromJSON(data);
      case GrossMarginDimension.KEY:
        return GrossMarginDimension.fromJSON();
      default:
        throw new Error(`'${data.key}' is not a valid chart dimension key`);
    }
  }
};
