const React = require('react');
const BuFilter = require('../../../../../common/react/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');

const BusinessUnitFilter = ({
  businessUnits,
  getItems,
  isWaiting,
  pageLimit,
  filters,
  sortBy,
  type,
  search,
  winProbabilityThreshold,
}) => {
  function onChange(name, units) {
    if (!isWaiting) {
      getItems(type, 0, pageLimit, filters, search, sortBy.col, sortBy.order, units,
        winProbabilityThreshold);
    }
  }

  return (
    <div className="pipeline__bu-select">
      <BuFilter onApply={onChange} selectedValues={businessUnits} />
    </div>
  );
};

module.exports = BusinessUnitFilter;
