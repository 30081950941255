const React = require('react');
const MoneyCell = require('./MoneyCell.react');
const CurrencyCell = require('./CurrencyCell.react');
const Cell = require('./Cell.react');
const Select = require('../../CurrencySelect.react');
const CurrencyManager = require('../../../containers/CurrencyManager');
const NumericField = require('../../../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const Delta = require('../NumericDelta.react');
const FormatService = require('../../../../../../../services/FormatService');
const CollaborativeInput = require('../../../containers/BudgetCollaborativeInput');

/**
 * Cell used to display external cost in rows that allow input (i.e. task rows).
 * It's able to display:
 * - cost input
 * - currency select if multicurrency is enabled
 * - cost value in master currency below cost input if multicurrency is enabled
 * - difference with previous budget version if previousValue is present
 *
 * @param isCurrencyEnabled
 * @param value
 * @param editable
 * @param save
 * @param masterCurrency
 * @param className
 * @param currencyCode
 * @param previousValue
 * @return {JSX.Element}
 * @constructor
 */
const ExternalCostCell = ({
  isCurrencyEnabled = false, value, editable, save = null, label,
  getAmountInMasterCurrency = null, id,
  getAmountInCurrency = null, className = '',
  currencyCode = null, previousValue = null,
}) => {
  function onValueChange(e) {
    let numericValue = parseFloat(e.target.value);
    numericValue = !Number.isNaN(numericValue) ? numericValue : 0;
    const masterCurrencyValue = isCurrencyEnabled
      ? getAmountInMasterCurrency(numericValue, currencyCode) : numericValue;

    save(masterCurrencyValue, currencyCode);
  }

  function onCurrencyChange(e) {
    const code = e.target.value;
    const currencyValue = getAmountInCurrency(value, currencyCode);
    const masterCurrencyValue = getAmountInMasterCurrency(currencyValue, code);
    save(masterCurrencyValue, code);
  }

  /**
   * Return the correct value to display in numeric field: value in master currency if
   * multicurrency disable, value in cost currency otherwise.
   */
  const getValueToDisplay = () => {
    if (isCurrencyEnabled) {
      return getAmountInCurrency(value, currencyCode);
    }
    return value;
  };
  const getValueForDelta = () => {
    if (isCurrencyEnabled) {
      return getAmountInCurrency(value, currencyCode);
    }
    return value;
  };

  const getHelperText = () => {
    if (isCurrencyEnabled) {
      return FormatService.formatDecimalNumber(value, false, 2, 2);
    }
    return '';
  };

  const getPreviousValue = () => (isCurrencyEnabled
    ? getAmountInCurrency(previousValue, currencyCode) : previousValue);

  const currencySelect = !isCurrencyEnabled ? null : (
    <CurrencyCell>
      <CurrencyManager>
        <Select onChange={onCurrencyChange}
          label={`${label} currency select`}
          code={currencyCode}
          editable={editable} />
      </CurrencyManager>
    </CurrencyCell>
  );

  return (
    <Cell className={`wethod-budget-calculator__cell-external-cost ${className}`}>
      <MoneyCell previousValue={previousValue}
        className="wethod-budget-calculator__cell-external-cost-value">
        <Delta actualValue={getValueForDelta()}
          label={`${label} delta`}
          previousValue={getPreviousValue()} />
        <CollaborativeInput id={id}>
          <NumericField
            delay={2000}
            onChange={onValueChange}
            label={label}
            value={getValueToDisplay()}
            readOnly={!editable}
            helperText={getHelperText()}
            minPrecision={2}
            precision={2}
            hideZero />
        </CollaborativeInput>
      </MoneyCell>
      {currencySelect}
    </Cell>
  );
};

module.exports = ExternalCostCell;
