module.exports = class ChartConfigTableColumn {
  /**
   * @param {string} key Key to use to find the related item attribute
   * @param {string} label
   * @param {string} sortKey Key to use for sorting by this column
   */
  constructor(key, label, sortKey) {
    this.key = key;
    this.label = label;
    this.sortKey = sortKey;
  }

  /**
   * @param data
   * @return {ChartConfigTableColumn}
   */
  static fromJSON(data) {
    const columnNames = {
      client: 'Client',
      name: 'Project',
      job_order: 'Job Order',
      probability: 'Probability',
      time: 'Time',
      revenues: 'Revenues',
      project_type: 'Project Type',
      account: 'Account',
      job_order_category: 'Job Order Category',
      gross_margin: 'Gross Margin',
    };

    return new ChartConfigTableColumn(data.key, columnNames[data.key], data.sort_key);
  }
};
