'use strict';

Wethod.module('TimesheetApp.Timesheet', function (Timesheet, Wethod, Backbone, Marionette, $) {
  this.ExportModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      var monthFilter = this.options.month ? '&month=' + this.options.month : '';
      return APIURL + '/export/entity?select=' + this.options.module + '&download=false' + monthFilter;
    },
  });

  this.ExportTimesheetCostModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/export/timetracking-cost/';
    },
  });

  this.ExportMonthModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/export/' + this.options.section + '?month=' + this.options.date;
    },
  });

  var API = {
    getTimesheetExport: function (options) {
      var record = new Timesheet.ExportModel({ module: 'timesheet', month: options.month || null });
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.reject(response.message);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
    get3ypProb: function () {
      var record = new Timesheet.ExportModel({ module: 'revenue_pipeline' });
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    getTimesheetValueExport: function (projectId) {
      var record = new Timesheet.ExportTimesheetCostModel({ id: projectId });
      var defer = $.Deferred();

      record.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.resolve(undefined);
          }
        },
        error: function () {
          defer.resolve(undefined);
        },
      });

      return defer.promise();
    },
    getMonthExport: function (section, date) {
      var model = new Timesheet.ExportMonthModel({ section: section, date: date });
      var defer = $.Deferred();

      model.fetch({
        success: function (data, response) {
          if (response.code === 200) {
            defer.resolve(data);
          } else {
            defer.reject(response.message);
          }
        },
        error: function () {
          defer.reject(undefined);
        },
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:timesheet:export', function (options) {
    return API.getTimesheetExport(options);
  });

  Wethod.reqres.setHandler('get:3yp:prob:export', function () {
    return API.get3ypProb();
  });

  Wethod.reqres.setHandler('get:timesheet:cost:export', function (projectId) {
    return API.getTimesheetValueExport(projectId);
  });

  // PAYSLIPS export
  Wethod.reqres.setHandler('get:timesheet:payslips:export', function (date) {
    return API.getMonthExport('payslips', date);
  });

  // MEAL VOUCHERS export
  Wethod.reqres.setHandler('get:timesheet:meal-vouchers:export', function (date) {
    return API.getMonthExport('meal-vouchers', date);
  });
});
