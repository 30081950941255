const React = require('react');
const $ = require('jquery');
const isEqual = require('react-fast-compare');
const Table = require('../../../../../common/react/Table2/Table2.react');
const TableBody = require('../../../../../common/react/Table2/TableBody.react');
const Header = require('../containers/ItemsHeader');
const Search = require('../containers/ItemsSearch');
const ItemWrapper = require('./ItemWrapper.react');

module.exports = class Items extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.pipeline__actions')
      .outerHeight(true) + $('.section-header').outerHeight(true) + 24);
  }

  constructor(props) {
    super(props);

    this.state = { tableHeight: null };

    this.loadMore = this.loadMore.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  componentDidUpdate(prevProps) {
    const filtersChanged = !isEqual(prevProps.filters, this.props.filters);
    if (filtersChanged) {
      this.props.getItems(this.props.type, 0, this.props.pageLimit, this.props.filters,
        this.props.search, this.props.sortBy.col, this.props.sortBy.order, this.props.bu,
        this.props.winProbabilityThreshold);
    }
  }

  onFilter(search) {
    this.props.getItems(this.props.type, 0, this.props.pageLimit, this.props.filters, search,
      this.props.sortBy.col, this.props.sortBy.order, this.props.bu,
      this.props.winProbabilityThreshold);
  }

  onSort(col, order) {
    if (!this.props.isWaiting) {
      this.props.getItems(this.props.type, 0, this.props.pageLimit, this.props.filters,
        this.props.search, col, order, this.props.bu, this.props.winProbabilityThreshold);
    }
  }

  getRows() {
    const metadataNameOverrides = {
      id_project_label: 'project-type',
      id_project_type: 'job-order-category',
      id_job_order: 'job-order',
      client_po: 'po',
      external_cost: 'costs',
      risk: 'risk-select',
      id_client: 'client',
      id_customer: 'customer',
      id_pm: 'pm',
      id_account: 'account',
    };

    return this.props.items.map((item) => (
      <ItemWrapper key={item.project.id}
        item={item}
        showSidebar={this.props.showSidebar}
        nameOverrides={metadataNameOverrides} />
    ));
  }

  updateTableHeight() {
    this.setState({ tableHeight: Items.getTableMaxHeight() });
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const offset = (page - 1) * size;
      this.props.getItems(this.props.type, offset, size, this.props.filters, this.props.search,
        this.props.sortBy.col, this.props.sortBy.order, this.props.bu,
        this.props.winProbabilityThreshold);
    }
  }

  render() {
    return (
      <div ref={(ref) => this.containerRef = ref}>
        <Table visibleColumns={this.props.visibleColumns}
          maxHeight={this.state.tableHeight}
          search={<Search filter={this.onFilter} />}>
          <Header sortBy={this.props.sortBy}
            onSort={this.onSort}
            editableColumns={this.props.editableColumns} />
          <TableBody hasMore={this.props.hasMore} loadMore={this.loadMore} editable>
            {this.getRows()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
