/* eslint-disable eqeqeq */

'use strict';

var React = require('react');
var ReactDOM = require('react-dom');

var BusinessUnitService = require('../../../services/BusinessUnitService');
var Timesheet = require('../../../apps/core/modules/settings/company/TimesheetSettingsIndex');
var JobOrderTemplates = require('../../../apps/core/modules/settings/company/JobOrderTemplatesIndex');
var OrderPlanSettings = require('../../../apps/core/modules/settings/company/OrderPlanSettingsIndex');
var EmployeeTags = require('../../../apps/core/modules/settings/company/EmployeeTagsIndex');
var CompanyHoliday = require('../../../apps/core/modules/settings/company/CompanyHolidayIndex');
var JobTitles = require('../../../apps/core/modules/settings/company/JobTitleIndex');
var FiscalYear = require('../../../apps/core/modules/settings/company/FiscalYearIndex');
var WorkHourCapacity = require('../../../apps/core/modules/settings/company/WorkHourCapacityIndex');
var BackboneService = require('../../../services/BackboneService');
var Location = require('../../../apps/core/modules/settings/company/LocationIndex');
var FeatureService = require('../../../services/FeatureService');
var WonLostBot = require('../../../apps/core/modules/settings/company/WonLostBotIndex');

Wethod.module('SettingsApp.Company', function (Company, Wethod, Backbone, Marionette, $, _) {
  // GENERAL LAYOUT
  this.CompanyLayout = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    className: 'fluid',
    template: '#companyStructure',
    regions: {
      header: '[data-region="companyHeaderLayout"]',
      contacts: '[data-region="companyContactLayout"]',
      levels: '[data-region="companyLevelsLayout"]',
      projectTypes: '[data-region="companyPTLayout"]',
      projectTypeGroups: '[data-region="companyPTGroupsLayout"]',
      projectLabels: '[data-region="companyProjectLabelsLayout"]',
      timesheet: '[data-region="companyTimesheet"]',
      pipelineOpportunities: '[data-region="companyOpportunities"]',
      projectStatus: '[data-region="companyProjectStatus"]',
      planning: '[data-region="companyPlanning"]',
      pipelineRevenue: '[data-region="companyRevenuePipeline"]',
      projectReview: '[data-region="companyProjectReview"]',
      multicurrency: '[data-region="companyMulticurrency"]',
      modal: '[data-region="companyModal"]',
      metadata: '[data-region="companyMetadataLayout"]',
      pipeline: '[data-region="companyPipeline"]',
      supplier: '[data-region="companySupplier"]',
      expense: '[data-region="companyExpense"]',
      feedback: '[data-region="companyWonLostFeedbackLayout"]',
      businessUnit: '[data-region="companyBusinessUnit"]',
      bankAccount: '[data-region="companyBankAccount"]',
      vatRate: '[data-region="companyVatRate"]',
      paymentTerm: '[data-region="companyPaymentTerm"]',
      googleDriveIntegration: '[data-region="googleDriveIntegration"]',
      jobOrderTemplate: '[data-region="companyJobOrderTemplate"]',
      orderPlanSettings: '[data-region="companyOrderPlanSettingsTemplate"]',
      jobTitleSettings: '[data-region="companyJobTitleSettings"]',
      fiscalYearSettings: '[data-region="companyFiscalYearSettings"]',
      workHourCapacitySettings: '[data-region="companyWorkHourCapacitySettings"]',
      employeeTagsSettings: '[data-region="companyEmployeeTagsSettings"]',
      locationSettings: '[data-region="companyLocationSettings"]',
      companyHolidaySettings: '[data-region="companyHolidaySettings"]',
      wonLostBotSettings: '[data-region="companyWonLostBotSettings"]',
    },
    childEvents: {
      'company:currency:warning-enable-multicurrency': 'onWarningEnableMulticurrency',
      'company:currency:cannot-disable': 'onCannotDisableCurrency',
      'company:currency:cannot-promote': 'onCannotPromoteCurrency',
    },
    showWarningEnableMulticurrencyModal: function () {
      var modal = new Company.LevelModal({
        title: 'Warning',
        text: 'Once you enable multicurrency for your company, you will not be able to go back. Do you want to continue?',
        buttonKoText: 'Cancel',
        buttonOkText: 'Enable',
      });
      var ModalView = new Company.MulticurrencyModalItemView({
        model: modal,
      });
      this.modal.show(ModalView);
    },
    onWarningEnableMulticurrency: function () {
      this.showWarningEnableMulticurrencyModal();
    },
    showCannotDisableCurrencyModal: function () {
      var modal = new Company.LevelModal({
        title: 'Cannot disable currency',
        text: 'You cannot disable this currency. Usually this happens because you are trying to disable a currency which is used in one or more of your budgets.',
        buttonKoText: 'Cancel',
        buttonOkText: 'Ok',
      });
      var ModalView = new Company.LevelModalItemView({
        model: modal,
      });
      this.modal.show(ModalView);
    },
    onCannotDisableCurrency: function () {
      this.showCannotDisableCurrencyModal();
    },
    showCannotPromoteCurrencyModal: function () {
      var modal = new Company.LevelModal({
        title: 'Cannot set as master',
        text: 'In order to set this currency as master you have to enable it.',
        buttonKoText: 'Cancel',
        buttonOkText: 'Ok',
      });
      var ModalView = new Company.LevelModalItemView({
        model: modal,
      });
      this.modal.show(ModalView);
    },
    onCannotPromoteCurrency: function () {
      this.showCannotPromoteCurrencyModal();
    },
    initialize: function () {
      dispatcher.on('company:metadata:modal:show', this.showMetadataModal, this);
    },
    onDestroy: function () {
      dispatcher.off('company:metadata:modal:show');
    },
    showMetadataModal: function (modal, metadataView) {
      var modalView = new Company.MetadataConfirmDeleteModalView({
        model: modal,
        metadataView: metadataView,
      });
      this.modal.show(modalView);
    },
  });

  // CONTACT
  this.ContactListView = Marionette.LayoutView.extend({
    template: '#companyContactListTemplate',
    saveRequestName: null,
    deleteRequestName: null,
    regions: {
      secondaryContacts: '[data-region="secondaryContactList"]',
    },
    ui: {
      emailInput: '[data-action="email"]',
      aliasInput: '[data-action="alias"]',
      feedback: '[data-action="feedback"]',
      addButton: '[data-action="add"]',
      deleteButton: '[data-action="delete"]',
    },
    events: {
      'blur @ui.emailInput': 'update',
      'blur @ui.aliasInput': 'update',
      'click @ui.addButton': 'addContact',
      'click @ui.deleteButton': 'delete',
    },
    templateHelpers: null,
    ModelInstance: null,
    initialize: function (attributes) {
      var contacts = attributes.contacts;
      this.saveRequestName = attributes.saveRequestName;
      this.deleteRequestName = attributes.deleteRequestName;
      this.ModelInstance = attributes.ModelInstance;
      var main = contacts.length ? contacts.shift().attributes : {};
      this.updateModel(main);
      this.secondary = contacts;
      this.templateHelpers = {
        description: function () {
          return attributes.description;
        },
      };
    },
    updateModel: function (attributes) {
      this.model = new this.ModelInstance(attributes);
      this.model.on('change:id', this.onCreated.bind(this));
    },
    onCreated: function () {
      this.render();
    },
    hideActions: function () {
      this.ui.addButton.addClass('hidden');
      this.ui.deleteButton.addClass('hidden');
    },
    showActions: function () {
      this.ui.addButton.removeClass('hidden');
      this.ui.deleteButton.removeClass('hidden');
    },
    manageActions: function () {
      if (this.model.isNew()) {
        this.hideActions();
      } else {
        this.showActions();
      }
    },
    onRender: function () {
      this.manageActions();
      this.showSecondaryList();
    },
    showSecondaryList: function () {
      var view = new Company.SecondaryContactList({
        collection: this.secondary,
        saveRequestName: this.saveRequestName,
        deleteRequestName: this.deleteRequestName,
      });
      this.secondaryContacts.show(view);
    },
    update: function (e) {
      if (!this.isDisabled($(e.target))) {
        this.model.set(e.target.name, e.target.value);
        this.save();
      }
    },
    showError: function (message) {
      this.ui.feedback.text(message);
      this.ui.feedback.addClass('settings-feedback--error');
    },
    showFeedback: function (message) {
      this.ui.feedback.text(message);
      this.ui.feedback.removeClass('settings-feedback--error');
    },
    hideFeedback: function () {
      this.ui.feedback.text('');
      this.ui.feedback.removeClass('settings-feedback--error');
    },
    disableInput: function () {
      this.ui.emailInput.prop('disabled', true);
      this.ui.aliasInput.prop('disabled', true);

      this.ui.emailInput.addClass('disabled');
      this.ui.aliasInput.addClass('disabled');
    },
    enableInput: function () {
      this.ui.emailInput.prop('disabled', false);
      this.ui.aliasInput.prop('disabled', false);

      this.ui.emailInput.removeClass('disabled');
      this.ui.aliasInput.removeClass('disabled');
    },
    isDisabled: function (el) {
      return el.hasClass('disabled');
    },
    save: function () {
      this.disableInput();
      this.showFeedback('SAVING...');
      $.when(Wethod.request(this.saveRequestName, this.model))
        .done(function () {
          this.hideFeedback();
        }.bind(this))
        .fail(function (message) {
          this.showError(message);
        }.bind(this))
        .always(function () {
          this.enableInput();
        }.bind(this));
    },
    addContact: function () {
      if (!this.isDisabled(this.ui.addButton)) {
        var contact = new this.ModelInstance();
        this.secondary.add(contact);
        this.ui.addButton.addClass('disabled');
      }
    },
    delete: function () {
      if (!this.isDisabled(this.ui.deleteButton)) {
        this.disableInput();
        $.when(Wethod.request(this.deleteRequestName, this.model))
          .done(function () {
            this.updateModel();
            this.hideFeedback();
            this.render();
          }.bind(this));
      }
    },
  });

  this.SecondaryContactItem = Marionette.ItemView.extend({
    template: '#companySecondaryContactListItemTemplate',
    saveRequestName: null,
    deleteRequestName: null,
    ui: {
      emailInput: '[data-action="email"]',
      aliasInput: '[data-action="alias"]',
      feedback: '[data-action="feedback"]',
      deleteButton: '[data-action="delete"]',
    },
    events: {
      'blur @ui.emailInput': 'update',
      'blur @ui.aliasInput': 'update',
      'click @ui.deleteButton': 'delete',
    },
    initialize: function (attributes) {
      this.saveRequestName = attributes.saveRequestName;
      this.deleteRequestName = attributes.deleteRequestName;
    },
    hideActions: function () {
      this.ui.deleteButton.addClass('hidden');
    },
    showActions: function () {
      this.ui.deleteButton.removeClass('hidden');
    },
    manageActions: function () {
      if (this.model.isNew()) {
        this.hideActions();
      } else {
        this.showActions();
      }
    },
    onRender: function () {
      this.manageActions();
    },
    update: function (e) {
      if (!this.isDisabled($(e.target))) {
        this.model.set(e.target.name, e.target.value);
        this.save();
      }
    },
    disableInput: function () {
      this.ui.emailInput.prop('disabled', true);
      this.ui.aliasInput.prop('disabled', true);

      this.ui.emailInput.addClass('disabled');
      this.ui.aliasInput.addClass('disabled');
    },
    enableInput: function () {
      this.ui.emailInput.prop('disabled', false);
      this.ui.aliasInput.prop('disabled', false);

      this.ui.emailInput.removeClass('disabled');
      this.ui.aliasInput.removeClass('disabled');
    },
    isDisabled: function (el) {
      return el.hasClass('disabled');
    },
    showError: function (message) {
      this.ui.feedback.text(message);
      this.ui.feedback.addClass('settings-feedback--error');
    },
    showFeedback: function (message) {
      this.ui.feedback.text(message);
      this.ui.feedback.removeClass('settings-feedback--error');
    },
    hideFeedback: function () {
      this.ui.feedback.text('');
      this.ui.feedback.removeClass('settings-feedback--error');
    },
    save: function () {
      this.showFeedback('SAVING...');
      this.disableInput();
      $.when(Wethod.request(this.saveRequestName, this.model))
        .done(function () {
          this.hideFeedback();
        }.bind(this))
        .fail(function (message) {
          this.showError(message);
        }.bind(this))
        .always(function () {
          this.enableInput();
        }.bind(this));
    },
    delete: function () {
      if (!this.isDisabled(this.ui.deleteButton)) {
        this.disableInput();
        $.when(Wethod.request(this.deleteRequestName, this.model));
      }
    },
  });

  this.SecondaryContactList = Marionette.CollectionView.extend({
    childView: Company.SecondaryContactItem,
    initialize: function (attributes) {
      this.childViewOptions = {
        saveRequestName: attributes.saveRequestName,
        deleteRequestName: attributes.deleteRequestName,
      };
    },
  });

  this.ContacItemView = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyContactTemplate',
    regions: {
      invoice: '[data-region="companyInvoiceContact"]',
      order: '[data-region="companyOrderContact"]',
      travel: '[data-region="companyTravelContact"]',
    },
    initialize: function (options) {
      this.invoices = options.invoiceContact;
      this.orders = options.orderContact;
      this.travels = options.travelContact;
    },
    onRender: function () {
      this.showInvoice();
      this.showOrder();
      this.showTravel();
    },
    showInvoice: function () {
      var view = new Company.ContactListView({
        contacts: this.invoices,
        description: 'Send invoices to:',
        saveRequestName: 'save:company:invoice:contact',
        deleteRequestName: 'delete:company:invoice:contact',
        ModelInstance: Company.InvoiceContactModel,
      });
      this.invoice.show(view);
    },
    showOrder: function () {
      var view = new Company.ContactListView({
        contacts: this.orders,
        description: 'Send orders to:',
        saveRequestName: 'save:company:order:contact',
        deleteRequestName: 'delete:company:order:contact',
        ModelInstance: Company.OrderContactModel,
      });
      this.order.show(view);
    },
    showTravel: function () {
      var view = new Company.ContactListView({
        contacts: this.travels,
        description: 'Send travel requests to:',
        saveRequestName: 'save:company:travel:contact',
        deleteRequestName: 'delete:company:travel:contact',
        ModelInstance: Company.TravelContactModel,
      });
      this.travel.show(view);
    },
  });

  // WON-LOST

  this.WonLostBotLayout = Marionette.LayoutView.extend({
    template: '#companyWonLostBotTemplate',
    regions: {
      body: '[data-region="companyWonLostBotLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showBody();
    },
    showBody: function () {
      BackboneService.injectReactComponent(
        WonLostBot,
        '[data-region="companyWonLostBotLayout"]',
        { store: this.options.store }
      );
    },
  });

  // LEVELS
  this.LevelLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyLevelsTemplate',
    regions: {
      levels: '[data-region="companyLevelsCollectionLayout"]',
    },
    ui: {
      showNewLevelButton: '[data-action="showLevelButton"]',
      hideNewLevelButton: '[data-action="hideNewLevelButton"]',
      saveNewLevelButton: '[data-action="saveNewLevelButton"]',

      newLevelRow: '#companyNewLevelRow',
      newLevelName: '[name="newLevelName"]',
      newLevelCode: '[name="newLevelCode"]',
      newLevelValue: '[name="newLevelValue"]',
      newLevelChargeability: '[name="newLevelChargeability"]',
      newLevelRate: '[name="newLevelRate"]',
      newLevelExt: '[name="newLevelExt"]',
      newLevelMustbePlanned: '[name="newLevelMustbePlanned"]',
      newLevelMustDoTimesheet: '[name="newLevelMustDoTimesheet"]',
      newLevelIsDefault: '[name="newLevelDefaultInBudget"]',

      inputNumber: '.levelNumberInput',
    },
    events: {
      'click input': 'selectText', // on click select text
      'click @ui.showNewLevelButton': 'showNewLevel',
      'click @ui.hideNewLevelButton': 'hideNewLevel',
      'click @ui.saveNewLevelButton': 'saveLevel',
      'focusout @ui.inputNumber': 'addSeparator',
      'focusin @ui.inputNumber': 'removeSeparator',
    },
    initialize: function () {
      this.model = new Backbone.Model();
    },
    showNewLevel: function () {
      if (!this.ui.showNewLevelButton.hasClass('disabled')) {
        this.ui.newLevelRow.fadeIn(100);
      }
    },
    hideNewLevel: function () {
      this.ui.newLevelRow.fadeOut(0);
      this.resetNewLevel();

      this.hideError(); // todo manage this
    },
    resetNewLevel: function () {
      this.ui.newLevelName.val('');
      this.ui.newLevelCode.val('');
      this.ui.newLevelValue.val('');
      this.ui.newLevelChargeability.val('');
      this.ui.newLevelRate.val('');
      this.ui.newLevelExt.removeAttr('checked');
      this.ui.newLevelMustbePlanned.removeAttr('checked');
      this.ui.newLevelMustDoTimesheet.removeAttr('checked');
      this.ui.newLevelIsDefault.removeAttr('checked');
    },
    saveLevel: function () {
      var level = {
        name: _.escape(this.ui.newLevelName.val()).trim(),
        code: _.escape(this.ui.newLevelCode.val()).trim(),
        value: numeral().unformat(this.ui.newLevelValue.val()),
        chargeability_target_percent: numeral().unformat(this.ui.newLevelChargeability.val()),
        rate: numeral().unformat(this.ui.newLevelRate.val()),
        ext: this.ui.newLevelExt.is(':checked'),
        must_be_planned: this.ui.newLevelMustbePlanned.is(':checked'),
        must_do_timesheet: this.ui.newLevelMustDoTimesheet.is(':checked'),
        is_default: this.ui.newLevelIsDefault.is(':checked'),
        is_deletable: true,
      };
      Company.Controller.saveLevel(level);
    },
    selectText: function (e) {
      e.target.select();
    },
    addSeparator: function (e) {
      e.target.value = numeral(e.target.value).format('0,0');
    },
    removeSeparator: function (e) {
      e.target.value = numeral().unformat(e.target.value);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewLevelRow').find('#newLevelErrorBox').remove();
      this.$el.find('#companyNewLevelRow')
        .append('<span id="newLevelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    showErrorSave: function (error) {
      this.$el.find('#companyNewLevelRow').find('#newLevelErrorBox').remove();
      this.$el.find('#companyNewLevelRow')
        .append('<span id="newLevelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      // todo remove this
      $('#newLevelErrorBox').remove();
    },
  });

  this.LevelItemView = Marionette.ItemView.extend({
    template: '#companyLevelsCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow',
    ui: {
      deleteLevelButton: '[data-action="deleteLevelRow"]',
    },
    events: {
      'click @ui.deleteLevelButton': 'deleteLevel',
    },
    initialize: function (options) {
      this.defaultLevelsCount = options.defaultLevelsCount;
      this.setDeletableBudget();
      dispatcher.on('user:level:updated', this.update.bind(this));
      dispatcher.on('user:level:added', this.update.bind(this));
      dispatcher.on('user:level:deleted', this.update.bind(this));
    },
    onRender: function () {
      if (this.defaultLevelsCount === 1 && this.model.get('is_default')) {
        this.model.set('can_delete', false);
      } else {
        this.model.set('can_delete', true);
      }
      if (!this.model.get('is_deletable')) {
        this.$('[data-action="deleteLevelRow"]').addClass('notDeletable');
      }
    },
    remove: function () {
      this.$el.fadeOut(150, function () {
        $(this).remove();
      });
    },
    update: function (model, levels) {
      if (model.get('id') === this.model.get('id')) {
        this.model.set(model.attributes);
      }
      var newDefaultLevelsCount = 0;
      levels.forEach(function (level) {
        if (level.get('is_default')) {
          newDefaultLevelsCount += 1;
        }
      });
      this.defaultLevelsCount = newDefaultLevelsCount;
      this.setDeletableBudget();
    },
    setDeletableBudget: function () {
      if (this.defaultLevelsCount === 1 && this.model.get('is_default')) {
        this.model.set('reason_cannot_change', 'You must have at least one default level');
      } else if (!this.model.get('is_default') && this.defaultLevelsCount >= 8) {
        this.model.set('reason_cannot_change', 'You reached the maximum number of default levels');
      } else {
        this.model.set('reason_cannot_change', null);
        this.$('[name="levelIsDefault"]').prop('disabled', false);
      }

      if (!this.model.get('is_default') && this.defaultLevelsCount >= 8) {
        this.model.set('can_delete', true);
        this.model.set('reason_cannot_change', 'You reached the maximum number of default levels');
      }
    },
    deleteLevel: function () {
      if (this.model.get('is_deletable')) {
        if (this.model.get('can_delete')) {
          Company.Controller.deleteLevel(this.model);
        } else {
          Company.Controller.showBudgetModal();
        }
      } else {
        Company.Controller.showLevelModal();
      }
    },
  });

  this.LevelEmptyItemView = Marionette.ItemView.extend({
    template: '#companyLevelsEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow',
  });

  this.LevelCollectionView = Marionette.CollectionView.extend({
    childView: Company.LevelItemView,
    emptyView: Company.LevelEmptyItemView,
    childViewOptions: function () {
      var defaultLevelsCount = this.collection.filter(function (level) {
        return level.get('is_default');
      });
      return {
        defaultLevelsCount: defaultLevelsCount.length,
      };
    },
    initialize: function () {
      this.collection.comparator = function (level) {
        return level.get('rate');
      };

      this.collection.sort();
    },
    ui: {
      ul: '.companyLevelRow',
    },
    events: {
      'change @ui.ul': 'updateLevel',
    },
    updateLevel: function (e) {
      var id = $(e.currentTarget).find('input[name="levelId"]').val();
      var name = $(e.currentTarget).find('input[name="levelName"]').val();
      var code = $(e.currentTarget).find('input[name="levelCode"]').val();
      var value = numeral().unformat($(e.currentTarget).find('input[name="levelValue"]').val());
      var rate = numeral().unformat($(e.currentTarget).find('#levelValue').context.innerText);
      var chargeability = numeral()
        .unformat($(e.currentTarget).find('input[name="levelChargeability"]').val());
      var ext = $(e.currentTarget).find('input[name="levelExt"]').is(':checked');
      var mustBePlanned = $(e.currentTarget).find('input[name="levelPlan"]').is(':checked');
      var mustDoTimesheet = $(e.currentTarget).find('input[name="levelTime"]').is(':checked');
      var isDefault = $(e.currentTarget).find('input[name="levelIsDefault"]').is(':checked');

      var defaultLevelsCountRemaining = this.collection.filter(function (level) {
        return level.get('is_default') && level.get('id') !== parseInt(id);
      });
      if (defaultLevelsCountRemaining.length === 0 && isDefault === false) {
        $(e.currentTarget).find('input[name="levelIsDefault"]').prop('checked', true);
        Company.Controller.showBudgetModal();
        return;
      }

      var level = {
        id: id,
        name: name,
        code: code,
        value: value,
        rate: rate,
        chargeability_target_percent: chargeability,
        ext: ext,
        must_be_planned: mustBePlanned,
        must_do_timesheet: mustDoTimesheet,
        is_default: isDefault,
      };
      Company.Controller.updateLevel(level);

      this.collection.sort();
    },
  });

  // CURRENCIES
  this.CurrencyLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyCurrenciesTemplate',
    regions: {
      content: '[data-region="companyCurrenciesContent"]',
    },
    childEvents: {
      'company:currency:warning-enable-multicurrency': 'onChildWarningEnableMulticurrency',
      'company:currency:cannot-disable': 'onChildCannotDisableCurrency',
      'company:currency:cannot-promote': 'onChildCannotPromoteCurrency',
    },
    onChildWarningEnableMulticurrency: function () {
      this.triggerMethod('company:currency:warning-enable-multicurrency');
    },
    onChildCannotDisableCurrency: function () {
      this.triggerMethod('company:currency:cannot-disable');
    },
    onChildCannotPromoteCurrency: function () {
      this.triggerMethod('company:currency:cannot-promote');
    },
  });

  this.CurrencyEnableView = Marionette.ItemView.extend({
    template: '#companyCurrenciesEnableTemplate',
    ui: {
      enableMulticurrencyButton: '[data-action="enableMulticurrencyButton"]',
    },
    events: {
      'click @ui.enableMulticurrencyButton': 'enableMulticurrency',
    },
    enableMulticurrency: function () {
      this.triggerMethod('company:currency:warning-enable-multicurrency');
    },
  });

  this.CurrencyLoadingView = Marionette.ItemView.extend({
    template: '#companyCurrenciesLoadingTemplate',
    className: 'topMargin bottomSpace',
  });

  this.CurrencyTableLayout = Marionette.LayoutView.extend({
    template: '#companyCurrenciesTableTemplate',
    regions: {
      currencies: '[data-region="companyCurrenciesCollectionLayout"]',
    },
    childEvents: {
      'company:currency:cannot-disable': 'onChildCannotDisableCurrency',
      'company:currency:cannot-promote': 'onChildCannotPromoteCurrency',
    },
    onChildCannotDisableCurrency: function () {
      this.triggerMethod('company:currency:cannot-disable');
    },
    onChildCannotPromoteCurrency: function () {
      this.triggerMethod('company:currency:cannot-promote');
    },
    onRender: function () {
      var currenciesCollectionView = new Company.CurrencyCollectionView({
        collection: this.collection,
      });
      this.currencies.show(currenciesCollectionView);
    },
  });

  this.CurrencyItemView = Marionette.ItemView.extend({
    template: '#companyCurrenciesCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow companyCurrencyRow',
    ui: {
      enabledCheckbox: 'input[name=\'enabled\']',
      masterRadio: 'input[name=\'is_master\']',
    },
    events: {
      'click @ui.enabledCheckbox': 'onEnabledClick',
      'click @ui.masterRadio': 'onMasterClick',
    },
    onEnabledClick: function () {
      var enabled = this.ui.enabledCheckbox.is(':checked');
      this.model.set('enabled', enabled);
      this.model.save().then(function (response) {
        if (response.code !== 200) {
          this.model.set('enabled', !enabled);
          this.ui.enabledCheckbox.prop('checked', !enabled);
          this.triggerMethod('company:currency:cannot-disable');
        }
      }.bind(this));
    },
    onMasterClick: function (e) {
      var isEnabled = this.model.get('enabled');
      if (!isEnabled) {
        e.preventDefault();
        this.triggerMethod('company:currency:cannot-promote');
      } else {
        Wethod.request('company:currencies:promote', this.model.get('id'));
      }
    },
  });

  this.CurrencyEmptyItemView = Marionette.ItemView.extend({
    template: '#companyCurrenciesEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow',
  });

  this.CurrencyCollectionView = Marionette.CollectionView.extend({
    childView: Company.CurrencyItemView,
    emptyView: Company.CurrencyEmptyItemView,
  });

  // PROJECT TYPE
  this.PTLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyPTTemplate',
    regions: {
      projectTypes: '[data-region="companyPTCollectionLayout"]',
    },
    ui: {
      showNewPT: '[data-action="showNewPT"]',
      hideNewPT: '[data-action="hideNewPT"]',
      saveNewPT: '[data-action="saveNewPT"]',
      newPTRow: '#companyNewPTRow',
      newPTWorkable: '[data-action="newPTWorkable"]',
      newPTInvestment: '[name="newPTInvestment"]',
      PTColoTooltip: '.companyPTColor',
      PTColorPicker: '.PTColorPicker',
    },
    events: {
      'click @ui.showNewPT': 'showNewPT',
      'click @ui.hideNewPT': 'hideNewPT',
      'click @ui.saveNewPT': 'saveNewPT',
      'click @ui.PTColoTooltip': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
      'change @ui.newPTWorkable': 'updateUnlimitedPlanning',
      'change @ui.newPTInvestment': 'updateExtCostsType',
    },
    initialize: function (options) {
      this.model = new Backbone.Model({ hasGroup: Wethod.userInfo.get('group') !== null });
      this.model.set('groups', options.groups.toJSON());
    },
    showNewPT: function () {
      this.ui.newPTRow.removeClass('hiddenRow');
    },
    hideNewPT: function () {
      this.ui.newPTRow.addClass('hiddenRow');
      this.resetNewPT();

      this.hideError(); // todo manage this
    },
    setUnlimitedPlanning: function () {
      this.ui.newPTRow.find('input[name="newPTUnlimited"]').prop('checked', true);
    },
    enableInputUnlimitedPlanning: function () {
      this.ui.newPTRow.find('input[name="newPTUnlimited"]').prop('disabled', false);
    },
    disableInputUnlimitedPlanning: function () {
      this.ui.newPTRow.find('input[name="newPTUnlimited"]').prop('disabled', true);
    },
    updateUnlimitedPlanning: function () {
      var workable = this.ui.newPTRow.find('select[name="newPTWorkable"]').val();

      var isTimeOff = workable === 'vacation' || workable === 'leave';
      if (isTimeOff) {
        this.disableInputUnlimitedPlanning();
        this.setUnlimitedPlanning();
      } else {
        this.enableInputUnlimitedPlanning();
      }
    },
    resetExtCostsType: function () {
      this.ui.newPTRow.find('select[name="PTExtCostType"]').val('on-goods-sold');
    },
    enableInputExtCostsType: function () {
      this.ui.newPTRow.find('select[name="PTExtCostType"]').prop('disabled', false);
    },
    disableInputExtCostsType: function () {
      this.ui.newPTRow.find('select[name="PTExtCostType"]').prop('disabled', true);
    },
    updateExtCostsType: function () {
      var investment = this.ui.newPTRow.find('input[name="newPTInvestment"]').is(':checked');

      if (investment) {
        this.disableInputExtCostsType();
        this.resetExtCostsType();
      } else {
        this.enableInputExtCostsType();
      }
    },
    resetNewPT: function () {
      this.ui.newPTRow.find('.companyPTColor').attr('color', '');
      this.ui.newPTRow.find('.companyPTColor').css('background-color', '#dedede');
      this.ui.newPTRow.find('input[name="newTPType"]').val('');
      this.ui.newPTRow.find('input[name="newPTChargeable"]').removeAttr('checked');
      this.ui.newPTRow.find('select[name="newPTWorkable"]').val('workable');
      this.ui.newPTRow.find('input[name="newPTWonLostFeedback"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTIntercompany"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTApproval"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTShared"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTProgram"]').removeAttr('checked');
      this.ui.newPTRow.find('select[name="newPTProgress"]').val('estimate_to_complete');
      this.ui.newPTRow.find('input[name="newPTUnlimited"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTInvestment"]').removeAttr('checked');
      this.resetExtCostsType();
      this.enableInputExtCostsType();
      this.ui.newPTRow.find('input[name="newPTInvoiceDriven"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTOpportunityStatus"]').removeAttr('checked');
      this.ui.newPTRow.find('input[name="newPTAutomaticTimesheet"]').removeAttr('checked');

      this.enableInputUnlimitedPlanning();
    },
    saveNewPT: function () {
      var color = this.ui.newPTRow.find('.companyPTColor').attr('color');
      var name = this.ui.newPTRow.find('input[name="newTPType"]').val();
      var chargeable = this.ui.newPTRow.find('input[name="newPTChargeable"]').is(':checked');
      var planUponApproval = this.ui.newPTRow.find('input[name="newPTApproval"]').is(':checked');
      var shared = this.ui.newPTRow.find('input[name="newPTShared"]').is(':checked');
      var program = this.ui.newPTRow.find('input[name="newPTProgram"]').is(':checked');
      var progress = this.ui.newPTRow.find('select[name="newPTProgress"]')
        .val() === 'time_based_progress';
      var progressPercent = this.ui.newPTRow.find('select[name="newPTProgress"]')
        .val() === 'progress_percent';
      var unlimitedPlanning = this.ui.newPTRow.find('input[name="newPTUnlimited"]').is(':checked');
      var workable = this.ui.newPTRow.find('select[name="newPTWorkable"]').val();
      var wonLostFeedback = this.ui.newPTRow.find('input[name="newPTWonLostFeedback"]')
        .is(':checked');
      var opportunityStatus = this.ui.newPTRow.find('input[name="newPTOpportunityStatus"]')
        .is(':checked');
      var intercompany = this.ui.newPTRow.find('input[name="newPTIntercompany"]').is(':checked');
      var investment = this.ui.newPTRow.find('input[name="newPTInvestment"]').is(':checked');
      var extCostType = this.ui.newPTRow.find('select[name="PTExtCostType"]').val();
      var invoiceDriven = this.ui.newPTRow.find('input[name="newPTInvoiceDriven"]').is(':checked');
      var group = parseInt(this.ui.newPTRow.find('select[name="newPTGroup"]').val());
      var autoTimesheet = this.ui.newPTRow.find('input[name="newPTAutomaticTimesheet"]')
        .is(':checked');

      var projectTypeData = {
        color: color,
        name: name,
        chargeable: chargeable,
        plan_upon_approval: planUponApproval,
        shared: shared,
        must_be_in_program: program,
        time_based_progress: progress,
        unlimited_planning: unlimitedPlanning,
        hours_type: workable,
        progress_percent: progressPercent,
        investment: investment,
        invoice_driven_budget: invoiceDriven,
        won_lost_feedback: wonLostFeedback,
        track_opportunity_status: opportunityStatus,
        intercompany: intercompany,
        group: group,
        is_timesheet_automatic: autoTimesheet,
        sort: null,
        request_review: true,
        external_drive_canvas_enabled: true,
      };

      // TODO [EXTERNAL_COST_SUSPENDED_CONTRACT] Remove this check
      if (Wethod.featureService.isEnabled(FeatureService.EXTERNAL_COST_SUSPENDED)) {
        projectTypeData.external_costs_type = extCostType;
      }

      Company.Controller.saveProjectType(projectTypeData);
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hidePTColorTooltip(picker);
      } else {
        this.showPTColorTooltip(picker);
      }
    },
    showPTColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hidePTColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target).closest('.companyPTRow').find('.companyPTColor').attr('color', color);
      $(e.target).closest('.companyPTRow').find('.companyPTColor').css('background-color', color);
      $(e.target).closest('.companyPTRow').find('.tooltip').removeClass('isVisible');

      // if id save the project type
      var id = $(e.target).closest('.companyPTRow').find('input[name="PTID"]').val();
      if (id) {
        // Update the input color of the job order category
        $(e.target).closest('.companyPTRow').find('input[name="PTColor"]').val(color);

        Company.trigger('update:company:PTColor', e);
      }
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewPTRow').find('#newPTErrorBox').remove();
      this.$el.find('#companyNewPTRow')
        .append('<span id="newPTErrorBox" style="color:red;display:inline-block;padding:5px;position: absolute;left: 0;bottom: 0;">! you must insert all fields</span>');
      $('.autoClip').animate({ scrollLeft: 0 }, 800);
    },
    hideError: function () {
      // todo remove this
      $('#newPTErrorBox').remove();
    },
  });

  this.PTItemView = Marionette.ItemView.extend({
    template: '#companyPTCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyPTRow',
    ui: {
      deletePTButton: '[data-action="deletePT"]',
    },
    events: {
      'click @ui.deletePTButton': 'deleteProjectType',
    },
    initialize: function (options) {
      this.model.set('hasGroup', Wethod.userInfo.get('group') !== null);
      this.model.set('groups', options.groups);
      Company.on('reset:company:PT', this.resetAttributes.bind(this));
    },
    deleteProjectType: function () {
      Company.Controller.showPTModal(this.model);
    },
    onRender: function () {
      this.$el.attr('data-id', this.model.get('id'));
    },
    resetAttributes: function (id) {
      if (id.toString() === this.model.get('id').toString()) {
        // Reset model with values before the update
        this.model.set(this.model.previousAttributes());
        this.render();
      }
    },
  });

  this.PTEmptyItemView = Marionette.ItemView.extend({
    template: '#companyPTEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyPTRow',
  });

  this.PTCollectionView = Marionette.CollectionView.extend({
    childView: Company.PTItemView,
    buildChildView: function (child, ChildViewClass) {
      var options = _.extend({ model: child }, { groups: this.groups });
      var view = new ChildViewClass(options);
      return view;
    },
    emptyView: Company.PTEmptyItemView,
    className: 'pt-collection',
    grid: null,
    initialize: function (options) {
      this.groups = options.groups.toJSON();
      Company.on('update:company:PTColor', this.prepareUpdateColor.bind(this));
      dispatcher.on('project:type:added', this.onProjectTypeAdded.bind(this));
      dispatcher.on('project:type:deleted', this.onProjectTypeDeleted.bind(this));
    },
    ui: {
      ul: '.companyPTRow',
    },
    /**
     * Returns an array where each element represents the position of an item in the collection.
     * @return {Array}
     */
    getItemsPosition: function () {
      var itemElems = this.grid.packery('getItemElements');
      var sortedItems = [];
      $(itemElems).each(function (i, itemElem) {
        sortedItems.push({
          id: $(itemElem).attr('data-id'),
          sort: i,
        });
      });
      return sortedItems;
    },
    onProjectTypeAdded: function () {
      this.grid.packery('destroy');
      this.render();
    },
    onProjectTypeDeleted: function () {
      this.grid.packery('destroy');
      this.render();
    },
    onDragItemPositioned: function () {
      var positions = this.getItemsPosition();

      Wethod.request('sort:company:projectType', positions);
    },
    /**
     * Makes each item of the collection sortable by drag.
     */
    initDraggable: function () {
      var grid = this.$el.packery({
        itemSelector: '.companyPTRow',
        // columnWidth helps with drop positioning
        columnWidth: 1024,
        rowHeight: 64,
      });

      grid.find('.companyPTRow').each(function (i, gridItem) {
        var draggie = new Draggabilly(gridItem);
        // bind drag events to Packery
        grid.packery('bindDraggabillyEvents', draggie);
      });

      this.grid = grid;
      this.grid.on('dragItemPositioned',
        this.onDragItemPositioned.bind(this));
    },
    onShow: function () {
      this.grid.packery('destroy');

      this.initDraggable();
    },
    onRender: function () {
      this.initDraggable();
    },
    selectText: function (e) {
      e.target.select();
    },
    events: {
      'click input': 'selectText',
      'change @ui.ul': 'prepareUpdateRow',
    },
    hasChanged: function (attribute, value, id) {
      var model = this.collection.get(id);
      var oldValue = model.get(attribute);
      return oldValue !== value;
    },
    setUnlimitedPlanning: function (row) {
      $(row).find('input[name="PTUnlimited"]').prop('checked', true);
    },
    enableInputUnlimitedPlanning: function (row) {
      $(row).find('input[name="PTUnlimited"]').prop('disabled', false);
    },
    disableInputUnlimitedPlanning: function (row) {
      $(row).find('input[name="PTUnlimited"]').prop('disabled', true);
    },
    setExtCostsType: function (row, value) {
      $(row).find('select[name="PTExtCostType"]').val(value);
    },
    enableInputExtCostsType: function (row) {
      $(row).find('select[name="PTExtCostType"]').prop('disabled', false);
    },
    disableInputExtCostsType: function (row) {
      $(row).find('select[name="PTExtCostType"]').prop('disabled', true);
    },
    prepareUpdateColor: function (e) {
      var row = $(e.target).closest('.companyPTRow');
      this.prepareUpdate(row);
    },
    prepareUpdateRow: function (e) {
      var row = $(e.currentTarget);
      this.prepareUpdate(row);
    },
    prepareUpdate: function (row) {
      var color = $(row).find('.companyPTColor').attr('color');
      var name = $(row).find('input[name="PTName"]').val();
      var id = $(row).find('input[name="PTID"]').val();
      var chargeable = $(row).find('input[name="PTChargeable"]').is(':checked');
      var planUponApproval = $(row).find('input[name="PTApproval"]').is(':checked');
      var shared = $(row).find('input[name="PTShared"]').is(':checked');
      var program = $(row).find('input[name="PTProgram"]').is(':checked');
      var progress = $(row)
        .find('select[name="PTProgress"]')
        .val() === 'time_based_progress';
      var progressPercent = $(row)
        .find('select[name="PTProgress"]')
        .val() === 'progress_percent';
      var unlimitedPlanning = $(row).find('input[name="PTUnlimited"]').is(':checked');
      var workable = $(row).find('select[name="PTWorkable"]').val();
      var wonLostFeedback = $(row)
        .find('input[name="PTWonLostFeedback"]')
        .is(':checked');
      var opportunityStatus = $(row)
        .find('input[name="PTOpportunityStatus"]')
        .is(':checked');
      var intercompany = $(row).find('input[name="PTIntercompany"]').is(':checked');
      var investment = $(row).find('input[name="PTInvestment"]').is(':checked');
      var extCostType = $(row).find('select[name="PTExtCostType"]').val();
      if (investment) {
        this.setExtCostsType(row, 'on-goods-sold');
        this.disableInputExtCostsType(row);
        extCostType = 'on-goods-sold';
      } else {
        this.enableInputExtCostsType(row);
      }
      var invoiceDriven = $(row).find('input[name="PTInvoiceDriven"]').is(':checked');
      var group = $(row).find('select[name="PTGroup"]').val();
      var autoTimesheet = $(row)
        .find('input[name="PTAutomaticTimesheet"]')
        .is(':checked');

      var isTimeOff = workable === 'vacation' || workable === 'leave';
      if (isTimeOff) {
        this.setUnlimitedPlanning(row);
        this.disableInputUnlimitedPlanning(row);
        unlimitedPlanning = true;
      } else {
        this.enableInputUnlimitedPlanning(row);
      }

      var hasOpportunityStatusChanged = this.hasChanged('track_opportunity_status', opportunityStatus, id);

      var PTData = this.collection.get(id).attributes;
      var viewValues = {
        id: id,
        color: color,
        name: name,
        chargeable: chargeable,
        plan_upon_approval: planUponApproval,
        shared: shared,
        must_be_in_program: program,
        time_based_progress: progress,
        unlimited_planning: unlimitedPlanning,
        hours_type: workable,
        progress_percent: progressPercent,
        investment: investment,
        invoice_driven_budget: invoiceDriven,
        won_lost_feedback: wonLostFeedback,
        track_opportunity_status: opportunityStatus,
        intercompany: intercompany,
        group: group,
        is_timesheet_automatic: autoTimesheet,
      };
      // TODO [EXTERNAL_COST_SUSPENDED_CONTRACT] Remove this check
      if (Wethod.featureService.isEnabled(FeatureService.EXTERNAL_COST_SUSPENDED)) {
        viewValues.external_costs_type = extCostType;
      }
      Object.assign(PTData, viewValues);

      this.updateProjectType(PTData, hasOpportunityStatusChanged);
    },
    updateProjectType: function (PTData, hasOpportunityStatusChanged) {
      Company.Controller.updateProjectType(PTData, false, hasOpportunityStatusChanged);
    },
  });

  // PROJECT TYPE GROUP
  this.PTGroupLayout = Marionette.LayoutView.extend({
    template: '#companyPTGroupLayoutTemplate',
    regions: {
      ptGroups: '[data-region="companyPTGroupCollectionLayout"]',
    },
    ui: {
      addGroupButton: '[data-action="addGroupButton"]',
      newGroup: '#companyNewGroupRow',
      hideNewGroup: '[data-action="hideNewGroup"]',
      saveNewGroup: '[data-action="saveNewGroup"]',
    },
    initialize: function (options) {
      this.groups = options.groups;
    },
    events: {
      'click @ui.addGroupButton': 'showNewGroup',
      'click @ui.hideNewGroup': 'hideNewGroup',
      'click @ui.saveNewGroup': 'saveNewGroup',
    },
    onRender: function () {
      var groupsView = new Company.PTGroupCollectionView({ collection: this.groups });
      this.ptGroups.show(groupsView);
    },
    showNewGroup: function () {
      this.ui.newGroup.fadeIn(0);
    },
    hideNewGroup: function () {
      this.ui.newGroup.fadeOut(0);
      this.resetNewGroup();
    },
    resetNewGroup: function () {
      this.ui.newGroup.find('input[name="newGroupName"]').val('');
    },
    saveNewGroup: function () {
      var name = this.ui.newGroup.find('input[name="newGroupName"]').val().trim();
      var data = {
        name: name,
        default: false,
      };

      Company.Controller.savePTGroup(data);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewGroupRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewGroupRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#companyNewGroupRow').find('#newLabelErrorBox').remove();
    },
  });

  this.PTGroupItemView = Marionette.ItemView.extend({
    template: '#companyPTGroupTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companySupplierStatusRow',
    ui: {
      isDefault: '[data-action="defaultGroup"]',
      name: '[data-action="groupName"]',
      deleteButton: '[data-action="deleteGroup"]',
    },
    events: {
      'click @ui.isDefault': 'onDefaultClick',
      'change @ui.name': 'onNameChange',
      'click @ui.deleteButton': 'delete',
    },
    initialize: function () {
      this.model.set('is_default', this.model.get('default'));
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onDefaultClick: function () {
      this.model.set({
        default: true,
        is_default: true,
      });

      Wethod.request('update:company:projectTypeGroups:default', this.model.get('id'))
        .done(function () {
        });
    },
    onNameChange: function () {
      var name = this.ui.name.val().trim();
      if (name !== '') {
        this.model.set({ name: name });
        this.updateGroup();
      }
    },
    updateGroup: function () {
      Company.Controller.updatePTGroup(this.model);
    },
    delete: function () {
      var isDefault = this.ui.isDefault.is(':checked');
      this.model.set('default', isDefault);
      Company.Controller.deletePTGroup(this.model);
    },
  });

  this.PTGroupCollectionView = Marionette.CollectionView.extend({
    childView: Company.PTGroupItemView,
    className: 'companyPTGroupCollection',
  });

  // PROJECT LABEL
  this.LabelLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyLabelTemplate',
    regions: {
      projectLabels: '[data-region="companyLabelCollectionLayout"]',
    },
    ui: {
      showNewLabel: '[data-action="showNewLabel"]',
      hideNewLabel: '[data-action="hideNewLabel"]',
      saveNewLabel: '[data-action="saveNewLabel"]',
      newLabelRow: '#companyNewLabelRow',
    },
    events: {
      'click @ui.showNewLabel': 'showNewLabel',
      'click @ui.hideNewLabel': 'hideNewLabel',
      'click @ui.saveNewLabel': 'saveNewLabel',
    },
    showNewLabel: function () {
      this.ui.newLabelRow.fadeIn(0);
    },
    hideNewLabel: function () {
      this.ui.newLabelRow.fadeOut(0);
      this.resetNewLabel();

      this.hideError(); // todo manage this
    },
    resetNewLabel: function () {
      this.ui.newLabelRow.find('input[name="newLabelType"]').val('');
    },
    saveNewLabel: function () {
      var data = {
        name: _.escape(this.ui.newLabelRow.find('input[name="newLabelType"]').val().trim()),
        category: _.escape(this.ui.newLabelRow.find('input[name="newLabelGroup"]').val().trim()),
        label: _.escape(this.ui.newLabelRow.find('input[name="newLabelShortcut"]').val().trim()),
        sort: this.projectLabels.currentView.collection.length,
      };

      Company.Controller.saveProjectLabel(data);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewLabelRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewLabelRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      $('#newDAErrorBox').remove();
    },
  });

  this.LabelItemView = Marionette.ItemView.extend({
    template: '#companyLabelCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companyLabel--draggable',
    ui: {
      deleteLabelButton: '[data-action="deleteLabel"]',
      labelName: '[data-action="LabelName"]',
      labelGroup: '[data-action="LabelGroup"]',
      labelShortcut: '[data-action="LabelShortcut"]',
    },
    events: {
      'click @ui.deleteLabelButton': 'deleteProjectLabel',
      'change @ui.labelName': 'updateProjectLabel',
      'change @ui.labelGroup': 'updateProjectLabelGroup',
      'change @ui.labelShortcut': 'updateProjectLabelShortcut',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    updateProjectLabel: function () {
      var name = _.escape(this.ui.labelName.val().trim());

      if (name !== '') {
        this.model.set({ name: name });
        Company.Controller.updateProjectLabel(this.model);
      }
    },
    updateProjectLabelGroup: function () {
      var name = _.escape(this.ui.labelGroup.val().trim());

      if (name !== '') {
        this.model.set({ category: name });
        Company.Controller.updateProjectLabel(this.model);
      }
    },
    updateProjectLabelShortcut: function () {
      var name = _.escape(this.ui.labelShortcut.val().trim());

      if (name !== '') {
        this.model.set({ label: name });
        Company.Controller.updateProjectLabel(this.model);
      }
    },

    deleteProjectLabel: function () {
      Company.Controller.deleteProjectLabel(this.model);
    },
  });

  this.LabelEmptyItemView = Marionette.ItemView.extend({
    template: '#companyLabelEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
  });

  this.LabelCollectionView = Marionette.CollectionView.extend({
    childView: Company.LabelItemView,
    emptyView: Company.LabelEmptyItemView,
    className: 'companyLabelCollection',
    gridEl: null,
    initialize: function () {
      this.collection.on('project:label:deleted', function () {
        this.gridEl.packery('destroy');
        this.render();
      }.bind(this));

      this.collection.on('project:label:added', function () {
        this.gridEl.packery('destroy');
        this.render();
      }.bind(this));
    },
    initPackery: function () {
      var $grid = $('.companyLabelCollection').packery({
        itemSelector: '.companyLabel--draggable',
        columnWidth: '.companyLabel--draggable',
      });

      // make all grid-items draggable
      $grid.find('.companyLabel--draggable').each(function (i, gridItem) {
        var draggie = new Draggabilly(gridItem);
        $grid.packery('bindDraggabillyEvents', draggie);
      });
      this.gridEl = $grid;
    },
    sortGrid: function () {
      var orderedElements = [];
      $(this.gridEl.packery('getItemElements')).each(function (i, item) {
        orderedElements.push({
          id: parseInt($(item).attr('data-id')),
          sort: i,
        });
      });
      Wethod.request('sort:company:projectType', orderedElements);
    },
    onRender: function () {
      this.initPackery();

      this.gridEl.on('dragItemPositioned', function () {
        this.sortGrid();
      }.bind(this));
    },
    onShow: function () {
      this.initPackery();

      this.gridEl.on('dragItemPositioned', function () {
        this.sortGrid();
      }.bind(this));
    },
  });

  // TIMESHEET OPTIONS
  this.TimesheetTemplateLayout = Marionette.LayoutView.extend({
    template: '#companyTimesheetTemplate',
    regions: {
      timesheetOptions: '[data-region="timesheetOptions"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showTimesheet();
    },
    showTimesheet: function () {
      var container = document.querySelector(this.timesheetOptions.el);
      var timesheetSection = React.createElement(Timesheet, { store: this.options.store });
      ReactDOM.render(timesheetSection, container);
    },
  });

  // PIPELINE
  this.PipelineLayout = Marionette.LayoutView.extend({
    _template: '#companyPipelineTemplate',
    ui: {
      requiredContact: '[data-region="requiredContact"]',
      requiredJobOrder: '[data-region="requiredJobOrder"]',
      wastedHours: '[data-region="wastedHours"]',
      valueAsUnit: '[data-region="valueAsUnit"]',
    },
    initialize: function (options) {
      this.options = options;
      this._pipelineContact = options.pipelineContact;
      _.bindAll(this, 'template');
    },
    template: function () {
      var model = {
        requiredContact: this.options.pipelineContact.get('required'),
        requiredJobOrder: this.options.pipelineRequiredJobOrder.get('required'),
        wastedHours: this.options.wastedHoursSettings.get('enabled'),
        valueAsUnit: this.options.valueAsUnit.get('enabled'),
      };
      return _.template($(this._template).html())(model);
    },
    events: {
      'click @ui.requiredContact': 'toggleRequiredContact',
      'click @ui.requiredJobOrder': 'toggleRequiredJobOrder',
      'click @ui.wastedHours': 'toggleWastedHours',
      'click @ui.valueAsUnit': 'toggleValueAsUnit',
    },
    toggleRequiredContact: function () {
      var require = this.ui.requiredContact.is(':checked');
      $.when(Wethod.request('set:company:pipeline:contact', require));
    },
    toggleRequiredJobOrder: function () {
      var require = this.ui.requiredJobOrder.is(':checked');
      $.when(Wethod.request('set:company:pipeline:requiredJobOrder', require));
    },
    toggleWastedHours: function () {
      var enabled = this.ui.wastedHours.is(':checked');
      $.when(Wethod.request('update:wasted:hours:enabled', enabled));
    },
    toggleValueAsUnit: function () {
      var enabled = this.ui.valueAsUnit.is(':checked');
      $.when(Wethod.request('set:company:pipeline:valueAsUnit', enabled));
    },
  });

  // Project Review
  this.ProjectReviewLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyProjectReviewTemplate',
    regions: {
      content: '[data-region="companyProjectReviewContent"]',
    },
    childEvents: {
      'company:project-review:enable-project-review': 'onChildEnableProjectReview',
      'company:project-review:disable-project-review': 'onChildDisableProjectReview',
    },
    onChildEnableProjectReview: function () {
      var loadingView = new Company.ProjectReviewLoadingView();
      this.content.show(loadingView);

      Company.Controller.enableProjectReview();
    },
    onChildDisableProjectReview: function () {
      var loadingView = new Company.ProjectReviewLoadingView();
      this.content.show(loadingView);
      var reviewModel = new Company.PipelineReviewConfig();
      reviewModel.set('enabled', false);

      Wethod.request('set:company:pipeline:reviewConfig', reviewModel).done(function (response) {
        var disabledView = new Company.ProjectReviewDisabledView({ model: response });
        this.content.show(disabledView);
      }.bind(this));
    },
  });

  this.ProjectReviewDisabledView = Marionette.ItemView.extend({
    template: '#companyProjectReviewDisabledTemplate',
    ui: {
      enableProjectReviewButton: '[data-action="enableProjectReviewButton"]',
    },
    events: {
      'click @ui.enableProjectReviewButton': 'enableProjectReview',
    },
    enableProjectReview: function () {
      this.triggerMethod('company:project-review:enable-project-review');
    },
  });

  this.ProjectReviewLoadingView = Marionette.ItemView.extend({
    template: '#companyProjectReviewLoadingTemplate',
    className: 'topMargin bottomSpace',
  });

  this.ProjectReviewJocItemView = Marionette.ItemView.extend({
    template: '#companyProjectReviewJocItemTemplate',
    className: 'col_16 no_padding companyPTRow companyProjectReviewJOC__row',
    tagName: 'ul',
    ui: {
      promptOnArchiveCheckbox: '[data-action="promptOnArchive"]',
      fromValueInput: '[data-action="fromValue"]',
      feedback: '[data-action="feedback"]',
    },
    events: {
      'click @ui.promptOnArchiveCheckbox': 'clickOnPromptOnArchive',
      'blur @ui.fromValueInput': 'fromValueInputBlur',
    },
    initialize: function () {
      this.model.set('prompt_on_archive', this.model.get('request_review') !== null);
      var fromValue = this.model.get('request_review') !== null ? this.model.get('request_review') : null;
      this.model.set('from_value', fromValue);
    },
    onRender: function () {
      this.initInput();
    },
    /**
     * Once called, this function "live-format" the input using a "," to separate thousands.
     * Decimals are not allowed.
     *
     * For more info: https://github.com/autoNumeric/autoNumeric#how-to-use
     */
    initInput: function () {
      this.ui.fromValueInput.autoNumeric('init', {
        aSep: '.',
        aDec: ',',
        mDec: 0,
      });
    },
    manageFromValueVisibility: function () {
      if (this.model.get('request_review') === null) {
        this.ui.fromValueInput.val('');
      } else {
        this.ui.fromValueInput.val(this.model.get('request_review'));
      }
    },
    clickOnPromptOnArchive: function () {
      var requestReview = this.model.get('request_review') !== null ? null : 0;
      this.model.set('request_review', requestReview);
      this.manageFromValueVisibility();
      this.onModelChange();
    },
    fromValueInputBlur: function (e) {
      var value = $(e.target).autoNumeric('get');
      this.model.set('from_value', value);
      this.onModelChange();
    },
    onModelChange: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var options = {
          id: this.model.get('id'),
          from_value: this.model.get('request_review') === null ? null : this.model.get('from_value'),
        };
        this.ui.feedback.text('Saving...');
        $.when(Wethod.request('edit:company:pipeline:review:config:joc', options))
          .done(function () {
            this.ui.feedback.text('');
            dispatcher.trigger('project:type:updated', this.model);
          }.bind(this));
      }.bind(this), 500);
    },
  });

  this.ProjectReviewJocCollectionView = Marionette.CollectionView.extend({
    childView: this.ProjectReviewJocItemView,
    initialize: function () {
      dispatcher.on('project:type:added', this.onProjectTypeAdded.bind(this));
      dispatcher.on('project:type:deleted', this.onProjectTypeDeleted.bind(this));
      dispatcher.on('project:type:updated', this.onProjectTypeUpdated.bind(this));
    },
    onProjectTypeDeleted: function (id) {
      this.collection.remove(id);
      this.render();
    },
    onProjectTypeAdded: function (model) {
      this.collection.add(model);
      this.render();
    },
    onProjectTypeUpdated: function (model) {
      this.collection.get(model.get('id')).set(model.attributes);
      this.render();
    },
  });

  this.ProjectReviewEnabledView = Marionette.LayoutView.extend({
    template: '#companyProjectReviewEnabledTemplate',
    ui: {
      disableReview: '[data-action="disableProjectReviewButton"]',
      minReviewers: '#companyProjectReviewMinReviewersInput',
      allowProjectsMissingCriteria: 'input[name="allow_projects_missing_criteria"]',
      allowUsersMissingCriteria: 'input[name="allow_users_missing_criteria"]',
      partialReviewRadio: 'input[name=\'partial_review\']',
      showNewProjectCriteria: '[data-action="addProjectReviewCriteriaButton"]',
      hideNewProjectCriteria: '[data-action="hideNewProjectReviewCriteria"]',
      saveNewProjectCriteria: '[data-action="saveNewProjectReviewCriteria"]',
      newProjectCriteria: '#companyNewProjectReviewCriteriaRow',
      showNewTeamMemberCriteria: '[data-action="addTeamMemberReviewCriteriaButton"]',
      hideNewTeamMemberCriteria: '[data-action="hideNewTeamMemberReviewCriteria"]',
      saveNewTeamMemberCriteria: '[data-action="saveNewTeamMemberReviewCriteria"]',
      newTeamMemberCriteria: '#companyNewTeamMemberReviewCriteriaRow',
    },
    regions: {
      projectCriteria: '[data-region="companyProjectReviewCriteriaCollectionLayout"]',
      teamMemberCriteria: '[data-region="companyTeamMemberReviewCriteriaCollectionLayout"]',
      projectTypes: '[data-region="companyPTCollectionLayout"]',
    },
    initialize: function (options) {
      this.options = options;
      this.model = options.model;
      this.jocList = options.projectTypes;
      var currency = Wethod.userInfo.get('currency') ? ' (' + Wethod.userInfo.get('currency').code + ')' : '';
      this.model.set('currency', currency);
      this.projectReviewCriteria = options.projectReviewCriteria;
      this.teamMemberReviewCriteria = options.teamMemberReviewCriteria;
      this.model.on('change', this.render, this);
    },
    events: {
      'click @ui.disableReview': 'disableProjectReview',
      'change @ui.minReviewers': 'updateMinReviewers',
      'click @ui.allowProjectsMissingCriteria': 'allowProjectsMissingCriteria',
      'click @ui.allowUsersMissingCriteria': 'allowUsersMissingCriteria',
      'click @ui.partialReviewRadio': 'setPartialReview',
      'click @ui.showNewProjectCriteria': 'showNewProjectCriteria',
      'click @ui.hideNewProjectCriteria': 'hideNewProjectCriteria',
      'click @ui.saveNewProjectCriteria': 'saveNewProjectCriteria',
      'click @ui.showNewTeamMemberCriteria': 'showNewTeamMemberCriteria',
      'click @ui.hideNewTeamMemberCriteria': 'hideNewTeamMemberCriteria',
      'click @ui.saveNewTeamMemberCriteria': 'saveNewTeamMemberCriteria',
    },
    onRender: function () {
      var projectTypesView = new Company.ProjectReviewJocCollectionView({
        collection: this.jocList,
      });
      this.projectTypes.show(projectTypesView);
      var projectReviewCriteriaView = new Company.ProjectReviewCriteriaCollectionView({
        collection: this.projectReviewCriteria,
      });
      var teamMemberReviewCriteriaView = new Company.TeamReviewCriteriaCollectionView({
        collection: this.teamMemberReviewCriteria,
      });
      this.projectCriteria.show(projectReviewCriteriaView);
      this.teamMemberCriteria.show(teamMemberReviewCriteriaView);
    },
    disableProjectReview: function () {
      this.triggerMethod('company:project-review:disable-project-review');
    },
    updateProjectReview: function (model) {
      Wethod.request('set:company:pipeline:reviewConfig', model).done(function (response) {
        this.model = response;
      }.bind(this));
    },
    updateMinReviewers: function () {
      var value = this.ui.minReviewers.val().trim();

      this.model.set('min_reviewers', value);
      this.updateProjectReview(this.model);
    },
    allowProjectsMissingCriteria: function () {
      var missingProjectCriteriaAllowed = this.ui.allowProjectsMissingCriteria.is(':checked');
      this.model.set({ allow_projects_missing_criteria: missingProjectCriteriaAllowed });
      this.updateProjectReview(this.model);
    },
    allowUsersMissingCriteria: function () {
      var missingUserCriteriaAllowed = this.ui.allowUsersMissingCriteria.is(':checked');
      this.model.set({ allow_users_missing_criteria: missingUserCriteriaAllowed });
      this.updateProjectReview(this.model);
    },
    setPartialReview: function () {
      var reviewMode = this.ui.partialReviewRadio.filter(':checked').val();
      this.model.set({ full_team: reviewMode === 'total' });
      this.updateProjectReview(this.model);
    },
    showNewProjectCriteria: function () {
      this.ui.newProjectCriteria.fadeIn(0);
    },
    hideNewProjectCriteria: function () {
      this.ui.newProjectCriteria.fadeOut(0);
      this.resetNewProjectCriteria();
    },
    resetNewProjectCriteria: function () {
      this.ui.newProjectCriteria.find('input[name="newProjectReviewCriteria"]').val('');
    },
    saveNewProjectCriteria: function () {
      var criteria = this.ui.newProjectCriteria.find('input[name="newProjectReviewCriteria"]')
        .val()
        .trim();
      var data = {
        name: criteria,
      };
      Company.Controller.insertReviewCriteria(data, 'project');
    },
    showNewTeamMemberCriteria: function () {
      this.ui.newTeamMemberCriteria.fadeIn(0);
    },
    hideNewTeamMemberCriteria: function () {
      this.ui.newTeamMemberCriteria.fadeOut(0);
      this.resetNewTeamMemberCriteria();
    },
    resetNewTeamMemberCriteria: function () {
      this.ui.newTeamMemberCriteria.find('input[name="newTeamMemberReviewCriteria"]').val('');
    },
    saveNewTeamMemberCriteria: function () {
      var criteria = this.ui.newTeamMemberCriteria.find('input[name="newTeamMemberReviewCriteria"]')
        .val()
        .trim();
      var data = {
        name: criteria,
      };
      Company.Controller.insertReviewCriteria(data, 'team');
    },
  });

  this.ProjectReviewCriteriaItemView = Marionette.ItemView.extend({
    template: '#companyReviewCriteriaCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
    ui: {
      deleteReviewCriteriaButton: '[data-action="deleteReviewCriteria"]',
      reviewCriteriaName: '[data-action="reviewCriteriaName"]',
    },
    events: {
      'click @ui.deleteReviewCriteriaButton': 'deleteReviewCriteria',
      'change @ui.reviewCriteriaName': 'updateReviewCriteria',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    updateReviewCriteria: function () {
      var name = _.escape(this.ui.reviewCriteriaName.val().trim());

      if (name !== '') {
        this.model.set({ name: name });
        Wethod.request('update:company:project:review:criteria', this.model)
          .done(function (response) {
            this.model = response;
          }.bind(this));
      }
    },
    deleteReviewCriteria: function () {
      Company.Controller.showReviewCriteriaModal(this.model, 'project');
    },
  });

  this.ProjectReviewCriteriaCollectionView = Marionette.CollectionView.extend({
    childView: Company.ProjectReviewCriteriaItemView,
    className: 'companyReviewCriteriaCollection',
  });

  this.TeamReviewCriteriaItemView = Marionette.ItemView.extend({
    template: '#companyReviewCriteriaCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
    ui: {
      deleteReviewCriteriaButton: '[data-action="deleteReviewCriteria"]',
      reviewCriteriaName: '[data-action="reviewCriteriaName"]',
    },
    events: {
      'click @ui.deleteReviewCriteriaButton': 'deleteReviewCriteria',
      'change @ui.reviewCriteriaName': 'updateReviewCriteria',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    updateReviewCriteria: function () {
      var name = _.escape(this.ui.reviewCriteriaName.val().trim());

      if (name !== '') {
        this.model.set({ name: name });
        Wethod.request('update:company:team:review:criteria', this.model).done(function (response) {
          this.model = response;
        }.bind(this));
      }
    },
    deleteReviewCriteria: function () {
      Company.Controller.showReviewCriteriaModal(this.model, 'team');
    },
  });

  this.TeamReviewCriteriaCollectionView = Marionette.CollectionView.extend({
    childView: Company.TeamReviewCriteriaItemView,
    className: 'companyReviewCriteriaCollection',
  });

  // Google Drive integration
  this.GoogleDriveIntegrationLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyGoogleDriveIntegrationTemplate',
    regions: {
      content: '[data-region="content"]',
      modal: '[data-region="modal"]',
    },
    ui: {
      permissionErrorMessage: '[data-action="permissionErrorMessage"]',
    },
    childEvents: {
      enable: 'onChildEnableIntegration',
      disable: 'onChildDisableIntegration',
    },
    initialize: function (options) {
      this.options = options;
    },
    /**
     * Show error about missing folder root permission if needed.
     */
    checkPermissionError: function () {
      if (this.options.config.error) {
        this.ui.permissionErrorMessage.show();
      } else {
        this.ui.permissionErrorMessage.hide();
      }
    },
    onRender: function () {
      // Get Google auth token for user
      var googleTokenRequest = Wethod.request('integration:google:token:get', { scope: 'drive' });
      $.when(googleTokenRequest).done(function (googleTokenResponse) {
        this.options.token = googleTokenResponse.token;
        var enabled = this.options.config.enabled;
        if (enabled) {
          this.showEnabledView(this.options.config);
        } else {
          this.showDisabledView(this.options.action);
        }
      }.bind(this));
    },
    showEnabledView: function (config) {
      this.options.config = config;
      this.checkPermissionError();
      var view = new Company
        .GoogleDriveIntegrationEnabledView({
          projectTypes: this.options.projectTypes,
          model: new Backbone.Model(config),
        });
      this.content.show(view);
    },
    showErrorModal: function (reason) {
      var model = null;
      switch (reason) {
        case 'insufficientFilePermissions':
          model = new Backbone.Model({
            title: 'Insufficient permissions',
            text: 'To enable this integration you need to be "Manager" or "Content manager" of the root folder you have chosen. Please refer to Google Drive documentation.',
          });
          break;
        case 'fileOrganizerOnNonTeamDriveNotSupported':
          model = new Backbone.Model({
            title: 'Invalid root folder',
            text: 'Root folder must belong to a <a href="https://support.google.com/a/answer/7212025" target="_blank">shared drive</a>',
          });
          break;
        default:
          model = new Backbone.Model({
            title: 'Something went wrong',
            text: 'Please retry or contact support.',
          });
      }

      var view = new Company.CanvasDriveIntegrationErrorModalItemView({ model: model });
      this.modal.show(view);
    },
    showDisabledView: function (action) {
      this.checkPermissionError();
      var loadingView = new Company.GoogleDriveIntegrationLoadingView();
      this.content.show(loadingView);
      // Get Google consent screen URL
      var authUrlRequest = Wethod.request('integration:enable:google', {
        scope: 'drive',
        referer: encodeURIComponent(document.location.origin + '/#settings/company/google-drive-sync/complete-setup'),
      });
      $.when(authUrlRequest).done(function (authUrlResponse) {
        var view = new Company.GoogleDriveIntegrationDisabledView({
          action: action,
          token: this.options.token,
          model: new Backbone.Model({
            url: decodeURI(authUrlResponse.data.url),
          }),
        });
        this.content.show(view);
      }.bind(this));
    },
    onChildEnableIntegration: function (childView, data) {
      var loadingView = new Company.GoogleDriveIntegrationLoadingView();
      this.content.show(loadingView);
      var enableRequest = Wethod.request('integration:google:drive:canvas:enable', data);
      $.when(enableRequest).done(function (enableResponse) {
        var failureReason = enableResponse.reason;
        if (failureReason) {
          this.showErrorModal(failureReason);
          this.showDisabledView();
        } else {
          this.showEnabledView(enableResponse);
        }
      }.bind(this));
    },
    onChildDisableIntegration: function () {
      var loadingView = new Company.GoogleDriveIntegrationLoadingView();
      this.content.show(loadingView);
      $.when(Wethod.request('integration:google:drive:canvas:disable')).done(function () {
        this.showDisabledView();
      }.bind(this));
    },
  });

  this.GoogleDriveIntegrationDisabledView = Marionette.ItemView.extend({
    template: '#companyGoogleDriveIntegrationDisabledTemplate',
    ui: {
      enableButton: '[data-action="enableButton"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    pickerCallback: function (data) {
      if (data[window.google.picker.Response.ACTION] == window.google.picker.Action.PICKED) {
        var doc = data[window.google.picker.Response.DOCUMENTS][0];
        var id = doc[window.google.picker.Document.ID];

        this.triggerMethod('enable', { rootId: id });
      }
    },
    onRender: function () {
      var action = this.options.action;
      if (action === 'complete-setup') {
        this.completeSetup();
      }
    },
    createPicker: function () {
      var docsView = new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS)
        .setIncludeFolders(true)
        .setMimeTypes('application/vnd.google-apps.folder')
        .setSelectFolderEnabled(true)
        .setMode(window.google.picker.DocsViewMode.LIST)
        .setEnableDrives(true);

      var picker = new window.google.picker.PickerBuilder()
        .addView(docsView)
        .enableFeature(window.google.picker.Feature.SUPPORT_DRIVES)
        .setOAuthToken(this.options.token)
        .setTitle('Pick root folder')
        .setCallback(this.pickerCallback.bind(this))
        .build();

      picker.setVisible(true);
    },
    completeSetup: function () {
      window.gapi.load('picker', this.createPicker.bind(this));
    },
  });

  this.GoogleDriveIntegrationLoadingView = Marionette.ItemView.extend({
    template: '#companyGoogleDriveIntegrationLoadingTemplate',
    className: 'topMargin bottomSpace',
  });

  this.GoogleDriveIntegrationJocItemView = Marionette.ItemView.extend({
    template: '#companyGoogleDriveIntegrationJocTemplate',
    className: 'col_16 no_padding companyPTRow companyProjectReviewJOC__row',
    tagName: 'ul',
    ui: {
      enabledCheckbox: '[data-action="sync"]',
      feedback: '[data-action="feedback"]',
    },
    events: {
      'click @ui.enabledCheckbox': 'clickOnEnabled',
    },
    clickOnEnabled: function () {
      var isCurrentlyEnabled = this.model.get('external_drive_canvas_enabled');
      this.model.set('external_drive_canvas_enabled', !isCurrentlyEnabled);
      this.onModelChange();
    },
    onModelChange: function () {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(function () {
        $.when(Wethod.request('save:company:projectType', this.model)).done(function () {
          dispatcher.trigger('project:type:updated', this.model);
        }.bind(this));
      }.bind(this), 500);
    },
  });

  this.GoogleDriveIntegrationJocCollectionView = Marionette.CollectionView.extend({
    childView: this.GoogleDriveIntegrationJocItemView,
    initialize: function () {
      dispatcher.on('project:type:added', this.onProjectTypeAdded.bind(this));
      dispatcher.on('project:type:deleted', this.onProjectTypeDeleted.bind(this));
      dispatcher.on('project:type:updated', this.onProjectTypeUpdated.bind(this));
    },
    onProjectTypeDeleted: function (id) {
      this.collection.remove(id);
      this.render();
    },
    onProjectTypeAdded: function (model) {
      this.collection.add(model);
      this.render();
    },
    onProjectTypeUpdated: function (model) {
      this.collection.get(model.get('id')).set(model.attributes);
      this.render();
    },
  });

  this.ExternalDriveDefaultFolderItemView = Marionette.ItemView.extend({
    template: '#companyGoogleDriveDefaultFolderTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
    ui: {
      delete: '[data-action="delete"]',
      name: '[data-action="name"]',
    },
    events: {
      'click @ui.delete': 'delete',
      'change @ui.name': 'update',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    update: function () {
      var name = _.escape(this.ui.name.val().trim());

      if (name !== '') {
        this.model.set({ name: name });
        var insertRequest = Wethod.request('integration:external:drive:canvas:default-folder:save', this.model);
        $.when(insertRequest).done();
      }
    },
    delete: function () {
      var request = Wethod.request('integration:external:drive:canvas:default-folder:delete', this.model);
      $.when(request).done(function () {
        this.destroy();
      }.bind(this));
    },
  });

  this.ExternalDriveDefaultFolderCollectionView = Marionette.CollectionView.extend({
    childView: Company.ExternalDriveDefaultFolderItemView,
    className: 'companyBusinessUnitCollection',
  });

  this.GoogleDriveIntegrationEnabledView = Marionette.LayoutView.extend({
    template: '#companyGoogleDriveIntegrationEnabledTemplate',
    regions: {
      defaultFolders: '[data-region="defaultFolders"]',
      projectTypes: '[data-region="companyPTCollectionLayout"]',
    },
    ui: {
      disable: '[data-action="disableIntegrationButton"]',
      newFolder: '#companyNewExternaDriveFolderRow',
      newFolderSaveButton: '[data-action="saveNewFolder"]',
      hideNewFolderButton: '[data-action="hideNewFolder"]',
      addFolderButton: '[data-action="addFolderButton"]',
    },
    events: {
      'click @ui.disable': 'disable',
      'click @ui.hideNewFolderButton': 'hideNewFolder',
      'click @ui.addFolderButton': 'showNewFolder',
      'click @ui.newFolderSaveButton': 'saveNewFolder',
    },
    initialize: function (options) {
      this.options = options;
      this.jocList = options.projectTypes;
    },
    disable: function () {
      this.triggerMethod('disable');
    },
    showDefaultFolders: function () {
      var folders = new Wethod.SettingsApp.Integration.ExternalDriveDefaultFolderCollection();
      folders.reset(this.model.get('default_folders'));
      var view = new Company.ExternalDriveDefaultFolderCollectionView({ collection: folders });
      this.defaultFolders.show(view);
    },
    showJocList: function () {
      var projectTypesView = new Company.GoogleDriveIntegrationJocCollectionView({
        collection: this.jocList,
      });
      this.projectTypes.show(projectTypesView);
    },
    onRender: function () {
      this.showJocList();
      this.showDefaultFolders();
    },
    showNewFolder: function () {
      this.ui.newFolder.fadeIn(0);
      this.ui.newFolderSaveButton.fadeIn(0);
    },
    hideNewFolder: function () {
      this.resetNewFolder();
      this.ui.newFolder.fadeOut(0);
    },
    resetNewFolder: function () {
      this.ui.newFolder.find('input[name="newFolder"]').val('');
    },
    saveNewFolder: function () {
      this.ui.newFolderSaveButton.fadeIn(0);
      var name = this.ui.newFolder.find('input[name="newFolder"]').val().trim();

      if (name !== '') {
        this.ui.newFolderSaveButton.fadeOut(0);
        var model = new Wethod.SettingsApp
          .Integration.ExternalDriveDefaultFolderModel({ name: name });
        var request = Wethod.request('integration:external:drive:canvas:default-folder:save', model);
        $.when(request).done(function (folder) {
          this.hideNewFolder();
          this.model.get('default_folders').push(folder);
          this.showDefaultFolders();
        }.bind(this));
      }
    },
  });

  // ProjectStatus
  this.ProjectStatusLayout = Marionette.LayoutView.extend({
    _template: '#companyProjectStatusTemplate',
    ui: {
      cascading: '[data-region="cascading"]',
      addRiskButton: '[data-action="addRiskButton"]',
      newRisk: '#companyNewRiskRow',
      hideNewRisk: '[data-action="hideNewRisk"]',
      saveNewRisk: '[data-action="saveNewRisk"]',
      riskColorButton: '[data-action="newRiskColor"]',
      PTColorPicker: '.PTColorPicker',
    },
    regions: {
      riskList: '[data-region="companyRiskCollectionLayout"]',
    },
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function () {
      var model = {
        cascading: this.options.cascading.get('cascading_project_status'),
      };
      return _.template($(this._template).html())(model);
    },
    events: {
      'click @ui.cascading': 'toggleCascading',
      'click @ui.addRiskButton': 'showNewRisk',
      'click @ui.hideNewRisk': 'hideNewRisk',
      'click @ui.saveNewRisk': 'saveNewRisk',
      'click @ui.riskColorButton': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
    },
    toggleCascading: function () {
      var require = this.ui.cascading.is(':checked');
      $.when(Wethod.request('update:company:project-status:cascading', require));
    },
    onRender: function () {
      var risksView = new Company.RisksCollectionView({ collection: this.options.risks });
      this.riskList.show(risksView);
    },
    showNewRisk: function () {
      this.ui.newRisk.fadeIn(0);
    },
    hideNewRisk: function () {
      this.ui.newRisk.fadeOut(0);
      this.resetNewRisk();
    },
    resetNewRisk: function () {
      this.ui.newRisk.find('input[name="newRiskName"]').val('');
      this.ui.newRisk.find('.companyPTColor').attr('color', '');
      this.ui.newRisk.find('.companyPTColor').css('background-color', '#dedede');
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hideColorTooltip(picker);
      } else {
        this.showColorTooltip(picker);
      }
    },
    showColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hideColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target).closest('.companyRiskRow').find('.companyPTColor').attr('color', color);
      $(e.target).closest('.companyRiskRow').find('.companyPTColor').css('background-color', color);
      $(e.target).closest('.companyRiskRow').find('.tooltip').removeClass('isVisible');
    },
    saveNewRisk: function () {
      var name = this.ui.newRisk.find('input[name="newRiskName"]').val().trim();
      var color = this.ui.newRisk.find('.companyPTColor').attr('color');
      var data = {
        color: color,
        name: name,
        is_default: false,
      };

      if (name !== '' && color) {
        var insertRequest = Wethod.request('create:company:project-status:risks', data);

        $.when(insertRequest).done(function (insertResponse) {
          this.hideError(); // todo better error handling
          this.hideNewRisk();
          this.options.risks.add(insertResponse);
        }.bind(this));
      } else {
        this.showError(); // todo better error handling
      }
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewRiskRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewRiskRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#companyNewRiskRow').find('#newLabelErrorBox').remove();
    },
  });

  // PLANNING
  this.PlanningTemplateLayout = Marionette.LayoutView.extend({
    _template: '#companyPlanningTemplate',
    ui: {
      planningOverLimit: '[data-region="planningOverLimit"]',
      planningInternationalCalendar: '[data-region="planningInternationalCalendar"]',
      planningHighlightWeekend: '[data-region="planningWeekendCalendar"]',
    },
    events: {
      'click @ui.planningOverLimit': 'updateTimesheetMode',
      'click @ui.planningInternationalCalendar': 'updateInternationalCalendar',
      'click @ui.planningHighlightWeekend': 'updateHighlightWeekend',
    },
    initialize: function (options) {
      this.options = options;
      this._planningModel = options.planningModel;
      this._internationalCalendarModel = options.internationalCalendarModel;
      _.bindAll(this, 'template');
    },
    onRender: function () {
      this.manageWeekendsRowVisibility();
    },
    template: function () {
      var model = {
        over_limit: this._planningModel.get('over_limit'),
        international_calendar: this._internationalCalendarModel.get('enabled'),
        highlight_weekend: this._internationalCalendarModel.get('highlight_weekend'),
      };
      return _.template($(this._template).html())(model);
    },
    isChecked: function (el) {
      return el.is(':checked');
    },
    isCheckedInternationalCalendar: function () {
      return this.isChecked(this.ui.planningInternationalCalendar);
    },
    isCheckedHighlightWeekends: function () {
      return this.isChecked(this.ui.planningHighlightWeekend);
    },
    updateTimesheetMode: function () {
      var isChecked = this.ui.planningOverLimit.is(':checked');
      Company.Controller.savePlanning(isChecked);
    },
    updateInternationalCalendar: function () {
      var isChecked = this.isCheckedInternationalCalendar();
      var highlightWeekends = isChecked ? this.isCheckedHighlightWeekends() : false;
      var options = {
        checked: isChecked,
        highlight_weekends: highlightWeekends,
      };
      Company.Controller.savePlanningInternationalCalendar(options);
      this.manageWeekendsRowVisibility();
    },
    manageWeekendsRowVisibility: function () {
      var rowEl = this.ui.planningHighlightWeekend.parent();
      if (!this.isCheckedInternationalCalendar()) {
        rowEl.addClass('disabled');
        this.ui.planningHighlightWeekend.attr('checked', false);
      } else {
        rowEl.removeClass('disabled');
      }
    },
    updateHighlightWeekend: function (e) {
      var options = {
        checked: this.isCheckedInternationalCalendar(),
        highlight_weekends: this.isCheckedHighlightWeekends(),
      };
      if (!options.checked) {
        e.preventDefault();
        return;
      }
      Company.Controller.savePlanningInternationalCalendar(options);
    },
  });

  // MODAL
  this.BaseModalItemView = Marionette.ItemView.extend({
    className: 'modalWrapper',
    initialize: function (options) {
      this._externalModel = options.externalModel;
    },
    onRender: function () {
      this.resizeModal();
    },
    placeModal: function (w, h) {
      this._modalW = typeof w !== 'undefined' ? w : 350;
      this._modalH = typeof h !== 'undefined' ? h : 150;

      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - this._modalW) / 2;
      var posTop = (contextH - this._modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    resizeModal: function () {
      var height = 0;
      $.each(this.ui.modalEL.children(), function (index, child) {
        height += $(child).outerHeight();
      });

      height = height < 150 ? 150 : height;

      this.ui.modalEL.css({
        height: height,
        width: this._modalW,
      });
      this.placeModal(this._modalW, height);
    },
  });

  this.MulticurrencyModalItemView = this.BaseModalItemView.extend({
    template: '#companyMulticurrencyModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      actionButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
      'click @ui.actionButton': 'submitAction',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancelAction: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    submitAction: function (e) {
      e.preventDefault();

      dispatcher.trigger('company:currency:enable-multicurrency');

      var multicurrencyRequest = Wethod.request('set:company:multicurrency', true);
      $.when(multicurrencyRequest).done(function (response) {
        if (response && response.code === 200) {
          dispatcher.trigger('company:currency:show-currencies');
        }
      });

      Company.Controller.closeModal(this);
    },
  });

  this.LevelModalItemView = this.BaseModalItemView.extend({
    template: '#companyLevelModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      actionButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.actionButton': 'okAction',
    },
    onShow: function () {
      this.resizeModal();
    },
    okAction: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
  });

  this.ReviewCriteriaConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    delete: function (e) {
      e.preventDefault();
      if (this.model.get('type') === 'project') {
        Wethod.request('delete:company:project:review:criteria', this.model.get('criteria'))
          .done(function () {
          });
      } else {
        Wethod.request('delete:company:team:review:criteria', this.model.get('criteria'))
          .done(function () {
          });
      }
      this.destroy();
    },
  });

  this.CanvasDriveIntegrationErrorModalItemView = this.BaseModalItemView.extend({
    template: '#companyCanvasDriveIntegrationErrorModalTemplate',
    ui: {
      modalEL: '.companyPTModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancelAction: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
  });

  this.PTErrorModalItemView = this.BaseModalItemView.extend({
    template: '#companyPTModalTemplate',
    ui: {
      modalEL: '.companyPTModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      submitButton: '[data-action="modalSubmitAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
      'click @ui.submitButton': 'submitAction',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancelAction: function (e) {
      e.preventDefault();
      Company.trigger('reset:company:PT', this.model.get('projectType').id);
      Company.Controller.closeModal(this);
    },
    submitAction: function (e) {
      e.preventDefault();
      var projectType = this.model.get('projectType');
      Company.Controller.updateProjectType(projectType, true);
      Company.Controller.closeModal(this);
    },
  });

  this.PTModalItemView = this.BaseModalItemView.extend({
    template: '#companyPTModalTemplate',
    ui: {
      modalEL: '.companyPTModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      submitButton: '[data-action="modalSubmitAction"]',
      projectTypes: '[data-region="companyModalPTSelect"]',
      projects: '[data-region="companyModalProjectList"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
      'click @ui.submitButton': 'submitAction',
    },
    initialize: function (options) {
      // eslint-disable-next-line no-underscore-dangle
      this.constructor.__super__.initialize.apply(this, arguments);

      this._projectTypesCollection = new Company.PTSelectCollectionView({
        collection: options.model.get('projectTypes'),
      });

      this._projectCollection = new Company.ProjectListCollectionView({
        collection: options.model.get('projects'),
      });
    },
    onShow: function () {
      this.ui.projectTypes.html(this._projectTypesCollection.render().$el);
      this.ui.projects.html(this._projectCollection.render().$el);
      this.resizeModal();
    },
    cancelAction: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    submitAction: function (e) {
      e.preventDefault();

      var oldPTId = this.model.get('PTId');
      var newPTId = this.ui.projectTypes.find('select').val();

      Company.Controller.deleteProjectType(oldPTId, newPTId);
      Company.Controller.closeModal(this);
    },
  });

  this.PTSelectItemView = Marionette.ItemView.extend({
    template: '#companyPTSelectTemplate',
    tagName: 'option',
    attributes: function () {
      return {
        value: this.model.get('id'),
      };
    },
  });

  this.PTSelectCollectionView = Marionette.CollectionView.extend({
    childView: Company.PTSelectItemView,
    className: 'col_9 col_offset_sx_1',
    tagName: 'select',
  });

  this.projectListItemView = Marionette.ItemView.extend({
    template: '#companyProjectList',
    tagName: 'li',
  });

  this.ProjectListCollectionView = Marionette.CollectionView.extend({
    childView: Company.projectListItemView,
    className: 'col_15 col_offset_sx_1 left ',
    tagName: 'ul',
  });

  // METADATA
  this.MetadataLayout = Marionette.LayoutView.extend({
    template: '#companyMetadataLayoutTemplate',
    className: 'companyMetadata bottomSpace',
    regions: {
      metadataCollection: '[data-region="companyMetadataCollection"]',
    },
    ui: {
      addMetadataButton: '[data-action="addMetadata"]',
    },
    events: {
      'click @ui.addMetadataButton': function () {
        if (this.ui.addMetadataButton.hasClass('disabled')) {
          return;
        }

        dispatcher.trigger('add:empty:company:metadata');
        this.addMetadataButtonSetEnabled(false);
      },
    },
    initialize: function () {
      dispatcher.on('added:company:metadata', function () {
        this.addMetadataButtonSetEnabled(true);
      }.bind(this));

      dispatcher.on('rollback:company:metadata', function () {
        this.addMetadataButtonSetEnabled(true);
      }.bind(this));
    },
    addMetadataButtonSetEnabled: function (bool) {
      if (bool) {
        this.ui.addMetadataButton.removeClass('disabled');
      } else {
        this.ui.addMetadataButton.addClass('disabled');
      }
    },
    onDestroy: function () {
      dispatcher.off('added:company:metadata');
      dispatcher.off('rollback:company:metadata');
    },
  });

  this.MetadataItemView = Marionette.ItemView.extend({
    tagName: 'ul',
    className: 'companyMetadata__row',
    getTemplate: function () {
      return this.model.isNew() ? '#companyMetadataAddTemplate' : '#companyMetadataItemTemplate';
    },
    ui: {
      keyInput: '[data-region="metadataKey"]',
      valuesInput: '[data-region="metadataValues"]',
      requiredCheck: '[data-region="metadataIsRequired"]',

      saveMetadataButton: '[data-action="saveMetadata"]',
      deleteMetadataButton: '[data-action="deleteMetadata"]',
    },
    events: {
      'click @ui.saveMetadataButton': 'createMetadata',
      'click @ui.deleteMetadataButton': 'showDeleteMetadataModal',
      'change input': 'updateModel',
    },
    initialize: function () {
      dispatcher.on('company:metadata:deleteMetadataConfirm', this.deleteMetadata);
    },
    syncAttributes: function () {
      this.model.set('key', this.ui.keyInput.val());
      this.model.set('values', this.ui.valuesInput.val());
      this.model.set('required', this.ui.requiredCheck.is(':checked'));
    },
    createMetadata: function () {
      this.syncAttributes();
      var createRequest = Wethod.request('create:company:metadata', this.model);
      $.when(createRequest).done(function (createResponse) {
        if (createResponse) {
          this.render();
          dispatcher.trigger('added:company:metadata');
        }
      }.bind(this));
    },
    updateModel: function () {
      // New metadata must be saved only when save button is pressed
      if (this.model.isNew()) {
        return;
      }

      this.syncAttributes();
      Wethod.request('update:company:metadata', this.model);
    },
    showDeleteMetadataModal: function () {
      if (this.model.isNew()) {
        dispatcher.trigger('rollback:company:metadata');
      }

      var modal = new Company.LevelModal({
        title: 'Delete metadata',
        text: 'You are about to delete this metadata. Are you sure you want to continue?',
        buttonOkText: 'DELETE',
        buttonKoText: 'GO BACK',
      });
      dispatcher.trigger('company:metadata:modal:show', modal, this);
    },
    deleteMetadata: function (modalView) {
      var deleteRequest = Wethod.request('delete:company:metadata', this.model);
      $.when(deleteRequest).done(function () {
        modalView.destroy();
      });
    },
  });

  this.MetadataConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    _metadataView: null,
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },

    initialize: function (options) {
      this._metadataView = options.metadataView;
    },

    onShow: function () {
      this.resizeModal();
    },

    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },

    delete: function (e) {
      e.preventDefault();
      this._metadataView.deleteMetadata(this);
      this.destroy();
    },
  });

  this.MetadataEmptyView = Marionette.ItemView.extend({
    template: '#companyMetadataEmptyTemplate',
    tagName: 'ul',
    className: 'companyMetadata__row',
  });

  this.MetadataCollectionView = Marionette.CollectionView.extend({
    childView: Company.MetadataItemView,
    emptyView: Company.MetadataEmptyView,
    initialize: function () {
      this.collection.comparator = function (metadata) {
        return metadata.get('key');
      };

      dispatcher.on('add:empty:company:metadata', this.addEmptyMetadata, this);
    },
    addEmptyMetadata: function () {
      var metadata = new Company.MetadataModel();
      this.collection.add(metadata, { at: 0 });
    },
    onDestroy: function () {
      dispatcher.off('add:empty:company:metadata');
    },
  });

  // WON/LOST FEEDBACK
  this.WonLostFeedbackLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyFeedbackTemplate',
    regions: {
      wonFeedback: '[data-region="companyWonFeedbackCollectionLayout"]',
      lostFeedback: '[data-region="companyLostFeedbackCollectionLayout"]',
    },
    ui: {
      showNewWonFeedback: '[data-action="showNewWonFeedback"]',
      hideNewWonFeedback: '[data-action="hideNewWonFeedback"]',
      saveNewWonFeedback: '[data-action="saveNewWonFeedback"]',
      newWonFeedbackRow: '#companyNewWonFeedbackRow',
      showNewLostFeedback: '[data-action="showNewLostFeedback"]',
      hideNewLostFeedback: '[data-action="hideNewLostFeedback"]',
      saveNewLostFeedback: '[data-action="saveNewLostFeedback"]',
      newLostFeedbackRow: '#companyNewLostFeedbackRow',
    },
    events: {
      'click @ui.showNewWonFeedback': 'showNewWonFeedback',
      'click @ui.hideNewWonFeedback': 'hideNewWonFeedback',
      'click @ui.saveNewWonFeedback': 'saveNewWonFeedback',
      'click @ui.showNewLostFeedback': 'showNewLostFeedback',
      'click @ui.hideNewLostFeedback': 'hideNewLostFeedback',
      'click @ui.saveNewLostFeedback': 'saveNewLostFeedback',
    },
    showNewWonFeedback: function () {
      this.ui.newWonFeedbackRow.fadeIn(0);
    },
    hideNewWonFeedback: function () {
      this.ui.newWonFeedbackRow.fadeOut(0);
      this.resetNewWonFeedback();

      this.hideError(); // todo manage this
    },
    resetNewWonFeedback: function () {
      this.ui.newWonFeedbackRow.find('input[name="newWonFeedbackName"]').val('');
    },
    saveNewWonFeedback: function () {
      var data = {
        name: _.escape(this.ui.newWonFeedbackRow.find('input[name="newWonFeedbackName"]')
          .val()
          .trim()),
        type: 'won',
      };

      Company.Controller.insertWonLostFeedback(data);
    },
    showNewLostFeedback: function () {
      this.ui.newLostFeedbackRow.fadeIn(0);
    },
    hideNewLostFeedback: function () {
      this.ui.newLostFeedbackRow.fadeOut(0);
      this.resetNewLostFeedback();

      this.hideError(); // todo manage this
    },
    resetNewLostFeedback: function () {
      this.ui.newLostFeedbackRow.find('input[name="newLostFeedbackName"]').val('');
    },
    saveNewLostFeedback: function () {
      var data = {
        name: _.escape(this.ui.newLostFeedbackRow.find('input[name="newLostFeedbackName"]')
          .val()
          .trim()),
        type: 'lost',
      };

      Company.Controller.insertWonLostFeedback(data);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewWonLabelRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewWonLabelRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      $('#newDAErrorBox').remove();
    },
  });

  this.WonLostFeedbackItemView = Marionette.ItemView.extend({
    template: '#companyFeedbackCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
    ui: {
      deleteFeedbackButton: '[data-action="deleteFeedback"]',
      feedbackName: '[data-action="FeedbackName"]',
    },
    events: {
      'click @ui.deleteFeedbackButton': 'deleteWonLostFeedback',
      'change @ui.feedbackName': 'updateWonLostFeedback',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    updateWonLostFeedback: function () {
      var name = _.escape(this.ui.feedbackName.val().trim());

      if (name !== '') {
        this.model.set({ name: name });
        Company.Controller.updateWonLostFeedback(this.model);
      }
    },
    deleteWonLostFeedback: function () {
      Company.Controller.showWonLostFeedbackModal(this.model);
    },
  });

  this.WonLostFeedbackEmptyItemView = Marionette.ItemView.extend({
    template: '#companyFeedbackEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
  });

  this.WonLostFeedbackCollectionView = Marionette.CollectionView.extend({
    childView: Company.WonLostFeedbackItemView,
    emptyView: Company.WonLostFeedbackEmptyItemView,
    className: 'companyFeedbackCollection',
  });

  this.SupplierLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companySupplierTemplate',
    regions: {
      categoryContent: '[data-region="companySupplierCategoryContent"]',
      statusContent: '[data-region="companySupplierStatusContent"]',
    },
    initialize: function (options) {
      this.model = options.model;
    },
    childEvents: {
      'company:supplier:enable-status': 'onChildEnableStatus',
      'company:supplier:disable-status': 'onChildDisableStatus',
    },
    onChildEnableStatus: function () {
      var loadingView = new Company.SupplierLoadingView();
      this.statusContent.show(loadingView);
      this.model.set('status_enabled', true);
      Wethod.request('set:company:supplier-status:enabled', true).done(function () {
        Wethod.request('get:company:supplier-status').done(function (response) {
          var enabledView = new Company.SupplierStatusEnabledView({ model: response });
          this.statusContent.show(enabledView);
        }.bind(this));
      }.bind(this));
    },
    onChildDisableStatus: function () {
      var loadingView = new Company.SupplierLoadingView();
      this.statusContent.show(loadingView);

      this.model.set('status_enabled', false);
      Wethod.request('set:company:supplier-status:enabled', false).done(function () {
        var disabledView = new Company.SupplierStatusDisabledView();
        this.statusContent.show(disabledView);
      }.bind(this));
    },
  });

  this.SupplierStatusDisabledView = Marionette.ItemView.extend({
    template: '#companySupplierStatusDisabledTemplate',
    ui: {
      enableSupplierStatusButton: '[data-action="enableSupplierStatusButton"]',
    },
    events: {
      'click @ui.enableSupplierStatusButton': 'enableSupplierStatus',
    },
    enableSupplierStatus: function () {
      this.triggerMethod('company:supplier:enable-status');
    },
  });

  this.SupplierStatusEnabledView = Marionette.LayoutView.extend({
    template: '#companySupplierStatusEnabledTemplate',
    ui: {
      disableStatus: '[data-action="disableSupplierStatusButton"]',
      showNewStatus: '[data-action="addSupplierStatusButton"]',
      hideNewStatus: '[data-action="hideNewSupplierStatus"]',
      saveNewStatus: '[data-action="saveNewSupplierStatus"]',
      newStatus: '#companyNewSupplierStatusRow',
      statusColorButton: '[data-action="newSupplierStatusColor"]',
      PTColorPicker: '.PTColorPicker',
    },
    regions: {
      supplierStatus: '[data-region="companySupplierStatusCollectionLayout"]',
    },
    initialize: function (options) {
      this.model = options.model;
    },
    events: {
      'click @ui.disableStatus': 'disableStatus',
      'click @ui.showNewStatus': 'showNewStatus',
      'click @ui.hideNewStatus': 'hideNewStatus',
      'click @ui.saveNewStatus': 'saveNewStatus',
      'click @ui.statusColorButton': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
    },
    onRender: function () {
      var supplierStatusView = new Company.SupplierStatusCollectionView({ collection: this.model });
      this.supplierStatus.show(supplierStatusView);
    },
    disableStatus: function () {
      this.triggerMethod('company:supplier:disable-status');
    },
    showNewStatus: function () {
      this.ui.newStatus.fadeIn(0);
    },
    hideNewStatus: function () {
      this.ui.newStatus.fadeOut(0);
      this.resetNewStatus();
    },
    resetNewStatus: function () {
      this.ui.newStatus.find('input[name="newSuppliersStatusName"]').val('');
      this.ui.newStatus.find('input[name="supplierStatusAvailable"]').removeAttr('checked');
      this.ui.newStatus.find('.companyPTColor').attr('color', '');
      this.ui.newStatus.find('.companyPTColor').css('background-color', '#dedede');
    },
    saveNewStatus: function () {
      var statusName = this.ui.newStatus.find('input[name="newSuppliersStatusName"]').val().trim();
      var color = this.ui.newStatus.find('.companyPTColor').attr('color');
      var available = this.ui.newStatus.find('input[name="supplierStatusAvailable"]')
        .is(':checked');
      var data = {
        color: color,
        name: statusName,
        available: available,
      };

      Company.Controller.insertSupplierStatus(data);
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hideColorTooltip(picker);
      } else {
        this.showColorTooltip(picker);
      }
    },
    showColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hideColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target).closest('.companySupplierStatusRow').find('.companyPTColor').attr('color', color);
      $(e.target)
        .closest('.companySupplierStatusRow')
        .find('.companyPTColor')
        .css('background-color', color);
      $(e.target).closest('.companySupplierStatusRow').find('.tooltip').removeClass('isVisible');
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewSupplierStatusRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewSupplierStatusRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      $('#newDAErrorBox').remove();
    },
  });

  this.RiskItemView = Marionette.ItemView.extend({
    template: '#companyProjectRiskTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companySupplierStatusRow',
    ui: {
      isDefault: '[data-action="defaultRisk"]',
      name: '[data-action="riskName"]',
      deleteButton: '[data-action="deleteRisk"]',
      colorButton: '[data-action="riskColor"]',
      PTColorPicker: '.PTColorPicker',
    },
    events: {
      'click @ui.isDefault': 'onDefaultClick',
      'change @ui.name': 'onNameChange',
      'click @ui.deleteButton': 'delete',
      'click @ui.colorButton': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onDefaultClick: function () {
      this.model.set({ is_default: true });

      Wethod.request('update:company:project-status:default:risk', this.model.get('id'))
        .done(function () {
        });
    },
    onNameChange: function () {
      var name = this.ui.name.val().trim();
      if (name !== '') {
        this.model.set({ name: name });
        this.updateStatus();
      }
    },
    updateStatus: function () {
      var isDefault = this.ui.isDefault.is(':checked');
      this.model.set('is_default', isDefault);
      Wethod.request('update:company:project-status:risks', this.model).done(function (response) {
        this.model = response;
      }.bind(this));
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hideColorTooltip(picker);
      } else {
        this.showColorTooltip(picker);
      }
    },
    showColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hideColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target).closest('.companyLabelRow').find('.companyPTColor').attr('color', color);
      $(e.target)
        .closest('.companyLabelRow')
        .find('.companyPTColor')
        .css('background-color', color);
      $(e.target).closest('.companyLabelRow').find('.tooltip').removeClass('isVisible');

      this.model.set('color', color);
      this.updateStatus();
    },
    delete: function () {
      var isDefault = this.ui.isDefault.is(':checked');
      this.model.set('is_default', isDefault);

      Company.Controller.showProjectStatusRiskDeleteModal(this.model);
    },
  });

  this.SupplierStatusItemView = Marionette.ItemView.extend({
    template: '#companySupplierStatusCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companySupplierStatusRow',
    ui: {
      statusAvailable: '[data-action="supplierStatusAvailable"]',
      statusName: '[data-action="supplierStatusName"]',
      deleteStatus: '[data-action="deleteSupplierStatus"]',
      statusColorButton: '[data-action="supplierStatusColor"]',
      PTColorPicker: '.PTColorPicker',
    },
    events: {
      'click @ui.statusAvailable': 'onStatusAvailableClick',
      'change @ui.statusName': 'onStatusNameChange',
      'click @ui.deleteStatus': 'deleteStatus',
      'click @ui.statusColorButton': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onStatusAvailableClick: function () {
      var available = this.ui.statusAvailable.is(':checked');
      this.model.set('available', available);
      this.updateStatus();
    },
    onStatusNameChange: function () {
      var name = this.ui.statusName.val().trim();
      if (name !== '') {
        this.model.set({ name: name });
        this.updateStatus();
      }
    },
    updateStatus: function () {
      Wethod.request('update:company:supplier-status', this.model).done(function (response) {
        this.model = response;
      }.bind(this));
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hideColorTooltip(picker);
      } else {
        this.showColorTooltip(picker);
      }
    },
    showColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hideColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target).closest('.companyLabelRow').find('.companyPTColor').attr('color', color);
      $(e.target)
        .closest('.companyLabelRow')
        .find('.companyPTColor')
        .css('background-color', color);
      $(e.target).closest('.companyLabelRow').find('.tooltip').removeClass('isVisible');

      this.model.set('color', color);
      this.updateStatus();
    },
    deleteStatus: function () {
      Company.Controller.deleteSupplierStatus(this.model);
    },
  });

  this.RisksCollectionView = Marionette.CollectionView.extend({
    childView: Company.RiskItemView,
    className: 'companySupplierStatusCollection',
  });

  this.SupplierStatusCollectionView = Marionette.CollectionView.extend({
    childView: Company.SupplierStatusItemView,
    className: 'companySupplierStatusCollection',
  });

  this.SupplierLoadingView = Marionette.ItemView.extend({
    template: '#companySupplierStatusLoadingTemplate',
    className: 'topMargin bottomSpace',
  });

  this.SupplierTagCategoryView = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companySupplierCategoryTemplate',
    regions: {
      category: '[data-region="companySupplierCategoryCollectionLayout"]',
    },
    initialize: function (options) {
      this.categories = options.categories;
      this.tags = options.tags;
    },
    ui: {
      showNewCategory: '[data-action="addSupplierCategoryButton"]',
      hideNewCategory: '[data-action="hideNewSupplierCategory"]',
      saveNewCategory: '[data-action="saveNewSupplierCategory"]',
      newCategoryRow: '#companyNewSupplierCategoryRow',
    },
    events: {
      'click @ui.showNewCategory': 'showNewCategory',
      'click @ui.hideNewCategory': 'hideNewCategory',
      'click @ui.saveNewCategory': 'saveNewCategory',
    },
    onRender: function () {
      var categoryView = new Company.SupplierTagCategoryCollectionView({
        collection: this.categories,
        tags: this.tags,
      });
      this.category.show(categoryView);
    },
    showNewCategory: function () {
      this.ui.newCategoryRow.fadeIn(0);
    },
    hideNewCategory: function () {
      this.ui.newCategoryRow.fadeOut(0);
      this.resetNewCategory();

      this.hideError(); // todo manage this
    },
    resetNewCategory: function () {
      this.ui.newCategoryRow.find('input[name="newSupplierCategoryName"]').val('');
    },
    saveNewCategory: function () {
      var name = this.ui.newCategoryRow.find('input[name="newSupplierCategoryName"]').val().trim();
      var data = {
        name: name,
      };
      Company.Controller.insertSupplierTagCategory(data);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewSupplierCategoryRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewSupplierCategoryRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      $('#newDAErrorBox').remove();
    },
  });

  this.SupplierTagCategoryItemView = Marionette.LayoutView.extend({
    template: '#companySupplierCategoryCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companySupplierCategoryRow',
    regions: {
      tag: '[data-region="companySupplierTagCollectionLayout"]',
    },
    ui: {
      categoryName: '[data-action="supplierCategoryName"]',
      deleteCategory: '[data-action="deleteSupplierCategory"]',
      showNewTag: '[data-action="addSupplierTagButton"]',
      hideNewTag: '[data-action="hideNewSupplierTag"]',
      saveNewTag: '[data-action="saveNewSupplierTag"]',
      newTagRow: '#companyNewSupplierTagRow',
    },
    events: {
      'change @ui.categoryName': 'onCategoryNameChange',
      'click @ui.deleteCategory': 'deleteCategory',
      'click @ui.showNewTag': 'showNewTag',
      'click @ui.hideNewTag': 'hideNewTag',
      'click @ui.saveNewTag': 'saveNewTag',
    },
    initialize: function (options) {
      this.tags = options.tags;
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));

      var tagView = new Company.SupplierTagCollectionView({ collection: this.tags });
      this.tag.show(tagView);
    },
    onCategoryNameChange: function () {
      var name = this.ui.categoryName.val().trim();
      if (name !== '') {
        this.model.set({ name: name });
        this.updateCategory();
      }
    },
    updateCategory: function () {
      Wethod.request('update:company:supplier-tag-category', this.model).done(function (response) {
        this.model = response;
      }.bind(this));
    },
    deleteCategory: function () {
      Company.Controller.deleteSupplierTagCategory(this.model);
    },
    showNewTag: function () {
      this.ui.newTagRow.fadeIn(0);
    },
    hideNewTag: function () {
      this.ui.newTagRow.fadeOut(0);
      this.resetNewTag();

      this.hideError(); // todo manage this
    },
    resetNewTag: function () {
      this.ui.newTagRow.find('input[name="newSupplierTagName"]').val('');
    },
    saveNewTag: function () {
      var name = this.ui.newTagRow.find('input[name="newSupplierTagName"]').val().trim();
      var data = {
        name: name,
        category: this.model.get('id'),
      };

      if (data.name !== '') {
        var insertRequest = Wethod.request('create:company:supplier-tag', data);

        $.when(insertRequest).done(function (insertResponse) {
          this.hideNewTag();
          this.tags.add(insertResponse);
          this.hideError(); // todo better error handling
        }.bind(this));
      } else {
        this.showError(); // todo better error handling
      }
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewSupplierCategoryRow').find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewSupplierCategoryRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      $('#newDAErrorBox').remove();
    },
  });

  this.SupplierTagCategoryCollectionView = Marionette.CollectionView.extend({
    childView: Company.SupplierTagCategoryItemView,
    className: 'companySupplierCategoryCollection',
    childViewOptions: function (model) {
      var tags = this.options.tags.filter(function (item) {
        return item.get('category') === model.get('id');
      });
      tags = new Company.SupplierTagCollection(tags);
      return {
        tags: tags,
      };
    },
  });

  this.SupplierTagItemView = Marionette.ItemView.extend({
    template: '#companySupplierTagCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companyTopBorderRow',
    ui: {
      tagName: '[data-action="supplierTagName"]',
      deleteTag: '[data-action="deleteSupplierTag"]',
    },
    events: {
      'change @ui.tagName': 'onTagNameChange',
      'click @ui.deleteTag': 'deleteTag',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onTagNameChange: function () {
      var name = this.ui.tagName.val().trim();
      if (name !== '') {
        this.model.set({ name: name });
        this.updateTag();
      }
    },
    updateTag: function () {
      Wethod.request('update:company:supplier-tag', this.model).done(function (response) {
        this.model = response;
      }.bind(this));
    },
    deleteTag: function () {
      Wethod.request('delete:company:supplier-tag', this.model).done(function (response) {
        this.model = response;
      }.bind(this));
      this.destroy();
    },
  });

  this.SupplierTagCollectionView = Marionette.CollectionView.extend({
    childView: Company.SupplierTagItemView,
    className: 'companySupplierTagCollection',
  });

  this.FeedbackConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },

    onShow: function () {
      this.resizeModal();
    },

    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },

    delete: function (e) {
      e.preventDefault();
      Company.Controller.deleteWonLostFeedback(this.model.get('feedback'));
      this.destroy();
    },
  });

  this.feedbackProjectListItemView = Marionette.ItemView.extend({
    template: '#companyFeedbackProjectList',
    tagName: 'li',
  });

  this.FeedbackProjectListCollectionView = Marionette.CollectionView.extend({
    childView: Company.feedbackProjectListItemView,
    className: 'col_15 col_offset_sx_1 left ',
    tagName: 'ul',
  });

  this.FeedbackCannotDeleteModalItemView = this.BaseModalItemView.extend({
    template: '#companyFeedbackCannotDeleteModalTemplate',
    ui: {
      modalEL: '.companyPTModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      projects: '[data-region="companyFeedbackModalProjectList"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function (options) {
      this._projectCollection = new Company.FeedbackProjectListCollectionView({
        collection: options.model.get('projects'),
      });
    },
    onShow: function () {
      this.ui.projects.html(this._projectCollection.render().$el);
      this.resizeModal();
    },
    cancelAction: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
  });

  this.SupplierStatusConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    delete: function (e) {
      e.preventDefault();
      Company.Controller.deleteSupplierStatus(this.model.get('status'), true);
      this.destroy();
    },
  });

  this.SupplierCategoryConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    delete: function (e) {
      e.preventDefault();
      Company.Controller.deleteSupplierTagCategory(this.model.get('category'), true);
      this.destroy();
    },
  });

  // Business Units
  this.BusinessUnitLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyBusinessUnitTemplate',
    regions: {
      content: '[data-region="companyBusinessUnitContent"]',
    },
    initialize: function (options) {
      this.defaultFilterModel = options.defaultFilterModel;
    },
    childEvents: {
      'company:business-unit:enable-business-unit': 'onChildEnableBusinessUnit',
      'company:business-unit:disable-business-unit': 'onChildDisableBusinessUnit',
    },

    onChildEnableBusinessUnit: function () {
      var loadingView = new Company.BusinessUnitLoadingView();
      this.content.show(loadingView);

      var enableBusinessUnitRequest = Wethod.request('set:company:business-unit:enabled', true);
      $.when(enableBusinessUnitRequest).done(function () {
        Company.Controller.updateUserInfoBusinessUnitEnabled(true);
        var businessUnits = new Company.BusinessUnitCollection();
        var businessUnitService = new BusinessUnitService();
        businessUnits.reset(businessUnitService.getForCompany());
        var enabledView = new Company.BusinessUnitEnabledView({
          model: this.defaultFilterModel,
          businessUnits: businessUnits,
        });
        this.content.show(enabledView);
        dispatcher.trigger('company:settings:bu:changedView', enabledView, businessUnits);
      }.bind(this));
    },
    onChildDisableBusinessUnit: function () {
      var loadingView = new Company.BusinessUnitLoadingView();
      this.content.show(loadingView);

      Wethod.request('set:company:business-unit:enabled', false).done(function (response) {
        Company.Controller.updateUserInfoBusinessUnitEnabled(false);
        var disabledView = new Company.BusinessUnitDisabledView({ model: response });
        this.content.show(disabledView);
      }.bind(this));
    },
  });

  this.BusinessUnitLoadingView = Marionette.ItemView.extend({
    template: '#companyBusinessUnitLoadingTemplate',
    className: 'topMargin bottomSpace',
  });

  this.BusinessUnitDisabledView = Marionette.ItemView.extend({
    template: '#companyBusinessUnitDisabledTemplate',
    ui: {
      enableBusinessUnitButton: '[data-action="enableBusinessUnitButton"]',
    },
    events: {
      'click @ui.enableBusinessUnitButton': 'enableBusinessUnit',
    },
    enableBusinessUnit: function () {
      this.triggerMethod('company:business-unit:enable-business-unit');
    },
  });

  this.BusinessUnitEnabledView = Marionette.LayoutView.extend({
    template: '#companyBusinessUnitEnabledTemplate',
    ui: {
      disableBU: '[data-action="disableBusinessUnitButton"]',
      defaultBUFilterSelect: '#defaultBUFilter',
      crossBUMarkup: '#crossBUMarkup',
      showNewBusinessUnit: '[data-action="addBusinessUnitButton"]',
      hideNewBusinessUnit: '[data-action="hideNewBusinessUnit"]',
      saveNewBusinessUnit: '[data-action="saveNewBusinessUnit"]',
      newBusinessUnit: '#companyNewBusinessUnitRow',
    },
    regions: {
      businessUnitList: '[data-region="companyBusinessUnitCollectionLayout"]',
    },
    initialize: function (options) {
      this.model = options.model;
      this._businessUnits = options.businessUnits;
    },
    events: {
      'click @ui.disableBU': 'disableBU',
      'change @ui.defaultBUFilterSelect': 'updateDefaultBUFilter',
      'input @ui.crossBUMarkup': 'formatBUMarkupRange',
      'change @ui.crossBUMarkup': 'updateBUMarkup',
      'click @ui.showNewBusinessUnit': 'showNewBusinessUnit',
      'click @ui.hideNewBusinessUnit': 'hideNewBusinessUnit',
      'click @ui.saveNewBusinessUnit': 'saveNewBusinessUnit',
    },
    onRender: function () {
      var businessUnitsView = new Company.BusinessUnitCollectionView({
        collection: this._businessUnits,
      });
      this.businessUnitList.show(businessUnitsView);
    },
    disableBU: function () {
      this.triggerMethod('company:business-unit:disable-business-unit');
    },
    updateDefaultBUFilter: function () {
      var userBUFilter = this.ui.defaultBUFilterSelect.val() === 'user_bu';
      Wethod.request('set:company:business-unit:filter', userBUFilter).done(function () {
        this.model.set('filter_on_user_bu', userBUFilter);
        Company.Controller.updateUserInfoBusinessUnitFilter(userBUFilter);
      }.bind(this));
    },
    formatBUMarkupRange: function () {
      var crossBUMarkup = this.ui.crossBUMarkup.val();
      if (crossBUMarkup > 100) {
        this.ui.crossBUMarkup.val(crossBUMarkup.slice(0, -1));
      } else if (crossBUMarkup < 0) {
        this.ui.crossBUMarkup.val(crossBUMarkup * -1);
      }
    },
    updateBUMarkup: function () {
      var crossBUMarkup = this.ui.crossBUMarkup.val();
      if (!crossBUMarkup) {
        crossBUMarkup = 0;
      }
      Wethod.request('set:company:business-unit:markup', crossBUMarkup).done(function () {
        this.model.set('markup', crossBUMarkup);
      }.bind(this));
    },
    showNewBusinessUnit: function () {
      this.ui.newBusinessUnit.fadeIn(0);
      this.ui.saveNewBusinessUnit.fadeIn(0);
    },
    hideNewBusinessUnit: function () {
      this.ui.newBusinessUnit.fadeOut(0);
      this.resetNewBusinessUnit();
    },
    resetNewBusinessUnit: function () {
      this.ui.newBusinessUnit.find('input[name="newBusinessUnit"]').val('');
    },
    saveNewBusinessUnit: function () {
      this.ui.saveNewBusinessUnit.fadeOut(0);
      var name = this.ui.newBusinessUnit.find('input[name="newBusinessUnit"]').val().trim();
      var data = {
        name: name,
      };
      Company.Controller.insertBusinessUnit(data);
    },
  });

  this.BusinessUnitItemView = Marionette.ItemView.extend({
    template: '#companyBusinessUnitCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow',
    ui: {
      deleteBusinessUnitButton: '[data-action="deleteBusinessUnit"]',
      businessUnitName: '[data-action="businessUnitName"]',
    },
    events: {
      'click @ui.deleteBusinessUnitButton': 'deleteBusinessUnit',
      'change @ui.businessUnitName': 'updateBusinessUnit',
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    updateBusinessUnit: function () {
      var name = _.escape(this.ui.businessUnitName.val().trim());

      if (name !== '') {
        this.model.set({ name: name });
        Company.Controller.updateBusinessUnit(this.model);
      }
    },
    deleteBusinessUnit: function () {
      Company.Controller.deleteBusinessUnit(this.model);
    },
  });

  this.BusinessUnitCollectionView = Marionette.CollectionView.extend({
    childView: Company.BusinessUnitItemView,
    className: 'companyBusinessUnitCollection',
  });

  this.BusinessUnitConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    delete: function (e) {
      e.preventDefault();
      Company.Controller.deleteBusinessUnit(this.model.get('business-unit'), true);
      this.destroy();
    },
  });

  this.ProjectStatusRiskConfirmDeleteModalView = this.BaseModalItemView.extend({
    template: '#companyMetadataModalTemplate',
    ui: {
      modalEL: '.companyLevelModalStructure',
      cancelButton: '[data-action="modalCancelAction"]',
      deleteButton: '[data-action="modalDoAction"]',
    },
    events: {
      'click @ui.cancelButton': 'cancel',
      'click @ui.deleteButton': 'delete',
    },
    onShow: function () {
      this.resizeModal();
    },
    cancel: function (e) {
      e.preventDefault();
      Company.Controller.closeModal(this);
    },
    delete: function (e) {
      e.preventDefault();
      Wethod.request('delete:company:project-status:risks', this.model.get('risk'))
        .done(function () {
        });
      this.destroy();
    },
  });

  this.PipelineOpportunityLayout = Marionette.LayoutView.extend({
    template: '#companyPipelineOpportunityTemplate',
    ui: {
      addStatusButton: '[data-action="addStatusButton"]',
      newStatus: '#companyNewStatusRow',
      hideNewStatus: '[data-action="hideNewStatus"]',
      saveNewStatus: '[data-action="saveNewStatus"]',
      statusColorButton: '[data-action="newStatusColor"]',
      PTColorPicker: '.PTColorPicker',
    },
    regions: {
      defaultStatus: '[data-region="companyOpportunityDefaultStatusLayout"]',
      statusList: '[data-region="companyOpportunityStatusCollectionLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    events: {
      'click @ui.addStatusButton': 'showNewStatus',
      'click @ui.hideNewStatus': 'hideNewStatus',
      'click @ui.saveNewStatus': 'saveNewStatus',
      'click @ui.statusColorButton': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
    },
    showNewStatus: function () {
      this.ui.newStatus.fadeIn(0);
    },
    hideNewStatus: function () {
      this.ui.newStatus.fadeOut(0);
      this.resetNewStatus();
    },
    resetNewStatus: function () {
      this.ui.newStatus.find('input[name="newStatusName"]').val('');
      this.ui.newStatus.find('input[name="newStatusDescription"]').val('');
      this.ui.newStatus.find('.companyPTColor').attr('color', '');
      this.ui.newStatus.find('.companyPTColor').css('background-color', '#dedede');
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hideColorTooltip(picker);
      } else {
        this.showColorTooltip(picker);
      }
    },
    showColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hideColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target).closest('.companyOpportunityStatusRow').find('.companyPTColor')
        .attr('color', color);
      $(e.target).closest('.companyOpportunityStatusRow').find('.companyPTColor')
        .css('background-color', color);
      $(e.target).closest('.companyOpportunityStatusRow').find('.tooltip').removeClass('isVisible');
    },
    saveNewStatus: function () {
      var name = this.ui.newStatus.find('input[name="newStatusName"]').val().trim();
      var description = this.ui.newStatus.find('input[name="newStatusDescription"]').val().trim();
      var color = this.ui.newStatus.find('.companyPTColor').attr('color');
      var data = {
        color: color,
        name: name,
        description: description,
      };

      Company.Controller.insertOpportunityStatus(data);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewStatusRow')
        .find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewStatusRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#companyNewStatusRow').find('#newLabelErrorBox').remove();
    },
  });

  this.PipelineOpportunityStatusEmptyItemView = Marionette.ItemView.extend({
    template: '#companyOpportunityStatusEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyOpportunityStatusRow',
  });

  this.PipelineOpportunityStatusDisabledItemView = Marionette.ItemView.extend({
    template: '#companyOpportunityStatusDisabledTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyOpportunityStatusRow',
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
  });

  this.PipelineOpportunityStatusItemView = Marionette.ItemView.extend({
    template: '#companyOpportunityStatusCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyOpportunityStatusRow companyOpportunityStatusRow--draggable',
    ui: {
      statusDescription: '[data-action="opportunityStatusDescription"]',
      statusName: '[data-action="opportunityStatusName"]',
      deleteStatus: '[data-action="deleteOpportunityStatus"]',
      statusColorButton: '[data-action="opportunityStatusColor"]',
      PTColorPicker: '.PTColorPicker',
    },
    events: {
      'change @ui.statusDescription': 'onStatusDescriptionChange',
      'change @ui.statusName': 'onStatusNameChange',
      'click @ui.deleteStatus': 'deleteStatus',
      'click @ui.statusColorButton': 'toggleColorPicker',
      'click @ui.PTColorPicker': 'pickColor',
    },
    modelEvents: {
      'change:position': 'onChangePosition',
    },
    onChangePosition: function () {
      this.render();
    },
    initialize: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
      var cannotDelete = this.model.get('is_won') || this.model.get('is_lost') || this.model.get('is_default');
      this.model.set('can_delete', !cannotDelete);
    },
    onStatusNameChange: function () {
      var name = this.ui.statusName.val().trim();
      if (name !== '') {
        var changes = { name: name };

        this.model.set(changes);
        this.updateStatus(changes);
      }
    },
    onStatusDescriptionChange: function () {
      var description = this.ui.statusDescription.val().trim();
      // Status which cannot be deleted has static description
      if (description !== '' && this.model.get('can_delete')) {
        var changes = { description: description };

        this.model.set(changes);
        this.updateStatus(changes);
      }
    },
    updateStatus: function (changes) {
      Company.Controller.updateOpportunityStatus(this.model, changes);
    },
    toggleColorPicker: function (e) {
      var picker = $(e.currentTarget.parentElement).find('.tooltip');

      if (picker.hasClass('isVisible')) {
        this.hideColorTooltip(picker);
      } else {
        this.showColorTooltip(picker);
      }
    },
    showColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.addClass('picker-visible');
      picker.addClass('isVisible');
    },
    hideColorTooltip: function (picker) {
      var colorCell = picker.parent().parent();
      colorCell.removeClass('picker-visible');
      picker.removeClass('isVisible');
    },
    pickColor: function (e) {
      var color = $(e.target).css('background-color');

      $(e.target)
        .closest('.companyOpportunityStatusRow')
        .find('.companyPTColor')
        .attr('color', color);
      $(e.target)
        .closest('.companyOpportunityStatusRow')
        .find('.companyPTColor')
        .css('background-color', color);
      $(e.target).closest('.companyOpportunityStatusRow').find('.tooltip').removeClass('isVisible');

      var changes = { color: color };

      this.model.set(changes);
      this.updateStatus(changes);
    },
    deleteStatus: function () {
      Company.Controller.deleteOpportunityStatus(this.model);
    },
  });

  this.PipelineOpportunityStatusCollectionView = Marionette.CollectionView.extend({
    childView: Company.PipelineOpportunityStatusItemView,
    emptyView: Company.PipelineOpportunityStatusEmptyItemView,
    className: 'companyOpportunityStatusCollection',
    grid: null,
    onShow: function () {
      this.initDraggable();
    },
    onRender: function () {
      this.initDraggable();
    },
    /**
     * Makes each item of the collection sortable by drag.
     */
    initDraggable: function () {
      this.grid = $('.companyOpportunityStatusCollection').packery({
        itemSelector: '.companyOpportunityStatusRow--draggable',
        // columnWidth helps with drop positioning
        columnWidth: 1024,
        rowHeight: 56,
      });

      this.grid.find('.companyOpportunityStatusRow--draggable').each(function (i, gridItem) {
        var draggie = new Draggabilly(gridItem);
        // bind drag events to Packery
        this.grid.packery('bindDraggabillyEvents', draggie);
      }.bind(this));

      this.grid.on('dragItemPositioned', this.onDragItemPositioned.bind(this));
    },
    /**
     * Returns an array where each element represents the position of an item in the collection.
     * @return {Array}
     */
    getItemsPosition: function (draggedItem) {
      var draggedId = draggedItem.element.getAttribute('data-id');

      var itemElems = this.grid.packery('getItemElements');
      var sortedItems = [];
      $(itemElems).each(function (i, itemElem) {
        var itemId = $(itemElem).attr('data-id');
        sortedItems.push({
          id: parseInt(itemId),
          sort: i + 1, // consider an additional element, the default one
          needsUpdate: draggedId === itemId, // Dragged item needs to save the changes
        });
      });
      return sortedItems;
    },
    onDragItemPositioned: function (event, draggedItem) {
      var positions = this.getItemsPosition(draggedItem);

      Company.Controller.sortOpportunityStatuses(positions, this.collection);
    },
    resetGrid: function () {
      this.grid.packery('destroy');
      this.render();
    },
  });

  // BANK ACCOUNT
  this.BankAccountLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyBankAccountTemplate',
    regions: {
      banks: '[data-region="companyBankAccountCollectionLayout"]',
    },
    ui: {
      showNewBankAccountButton: '[data-action="showBankAccountButton"]',
      hideNewBankAccountButton: '[data-action="hideNewBankAccount"]',
      saveNewBankAccountButton: '[data-action="saveNewBankAccount"]',

      newBankAccount: '#companyNewBankAccountRow',
    },
    events: {
      'click @ui.showNewBankAccountButton': 'showNewBankAccount',
      'click @ui.hideNewBankAccountButton': 'hideNewBankAccount',
      'click @ui.saveNewBankAccountButton': 'saveNewBankAccount',
    },
    showNewBankAccount: function () {
      this.ui.newBankAccount.fadeIn(0);
    },
    hideNewBankAccount: function () {
      this.ui.newBankAccount.fadeOut(0);
      this.resetNewBankAccount();
    },
    resetNewBankAccount: function () {
      this.ui.newBankAccount.find('input[name="newBankAccountName"]').val('');
      this.ui.newBankAccount.find('input[name="newBankAccountIban"]').val('');
    },
    saveNewBankAccount: function () {
      var name = this.ui.newBankAccount.find('input[name="newBankAccountName"]').val().trim();
      var iban = this.ui.newBankAccount.find('input[name="newBankAccountIban"]').val().trim();
      var data = {
        name: name,
        iban: iban,
        is_archived: false,
      };

      Company.Controller.insertBankAccount(data);
    },
    showError: function () {
      // todo do a better notification system
      this.$el.find('#companyNewBankAccountRow')
        .find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewBankAccountRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">! you must insert all fields</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#companyNewBankAccountRow').find('#newLabelErrorBox').remove();
    },
  });

  this.BankAccountItemView = Marionette.ItemView.extend({
    template: '#companyBankAccountCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow companyExpenseRow',
    ui: {
      moreActions: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelect"]',

      archivedIcon: '[data-region="bankAccountArchivedLabel"]',
      archiveButton: '[data-action="archiveBankAccount"]',
      archiveLabel: '[data-region="archiveBankAccountLabel"]',
    },
    events: {
      'click @ui.moreActions': 'moreActions',
      'click @ui.archiveButton': 'archiveBankAccount',
    },
    initialize: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onRender: function () {
      if (this.model.get('is_archived')) {
        $(this.$el).addClass('companyExpenseRow--archived');
        this.ui.archivedIcon.show();
      } else {
        $(this.$el).removeClass('companyExpenseRow--archived');
      }
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        if (this.model.get('is_archived')) {
          this.ui.archiveLabel.text('UNARCHIVE');
        } else {
          this.ui.archiveLabel.text('ARCHIVE');
        }

        $('.isVisible').removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick('[data-region="moreOptionSelect"]', 'isVisible', 'remove');
    },
    archiveBankAccount: function () {
      var archive = !this.model.get('is_archived');
      var changes = { is_archived: archive };

      this.model.set(changes);
      Company.Controller.archiveBankAccount(this.model, changes, this);
    },
  });

  this.BankAccountEmptyItemView = Marionette.ItemView.extend({
    template: '#companyBankAccountEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow',
  });

  this.BankAccountCollectionView = Marionette.CollectionView.extend({
    childView: Company.BankAccountItemView,
    emptyView: Company.BankAccountEmptyItemView,
    className: 'companyBankAccountCollection',
  });

  // VAT RATE
  this.VatRateLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyVatRateTemplate',
    regions: {
      rates: '[data-region="companyVatRateCollectionLayout"]',
    },
    ui: {
      showNewVatRateButton: '[data-action="showVatRateButton"]',
      hideNewVatRateButton: '[data-action="hideNewVatRate"]',
      saveNewVatRateButton: '[data-action="saveNewVatRate"]',

      newVatRate: '#companyNewVatRateRow',
    },
    events: {
      'click @ui.showNewVatRateButton': 'showNewVatRate',
      'click @ui.hideNewVatRateButton': 'hideNewVatRate',
      'click @ui.saveNewVatRateButton': 'saveNewVatRate',
    },
    showNewVatRate: function () {
      this.ui.newVatRate.fadeIn(0);
    },
    hideNewVatRate: function () {
      this.ui.newVatRate.fadeOut(0);
      this.resetNewVatRate();
    },
    resetNewVatRate: function () {
      this.ui.newVatRate.find('input[name="newVatRateLabel"]').val('');
      this.ui.newVatRate.find('input[name="newVatRatePercentage"]').val('');
      this.ui.newVatRate.find('input[name="newVatRateDescription"]').val('');
      this.ui.newVatRate.find('input[name="newVatRateNature"]').val('');
      this.ui.newVatRate.find('input[name="newVatRateSplitPayment"]').removeAttr('checked');
    },
    saveNewVatRate: function () {
      var label = this.ui.newVatRate.find('input[name="newVatRateLabel"]').val().trim();
      var percentage = this.ui.newVatRate.find('input[name="newVatRatePercentage"]').val().trim();
      var description = this.ui.newVatRate.find('input[name="newVatRateDescription"]').val().trim();
      var nature = this.ui.newVatRate.find('input[name="newVatRateNature"]').val().trim();
      var isSplitPayment = this.ui.newVatRate.find('input[name="newVatRateSplitPayment"]')
        .is(':checked');
      var data = {
        label: label,
        rate: percentage,
        description: description,
        nature: nature,
        is_split_payment: isSplitPayment,
      };

      Company.Controller.insertVatRate(data);
    },
    showError: function (error) {
      // todo do a better notification system
      this.$el.find('#companyNewVatRateRow')
        .find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewVatRateRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#companyNewVatRateRow').find('#newLabelErrorBox').remove();
    },
  });

  this.VatRateItemView = Marionette.ItemView.extend({
    template: '#companyVatRateCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companyExpenseRow',
    ui: {
      vatRateLabel: '[data-action="vatRateLabel"]',

      moreActions: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelect"]',

      archivedIcon: '[data-region="vatRateArchivedLabel"]',
      archiveButton: '[data-action="archiveVatRate"]',
      archiveLabel: '[data-region="archiveVatRateLabel"]',
    },
    events: {
      'change @ui.vatRateLabel': 'onVatRateLabelChange',

      'click @ui.moreActions': 'moreActions',
      'click @ui.archiveButton': 'archiveVatRate',
    },
    initialize: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onRender: function () {
      if (this.model.get('is_archived')) {
        $(this.$el).addClass('companyExpenseRow--archived');
        this.ui.archivedIcon.show();
      } else {
        $(this.$el).removeClass('companyExpenseRow--archived');
      }
    },
    onVatRateLabelChange: function () {
      var label = this.ui.vatRateLabel.val().trim();
      if (label !== '') {
        this.hideError();

        var changes = { label: label };

        this.model.set(changes);
        this.updateVatRate(changes);
      } else {
        this.showMissingFieldError();
      }
    },
    updateVatRate: function (changes, itemView) {
      Company.Controller.updateVatRate(this.model, changes, itemView);
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        if (this.model.get('is_archived')) {
          this.ui.archiveLabel.text('UNARCHIVE');
        } else {
          this.ui.archiveLabel.text('ARCHIVE');
        }

        $('.isVisible').removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick('[data-region="moreOptionSelect"]', 'isVisible', 'remove');
    },
    archiveVatRate: function () {
      var archive = !this.model.get('is_archived');
      var changes = { is_archived: archive };

      this.model.set(changes);
      this.updateVatRate(changes, this);
    },
    showMissingFieldError: function () {
      this.showError('! you must insert all fields');
    },
    showError: function (error) {
      // todo do a better notification system
      this.$el.find('#newLabelErrorBox').remove();
      this.$el.append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#newLabelErrorBox').remove();
    },
  });

  this.VatRateEmptyItemView = Marionette.ItemView.extend({
    template: '#companyVatRateEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow',
  });

  this.VatRateCollectionView = Marionette.CollectionView.extend({
    childView: Company.VatRateItemView,
    emptyView: Company.VatRateEmptyItemView,
    className: 'companyVatRateCollection',
  });

  this.ExpensesLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyExpensesTemplate',
    ui: {
      showNewCategory: '[data-action="addExpenseCategoryButton"]',
      hideNewCategory: '[data-action="hideNewExpenseCategory"]',
      saveNewCategory: '[data-action="saveNewExpenseCategory"]',
      newCategory: '#companyNewExpenseCategoryRow',
      newCategoryType: '[data-action="newExpenseCategoryType"]',
    },
    regions: {
      category: '[data-region="companyExpenseCategoryCollectionLayout"]',
    },
    initialize: function (options) {
      var defaultType = options.types.select(function (model) {
        return this.isTotal(model);
      }.bind(this))[0].toJSON();
      this.types = options.types;

      this.model = new Backbone.Model({ reimbursement_type: defaultType });
      this.model.set('types', options.types.toJSON());
    },
    events: {
      'click @ui.showNewCategory': 'showNewCategory',
      'click @ui.hideNewCategory': 'hideNewCategory',
      'click @ui.saveNewCategory': 'saveNewCategory',
      'change @ui.newCategoryType': 'onCategoryTypeChange',
    },
    isTotal: function (typeModel) {
      return typeModel.get('key') === 'total';
    },
    isMax: function (typeModel) {
      return typeModel.get('key') === 'max';
    },
    isMileage: function (typeModel) {
      return typeModel.get('key') === 'mileage';
    },
    isQuantity: function (typeModel) {
      return typeModel.get('key') === 'quantity';
    },
    isReimbursementRequired: function (typeModel) {
      return typeModel.get('is_reimbursement_required');
    },
    updatePlaceholderAndLabel: function (type) {
      var $valueInput = this.ui.newCategory.find('input[name="newExpenseCategoryValue"]');
      var $valueLabel = this.ui.newCategory.find('#companyExpenseValueLabel');

      var placeholder = this.isReimbursementRequired(type) ? 'Value' : '-';
      var label = '';
      var disabled = !this.isReimbursementRequired(type);

      if (this.isMax(type)) { // TOTAL MAX
        label = 'max';
      } else if (this.isMileage(type)) { // MI/KM
        label = 'per mi./km';
      } else if (this.isQuantity(type)) { // AMOUNT per QUANTITY
        label = 'per unit';
      }

      $valueInput.attr('placeholder', placeholder);
      $valueInput.attr('disabled', disabled);
      $valueLabel.text(label);
    },
    onCategoryTypeChange: function () {
      var type = this.ui.newCategory.find('select[name="newExpenseCategoryType"]').val();
      type = this.types.select(function (model) {
        return model.get('key') === type;
      })[0];

      this.model.set('reimbursement_type', type);

      this.updatePlaceholderAndLabel(type);
    },
    showNewCategory: function () {
      this.ui.newCategory.fadeIn(0);
    },
    hideNewCategory: function () {
      this.ui.newCategory.fadeOut(0);
      this.resetNewCategory();
    },
    resetNewCategory: function () {
      this.ui.newCategory.find('input[name="newExpenseCategoryName"]').val('');
      this.ui.newCategory.find('input[name="newExpenseCategoryRoute"]').removeAttr('checked');
      this.ui.newCategory.find('input[name="newExpenseCategoryValue"]').val('');
    },
    saveNewCategory: function () {
      var categoryName = this.ui.newCategory.find('input[name="newExpenseCategoryName"]')
        .val()
        .trim();
      var routeRequired = this.ui.newCategory.find('input[name="newExpenseCategoryRoute"]')
        .is(':checked');
      var categoryValue = this.ui.newCategory.find('input[name="newExpenseCategoryValue"]').val();
      categoryValue = Number.isNaN(categoryValue) ? null : categoryValue;
      var type = this.ui.newCategory.find('select[name="newExpenseCategoryType"]').val();
      var data = {
        name: categoryName,
        is_route_required: routeRequired,
        reimbursement_type: type,
      };

      if (categoryValue) {
        data.reimbursement = categoryValue;
      }

      Company.Controller.insertExpenseCategory(data);
    },
    showError: function (message) {
      // todo do a better notification system
      message = (typeof message === 'undefined') ? 'All fields must be compiled!' : message;
      this.ui.newCategory.find('#newLabelErrorBox').remove();
      this.ui.newCategory
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + message + '</span>');
    },
    hideError: function () {
      // todo remove this
      this.ui.newCategory.find('#newLabelErrorBox').remove();
    },
  });

  this.ExpenseCategoryItemView = Marionette.ItemView.extend({
    template: '#companyExpenseCategoryTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companyExpenseRow',
    ui: {
      moreActions: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelect"]',

      archivedIcon: '[data-region="expenseCategoryArchivedLabel"]',
      archiveButton: '[data-action="archiveExpenseCategory"]',
      archiveLabel: '[data-region="archiveExpenseCategoryLabel"]',
    },
    events: {
      'click @ui.moreActions': 'moreActions',
      'click @ui.archiveButton': 'archive',
    },
    initialize: function () {
      var type = this.model.get('reimbursement_type');
      type = type ? type.key : null;
      var label = '';

      if (this.isMax(type)) { // TOTAL MAX
        label = 'max';
      } else if (this.isMileage(type)) { // MI/KM
        label = 'per mi./km';
      } else if (this.isQuantity(type)) { // AMOUNT per QUANTITY
        label = 'per unit';
      }

      this.model.set('valueLabel', label);
    },
    onRender: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
      if (this.model.get('is_archived')) {
        $(this.$el).addClass('companyExpenseRow--archived');
        this.ui.archivedIcon.show();
      } else {
        $(this.$el).removeClass('companyExpenseRow--archived');
      }
    },
    isMax: function (typeKey) {
      return typeKey === 'max';
    },
    isMileage: function (typeKey) {
      return typeKey === 'mileage';
    },
    isQuantity: function (typeKey) {
      return typeKey === 'quantity';
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        if (this.model.get('is_archived')) {
          this.ui.archiveLabel.text('UNARCHIVE');
        } else {
          this.ui.archiveLabel.text('ARCHIVE');
        }

        $('.isVisible').removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick('[data-region="moreOptionSelect"]', 'isVisible', 'remove');
    },
    archive: function () {
      var archive = !this.model.get('is_archived');
      Company.Controller.archiveExpenseCategory(this.model, archive, this);
    },
  });

  this.ExpenseCategoryEmptyItemView = Marionette.ItemView.extend({
    template: '#companyExpensesCategoryEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding',
  });

  this.ExpenseCategoryCollectionView = Marionette.CollectionView.extend({
    childView: Company.ExpenseCategoryItemView,
    emptyView: Company.ExpenseCategoryEmptyItemView,
    className: 'companyExpenseCategoryCollection',
  });

  // PAYMENT TERMS
  this.PaymentTermLayout = Marionette.LayoutView.extend({
    className: 'grid bottomSpace',
    template: '#companyPaymentTermTemplate',
    regions: {
      terms: '[data-region="companyPaymentTermCollectionLayout"]',
    },
    ui: {
      showNewPaymentTermButton: '[data-action="showPaymentTermButton"]',
      hideNewPaymentTermButton: '[data-action="hideNewPaymentTerm"]',
      saveNewPaymentTermButton: '[data-action="saveNewPaymentTerm"]',

      newPaymentTerm: '#companyNewPaymentTermRow',
    },
    events: {
      'click @ui.showNewPaymentTermButton': 'showNewPaymentTerm',
      'click @ui.hideNewPaymentTermButton': 'hideNewPaymentTerm',
      'click @ui.saveNewPaymentTermButton': 'saveNewPaymentTerm',
    },
    showNewPaymentTerm: function () {
      this.ui.newPaymentTerm.fadeIn(0);
    },
    hideNewPaymentTerm: function () {
      this.ui.newPaymentTerm.fadeOut(0);
      this.resetNewPaymentTerm();
    },
    resetNewPaymentTerm: function () {
      this.ui.newPaymentTerm.find('input[name="newPaymentTermName"]').val('');
      this.ui.newPaymentTerm.find('input[name="newPaymentTermDays"]').val('');
    },
    saveNewPaymentTerm: function () {
      var name = this.ui.newPaymentTerm.find('input[name="newPaymentTermName"]').val().trim();
      var days = this.ui.newPaymentTerm.find('input[name="newPaymentTermDays"]').val().trim();
      var type = this.ui.newPaymentTerm.find('select[name="newPaymentTermType"]').val() === 'true';

      var data = {
        name: name,
        days: days,
        is_from_end_of_month: type,
      };

      Company.Controller.insertPaymentTerm(data);
    },
    showError: function (error) {
      // todo do a better notification system
      this.$el.find('#companyNewPaymentTermRow')
        .find('#newLabelErrorBox').remove();
      this.$el.find('#companyNewPaymentTermRow')
        .append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#companyNewPaymentTermRow').find('#newLabelErrorBox').remove();
    },
  });

  this.PaymentTermItemView = Marionette.ItemView.extend({
    template: '#companyPaymentTermCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLabelRow companyExpenseRow',
    ui: {
      paymentTermName: '[data-action="paymentTermName"]',

      moreActions: '[data-action="moreActions"]',
      moreActionSelectEl: '[data-region="moreOptionSelect"]',

      archivedIcon: '[data-region="paymentTermArchivedLabel"]',
      archiveButton: '[data-action="archivePaymentTerm"]',
      archiveLabel: '[data-region="archivePaymentTermLabel"]',
    },
    events: {
      'change @ui.paymentTermName': 'onPaymentTermNameChange',

      'click @ui.moreActions': 'moreActions',
      'click @ui.archiveButton': 'archivePaymentTerm',
    },
    initialize: function () {
      $(this.$el).attr('data-id', this.model.get('id'));
    },
    onRender: function () {
      if (this.model.get('is_archived')) {
        $(this.$el).addClass('companyExpenseRow--archived');
        this.ui.archivedIcon.show();
      } else {
        $(this.$el).removeClass('companyExpenseRow--archived');
      }
    },
    onPaymentTermNameChange: function () {
      var name = this.ui.paymentTermName.val().trim();
      if (name !== '') {
        this.hideError();

        var changes = { name: name };

        this.model.set(changes);
        this.updatePaymentTerm(changes);
      } else {
        this.showMissingFieldError();
      }
    },
    updatePaymentTerm: function (changes, itemView) {
      Company.Controller.updatePaymentTerm(this.model, changes, itemView);
    },
    moreActions: function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.ui.moreActionSelectEl.hasClass('isVisible')) {
        if (this.model.get('is_archived')) {
          this.ui.archiveLabel.text('UNARCHIVE');
        } else {
          this.ui.archiveLabel.text('ARCHIVE');
        }

        $('.isVisible').removeClass('isVisible');

        this.ui.moreActionSelectEl.addClass('isVisible');
      } else {
        this.ui.moreActionSelectEl.removeClass('isVisible');
      }

      // Hide select when click on 'html'
      Wethod.onHtmlClick('[data-region="moreOptionSelect"]', 'isVisible', 'remove');
    },
    archivePaymentTerm: function () {
      var archive = !this.model.get('is_archived');
      var changes = { is_archived: archive };

      this.model.set(changes);
      this.updatePaymentTerm(changes, this);
    },
    showMissingFieldError: function () {
      this.showError('! you must insert all fields');
    },
    showError: function (error) {
      // todo do a better notification system
      this.$el.find('#newLabelErrorBox').remove();
      this.$el.append('<span id="newLabelErrorBox" style="color:red;display:inline-block;padding:5px;">' + error + '</span>');
    },
    hideError: function () {
      // todo remove this
      this.$el.find('#newLabelErrorBox').remove();
    },
  });

  this.PaymentTermEmptyItemView = Marionette.ItemView.extend({
    template: '#companyPaymentTermEmptyCollectionTemplate',
    tagName: 'ul',
    className: 'col_16 no_padding companyLevelRow',
  });

  this.PaymentTermCollectionView = Marionette.CollectionView.extend({
    childView: Company.PaymentTermItemView,
    emptyView: Company.PaymentTermEmptyItemView,
    className: 'companyPaymentTermCollection',
  });

  // JOB ORDER TEMPLATES
  this.JobOrderTemplateLayout = Marionette.LayoutView.extend({
    template: '#jobOrderTemplatesTemplate',
    regions: {
      templatesRegion: '[data-region="companyJobOrderTemplateLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showJobOrderTemplates();
    },
    showJobOrderTemplates: function () {
      var container = document.querySelector(this.templatesRegion.el);
      var templateSection = React.createElement(JobOrderTemplates, { store: this.options.store });
      ReactDOM.render(templateSection, container);
    },
  });

  // EMPLOYEE TAGS TEMPLATES
  this.EmployeeTagsLayout = Marionette.LayoutView.extend({
    template: '#employeeTagsTemplate',
    regions: {
      templatesRegion: '[data-region="companyEmployeeTagsLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showEmployeeTags();
    },
    showEmployeeTags: function () {
      var container = document.querySelector(this.templatesRegion.el);
      var templateSection = React.createElement(EmployeeTags, { store: this.options.store });
      ReactDOM.render(templateSection, container);
    },
  });

  // LOCATION TEMPLATES
  this.LocationLayout = Marionette.LayoutView.extend({
    template: '#locationTemplate',
    regions: {
      templatesRegion: '[data-region="locationLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showLocations();
    },
    showLocations: function () {
      var isJakalaFeaturesNovemberBlockEnabled = Wethod.featureService
        .isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK);

      BackboneService.injectReactComponent(
        Location,
        '[data-region="locationLayout"]',
        {
          store: this.options.store,
          isJakalaFeaturesNovemberBlockEnabled: isJakalaFeaturesNovemberBlockEnabled,
        }
      );
    },
  });

  // COMPANY HOLIDAYS
  this.CompanyHolidaySettingsLayout = Marionette.LayoutView.extend({
    template: '#companyHolidaySettingsTemplate',
    regions: {
      body: '[data-region="companyHolidaySettingsLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showCompanyHoliday();
    },
    showCompanyHoliday: function () {
      BackboneService.injectReactComponent(
        CompanyHoliday,
        '[data-region="companyHolidaySettingsLayout"]',
        { store: this.options.store }
      );
    },
  });

  // ORDER PLAN VIEW SETTINGS LAYOUT
  this.OrderPlanSettingsLayout = Marionette.LayoutView.extend({
    template: '#orderPlanSettingsTemplate',
    regions: {
      templatesRegion: '[data-region="companyOrderPlanSettingsLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showCompanyOrderPlanView();
    },
    showCompanyOrderPlanView: function () {
      var container = document.querySelector(this.templatesRegion.el);
      var templateSection = React.createElement(OrderPlanSettings,
        { store: this.options.store });
      ReactDOM.render(templateSection, container);
    },
  });

  // JOB TITLE SETTINGS
  this.JobTitleTemplateLayout = Marionette.LayoutView.extend({
    template: '#jobTitleTemplate',
    regions: {
      body: '[data-region="companyJobTitleLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showBody();
    },
    showBody: function () {
      var container = document.querySelector(this.body.el);
      var body = React.createElement(JobTitles, { store: this.options.store });
      ReactDOM.render(body, container);
    },
  });

  // FISCAL YEAR SETTINGS
  this.FiscalYearSettingsLayout = Marionette.LayoutView.extend({
    template: '#fiscalYearTemplate',
    regions: {
      body: '[data-region="companyFiscalYearLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showBody();
    },
    showBody: function () {
      var container = document.querySelector(this.body.el);
      var body = React.createElement(FiscalYear, { store: this.options.store });
      ReactDOM.render(body, container);
    },
  });
  this.WorkHourCapacitySettingsLayout = Marionette.LayoutView.extend({
    template: '#workHourCapacityTemplate',
    regions: {
      body: '[data-region="companyWorkHourCapacityLayout"]',
    },
    initialize: function (options) {
      this.options = options;
    },
    onAttach: function () {
      this.showBody();
    },
    showBody: function () {
      BackboneService.injectReactComponent(
        WorkHourCapacity,
        '[data-region="companyWorkHourCapacityLayout"]',
        { store: this.options.store }
      );
    },
  });
});
