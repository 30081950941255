'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BasketStore = require('../../apps/core/modules/basket/store');
var BasketReducer = require('../../apps/core/modules/basket/reducer');
var Basket = require('../../apps/core/modules/basket/index');
var ProjectsStore = require('../../apps/core/modules/pipeline/store');
var ProjectsReducer = require('../../apps/core/modules/pipeline/reducer');
var Projects = require('../../apps/core/modules/pipeline');
var TranslatorService = require('../../apps/core/modules/pipeline/services/TranslatorService');
var SegmentsModel = require('../../models/SectionSegment');
var JobOrderTemplateModel = require('../../models/JobOrderTemplate');
var PipedriveIntegration = require('../../models/PipedriveIntegration');
var BusinessUnitService = require('../../services/BusinessUnitService');
var PropertyMetadataModel = require('../../models/PropertyMetadata');
var WinLossProbabilityThreshold = require('../../models/WinLossProbabilityThreshold');

Wethod.module('PipelineApp', function (PipelineApp, Wethod, Backbone, Marionette, $, _) {
  PipelineApp.Controller = {
    /**
     * Here arrive fragments with old filter management. They are translated with new filters and
     * user is redirected accordingly.
     */
    redirectToNewPipeline: function () {
      var fragment = TranslatorService.translate(Backbone.history.getFragment());
      Wethod.navigate(fragment, {
        trigger: true,
        replace: true,
      });
    },
    showPipeline: function (options) {
      options = options || {};
      var businessUnitService = new BusinessUnitService();
      var type = options.type || 'projects';
      var filters = options.filters ? TranslatorService.parseFilters(options.filters, type) : {};
      var basket = options.basket || null;
      var idTour = filters.product_tour_id;
      delete filters.product_tour_id;

      $.when(WinLossProbabilityThreshold.get())
        .done(function (winLossProbabilityThresholdResponse) {
          TranslatorService
            .getProjectSection(
              filters.id, winLossProbabilityThresholdResponse.win_probability_threshold
            )
            .done(function (section) {
              if (section) {
                type = section;
                filters = TranslatorService.parseFilters(options.filters, type);
              }
              var authorizationRequest = Wethod.request('get:pipeline:authorization');
              var projectTypeRequest = Wethod.request('get:pipeline:projects:type');
              var projectLabelRequest = Wethod.request('get:pipeline:projects:label');
              var projectMetadataRequest = Wethod.request('get:pipeline:projects:metadata');
              var isContactRequiredRequest = Wethod.request('get:company:pipeline:contact');
              var projectReviewConfigRequest = Wethod.request('get:company:pipeline:review:config');
              var isValueAsUnitRequest = Wethod.request('get:company:pipeline:valueAsUnit');
              var columnsRequest = Wethod.request('section:columns:get', type);
              var riskRequest = Wethod.request('get:company:project-status:risks');
              var stageRequest = Wethod.request('get:company:opportunity-status');

              $.when(
                authorizationRequest,
                projectTypeRequest,
                projectLabelRequest,
                projectMetadataRequest,
                isContactRequiredRequest,
                projectReviewConfigRequest,
                SegmentsModel.getAll(type),
                riskRequest,
                columnsRequest,
                stageRequest,
                isValueAsUnitRequest,
                JobOrderTemplateModel.isEnabled(),
                PipedriveIntegration.isEnabled(),
                PropertyMetadataModel.list(PropertyMetadataModel.PROJECT_ENTITY)
              ).done(function (authorizationResponse,
                projectTypeResponse,
                projectLabelResponse,
                projectMetadataResponse,
                isContactRequiredResponse,
                projectReviewConfig,
                segmentResponse,
                riskResponse,
                columnsResponse,
                stageResponse,
                isValueAsUnitResponse,
                isAutoJobOrderEnabledResponse,
                isPipedriveIntegrationEnabled,
                projectMetadata) {
                if (_.isUndefined(authorizationResponse)) {
                  return;
                }

                if (!authorizationResponse.get('view')) {
                  var permissionDeniedView = new Wethod
                    .PermissionDenied.PermissionDeniedTemplateView();
                  Wethod.regions.body.show(permissionDeniedView);
                  return;
                }

                // set project labels and types
                var projectTypes = !_.isUndefined(projectTypeResponse)
                  ? projectTypeResponse.toJSON() : [];
                var projectLabels = !_.isUndefined(projectLabelResponse)
                  ? projectLabelResponse.toJSON() : [];
                var metadata = !_.isUndefined(projectMetadataResponse)
                  ? projectMetadataResponse.toJSON() : [];
                var isContactRequired = isContactRequiredResponse.get('required');
                var isValueAsUnit = isValueAsUnitResponse.get('enabled');
                var projectReviewEnabled = projectReviewConfig.get('enabled');

                var risks = !_.isUndefined(riskResponse)
                  ? riskResponse.toJSON() : [];

                var stages = !_.isUndefined(stageResponse)
                  ? stageResponse.toJSON() : [];

                var pipelineLayout = new Wethod.PipelineApp.Layout();
                pipelineLayout.render();
                var search = filters.search || '';
                delete filters.search;

                var reducerWrapper = new ProjectsReducer({
                  search: search,
                  filters: filters,
                  projectTypes: projectTypes,
                  projectLabels: projectLabels,
                  metadata: metadata,
                  isContactRequired: isContactRequired,
                  isValueAsUnit: isValueAsUnit,
                  projectReviewEnabled: projectReviewEnabled,
                  type: type,
                  pipelinePermissions: authorizationResponse.attributes,
                  basket: basket,
                  availableSegments: segmentResponse,
                  visibleColumns: columnsResponse,
                  intercompany: options.token ? { token: options.token } : null,
                  risks: risks,
                  stages: stages,
                  buFilter: businessUnitService.getLastSelected(),
                  buEnabled: businessUnitService.isEnabled(),
                  isAutoJobOrderEnabled: isAutoJobOrderEnabledResponse.enabled,
                  isPipedriveIntegrationEnabled: isPipedriveIntegrationEnabled,
                  projectMetadata: projectMetadata,
                  winLossProbabilityThreshold: {
                    winProbabilityThreshold: winLossProbabilityThresholdResponse
                      .win_probability_threshold,
                  },
                });

                var store = ProjectsStore(reducerWrapper.reduxReducer);
                var element = React.createElement(Projects, {
                  store: store,
                });
                var container = document.getElementById('pipelineBody');
                if (container !== null) {
                  ReactDOM.render(element, container);
                }

                dispatcher.trigger('tour:start', idTour);
              });
            });
        });
    },

    showPipelineProgram: function () {
      var pipelineAuthorizationRequest = Wethod.request('get:pipeline:authorization');
      $.when(pipelineAuthorizationRequest)
        .done(function (pipelineAuthorizationResponse) {
          if (_.isUndefined(pipelineAuthorizationResponse)) {
            return;
          }

          Wethod.PipelineApp.authorization = pipelineAuthorizationResponse;
          if (Wethod.PipelineApp.getPermission('view')) {
            PipelineApp.initProgram();
          } else {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
          }
        });
    },

    showPipelineBasket: function (options) {
      Wethod.module('PipelineApp.Pipeline').stop();

      var authorizationReq = Wethod.request('get:pipeline:authorization');
      var isValueAsUnitRequest = Wethod.request('get:company:pipeline:valueAsUnit');

      $.when(authorizationReq, isValueAsUnitRequest, WinLossProbabilityThreshold.get())
        .done(function (authorizationResp, isValueAsUnitResponse,
          winLossProbabilityThresholdResponse) {
          if (_.isUndefined(authorizationResp)) {
            return;
          }

          Wethod.PipelineApp.authorization = authorizationResp;

          if (
            !Wethod.PipelineApp.getPermission('view')
          || !Wethod.PipelineApp.getPermission('edit')
          || !Wethod.PipelineApp.getPermission('create')
          ) {
            var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
            Wethod.regions.body.show(permissionDeniedView);
            return;
          }

          var pipelineLayout = new PipelineApp.Layout();
          pipelineLayout.render();

          var reducerWrapper = new BasketReducer({
            keyword: options.params,
            isValueAsUnit: isValueAsUnitResponse.get('enabled'),
            permissions: authorizationResp.attributes,
            winLossProbabilityThreshold: {
              winProbabilityThreshold: winLossProbabilityThresholdResponse
                .win_probability_threshold,
            },
          });

          var store = BasketStore(reducerWrapper.reduxReducer);
          var element = React.createElement(Basket, { store: store });
          var container = document.getElementById('pipelineBody');
          if (container !== null) {
            ReactDOM.render(element, container);
          }
        });
    },

    linkIntercompanyProject: function (options) {
      Wethod.module('PipelineApp.Pipeline').stop();

      var authorizationReq = Wethod.request('get:pipeline:authorization');
      $.when(authorizationReq).done(function (authorizationResp) {
        if (_.isUndefined(authorizationResp)) {
          return;
        }

        Wethod.PipelineApp.authorization = authorizationResp;

        if (
          !Wethod.PipelineApp.getPermission('view')
          || !Wethod.PipelineApp.getPermission('edit')
          || !Wethod.PipelineApp.getPermission('create')
          || !Wethod.PipelineApp.getPermission('delete')
        ) {
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
          return;
        }

        var reducerWrapper = new ProjectsReducer({
          intercompany: options.token ? { token: options.token } : null,
        });

        var store = ProjectsStore(reducerWrapper.reduxReducer);
        var element = React.createElement(Projects, { store: store });
        var container = document.getElementById('appBody');
        if (container !== null) {
          ReactDOM.render(element, container);
        }
      });
    },
  };
});
