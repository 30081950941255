const ChartConfigFilterAccount = require('./ChartConfigFilterAccount');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterAccountNotIncluded extends ChartConfigFilterAccount {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_NOT_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterAccountNotIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterAccountNotIncluded(data.values);
  }

  // eslint-disable-next-line class-methods-use-this
  isValid() {
    return true;
  }
};
