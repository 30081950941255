const { connect } = require('react-redux');
const Component = require('../components/Pipeline.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  basket: state.basket,
  type: state.type,
  visibleColumns: state.visibleColumns,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

const mapDispatchToProps = (dispatch) => ({
  addOpportunity: (attributes, winProbabilityThreshold) => dispatch(actions
    .addOpportunity(attributes, winProbabilityThreshold)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
