const React = require('react');
const _ = require('underscore');
const moment = require('moment');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;
const SidebarButton = require('../../../../../../common/react/OpenSidebarButton.react');
const Multicurrency = require('../services/multicurrencyService');
const { getPermission } = require('../services/authManager');
const IconButton = require('../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const Loader = require('../../../../../../common/react/Loader/Loader.react');

const Order = ({
  order,
  isWaitingToSend,
  showEditNotesModal,
  showApprovePaymentModal,
  showSidebar,
  showSendOrderModal,
  contacts,
  sendOrder,
  ordersBeingSent,
  setOrderAsPaid,
  ordersBeingSetAsPaid,
  ordersBeingApproved,
}) => {
  function showNotes() {
    showEditNotesModal({ order });
  }

  function showOrderSidebar() {
    showSidebar(order.id);
  }

  function approvePayment() {
    showApprovePaymentModal({ order });
  }

  function setAsPaid() {
    setOrderAsPaid({
      ...order,
      status: Order.STATUS_PAID,
    });
  }

  function send() {
    if (contacts.length < 2) {
      const contact = contacts[0] ? contacts[0].id : null;
      sendOrder(order, contact);
    } else {
      showSendOrderModal({ order });
    }
  }

  function getAttribute(object, attribute) {
    return object ? object[attribute] : null;
  }

  const getStatus = () => {
    const sendingCurrentOrder = ordersBeingSent.some((element) => element === `send:${order.id}`);
    const settingAsPaid = ordersBeingSetAsPaid.some((element) => element === `set-paid:${order.id}`);
    const approving = ordersBeingApproved.some((element) => element === `approve:${order.id}`);
    if ((isWaitingToSend && sendingCurrentOrder) || settingAsPaid || approving) {
      return <Loader />;
    }
    let allowed;

    switch (order.status) {
      case Order.STATUS_NOT_SENT:
        allowed = getPermission('send', order);
        if (!allowed) {
          return '';
        }
        return <button onClick={send} type="button" className="wethod-button">Send</button>;
      case Order.STATUS_SENT:
        allowed = getPermission('approve', order);
        if (!allowed) {
          return '';
        }
        return (
          <button onClick={approvePayment}
            type="button"
            className="wethod-button wethod-button--alert">Approve
          </button>
        );
      case Order.STATUS_APPROVED:
        // TODO use ad-hoc permission?
        return (
          <button onClick={setAsPaid}
            type="button"
            className="wethod-button">Set as paid
          </button>
        );
      case Order.STATUS_PAID:
        return <div data-testid="order-paid-icon" className="wethod-icon wethod-icon-checked" />;
      default:
        return '';
    }
  };

  const getPeriod = () => {
    const start = order.start ? moment(order.start).format('MMM YYYY') : '';
    const duration = order.duration ? `${order.duration}m` : '';
    return `${start} - ${duration}`;
  };

  const getNoteButton = () => {
    let iconStyle = 'wethod-icon-notes wethod-icon-notes--black';
    iconStyle += order.note ? ' wethod-icon-notes--done' : '';
    return (
      <IconButton onClick={showNotes}
        label="Notes"
        className="wethod-icon-button--no-margin"
        borderless>
        <span className={iconStyle} />
      </IconButton>
    );
  };

  const getValue = () => {
    if (Multicurrency.isOrderMulticurrency(order)) {
      const orderCurrency = Multicurrency.getSelectedCurrency(order);
      const masterCurrency = Multicurrency.getCurrencyCode(Multicurrency.getMasterCurrency(order));
      if (orderCurrency !== masterCurrency) {
        return (
          <div className="orders__multirow-info">
            <span className="orders__multirow-info-row">
              {Multicurrency.getFormattedConvertedValue(order)} {orderCurrency}
            </span>
            <span className="orders__multirow-info-row--secondary">
              {formatNumber(order.value, false, 2)} {masterCurrency}
            </span>
          </div>
        );
      }
    }
    return formatNumber(order.value, false, 2);
  };

  const getUnescapedValue = (value) => (value ? _.unescape(value) : null);

  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);

  return (
    <TableRow>
      <TableCell name="project"
        className="orders__column-project">
        <div className="orders__multirow-info">
          <span className="orders__multirow-info-row">{getUnescapedValue(order.project.name)}</span>
          <span className="orders__multirow-info-row--secondary">
            {order.project.pm.name} {order.project.pm.surname}
          </span>
          <span className="orders__multirow-info-row--secondary">
            {order.project.job_order}
          </span>
        </div>
      </TableCell>
      <TableCell name="area"
        className="orders__column-area">
        {getUnescapedValue(getAttribute(order.area, 'name'))}
      </TableCell>
      <TableCell name="supplier"
        className="orders__column-supplier">
        {order.supplier.name}
      </TableCell>
      <TableCell name="duration"
        className="orders__column-period">
        {getPeriod()}
      </TableCell>
      <TableCell name="date"
        className="orders__column-date">
        {formatDate(order.date)}
      </TableCell>
      <TableCell name="code"
        className="orders__column-code">
        {order.code}
      </TableCell>
      <TableCell name="value"
        className="orders__column-value">
        {getValue()}
      </TableCell>
      <TableCell name="date_paid_on"
        className="orders__column-date">
        {formatDate(order.date_paid_on)}
      </TableCell>
      <TableCell name="status"
        className="orders__column-status">
        {getStatus()}
      </TableCell>
      <TableCell name="notes" className="orders__column-actions">{getNoteButton()}</TableCell>
      <TableCell name="sidebar" className="orders__column-actions">
        <SidebarButton onClick={showOrderSidebar} />
      </TableCell>
    </TableRow>
  );
};

Order.STATUS_NOT_SENT = 0;
Order.STATUS_SENT = 1;
Order.STATUS_APPROVED = 2;
Order.STATUS_PAID = 3;

module.exports = Order;
