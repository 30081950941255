const React = require('react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const IconLink = require('../../../../../../common/react/TooltipFixed/IconLinkTooltip.react');
const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;
const Trend = require('./KudosTrend.react');
const EmployeeInfo = require('../../knowledge/components/EmployeeInfo');

const EmployeeRow = ({ employee, role, selectedCriterion, timeFilter }) => {
  const selectedCriterionData = employee[role]
    .filter((criterion) => criterion.criterion_id === selectedCriterion)[0];

  function getNonEmptyFormattedValue(value) {
    return (value || value === 0) ? formatNumber(value, false, 1) : '-';
  }

  return (
    <TableRow className="kudos__employee-row">
      <TableCell name="name" className="kudos__column-name">
        <EmployeeInfo employee={employee} />
      </TableCell>
      <TableCell name="projects" className="kudos__column-project kudos__column--right">
        {selectedCriterionData.projects}
      </TableCell>
      <TableCell name="reviews" className="kudos__column-review kudos__column--right">
        {selectedCriterionData.reviews}
      </TableCell>
      <TableCell name="trend" className="kudos__column-trend">
        <Trend data={selectedCriterionData.data}
          selectedCriterion={selectedCriterion}
          timeFilter={timeFilter} />
      </TableCell>
      <TableCell name="avg" className="kudos__column-avg kudos__column--right">
        {getNonEmptyFormattedValue(selectedCriterionData.avg)}
      </TableCell>
      <TableCell name="link" className="kudos__column-link kudos__column--right">
        <IconLink label="Go to profile"
          className="chargeability__button"
          borderless
          href={`#settings/profile/${employee.id}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </IconLink>
      </TableCell>
    </TableRow>
  );
};

module.exports = EmployeeRow;
