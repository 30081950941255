'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var RevenuesInsightsComponent = require('../../apps/core/modules/insights/revenues');
var GrossMarginInsightsComponent = require('../../apps/core/modules/insights/gross-margin');

Wethod.module('InsightsApp', function (InsightsApp) {
  InsightsApp.Controller = {
    showRevenuesInsights: function () {
      var layout = new InsightsApp.StructureLayoutView();
      layout.render();

      var element = React.createElement(RevenuesInsightsComponent);
      var container = document.getElementById('root');
      if (container !== null) {
        ReactDOM.render(element, container);
      }
    },
    showGrossMarginInsights: function () {
      var layout = new InsightsApp.StructureLayoutView();
      layout.render();

      var element = React.createElement(GrossMarginInsightsComponent);
      var container = document.getElementById('root');
      if (container !== null) {
        ReactDOM.render(element, container);
      }
    },
  };
});
