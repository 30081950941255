const React = require('react');
const PropTypes = require('prop-types');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const MonthSelect = require('../../../inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');

const ChartSidebarTimeRangeFilterAbsoluteOption = ({
  id, name, onClick, checked, range, onChange, value,
}) => {
  /**
   * Format range to contain ISO date string.
   * @return {{min: string, max: string}}
   */
  const getFormattedRange = () => {
    const formattedMin = range.min ? range.min.toISOString() : null;
    const formattedMax = range.max ? range.max.toISOString() : null;

    return {
      min: formattedMin,
      max: formattedMax,
    };
  };
  /**
   * @param {string} attrName
   * @param {Date|null} attrValue
   */
  function handleChange(attrName, attrValue) {
    const formattedRange = { ...range, [attrName]: attrValue };
    onChange(value, formattedRange);
  }

  function handleMonthChange(e) {
    const formattedValue = new Date(e.target.value);
    handleChange(e.target.name, formattedValue);
  }

  function handleClick() {
    onClick(value, range);
  }

  const getRangeInput = () => {
    const formattedRange = getFormattedRange();

    if (checked) {
      return (
        <div className="wethod-advanced-search-filter-range__content">
          <MonthSelect name="min"
            id={`${name}-min`}
            onChange={handleMonthChange}
            value={formattedRange.min}
            showIcon={false}
            label="From"
            disableAfter={formattedRange.max} />
          <MonthSelect name="max"
            id={`${name}-max`}
            onChange={handleMonthChange}
            value={formattedRange.max}
            showIcon={false}
            label="To"
            disableBefore={formattedRange.min} />
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      <Radio
        id={id}
        name={name}
        onClick={handleClick}
        checked={checked}
        label="Select date"
        value={value} />
      <div className="wethod-chart-sidebar__dimension-option-attributes">
        {getRangeInput()}
      </div>
    </div>
  );
};

ChartSidebarTimeRangeFilterAbsoluteOption.defaultProps = {
  name: undefined,
  checked: false,
  onClick: null,
  id: null,
  range: {
    min: null,
    max: null,
  },
};

ChartSidebarTimeRangeFilterAbsoluteOption.propTypes = {
  id: PropTypes.string,
  /**
   * Used to group radio buttons. All buttons in the same group must have the same name.
   */
  name: PropTypes.string,
  checked: PropTypes.bool,
  /**
   * Function to call when radio is clicked.
   * @param event {{}}
   */
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  range: PropTypes.shape({
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
  }),
  /**
   * The time filter id.
   */
  value: PropTypes.string.isRequired,
};

module.exports = ChartSidebarTimeRangeFilterAbsoluteOption;
