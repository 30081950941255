const React = require('react');
const PropTypes = require('prop-types');
const Sidebar = require('../../../Chart/ChartSidebar/ChartSidebar.react');
const Chart = require('../../../Chart/Chart/models/Chart');
const ChartModel = require('../../../../../models/Chart');
const sidebarService = require('../../../sidebar/SidebarManager/services/SidebarService');
const DimensionSelectItem = require('../../../Chart/ChartSidebar/models/DimensionSelectItem');

/**
 * Sidebar used to update a chart that exists.
 */
class EditSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      serverErrors: [],
    };

    this.onSave = this.onSave.bind(this);
  }

  /**
   * @param {ChartConfig} config
   */
  onSave(config) {
    const chart = this.props.chart.clone();
    chart.config = config;
    this.setState({ isWaiting: true });
    ChartModel.update(chart.id, chart)
      .done((serializedChart) => {
        const updatedChart = Chart.fromJSON(serializedChart);
        sidebarService.closeSidebar();
        this.setState({ isWaiting: false });
        this.props.onSave(updatedChart);
      })
      .fail((errors) => {
        this.setState({
          isWaiting: false,
          serverErrors: errors,
        });
      });
  }

  render() {
    return (
      <Sidebar onCloseClick={this.props.onCloseClick}
        availableDimensions={this.props.availableDimensions}
        title="Edit chart"
        config={this.props.chart.config}
        errors={this.state.serverErrors}
        isSaving={this.state.isWaiting}
        onSave={this.onSave} />
    );
  }
}

EditSidebar.defaultProps = {
  availableDimensions: [],
};

EditSidebar.propTypes = {
  chart: PropTypes.instanceOf(Chart).isRequired,
  onSave: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  availableDimensions: PropTypes.arrayOf(PropTypes.instanceOf(DimensionSelectItem)),
};

module.exports = EditSidebar;
