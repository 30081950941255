const React = require('react');
const PropTypes = require('prop-types');
const ChartSidebarStep = require('./ChartSidebarStep.react');
const Dimension = require('./ChartSidebarDimension.react');
const ChartConfig = require('../Chart/models/ChartConfig');
const ChartConfigDimensions = require('../Chart/models/ChartConfigDimensions');
const DimensionSelectItem = require('./models/DimensionSelectItem');
const ChartAxis = require('./models/ChartAxis');

const ChartSidebarDimensionsStep = ({
  chartType, updateErrors, getErrorText, chartDimensions,
  onChange, hasError, availableDimensions,
}) => {
  const getAxis = () => {
    switch (chartType) {
      case ChartConfig.TYPE_STACKED_BAR:
        return [
          new ChartAxis('x', 'X Axis', false),
          new ChartAxis('y', 'Y Axis', true),
          new ChartAxis('color', 'Color', false),
        ];
      default:
        return [
          new ChartAxis('x', 'X Axis', false),
          new ChartAxis('y', 'Y Axis', true),
        ];
    }
  };

  const getDimensions = () => getAxis().map((axis) => (
    <Dimension availableDimensions={availableDimensions}
      axisLabel={axis.label}
      key={axis.key}
      axisKey={axis.key}
      isDataSourceAxis={axis.isDataSourceAxis}
      updateErrors={updateErrors}
      getError={getErrorText}
      dimension={chartDimensions[axis.key]}
      onChange={onChange} />
  ));

  return (
    <ChartSidebarStep title="Dimensions"
      hasError={hasError}>
      <div>
        {getDimensions()}
      </div>
    </ChartSidebarStep>
  );
};

ChartSidebarDimensionsStep.defaultProps = {
  availableDimensions: [],
};

ChartSidebarDimensionsStep.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartDimensions: PropTypes.instanceOf(ChartConfigDimensions).isRequired,
  updateErrors: PropTypes.func.isRequired,
  getErrorText: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  availableDimensions: PropTypes.arrayOf(PropTypes.instanceOf(DimensionSelectItem)),
};

module.exports = ChartSidebarDimensionsStep;
