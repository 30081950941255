const React = require('react');
const PropTypes = require('prop-types');
const isEqual = require('react-fast-compare');
const Table = require('../../Table2/Table2.react');
const TableCell = require('../../Table2/TableCell.react');
const TableRow = require('../../Table2/TableRow.react');
const TableHead = require('../../Table2/TableHead.react');
const TableBody = require('../../Table2/TableBody.react');
const ChartModel = require('../../../../models/Chart');
const ChartConfigTable = require('../../Chart/Chart/models/ChartConfigTable/ChartConfigTable');
const PipelineLink = require('./PipelineLink');
const Column = require('../../Table2/models/TableColumn');
const ColumnList = require('../../Table2/models/TableColumnList');
const ChartConfigTableColumn = require('../../Chart/Chart/models/ChartConfigTable/ChartConfigTableColumn');
const TableSearch = require('../../Table2/TableSearch.react');
const NumberFormatter = require('../../formatters/NumberFormatter.react');
const MoneyCell = require('./InsightLevelTableViewMoneyCell.react');
const ChartConfigFilter = require('../../Chart/Chart/models/ChartConfigFilter/ChartConfigFilter');
const TableSummaryRow = require('../../Table2/TableSummaryRow.react');
const Chart = require('../../Chart/Chart/models/Chart');

class InsightLevelTableViewContainer extends React.Component {
  constructor(props) {
    super(props);

    /**
     * @type {{projects: *[], hasMore: boolean, isLoading: boolean}}
     */
    this.state = {
      projects: [],
      hasMore: true,
      sortBy: {
        col: 'name',
        order: 'asc',
      },
      keyword: '',
      isLoading: false,
      pageLimit: 0,
      pageOffset: null,
      totals: {},
    };

    this.tableHeight = 618;

    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.loadTotals();
  }

  componentDidUpdate(prevProps, prevState) {
    const increasedOffset = prevState.pageOffset < this.state.pageOffset;
    const isFirstLoad = prevState.pageOffset === null && this.state.pageOffset === 0;
    const changedSort = prevState.sortBy !== this.state.sortBy;
    const changedSearchFilter = prevState.keyword !== this.state.keyword;
    const parentFiltersChanged = !isEqual(this.props.filters, prevProps.filters);
    const parentChanged = !isEqual(this.props.parent, prevProps.parent);
    if (changedSort || changedSearchFilter || parentFiltersChanged || parentChanged) {
      this.loadMore(0);
      this.loadTotals();
    } else if (isFirstLoad || increasedOffset) {
      this.loadMore();
    }
  }

  handleSort(col, order) {
    this.setState({
      sortBy: {
        col,
        order,
      },
    });
  }

  handleLoadMore(size, page) {
    this.setState({
      pageLimit: size,
      pageOffset: (page - 1) * size,
    });
  }

  handleSearch(keyword) {
    this.setState({
      keyword,
    });
  }

  getSort(col) {
    const currentSort = this.state.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  /**
   * Return body row cells related to given project.
   * @param project
   * @return {JSX.Element[]}
   */
  getRowCells(project) {
    return this.getColumns().map((column) => {
      const className = `wethod-insight-level__table-cell--${column.key}`;
      switch (column.key) {
        case 'actions':
          return (
            <TableCell key="actions" name="actions" className={className}>
              <PipelineLink projectId={project.id} />
            </TableCell>
          );
        case 'gross_margin':
        case 'revenues':
          return (
            <MoneyCell key={column.key} name={column.sortKey} className={className}>
              <NumberFormatter>{project[column.key]}</NumberFormatter>
            </MoneyCell>
          );
        default: {
          const value = project[column.key];
          return (
            <TableCell
              key={column.key}
              name={column.sortKey}
              className={className}>
              {value}
            </TableCell>
          );
        }
      }
    });
  }

  /**
   * Return total row cells.
   * @param totals
   * @return {JSX.Element[]}
   */
  getTotalRowCells(totals) {
    return this.getColumns().map((column) => {
      const className = `wethod-insight-level__table-cell--${column.key}`;
      switch (column.key) {
        case 'actions':
          return (
            <TableCell key="actions" name="actions" className={className} />
          );
        case 'revenues':
        case 'gross_margin': {
          const amount = totals[column.key];
          if (amount) {
            return (
              <MoneyCell key={column.key} name={column.sortKey} className={className}>
                <NumberFormatter>{amount}</NumberFormatter>
              </MoneyCell>
            );
          }
          return (
            <MoneyCell key={column.key} name={column.sortKey} className={className}>
              -
            </MoneyCell>
          );
        }
        case 'name':
          return (
            <TableCell
              key={column.key}
              name={column.sortKey}
              className={className}>
              Total
            </TableCell>
          );
        default: {
          return (
            <TableCell
              key={column.key}
              name={column.sortKey}
              className={className} />
          );
        }
      }
    });
  }

  /**
   * Return table body rows.
   * @return {JSX.Element[]}
   */
  getRows() {
    return this.state.projects.map((project) => (
      <TableRow key={project.id}>
        {this.getRowCells(project)}
      </TableRow>
    ));
  }

  /**
   * Return list of table columns.
   *
   * @return {ChartConfigTableColumn[]}
   */
  getColumns() {
    const actionsColumn = new ChartConfigTableColumn('actions', '', 'actions');

    return this.props.config.columns
      .concat(actionsColumn);
  }

  /**
   * @return {TableColumnList}
   */
  getVisibleColumnList() {
    const tableColumns = this.getColumns().map((column) => {
      if (column.key === 'actions') {
        return new Column(column.key, column.label, true, false, '46px');
      }
      return new Column(column.sortKey, column.label, true, false);
    });
    const tableColumnList = new ColumnList(tableColumns);

    return tableColumnList.getVisible();
  }

  /**
   * Return table search.
   * @return {JSX.Element}
   */
  getSearch() {
    return (
      <TableSearch keyword={this.state.keyword}
        placeholder="Search"
        filter={this.handleSearch} />
    );
  }

  /**
   * Retrieve projects respecting the current filters.
   */
  loadMore(offset = this.state.pageOffset) {
    const filters = {
      offset,
      limit: this.state.pageLimit,
      order: this.state.sortBy.col,
      sort: this.state.sortBy.order,
      search: this.state.keyword,
      filters: JSON.stringify(this.props.filters),
    };

    if (!this.state.isLoading) {
      this.setState((state) => ({
        isLoading: true,
        pageOffset: offset,
        hasMore: true,
        projects: offset === 0 ? [] : state.projects,
      }));
      ChartModel
        .getTableData(this.props.parent.id, filters)
        .done((projects) => this.setState((prevState) => ({
          projects: prevState.projects.concat(projects),
          hasMore: projects.length === prevState.pageLimit,
          isLoading: false,
        })));
    }
  }

  /**
   * Retrieve table totals from API.
   */
  loadTotals() {
    const filters = {
      order: this.state.sortBy.col,
      sort: this.state.sortBy.order,
      search: this.state.keyword,
      filters: JSON.stringify(this.props.filters),
    };

    ChartModel
      .getTableTotals(this.props.parent.id, filters)
      .done((totals) => this.setState({ totals }));
  }

  /**
   * Return header columns.
   * @return {JSX.Element[]}
   */
  renderHeaderColumns() {
    return this.getColumns().map((column) => {
      const sort = this.getSort(column.sortKey);
      switch (column.key) {
        case 'gross_margin':
        case 'revenues':
          return (
            <MoneyCell sort={sort}
              onSort={this.handleSort}
              key={column.key}
              name={column.sortKey}
              className={column.key}>
              {column.label}
            </MoneyCell>
          );
        default: {
          return (
            <TableCell sort={sort}
              onSort={this.handleSort}
              key={column.key}
              name={column.sortKey}
              className={column.key}>
              {column.label}
            </TableCell>
          );
        }
      }
    });
  }

  /**
   * Return totals row.
   * @return {JSX.Element}
   */
  renderTotals() {
    return (
      <TableSummaryRow>
        {this.getTotalRowCells(this.state.totals)}
      </TableSummaryRow>
    );
  }

  render() {
    return (
      <div className="wethod-insight-level__chart-container">
        <Table maxHeight={this.tableHeight}
          visibleColumns={this.getVisibleColumnList()}
          search={this.getSearch()}>
          <TableHead>
            <TableRow>
              {this.renderHeaderColumns()}
            </TableRow>
            {this.renderTotals()}
          </TableHead>
          <TableBody hasMore={this.state.hasMore} loadMore={this.handleLoadMore}>
            {this.getRows()}
          </TableBody>
        </Table>
      </div>
    );
  }
}

InsightLevelTableViewContainer.defaultProps = {
  filters: [],
};

InsightLevelTableViewContainer.propTypes = {
  config: PropTypes.instanceOf(ChartConfigTable).isRequired,
  parent: PropTypes.instanceOf(Chart).isRequired,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(ChartConfigFilter)),
};

module.exports = InsightLevelTableViewContainer;
