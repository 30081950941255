const React = require('react');
const PropTypes = require('prop-types');
const Sidebar = require('../../../Chart/ChartSidebar/ChartSidebar.react');
const ChartConfig = require('../../../Chart/Chart/models/ChartConfig');
const BarChartConfigDimensions = require('../../../Chart/Chart/models/BarChartConfigDimensions');
const RevenuesDimension = require('../../../Chart/Chart/models/ChartConfigDimension/ChartConfigRevenuesDimension/ChartConfigRevenuesDimension');
const GrossMarginDimension = require('../../../Chart/Chart/models/ChartConfigDimension/ChartConfigGrossMarginDimension/ChartConfigGrossMarginDimension');
const Chart = require('../../../Chart/Chart/models/Chart');
const ChartModel = require('../../../../../models/Chart');
const sidebarService = require('../../../sidebar/SidebarManager/services/SidebarService');
const DimensionSelectItem = require('../../../Chart/ChartSidebar/models/DimensionSelectItem');

/**
 * Sidebar used to create a new chart.
 */
class CreateSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      serverErrors: [],
    };

    this.defaultConfig = new ChartConfig('', '', ChartConfig.TYPE_BAR, [], new BarChartConfigDimensions(null, this.getDataSourceDimension()));

    this.onSave = this.onSave.bind(this);
  }

  /**
   * @param {ChartConfig} config
   */
  onSave(config) {
    const chart = new Chart(null, this.props.parent, this.props.dataSource, config);
    this.setState({ isWaiting: true });
    ChartModel.create(chart)
      .done((serializedChart) => {
        const createdChart = Chart.fromJSON(serializedChart);
        sidebarService.closeSidebar();
        this.setState({ isWaiting: false });
        this.props.onSave(createdChart);
      })
      .fail((errors) => {
        this.setState({
          isWaiting: false,
          serverErrors: errors,
        });
      });
  }

  /**
   * Return ChartConfigDimension related to dataSource.
   * @return {null|ChartConfigDimension}
   */
  getDataSourceDimension() {
    switch (this.props.dataSource) {
      case RevenuesDimension.KEY:
        return new RevenuesDimension();
      case GrossMarginDimension.KEY:
        return new GrossMarginDimension();
      default:
        return null;
    }
  }

  render() {
    return (
      <Sidebar onCloseClick={this.props.onCloseClick}
        title="New chart"
        config={this.defaultConfig}
        errors={this.state.serverErrors}
        availableDimensions={this.props.availableDimensions}
        isSaving={this.state.isWaiting}
        onSave={this.onSave} />
    );
  }
}

CreateSidebar.defaultProps = {
  parent: null,
  availableDimensions: [],
};

CreateSidebar.propTypes = {
  parent: PropTypes.number,
  dataSource: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  availableDimensions: PropTypes.arrayOf(PropTypes.instanceOf(DimensionSelectItem)),
};

module.exports = CreateSidebar;
