/* eslint-disable react/sort-comp,react/no-did-update-set-state,react/no-access-state-in-setstate,
 class-methods-use-this */
const React = require('react');
const FormatService = require('../../../../../../../../../services/FormatService');
const Loading = require('./RevenuesReportLoading.react');
const TimeFilterFactory = require('../../models/TimeFilter/TimeFilterFactory');
const QuarterFilter = require('../../models/TimeFilter/QuarterFilter');
const FiscalYearService = require('../../../../../../../../../services/FiscalYearService');

module.exports = class SoldValueReport extends React.Component {
  constructor(props) {
    super(props);
    this.fiscalYearService = new FiscalYearService();

    this.chartOptions = {
      chart: {
        type: 'column',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
      },
      title: {
        text: '',
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false,
          text: '',
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 100,
            color: '#a5a5a5',
          },
        },
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,

          },
        },
        series: {
          stickyTracking: false,
          cursor: 'pointer',
          minPointLength: 4,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        formatter() {
          return `<b>${FormatService.formatNumberAsUnit(this.y)}</b>`;
        },
      },
    };
  }

  getCategories() {
    return TimeFilterFactory(this.props.timeFilter)
      .getBreakpoints()
      .map((breakpoint) => breakpoint.getLabel());
  }

  getSeries() {
    const filter = TimeFilterFactory(this.props.timeFilter);
    const categories = filter.getBreakpoints();
    const data = categories.map((category) => this.props.projects
      .filter((project) => {
        const lastProbabilityUpdatedAt = moment(project.last_probability_update);
        if (filter instanceof QuarterFilter) {
          const quarterMonth = category.getValue();
          const prevQuarterMonth = moment(quarterMonth).subtract(2, 'months').format('YYYY-MM-DD');
          return (lastProbabilityUpdatedAt.isBefore(quarterMonth)
              || lastProbabilityUpdatedAt.isSame(quarterMonth))
            && (lastProbabilityUpdatedAt.isAfter(prevQuarterMonth)
              || lastProbabilityUpdatedAt.isSame(prevQuarterMonth));
        }
        const startYear = this.fiscalYearService.getFiscalYearStartDate(category.getValue());
        const endYear = this.fiscalYearService.getFiscalYearEndDate(category.getValue());

        return (lastProbabilityUpdatedAt.isAfter(startYear)
            || lastProbabilityUpdatedAt.isSame(startYear))
          && (lastProbabilityUpdatedAt.isBefore(endYear)
            || lastProbabilityUpdatedAt.isSame(endYear));
      })
      .reduce((sum, project) => sum + project.estimate, 0));
    return [{ data }];
  }

  onPointClick(point) {
    const filter = TimeFilterFactory(this.props.timeFilter);
    filter.setFrame(point.category);
    this.props.updateTimeFilter(filter.toJSON());
  }

  getChartOptions() {
    return {
      ...this.chartOptions,
      series: this.getSeries(),
      xAxis: {
        ...this.chartOptions.xAxis,
        tickWidth: 0,
        categories: this.getCategories(),
      },
      plotOptions: {
        ...this.chartOptions.plotOptions,
        series: {
          ...this.chartOptions.plotOptions.series,
          cursor: 'pointer',
          color: 'rgba(79, 196, 247, 0.5)',
          allowPointSelect: true,
          states: {
            select: {
              color: 'rgba(79, 196, 247, 1)',
            },
          },
          point: {
            events: {
              // We need to bind this function's context to the component's one, otherwise we
              // cannot call onPointClick()
              click: function click(event) {
                this.onPointClick(event.point);
              }.bind(this),
            },
          },
        },
      },
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  componentDidUpdate(prevProps) {
    const timeFilterHasChanged = this.props.timeFilter.period !== prevProps.timeFilter.period;
    if (!this.props.waiting && (this.props.projects.length !== prevProps.projects.length
      || timeFilterHasChanged)) {
      this.renderChart();
    }
  }

  getTotalSold() {
    const sold = this.props.projectsInFrame.reduce((sum, project) => sum + project.estimate, 0);

    return FormatService.formatNumberAsUnit(sold);
  }

  getReport() {
    if (this.props.waiting) {
      return <Loading />;
    }
    return (
      <div>
        <div className="client-revenues-report__header">
          <div>
            <h3 className="client-revenues-report__header-title">Won projects' value</h3>
            <span className="client-revenues-report__total">{this.getTotalSold()}</span>
          </div>
          <div />
        </div>
        <div className="client-sold-value-report__chart"
          ref={(chartContainer) => this.chartContainer = chartContainer} />
      </div>
    );
  }

  render() {
    return (
      <div className="client-sold-value-report">
        {this.getReport()}
      </div>
    );
  }
};
