const ChartConfigFilterAccount = require('./ChartConfigFilterAccount');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterAccountIncluded extends ChartConfigFilterAccount {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterAccountIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterAccountIncluded(data.values);
  }

  isValid() {
    return Array.isArray(this.values) && this.values.length > 0;
  }
};
