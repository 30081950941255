/* eslint-disable react/prop-types */
const React = require('react');
const Loading = require('./TrendItemLoading.react');

module.exports = class TrendItem extends React.Component {
  getChart() {
    if (this.props.isWaiting) {
      return <Loading />;
    }
    return (
      <div style={{ width: '100%' }}>
        <div className="trend__overview">
          <h3 className="trend__title">{this.props.title}</h3>
          <div className="trend__total">{this.props.total}</div>
        </div>
        <div className="trend__report">
          {this.props.chart}
        </div>
      </div>
    );
  }

  getClassName() {
    let style = 'trend ';
    if (this.props.className) {
      style += ` ${this.props.className}`;
    }
    return style;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getChart()}
      </div>
    );
  }
};
