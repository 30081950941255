const ChartConfigDimension = require('../ChartConfigDimension');

class ChartConfigGrossMarginDimension extends ChartConfigDimension {
  constructor() {
    super(ChartConfigGrossMarginDimension.KEY);
  }

  /**
   * @return {ChartConfigGrossMarginDimension}
   */
  static fromJSON() {
    return new ChartConfigGrossMarginDimension();
  }

  // eslint-disable-next-line class-methods-use-this
  clone() {
    return new ChartConfigGrossMarginDimension();
  }
}

ChartConfigGrossMarginDimension.KEY = 'gross-margin';

module.exports = ChartConfigGrossMarginDimension;
