const React = require('react');
require('./style.scss');
const { Provider } = require('react-redux');
const WonLostBot = require('./containers/won-lost-bot/WonLostBot');

module.exports = ({ store }) => (
  <Provider store={store}>
    <WonLostBot />
  </Provider>
);
