'use strict';

/* eslint-disable eqeqeq */
var SettingsStore = require('../../../apps/core/modules/settings/company/store');
var SettingsReducer = require('../../../apps/core/modules/settings/company/reducer');
var BusinessUnitService = require('../../../services/BusinessUnitService');
var FeatureService = require('../../../services/FeatureService');
var JobTitleModel = require('../../../models/JobTitle');
var FiscalYearModel = require('../../../models/FiscalYear');
var Location = require('../../../models/Location');
var WorkHourCapacityModel = require('../../../models/WorkHourCapacity');
var TimesheetSettingsModel = require('../../../models/TimesheetSettings');
var CompanyHolidayModel = require('../../../models/CompanyHoliday');
var WinLossProbabilityThreshold = require('../../../models/WinLossProbabilityThreshold');
var WinLossEmailAddress = require('../../../models/WinLossEmailAddress');

Wethod.module('SettingsApp.Company', function (Company, Wethod, Backbone, Marionette, $, _, Header) {
  // views
  var companyLayout;
  var headerItemView;
  var contactItemView;
  var levelLayout;
  var levelCollectionView;
  var PTLayout;
  var PTCollectionView;
  var PTGroupLayout;
  var labelLayout;
  var labelCollectionView;
  var timesheetLayout;
  var planningLayout;
  var pipelineLayout;
  var projectReviewLayout;
  var projectReviewDisabledView;
  var projectReviewEnabledView;
  var googleDriveIntegrationLayout;
  var projectStatusLayout;
  var currenciesLayout;
  var currenciesTableView;
  var currencyEnableView;
  var currencyLoadingView;
  var wonLostFeedbackLayout;
  var wonFeedbackCollectionView;
  var lostFeedbackCollectionView;
  var businessUnitLayout;
  var businessUnitDisabledView;
  var supplierLayout;
  var supplierStatusDisabledView;
  var supplierStatusEnabledView;
  var supplierTagCategoryView;
  var businessUnitEnabledView;
  var pipelineOpportunityLayout;
  var opportunityStageCollectionView;
  var expensesLayout;
  var expenseCategoryCollectionView;
  var bankAccountLayout;
  var bankAccountCollectionView;
  var vatRateLayout;
  var vatRateCollectionView;
  var paymentTermLayout;
  var paymentTermCollectionView;
  var businessUnitService;
  var jobOrderTemplateLayout;
  var orderPlanSettingsLayout;
  var jobTitleSettingsLayout;
  var fiscalYearSettingsLayout;
  var workHourCapacitySettingsLayout;
  var employeeTagsLayout;
  var locationLayout;
  var companyHolidaySettingsLayout;
  var companyWonLostBotLayout;

  // collections
  var levels;
  var currencies;
  var projectTypes;
  var projectTypeGroups;
  var projectLabels;
  var projectReviewCriteria;
  var teamReviewCriteria;
  var wonFeedback;
  var lostFeedback;
  var supplierStatus;
  var supplierTagCategory;
  var supplierTag;
  var businessUnit;
  var travelContacts;
  var opportunityStatus;
  var expenseCategory;
  var expenseCategoryType;
  var bankAccount;
  var vatRate;
  var paymentTerm;

  var resetData = function () {
    companyLayout = {};
    headerItemView = {};
    contactItemView = {};
    levelLayout = {};
    levelCollectionView = {};
    currenciesLayout = {};
    currenciesTableView = {};
    PTLayout = {};
    PTCollectionView = {};
    PTGroupLayout = {};
    labelLayout = {};
    labelCollectionView = {};
    wonLostFeedbackLayout = {};
    wonFeedbackCollectionView = {};
    lostFeedbackCollectionView = {};
    pipelineOpportunityLayout = {};
    opportunityStageCollectionView = {};
    expensesLayout = {};
    expenseCategoryCollectionView = {};
    bankAccountLayout = {};
    bankAccountCollectionView = {};
    vatRateLayout = {};
    vatRateCollectionView = {};
    paymentTermLayout = {};
    paymentTermCollectionView = {};
    jobOrderTemplateLayout = {};
    orderPlanSettingsLayout = {};
    jobTitleSettingsLayout = {};
    fiscalYearSettingsLayout = {};
    workHourCapacitySettingsLayout = {};
    employeeTagsLayout = {};
    locationLayout = {};
    companyHolidaySettingsLayout = {};
    companyWonLostBotLayout = {};

    timesheetLayout = undefined;
    planningLayout = undefined;
    pipelineLayout = undefined;
    projectReviewLayout = undefined;
    googleDriveIntegrationLayout = undefined;
    projectStatusLayout = undefined;
    businessUnitLayout = undefined;
    supplierLayout = undefined;

    levels = {};
    projectTypes = {};
    projectTypeGroups = {};
    projectLabels = {};
    projectReviewCriteria = {};
    teamReviewCriteria = {};
    wonFeedback = {};
    lostFeedback = {};
    businessUnit = {};
    opportunityStatus = {};
    expenseCategory = {};
    expenseCategoryType = {};
    bankAccount = {};
    vatRate = {};
    paymentTerm = {};
  };

  Company.updateBuEnabledView = function (newView, buCollection) {
    businessUnitEnabledView = newView;
    businessUnit = buCollection;
  };

  Company.Controller = {

    showCompany: function (options) {
      businessUnitService = new BusinessUnitService();
      dispatcher.on('company:settings:bu:changedView', Company.updateBuEnabledView);
      resetData();

      // Splash screen loading
      var sentence = Wethod.Utility.randomSentence(Wethod.sentencesDays, Wethod.sentencesSongs);
      var loadingView = new Wethod.View.Loading.BigView({ sentence: sentence });
      Wethod.regions.body.show(loadingView);

      var authorizationRequest = Wethod.request('get:settings:authorization');
      $.when(authorizationRequest).done(function (authorizationResponse) {
        if (_.isUndefined(authorizationResponse)) {
          return;
        }

        Wethod.SettingsApp.authorization = authorizationResponse;

        if (Wethod.SettingsApp.getPermission('company')) {
          var sectionHeaderModel = new Header.SectionHeaderModel({
            current_section: 'Company',
            helper_url: 'settings/index/#company',
          });

          companyLayout = new Company.CompanyLayout();
          headerItemView = new Header.SectionHeaderView({ model: sectionHeaderModel });
          levelLayout = new Company.LevelLayout();
          labelLayout = new Company.LabelLayout();
          wonLostFeedbackLayout = new Company.WonLostFeedbackLayout();

          var getLevels = Wethod.request('get:company:levels');
          var getMulticurrency = Wethod.request('get:company:multicurrency');
          var getCurrencies = Wethod.request('get:company:currencies');
          var getProjectTypes = Wethod.request('get:company:projectType');
          var getProjectTypeGroups = Wethod.request('get:company:projectTypeGroups');
          var getProjectLabels = Wethod.request('get:company:projectLabels');
          var getPlanning = Wethod.request('get:company:planning');
          var getPlanningInternationalCalendar = Wethod.request('get:company:planning:calendar');
          var getInvoiceContact = Wethod.request('get:company:invoice:contact');
          var getOrderContact = Wethod.request('get:company:order:contact');
          var getTravelContact = Wethod.request('get:company:travel:contacts');
          var getMetadata = Wethod.request('get:company:metadata');
          var getDetailedTimesheet = Wethod.request('get:company:timesheet:detailed');
          var getTimesheetEmailFrequency = Wethod.request('get:company:timesheet:emailFrequency');
          var getTimesheetTimeLimit = Wethod.request('get:company:timesheet:timelimit');
          var getPipelineContact = Wethod.request('get:company:pipeline:contact');
          var getPipelineRequiredJobOrder = Wethod.request('get:company:pipeline:requiredJobOrder');
          var getPipelineReviewConfig = Wethod.request('get:company:pipeline:review:config');
          var getProjectReviewCriteria = Wethod.request('get:company:project:review:criteria');
          var getGoogleDriveIntegrationConfig = Wethod.request('get:company:integration:gdrive:config');
          var getTeamReviewCriteria = Wethod.request('get:company:team:review:criteria');
          var getCascadingProjectStatus = Wethod.request('get:company:project-status:cascading');
          var getProjectStatusRisks = Wethod.request('get:company:project-status:risks');
          var getWonLostFeedback = Wethod.request('get:company:wonLostFeedback');
          var getSupplierStatusEnabled = Wethod.request('get:company:supplier-status:enabled');
          var getSupplierStatus = Wethod.request('get:company:supplier-status');
          var getSupplierTagCategory = Wethod.request('get:company:supplier-tag-category');
          var getSupplierTag = Wethod.request('get:company:supplier-tag');
          var getBusinessUnitMarkup = Wethod.request('get:company:business-unit:markup');
          var getWastedHoursSettings = Wethod.request('get:wasted:hours:settings');
          var getOpportunityStatus = Wethod.request('get:company:opportunity-status');
          var getExpenseCategory = Wethod.request('get:company:expense-category', true);
          var getExpenseCategoryType = Wethod.request('get:company:expense-category-type');
          var getBankAccount = Wethod.request('get:company:bank-account', true); // Show also archived
          var getVatRate = Wethod.request('get:company:vat-rate', true); // Show also archived
          var getPaymentTerm = Wethod.request('get:company:payment-term', true); // Show also archived
          var getPipelineValueAsUnit = Wethod.request('get:company:pipeline:valueAsUnit');
          var getJobOrderTemplateEnabled = Wethod.request('get:company:jobOrderTemplate:enabled');
          var getJobOrderTemplates = Wethod.request('get:company:jobOrderTemplate:list');
          var getOrderPlanSettings = Wethod.request('get:company:orderPlanSettings');
          $.when(
            getLevels,
            getMulticurrency,
            getCurrencies,
            getProjectTypes,
            getProjectTypeGroups,
            getPlanning,
            getPlanningInternationalCalendar,
            getProjectLabels,
            getInvoiceContact,
            getOrderContact,
            getTravelContact,
            getMetadata,
            getDetailedTimesheet,
            getTimesheetEmailFrequency,
            getTimesheetTimeLimit,
            getPipelineContact,
            getPipelineRequiredJobOrder,
            getPipelineReviewConfig,
            getProjectReviewCriteria,
            getTeamReviewCriteria,
            getGoogleDriveIntegrationConfig,
            getCascadingProjectStatus,
            getProjectStatusRisks,
            getWonLostFeedback,
            getSupplierStatusEnabled,
            getSupplierStatus,
            getSupplierTagCategory,
            getSupplierTag,
            getBusinessUnitMarkup,
            getWastedHoursSettings,
            getOpportunityStatus,
            getExpenseCategory,
            getExpenseCategoryType,
            getBankAccount,
            getVatRate,
            getPaymentTerm,
            getPipelineValueAsUnit,
            getJobOrderTemplateEnabled,
            getJobOrderTemplates,
            getOrderPlanSettings,
            JobTitleModel.list({ archived: false }),
            FiscalYearModel.get(),
            Location.get(),
            WorkHourCapacityModel.list(),
            TimesheetSettingsModel.getDailyModeToggle(),
            CompanyHolidayModel.list(),
            WinLossProbabilityThreshold.get(),
            WinLossEmailAddress.get()
          ).done(function (
            levelResponse,
            multicurrencyResponse,
            currenciesResponse,
            projectTypesResponse,
            projectTypeGroupsResponse,
            planningResponse,
            planningInternationalCalendarResponse,
            projectLabelsResponse,
            invoiceContactResponse,
            orderContactResponse,
            travelContactResponse,
            metadataResponse,
            timesheetDetailedResponse,
            timesheetEmailFrequencyResponse,
            timesheetTimeLimitResponse,
            pipelineContactResponse,
            getPipelineRequiredJobOrderResponse,
            pipelineReviewConfig,
            projectReviewCriteriaResponse,
            teamReviewCriteriaResponse,
            googleDriveIntegrationConfig,
            cascadingProjectStatusResponse,
            projectStatusRisksResponse,
            wonLostFeedbackResponse,
            supplierStatusEnabledResponse,
            supplierStatusResponse,
            supplierTagCategoryResponse,
            supplierTagResponse,
            businessUnitMarkupResponse,
            getWastedHoursSettingsResponse,
            opportunityStatusResponse,
            expenseCategoryResponse,
            expenseCategoryTypeResponse,
            bankAccountResponse,
            vatRateResponse,
            paymentTermResponse,
            getPipelineValueAsUnitResponse,
            getJobOrderTemplateEnabledResponse,
            getJobOrderTemplatesResponse,
            getOrderPlanSettingsResponse,
            jobTitleResponse,
            fiscalYearResponse,
            locationResponse,
            workHourCapacityResponse,
            timesheetDailyModeToggleResponse,
            companyHolidayResponse,
            winLossProbabilityThresholdResponse,
            winLossEmailAddressResponse
          ) {
            /**
             * Add Settings reducer store, which can be used by any React settings subsection
             */
            var reducerWrapper;
            reducerWrapper = new SettingsReducer({
              timesheet: {
                strategy: timesheetDetailedResponse.get('strategy'),
                frequency: timesheetEmailFrequencyResponse.frequency,
                timeLimit: timesheetTimeLimitResponse.timeLimit,
                dailyModeToggle: timesheetDailyModeToggleResponse.daily_mode,
              },
              jobOrderCategories: projectTypesResponse.toJSON(),
              jobOrderTemplates: {
                enabled: getJobOrderTemplateEnabledResponse.enabled,
                templates: getJobOrderTemplatesResponse,
              },
              orderPlanSettings: getOrderPlanSettingsResponse,
              levels: levelResponse.toJSON(),
              jobTitleSettings: {
                jobTitles: jobTitleResponse,
                archivedFilter: false,
              },
              fiscalYear: {
                start: fiscalYearResponse.fiscal_year_start,
                disabled: fiscalYearResponse.fiscal_year_start !== 1,
              },
              locations: locationResponse,
              companyHolidays: companyHolidayResponse,
              workHourCapacities: workHourCapacityResponse,
              winLossProbabilityThreshold: {
                winProbabilityThreshold: winLossProbabilityThresholdResponse
                  .win_probability_threshold,
              },
              winLossEmailAdddress: {
                winEmail: winLossEmailAddressResponse.win_email,
                lossEmail: winLossEmailAddressResponse.loss_email,
              },
            });
            var store = SettingsStore(reducerWrapper.reduxReducer);

            jobOrderTemplateLayout = new Company.JobOrderTemplateLayout({ store: store });

            timesheetLayout = new Company.TimesheetTemplateLayout({ store: store });

            orderPlanSettingsLayout = new Company.OrderPlanSettingsLayout({ store: store });

            jobTitleSettingsLayout = new Company.JobTitleTemplateLayout({ store: store });

            fiscalYearSettingsLayout = new Company.FiscalYearSettingsLayout({ store: store });

            companyWonLostBotLayout = new Company.WonLostBotLayout({ store: store });

            employeeTagsLayout = new Company.EmployeeTagsLayout({ store: store });
            locationLayout = new Company.LocationLayout({ store: store });

            companyHolidaySettingsLayout = new Company
              .CompanyHolidaySettingsLayout({ store: store });

            workHourCapacitySettingsLayout = new Company
              .WorkHourCapacitySettingsLayout({ store: store });

            levels = levelResponse;
            currencies = currenciesResponse;
            projectTypes = projectTypesResponse;
            projectTypeGroups = projectTypeGroupsResponse;
            projectLabels = projectLabelsResponse;
            var feedback = wonLostFeedbackResponse;

            wonFeedback = feedback.select(function (model) {
              return model.get('type') === 'won';
            });
            wonFeedback = new Company.WonLostFeedbackCollection(wonFeedback);

            lostFeedback = feedback.select(function (model) {
              return model.get('type') === 'lost';
            });
            lostFeedback = new Company.WonLostFeedbackCollection(lostFeedback);

            levelCollectionView = new Company.LevelCollectionView({
              collection: levels,
            });

            PTLayout = new Company.PTLayout({
              groups: projectTypeGroups,
            });

            PTCollectionView = new Company.PTCollectionView({
              collection: projectTypes,
              groups: projectTypeGroups,
            });

            PTGroupLayout = new Company.PTGroupLayout({
              groups: projectTypeGroups,
            });

            labelCollectionView = new Company.LabelCollectionView({
              collection: projectLabels,
            });
            pipelineLayout = new Company.PipelineLayout({
              pipelineContact: pipelineContactResponse,
              pipelineRequiredJobOrder: getPipelineRequiredJobOrderResponse,
              wastedHoursSettings: getWastedHoursSettingsResponse,
              valueAsUnit: getPipelineValueAsUnitResponse,
            });
            projectStatusLayout = new Company.ProjectStatusLayout({
              cascading: cascadingProjectStatusResponse,
              risks: projectStatusRisksResponse,
            });
            planningLayout = new Company.PlanningTemplateLayout({
              planningModel: planningResponse,
              internationalCalendarModel: planningInternationalCalendarResponse,
            });
            wonFeedbackCollectionView = new Company.WonLostFeedbackCollectionView({
              collection: wonFeedback,
            });
            lostFeedbackCollectionView = new Company.WonLostFeedbackCollectionView({
              collection: lostFeedback,
            });

            var invoiceContact = invoiceContactResponse;
            var orderContact = orderContactResponse;
            travelContacts = travelContactResponse;

            contactItemView = new Company.ContacItemView({
              invoiceContact: invoiceContact,
              orderContact: orderContact,
              travelContact: travelContacts,
            });

            companyLayout.render();
            companyLayout.header.show(headerItemView);

            companyLayout.jobOrderTemplate.show(jobOrderTemplateLayout);

            companyLayout.orderPlanSettings.show(orderPlanSettingsLayout);

            companyLayout.jobTitleSettings.show(jobTitleSettingsLayout);

            companyLayout.fiscalYearSettings.show(fiscalYearSettingsLayout);
            companyLayout.wonLostBotSettings.show(companyWonLostBotLayout);

            if (Wethod.featureService.isEnabled(
              FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK
            )) {
              companyLayout.workHourCapacitySettings.show(workHourCapacitySettingsLayout);
              companyLayout.companyHolidaySettings.show(companyHolidaySettingsLayout);
            }

            companyLayout.employeeTagsSettings.show(employeeTagsLayout);
            companyLayout.locationSettings.show(locationLayout);

            companyLayout.contacts.show(contactItemView);
            companyLayout.levels.show(levelLayout);
            companyLayout.projectTypes.show(PTLayout);
            companyLayout.projectTypeGroups.show(PTGroupLayout);
            companyLayout.projectLabels.show(labelLayout);

            companyLayout.timesheet.show(timesheetLayout);

            companyLayout.planning.show(planningLayout);

            companyLayout.pipeline.show(pipelineLayout);

            projectReviewLayout = new Company.ProjectReviewLayout();
            companyLayout.projectReview.show(projectReviewLayout);
            projectReviewCriteria = projectReviewCriteriaResponse;
            teamReviewCriteria = teamReviewCriteriaResponse;
            if (pipelineReviewConfig.get('enabled')) {
              projectReviewEnabledView = new Company.ProjectReviewEnabledView({
                model: pipelineReviewConfig,
                projectTypes: projectTypes,
                projectReviewCriteria: projectReviewCriteria,
                teamMemberReviewCriteria: teamReviewCriteria,
              });
              projectReviewLayout.content.show(projectReviewEnabledView);
            } else {
              projectReviewDisabledView = new Company.ProjectReviewDisabledView();
              projectReviewLayout.content.show(projectReviewDisabledView);
            }

            supplierLayout = new Company.SupplierLayout({
              model: supplierStatusEnabledResponse,
            });
            supplierTagCategory = supplierTagCategoryResponse;
            supplierTag = supplierTagResponse;
            supplierTagCategoryView = new Company.SupplierTagCategoryView({
              categories: supplierTagCategoryResponse,
              tags: supplierTag,
            });
            companyLayout.supplier.show(supplierLayout);
            supplierLayout.categoryContent.show(supplierTagCategoryView);
            if (supplierStatusEnabledResponse.get('status_enabled')) {
              supplierStatus = supplierStatusResponse;
              supplierStatusEnabledView = new Company.SupplierStatusEnabledView({
                model: supplierStatusResponse,
              });
              supplierLayout.statusContent.show(supplierStatusEnabledView);
            } else {
              supplierStatusDisabledView = new Company.SupplierStatusDisabledView();
              supplierLayout.statusContent.show(supplierStatusDisabledView);
            }

            companyLayout.projectStatus.show(projectStatusLayout);

            // Opportunity status

            // Select default status which should be disabled
            var defaultStatus = opportunityStatusResponse.select(function (model) {
              return model.get('is_default') === true;
            });
            defaultStatus = defaultStatus && defaultStatus.length > 0 ? defaultStatus[0] : null;

            // Select statuses which are customizable
            opportunityStatus = new Company.OpportunityStatusCollection(opportunityStatusResponse
              .select(function (model) {
                return model.get('is_default') !== true;
              }));
            pipelineOpportunityLayout = new Company.PipelineOpportunityLayout();

            var opportunityStageDisabledView = new Company
              .PipelineOpportunityStatusDisabledItemView({
                model: defaultStatus,
              });
            opportunityStageCollectionView = new Company.PipelineOpportunityStatusCollectionView({
              collection: opportunityStatus,
            });

            companyLayout.pipelineOpportunities.show(pipelineOpportunityLayout);
            pipelineOpportunityLayout.defaultStatus.show(opportunityStageDisabledView);
            pipelineOpportunityLayout.statusList.show(opportunityStageCollectionView);

            currenciesLayout = new Company.CurrencyLayout();
            companyLayout.multicurrency.show(currenciesLayout);
            if (currencies.length) {
              currenciesTableView = new Company.CurrencyTableLayout({
                collection: currencies,
              });
              currenciesLayout.content.show(currenciesTableView);
            } else {
              currencyEnableView = new Company.CurrencyEnableView({ model: multicurrencyResponse });

              dispatcher.on('company:currency:enable-multicurrency', function () {
                currencyLoadingView = new Company.CurrencyLoadingView();
                currenciesLayout.content.show(currencyLoadingView);
              });

              dispatcher.on('company:currency:show-currencies', function () {
                Wethod.request('get:company:currencies').done(function (currencyList) {
                  currenciesTableView = new Company.CurrencyTableLayout({
                    collection: currencyList,
                  });
                  currenciesLayout.content.show(currenciesTableView);
                });
              });

              currenciesLayout.content.show(currencyEnableView);
            }
            companyLayout.feedback.show(wonLostFeedbackLayout);

            levelLayout.levels.show(levelCollectionView);
            PTLayout.projectTypes.show(PTCollectionView);
            labelLayout.projectLabels.show(labelCollectionView);
            wonLostFeedbackLayout.wonFeedback.show(wonFeedbackCollectionView);
            wonLostFeedbackLayout.lostFeedback.show(lostFeedbackCollectionView);

            dispatcher.on('company:project-type-groups:update', function (groups) {
              // Project types may not be in consistent state and need to be updated
              Wethod.request('get:company:projectType').done(function (response) {
                projectTypes = response;

                PTLayout = new Company.PTLayout({
                  groups: groups,
                });
                PTCollectionView = new Company.PTCollectionView({
                  collection: projectTypes,
                  groups: groups,
                });
                companyLayout.projectTypes.show(PTLayout);
                PTLayout.projectTypes.show(PTCollectionView);
              });
            });

            var businessUnitUserDefaultFilter = Wethod.userInfo.get('filter_on_user_bu');
            var businessUnitMarkup = businessUnitMarkupResponse.get('markup');
            var defaultFilterModel = new Company.BusinessUnitDefaultFilterModel({
              filter_on_user_bu: businessUnitUserDefaultFilter,
              markup: businessUnitMarkup,
            });
            businessUnitLayout = new Company.BusinessUnitLayout({
              defaultFilterModel: defaultFilterModel,
            });
            companyLayout.businessUnit.show(businessUnitLayout);
            var businessUnitEnabled = businessUnitService.isEnabled();
            if (businessUnitEnabled) {
              businessUnit = new Company.BusinessUnitCollection();
              businessUnit.reset(businessUnitService.getForCompany());
              businessUnitEnabledView = new Company.BusinessUnitEnabledView({
                model: defaultFilterModel,
                businessUnits: businessUnit,
              });
              businessUnitLayout.content.show(businessUnitEnabledView);
            } else {
              businessUnitDisabledView = new Company.BusinessUnitDisabledView();
              businessUnitLayout.content.show(businessUnitDisabledView);
            }

            var metadataLayout = new Company.MetadataLayout();
            companyLayout.metadata.show(metadataLayout);
            var metadataCollectionView = new Company.MetadataCollectionView({
              collection: metadataResponse,
            });
            metadataLayout.metadataCollection.show(metadataCollectionView);

            expenseCategoryType = expenseCategoryTypeResponse;
            expensesLayout = new Company.ExpensesLayout({
              types: expenseCategoryType,
            });

            expenseCategory = expenseCategoryResponse;
            expenseCategoryCollectionView = new Company.ExpenseCategoryCollectionView({
              collection: expenseCategory,
            });

            companyLayout.expense.show(expensesLayout);
            expensesLayout.category.show(expenseCategoryCollectionView);

            // Bank account
            bankAccount = bankAccountResponse;
            bankAccountLayout = new Company.BankAccountLayout();

            bankAccountCollectionView = new Company.BankAccountCollectionView({
              collection: bankAccount,
            });

            companyLayout.bankAccount.show(bankAccountLayout);
            bankAccountLayout.banks.show(bankAccountCollectionView);

            // VAT rate
            vatRate = vatRateResponse;
            vatRateLayout = new Company.VatRateLayout();

            vatRateCollectionView = new Company.VatRateCollectionView({
              collection: vatRate,
            });

            companyLayout.vatRate.show(vatRateLayout);
            vatRateLayout.rates.show(vatRateCollectionView);

            // Payment terms
            paymentTerm = paymentTermResponse;
            paymentTermLayout = new Company.PaymentTermLayout();

            paymentTermCollectionView = new Company.PaymentTermCollectionView({
              collection: paymentTerm,
            });
            companyLayout.paymentTerm.show(paymentTermLayout);
            paymentTermLayout.terms.show(paymentTermCollectionView);

            googleDriveIntegrationLayout = new Company.GoogleDriveIntegrationLayout({
              projectTypes: projectTypes,
              config: googleDriveIntegrationConfig.attributes,
              action: options.action,
            });
            companyLayout.googleDriveIntegration.show(googleDriveIntegrationLayout);
            // adjust the height of the div with white background
            var fill = $('.fillHeight');
            if (fill.height() < $('html').height()) {
              fill.height('100%');
            } else {
              fill.height('auto');
            }

            Company.Controller.scrollToSection(options.idSection);

            dispatcher.trigger('tour:start', options.idTour);
          });
        } else {
          loadingView.destroy();
          var permissionDeniedView = new Wethod.PermissionDenied.PermissionDeniedTemplateView();
          Wethod.regions.body.show(permissionDeniedView);
        }
      });
    },

    saveLevel: function (level) {
      if (level.name && level.code && !_.isNull(level.value) && !_.isNull(level.rate)) {
        var attributes = {
          text: level.name,
          short_text: level.code,
          chargeability_target_percent: level.chargeability_target_percent,
          external: level.ext,
          must_be_planned: level.must_be_planned,
          must_do_timesheet: level.must_do_timesheet,
          is_default: level.is_default,
          is_deletable: level.is_deletable,
        };

        var levelModel = new Company.LevelModel(attributes);

        $.when(Wethod.request('save:company:level', levelModel)).done(function (response) {
          if (response.changed.id) {
            levels.add(response);
            levelLayout.hideNewLevel();

            levelLayout.hideError(); // todo better error handling

            dispatcher.trigger('user:level:added', response, levels);
            var levelCollectionViewUpdated = new Company.LevelCollectionView({
              collection: levels,
            });
            levelLayout.levels.show(levelCollectionViewUpdated);
          } else {
            var data = response.changed.message;
            levelLayout.showErrorSave(data);
          }
        });
      } else {
        levelLayout.showError(); // todo better error handling
      }
    },
    updateLevel: function (level) {
      if (level.name && level.code && !_.isNull(level.value) && !_.isNull(level.rate)) {
        var attributes = {
          id: level.id,
          text: level.name,
          short_text: level.code,
          chargeability_target_percent: level.chargeability_target_percent,
          external: level.ext,
          must_be_planned: level.must_be_planned,
          must_do_timesheet: level.must_do_timesheet,
          is_default: level.is_default,
        };

        var levelModel = new Company.LevelModel(attributes);

        $.when(Wethod.request('save:company:level', levelModel)).done(function () {
          dispatcher.trigger('user:level:updated', levelModel, levels);
          var levelCollectionViewUpdated = new Company.LevelCollectionView({
            collection: levels,
          });
          levelLayout.levels.show(levelCollectionViewUpdated);
        });
      }
    },
    deleteLevel: function (levelModel) {
      $.when(Wethod.request('delete:company:level', levelModel)).done(function () {
        dispatcher.trigger('user:level:deleted', levelModel, levels);
        levels.remove(levelModel);
        var levelCollectionViewUpdated = new Company.LevelCollectionView({
          collection: levels,
        });
        levelLayout.levels.show(levelCollectionViewUpdated);
      });
    },
    saveProjectType: function (projectType) {
      if (projectType.color && projectType.name) {
        var ptModel = new Company.ProjectTypeModel(projectType);

        $.when(Wethod.request('save:company:projectType', ptModel)).done(function (response) {
          projectTypes.add(response);
          dispatcher.trigger('project:type:added', response);
          PTLayout.hideNewPT();

          PTLayout.hideError(); // todo better error handling
        });
      } else {
        PTLayout.showError(); // todo better error handling
      }
    },
    updateProjectType: function (projectType, force, forceUpdateOnError) {
      if (projectType.name) {
        var ptModel = new Company.ProjectTypeModel(projectType);

        $.when(Wethod.request('save:company:projectType', ptModel, force)).done(function () {
          dispatcher.trigger('project:type:updated', ptModel);
        }).fail(function (response) {
          var data = response;
          data.projectType = projectType;
          Company.Controller.showProjectTypeErrorModal(data, forceUpdateOnError);
        });
      }
    },
    deleteProjectType: function (oldPTId, newPTId) {
      $.when(Wethod.request('delete:company:projectType', oldPTId, newPTId))
        .done(function (response) {
          if (response.code == 200) {
            projectTypes.remove(oldPTId);
            dispatcher.trigger('project:type:deleted', oldPTId);
          } else if (response.code === 400) {
            var data = projectTypes.get(oldPTId);
            data.message = response.message;
            Company.Controller.showProjectTypeErrorModal(data, false, 'Cannot delete job order category');
          }
        });
    },
    showPTModal: function (projectType) {
      var idPT = projectType.id;
      var PTModal = new Company.LevelModal({
        loading: 'deleting...',
      });
      var modalItemView = new Company.PTModalItemView({
        model: PTModal,
      });
      companyLayout.modal.show(modalItemView);

      $.when(Wethod.request('get:company:projects', projectType.get('name'))).done(function (projectCollection) {
        // eslint-disable-next-line no-shadow
        var PTModal = new Company.LevelModal();

        var actualProject = new Company.ProjectCollection();
        projectCollection.each(function (project) {
          if (project.get('project_type').id == idPT) {
            actualProject.push(project);
          }
        });

        if (actualProject.length > 0) {
          var actualPT = new Company.ProjectCollection();
          projectTypes.each(function (PT) {
            if (PT.get('id') != idPT) {
              actualPT.push(PT);
            }
          });

          if (actualPT.length === 0) {
            PTModal = new Company.LevelModal({
              title: 'OOPS!',
              text: 'You cannot delete all the project\'s type',
              buttonCancelText: 'cancel',
            });
          } else {
            PTModal = new Company.LevelModal({
              title: 'OOPS!',
              text: 'This type is used by the following projects:',
              buttonCancelText: 'cancel',
              buttonSubmitText: 'do delete',
              PTId: idPT,
              projects: actualProject,
              projectTypes: actualPT,
            });
          }

          modalItemView = new Company.PTModalItemView({
            model: PTModal,
          });

          companyLayout.modal.show(modalItemView);
        } else {
          Company.Controller.deleteProjectType(idPT);
          Company.Controller.closeModal(modalItemView);
        }
      });
    },
    savePTGroup: function (data) {
      if (data.name !== '') {
        var insertRequest = Wethod.request('create:company:projectTypeGroups', data);

        $.when(insertRequest).done(function (insertResponse) {
          PTGroupLayout.hideNewGroup();
          PTGroupLayout.hideError(); // todo better error handling

          projectTypeGroups.add(insertResponse);
          dispatcher.trigger('company:project-type-groups:update', projectTypeGroups);
        });
      } else {
        PTGroupLayout.showError(); // todo better error handling
      }
    },
    updatePTGroup: function (model) {
      Wethod.request('update:company:projectTypeGroups', model).done(function () {
        dispatcher.trigger('company:project-type-groups:update', projectTypeGroups);
      });
    },
    deletePTGroup: function (model) {
      if (model.get('default')) {
        var modal = new Company.LevelModal({
          title: 'Cannot delete group',
          text: 'Oops! Please, set another job order category group as default before you delete this one.',
          buttonOkText: 'Ok',
        });
        var modalView = new Company.LevelModalItemView({
          model: modal,
        });
        companyLayout.modal.show(modalView);
      } else {
        Wethod.request('delete:company:projectTypeGroups', model).done(function () {
          dispatcher.trigger('company:project-type-groups:update', projectTypeGroups);
        });
      }
    },
    showBudgetModal: function () {
      var levelModal = new Company.LevelModal({
        title: 'Cannot delete this level!',
        text: 'This level is the only one default for budget.',
        buttonKoText: 'Cancel',
        buttonOkText: 'Ok',
      });
      var SettingLevelModal = new Company.LevelModalItemView({
        model: levelModal,
      });
      companyLayout.modal.show(SettingLevelModal);
    },
    showLevelModal: function () {
      var levelModal = new Company.LevelModal({
        title: 'Cannot delete this level!',
        text: 'This level is used in some budget, by some employee or in some product',
        buttonKoText: 'Cancel',
        buttonOkText: 'Ok',
      });
      var SettingLevelModal = new Company.LevelModalItemView({
        model: levelModal,
      });
      companyLayout.modal.show(SettingLevelModal);
    },
    closeModal: function (obj) {
      obj.destroy();
    },
    saveProjectLabel: function (data) {
      if (data.name !== '') {
        var insertRequest = Wethod.request('insert:company:projectLabels', data);

        $.when(insertRequest).done(function (insertResponse) {
          labelLayout.hideNewLabel();
          labelLayout.hideError(); // todo better error handling

          projectLabels.add(insertResponse);
          projectLabels.trigger('project:label:added');
        });
      } else {
        labelLayout.showError(); // todo better error handling
      }
    },
    updateProjectLabel: function (model) {
      var insertRequest = Wethod.request('update:company:projectLabels', model);
      $.when(insertRequest).done(function () {
      });
    },
    deleteProjectLabel: function (model) {
      Wethod.request('delete:company:projectLabels', model);
    },
    saveInvoicesContact: function (newEmail, oldEmail) {
      $.when(Wethod.request('set:company:invoice:contact', newEmail, oldEmail))
        .done(function (response) {
          if (response.code == 200 || response.code == 201) {
            contactItemView.invoiceNotify('SAVED');
            contactItemView.invoiceUpdateEmail(newEmail);
          } else {
            contactItemView.invoiceNotify(response.message);
          }
        });
    },
    saveOrdersContact: function (newEmail, oldEmail) {
      $.when(Wethod.request('set:company:order:contact', newEmail, oldEmail))
        .done(function (response) {
          if (response.code == 200 || response.code == 201) {
            contactItemView.orderNotify('SAVED');
            contactItemView.orderUpdateEmail(newEmail);
          } else {
            contactItemView.orderNotify(response.message);
          }
        });
    },
    saveTravelsContact: function (data) {
      var travelModel = new Company.TravelContactModel(data);
      travelContacts.add(travelModel);
    },
    savePlanning: function (checked) {
      $.when(Wethod.request('update:company:planning', checked)).done(function () {
      });
    },
    savePlanningInternationalCalendar: function (options) {
      $.when(Wethod.request('update:company:planning:calendar', options)).done(function () {
      });
    },
    enableProjectReview: function () {
      var reviewModel = new Company.PipelineReviewConfig();
      reviewModel.set('enabled', true);

      Wethod.request('set:company:pipeline:reviewConfig', reviewModel).done(function (response) {
        projectReviewEnabledView = new Company.ProjectReviewEnabledView({
          model: response,
          projectTypes: projectTypes,
          projectReviewCriteria: projectReviewCriteria,
          teamMemberReviewCriteria: teamReviewCriteria,
        });
        projectReviewLayout.content.show(projectReviewEnabledView);
      });
    },
    showReviewCriteriaModal: function (model, type) {
      var confirmModal = new Company.LevelModal({
        title: 'Delete criterion',
        text: '<strong>All the results linked with this criterion will be lost.</strong> Are you sure you want to continue?',
        buttonOkText: 'Yes, delete',
        buttonKoText: 'Cancel',
        type: type,
        criteria: model,
      });

      var modalItemView = new Company.ReviewCriteriaConfirmDeleteModalView({ model: confirmModal });
      companyLayout.modal.show(modalItemView);
    },
    insertReviewCriteria: function (data, type) {
      if (data.name !== '') {
        if (type === 'project') {
          var projectInsertRequest = Wethod.request('create:company:project:review:criteria', data, type);
          $.when(projectInsertRequest).done(function (insertResponse) {
            projectReviewEnabledView.hideNewProjectCriteria();
            projectReviewCriteria.add(insertResponse);
          });
        } else {
          var teamInsertRequest = Wethod.request('create:company:team:review:criteria', data, type);
          $.when(teamInsertRequest).done(function (insertResponse) {
            projectReviewEnabledView.hideNewTeamMemberCriteria();
            teamReviewCriteria.add(insertResponse);
          });
        }
      }
    },
    insertWonLostFeedback: function (data) {
      if (data.name !== '') {
        var insertRequest = Wethod.request('create:company:wonLostFeedback', data);

        $.when(insertRequest).done(function (insertResponse) {
          if (data.type === 'won') {
            wonLostFeedbackLayout.hideNewWonFeedback();
            wonFeedback.add(insertResponse);
          } else {
            wonLostFeedbackLayout.hideNewLostFeedback();
            lostFeedback.add(insertResponse);
          }
          wonLostFeedbackLayout.hideError(); // todo better error handling
        });
      } else {
        wonLostFeedbackLayout.showError(); // todo better error handling
      }
    },
    updateWonLostFeedback: function (model) {
      var insertRequest = Wethod.request('update:company:wonLostFeedback', model);
      $.when(insertRequest).done(function () {
      });
    },
    deleteWonLostFeedback: function (model) {
      var deleteRequest = Wethod.request('delete:company:wonLostFeedback', model);
      $.when(deleteRequest).done(function () {
      });
    },
    showWonLostFeedbackModal: function (model) {
      var loadingModal = new Company.LevelModal({
        loading: 'deleting...',
      });
      var modalItemView = new Company.FeedbackCannotDeleteModalItemView({
        model: loadingModal,
      });
      companyLayout.modal.show(modalItemView);

      $.when(Wethod.request('get:company:usage:wonLostFeedback', model.options.id))
        .done(function (projects) {
          if (projects.length === 0) {
            var confirmModal = new Company.LevelModal({
              title: 'Delete feedback',
              text: 'You are about to delete this feedback. Are you sure you want to continue?',
              buttonOkText: 'DELETE',
              buttonKoText: 'GO BACK',
              feedback: model,
            });

            modalItemView = new Company.FeedbackConfirmDeleteModalView({ model: confirmModal });
            companyLayout.modal.show(modalItemView);
          } else {
            var cannotDeleteModal = new Company.LevelModal({
              title: 'CANNOT DELETE THIS FEEDBACK!',
              text: 'This feedback is used by the following projects:',
              buttonCancelText: 'cancel',
              projects: projects,
            });

            modalItemView = new Company.FeedbackCannotDeleteModalItemView({
              model: cannotDeleteModal,
            });

            companyLayout.modal.show(modalItemView);
          }
        });
    },
    insertBusinessUnit: function (data) {
      if (data.name !== '') {
        var businessUnitRequest = Wethod.request('create:company:business-unit', data);
        $.when(businessUnitRequest).done(function (insertResponse) {
          businessUnitEnabledView.hideNewBusinessUnit();
          businessUnit.add(insertResponse);
          Company.Controller.updateUserInfoBusinessUnits(businessUnit);
        });
      }
    },
    updateBusinessUnit: function (model) {
      var insertRequest = Wethod.request('update:company:business-unit', model);
      $.when(insertRequest).done(function () {
        Company.Controller.updateUserInfoBusinessUnits(businessUnit);
      });
    },
    deleteBusinessUnit: function (model, force) {
      var deleteRequest = Wethod.request('delete:company:business-unit', model, force);
      $.when(deleteRequest).done(function (response) {
        if (response.code === 400) {
          Company.Controller.showBusinessUnitDeleteErrorModal(response.message);
        } else if (response.code === 401) {
          Company.Controller.showBusinessUnitDeleteModal(model, response.data);
        } else {
          businessUnit.remove(model);
          Company.Controller.updateUserInfoBusinessUnits(businessUnit);
        }
      });
    },
    updateUserInfoBusinessUnits: function (businessUnitCollection) {
      Wethod.userInfo.set('business_units', businessUnitCollection.toJSON());
      businessUnitService = new BusinessUnitService();
    },
    updateUserInfoBusinessUnitEnabled: function (enabled) {
      Wethod.userInfo.set('business_units_enabled', enabled);
      businessUnitService = new BusinessUnitService();
    },
    updateUserInfoBusinessUnitFilter: function (userBUFilter) {
      Wethod.userInfo.set('filter_on_user_bu', userBUFilter);
      businessUnitService = new BusinessUnitService();
    },
    showBusinessUnitDeleteModal: function (model, data) {
      var projectsText = data.projects ? data.projects + 'projects' : '';
      // eslint-disable-next-line no-nested-ternary
      var employeesText = data.employees ? data.projects ? ' and ' + data.employees + ' employees' : ' ' + data.employees + 'employees'
        : '';

      var confirmModal = new Company.LevelModal({
        title: 'Delete business unit',
        text: 'This business unit is used for ' + projectsText + ' ' + employeesText + '. <br/>Are you sure you want to continue?',
        buttonOkText: 'Yes, delete',
        buttonKoText: 'Cancel',
        'business-unit': model,
      });

      var modalItemView = new Company.BusinessUnitConfirmDeleteModalView({ model: confirmModal });
      companyLayout.modal.show(modalItemView);
    },
    showBusinessUnitDeleteErrorModal: function (message) {
      var confirmModal = new Company.LevelModal({
        title: 'Cannot delete business unit',
        text: message,
        buttonOkText: 'Ok',
      });

      var modalItemView = new Company.LevelModalItemView({ model: confirmModal });
      companyLayout.modal.show(modalItemView);
    },
    insertSupplierStatus: function (data) {
      if (data.name !== '' && data.color) {
        var insertRequest = Wethod.request('create:company:supplier-status', data);

        $.when(insertRequest).done(function (insertResponse) {
          supplierStatusEnabledView.hideNewStatus();
          supplierStatus.add(insertResponse);
          supplierStatusEnabledView.hideError(); // todo better error handling
        });
      } else {
        supplierStatusEnabledView.showError(); // todo better error handling
      }
    },
    deleteSupplierStatus: function (model, force) {
      var deleteRequest = Wethod.request('delete:company:supplier-status', model, force);
      $.when(deleteRequest).done(function (response) {
        if (response.code !== 200) {
          Company.Controller
            .showSupplierStatusDeleteModal(model, response.data);
        }
      });
    },
    insertSupplierTagCategory: function (data) {
      if (data.name !== '') {
        var insertRequest = Wethod.request('create:company:supplier-tag-category', data);

        $.when(insertRequest).done(function (insertResponse) {
          supplierTagCategoryView.hideNewCategory();
          supplierTagCategory.add(insertResponse);
          supplierTagCategoryView.hideError(); // todo better error handling
        });
      } else {
        supplierTagCategoryView.showError(); // todo better error handling
      }
    },
    deleteSupplierTagCategory: function (model, force) {
      var categoryTags = supplierTag.select(function (tag) {
        return tag.get('category') === model.get('id');
      });

      if (force || categoryTags <= 0) {
        Wethod.request('delete:company:supplier-tag-category', model);
      } else {
        this.showSupplierTagCategoryDeleteModal(model);
      }
    },
    showSupplierStatusDeleteModal: function (model, data) {
      var totSuppliers = data.length;

      var confirmModal = new Company.LevelModal({
        title: 'Delete supplier status',
        text: 'This status is used for ' + totSuppliers + ' suppliers. <br/>Are you sure you want to continue?',
        buttonOkText: 'Yes, delete',
        buttonKoText: 'Cancel',
        status: model,
      });

      var modalItemView = new Company.SupplierStatusConfirmDeleteModalView({ model: confirmModal });
      companyLayout.modal.show(modalItemView);
    },
    showSupplierTagCategoryDeleteModal: function (model) {
      var confirmModal = new Company.LevelModal({
        title: 'Delete supplier category',
        text: 'You are about to delete all the tags associated to this category. Are you sure you want to continue?',
        buttonOkText: 'Yes, delete',
        buttonKoText: 'Cancel',
        category: model,
      });

      var modalItemView = new Company
        .SupplierCategoryConfirmDeleteModalView({ model: confirmModal });
      companyLayout.modal.show(modalItemView);
    },
    showProjectStatusRiskDeleteModal: function (model) {
      if (model.get('is_default')) {
        var modal = new Company.LevelModal({
          title: 'Cannot delete risk status',
          text: 'Oops! Please, set another risk status as default before you delete this one.',
          buttonOkText: 'Ok',
        });
        var modalView = new Company.LevelModalItemView({
          model: modal,
        });
        companyLayout.modal.show(modalView);
      } else {
        var confirmModal = new Company.LevelModal({
          title: 'Delete risk status',
          text: 'All the projects with this status will be labelled with the default risk status.<br/>'
            + ' Are you sure to continue?',
          buttonOkText: 'Yes, delete',
          buttonKoText: 'Cancel',
          risk: model,
        });

        var modalItemView = new Company
          .ProjectStatusRiskConfirmDeleteModalView({ model: confirmModal });
        companyLayout.modal.show(modalItemView);
      }
    },
    showProjectTypeErrorModal: function (data, forceUpdateOnError, title) {
      var cancelButton = forceUpdateOnError ? 'Cancel' : 'Ok';
      var confirmButton = forceUpdateOnError ? 'Ok, do it anyway' : undefined;

      var confirmModal = new Company.LevelModal({
        title: title || 'Cannot save job order category',
        text: data.message,
        buttonCancelText: cancelButton,
        buttonSubmitText: confirmButton,
        projectType: data.projectType,
      });

      var modalItemView = new Company.PTErrorModalItemView({ model: confirmModal });
      companyLayout.modal.show(modalItemView);
    },
    updateOpportunityStatus: function (model, changes) {
      Wethod.request('update:company:opportunity-status', model, changes).done(function (response) {
        model = response;
      });
    },
    insertOpportunityStatus: function (data) {
      if (data.name !== '' && data.color) {
        var createRequest = Wethod.request('create:company:opportunity-status', data);

        $.when(createRequest).done(function (createResponse) {
          pipelineOpportunityLayout.hideError(); // todo better error handling
          pipelineOpportunityLayout.hideNewStatus();
          opportunityStatus.add(createResponse);
          opportunityStageCollectionView.resetGrid();
        });
      } else {
        pipelineOpportunityLayout.showError(); // todo better error handling
      }
    },
    deleteOpportunityStatus: function (model) {
      var forceDelete = true;
      var deleteRequest = Wethod.request('delete:company:opportunity-status', model, forceDelete);
      $.when(deleteRequest).done(function () {
        opportunityStatus.remove(model);
        opportunityStageCollectionView.resetGrid();
      });
    },
    sortOpportunityStatuses: function (positions, collection) {
      collection.each(function (model) {
        var item = positions.filter(function (el) {
          return el.id === model.get('id');
        });
        item = item ? item[0] : null;

        if (item) {
          if (model.get('hierarchy') !== item.sort) {
            var changes = { hierarchy: item.sort };
            model.set(changes);
            model.trigger('change:position', model, item.sort);

            if (item.needsUpdate) { // Dragged item needs to save the changes
              Company.Controller.updateOpportunityStatus(model, changes);
            }
          }
        }
      });
    },
    insertBankAccount: function (data) {
      if (data.name !== '' && data.iban !== '') {
        var insertRequest = Wethod.request('create:company:bank-account', data);

        $.when(insertRequest).done(function (insertResponse) {
          bankAccountLayout.hideNewBankAccount();
          bankAccount.add(insertResponse);
          bankAccountLayout.hideError(); // todo better error handling
        });
      } else {
        bankAccountLayout.showError(); // todo better error handling
      }
    },
    archiveBankAccount: function (model, changes, itemView) {
      var archiveRequest = Wethod.request('archive:company:bank-account', model, changes);

      $.when(archiveRequest).done(function (response) {
        model = response;
        itemView.render();
      });
    },
    insertVatRate: function (data) {
      if (data.label !== '' && data.rate !== '' && data.description !== '') {
        if (data.rate < 0) {
          vatRateLayout.showError('The rate must be greater or equal to 0'); // todo better error handling
        } else {
          var insertRequest = Wethod.request('create:company:vat-rate', data);

          $.when(insertRequest).done(function (insertResponse) {
            vatRateLayout.hideNewVatRate();
            vatRate.add(insertResponse);
            vatRateLayout.hideError(); // todo better error handling
          });
        }
      } else {
        vatRateLayout.showError('! you must insert all fields'); // todo better error handling
      }
    },
    updateVatRate: function (model, changes, itemView) {
      Wethod.request('update:company:vat-rate', model, changes).done(function (response) {
        model = response;
        if (itemView) {
          itemView.render();
        }
      });
    },
    insertExpenseCategory: function (data) {
      var type = expenseCategoryType.select(function (model) {
        return model.get('key') === data.reimbursement_type;
      })[0];
      var isReimbursementRequired = type.get('is_reimbursement_required');
      var isReimbursementPresent = isReimbursementRequired && data.reimbursement;
      var isReimbursementMissing = !isReimbursementRequired && !data.reimbursement;
      var isReimbursementValid = isReimbursementPresent || isReimbursementMissing;

      if (data.name !== '' && isReimbursementValid) {
        var createRequest = Wethod.request('create:company:expense-category', data);

        $.when(createRequest).done(function (createResponse) {
          expensesLayout.hideError(); // todo better error handling
          expensesLayout.hideNewCategory();
          expenseCategory.add(createResponse);
        });
      } else {
        var message = 'Missing category name';
        if (!isReimbursementValid) {
          message = isReimbursementRequired ? 'Please provide a reimbursement value'
            : 'This reimbursement type does not accept a reimbursement value';
        }
        expensesLayout.showError(message); // todo better error handling
      }
    },
    archiveExpenseCategory: function (model, archive, itemView) {
      var archiveRequest = Wethod.request('archive:company:expense-category', model, archive);

      $.when(archiveRequest).done(function () {
        model.set('is_archived', archive);
        itemView.render();
      });
    },
    insertPaymentTerm: function (data) {
      if (data.name !== '' && data.days !== '' && data.is_from_end_of_month !== '') {
        if (data.days < 0) {
          paymentTermLayout.showError('The days must be greater or equal to 0'); // todo better error handling
        } else {
          var insertRequest = Wethod.request('create:company:payment-term', data);

          $.when(insertRequest).done(function (insertResponse) {
            paymentTermLayout.hideNewPaymentTerm();
            paymentTerm.add(insertResponse);
            paymentTermLayout.hideError(); // todo better error handling
          });
        }
      } else {
        paymentTermLayout.showError('! you must insert all fields'); // todo better error handling
      }
    },
    updatePaymentTerm: function (model, changes, itemView) {
      Wethod.request('update:company:payment-term', model, changes).done(function (response) {
        model = response;
        if (itemView) {
          itemView.render();
        }
      });
    },
    scrollToSection: function (idSection) { // Scroll to section specified in url
      var section = document.getElementById(idSection);
      if (section) {
        section.scrollIntoView();
      }

      // now account for fixed header
      var scrolledY = window.scrollY;
      if (scrolledY) {
        window.scroll(0, scrolledY - Wethod.getHeaderHeight());
      }
    },
  };
}, Wethod.module('HeaderApp.Header'));
