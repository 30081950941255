const ChartConfigFilterClient = require('./ChartConfigFilterClient');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterClientIncluded extends ChartConfigFilterClient {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterClientIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterClientIncluded(data.values);
  }

  isValid() {
    return Array.isArray(this.values) && this.values.length > 0;
  }
};
