const React = require('react');

const AddItemButton = ({ onClick, winProbabilityThreshold }) => {
  function handleClick() {
    onClick(winProbabilityThreshold);
  }
  return (
    <button type="button"
      className="wethod-button"
      onClick={handleClick}>
      Add opportunity
    </button>
  );
};

module.exports = AddItemButton;
