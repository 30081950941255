const ChartConfigFilterJobOrderCategory = require('./ChartConfigFilterJobOrderCategory');
const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterJobOrderCategoryNotIncluded extends ChartConfigFilterJobOrderCategory {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_NOT_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterJobOrderCategoryNotIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterJobOrderCategoryNotIncluded(data.values);
  }

  // eslint-disable-next-line class-methods-use-this
  isValid() {
    return true;
  }
}

module.exports = ChartConfigFilterJobOrderCategoryNotIncluded;
