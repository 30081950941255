const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('../Icon/Icon.react');
const IconButtonTooltip = require('../TooltipFixed/IconButtonTooltip.react');
const Menu = require('../Menu/ListMenu/ListMenu.react');
const ShowIf = require('../ShowIf/ShowIf.react');

class MoreOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.setButtonRef = this.setButtonRef.bind(this);
  }

  getButton() {
    return React.cloneElement(this.props.button, {
      onClick: this.showMenu,
    });
  }

  setButtonRef(ref) {
    this.button = ref;
    if (this.props.setRef) {
      this.props.setRef(ref);
    }
  }

  getClassName() {
    const name = 'wethod-more-options';

    return `${name} ${this.props.className}`;
  }

  getMenu() {
    if (this.button) {
      return (
        <Menu
          open={this.state.showMenu}
          onClose={this.closeMenu}
          anchorEl={this.button}>
          {this.props.children}
        </Menu>
      );
    }
    return null;
  }

  closeMenu() {
    this.setState({ showMenu: false });
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hasOptions() {
    return React.Children.count(this.props.children) > 0;
  }

  render() {
    return (
      <ShowIf condition={this.hasOptions()} showElse={null}>
        <div style={{ display: 'inline-block' }} className={this.getClassName()}>
          <div ref={this.setButtonRef}>
            {this.getButton()}
          </div>
          {this.getMenu()}
        </div>
      </ShowIf>
    );
  }
}

MoreOptionsButton.defaultProps = {
  button: (
    <IconButtonTooltip label="Show options"
      borderless>
      <Icon icon="more" />
    </IconButtonTooltip>
  ),
  setRef: null,
  className: '',
};

MoreOptionsButton.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Element to use as button. Must accept onClick prop.
   */
  button: PropTypes.node,
  setRef: PropTypes.func,
  className: PropTypes.string,
};

module.exports = MoreOptionsButton;
