const React = require('react');
const PropTypes = require('prop-types');
const isEqual = require('react-fast-compare');
const ChartModel = require('../../../../models/Chart');
const ChartPicker = require('./InsightLevelChartPicker/InsightLevelChartPicker.react');
const ChartContainer = require('./InsightLevelChartContainer.react');
const Chart = require('../../Chart/Chart/models/Chart');
const ChartConfigFilter = require('../../Chart/Chart/models/ChartConfigFilter/ChartConfigFilter');
const ShowIf = require('../../ShowIf/ShowIf.react');
const TableViewContainer = require('./InsightLevelTableViewContainer.react');
const ChartConfigTable = require('../../Chart/Chart/models/ChartConfigTable/ChartConfigTable');
const Actions = require('./InsightLevelActions.react');
const CreateSidebar = require('./sidebars/CreateSidebar.react');
const EditSidebar = require('./sidebars/EditSidebar.react');
const sidebarService = require('../../sidebar/SidebarManager/services/SidebarService');
const DimensionSelectItem = require('../../Chart/ChartSidebar/models/DimensionSelectItem');

require('../style.scss');

class InsightLevel extends React.Component {
  /**
   * Return error code based on given reason.
   * @param {string|number} reason
   * @return {string}
   */
  static getError(reason) {
    switch (reason) {
      case 403:
        return InsightLevel.ERROR_UNAUTHORIZED;
      default:
        return InsightLevel.ERROR_UNKNOWN;
    }
  }

  constructor(props) {
    super(props);

    /**
     * @type {{isLoading: boolean, charts: Chart[], selectedId: ?number}}
     */
    this.state = {
      charts: [],
      isLoading: true,
      selectedId: null, // ID of the currently selected chart
      childrenFilters: [], // List of filters to apply to children levels
    };

    this.updateSelected = this.updateSelected.bind(this);
    this.updateChildrenFilters = this.updateChildrenFilters.bind(this);
    this.onAddChartClick = this.onAddChartClick.bind(this);
    this.onChartCreate = this.onChartCreate.bind(this);
    this.onDeleteChart = this.onDeleteChart.bind(this);
    this.onEditChartClick = this.onEditChartClick.bind(this);
    this.onChartUpdate = this.onChartUpdate.bind(this);
  }

  componentDidMount() {
    this.loadCharts();
  }

  componentDidUpdate(prevProps) {
    const parentChanged = !isEqual(this.props.parent, prevProps.parent);
    if (parentChanged) {
      this.loadCharts();
    }
  }

  onAddChartClick() {
    sidebarService.openSidebar(<CreateSidebar onSave={this.onChartCreate}
      availableDimensions={this.props.availableDimensions}
      parent={this.props.parent ? this.props.parent.id : null}
      dataSource={this.props.dataSource} />);
  }

  onEditChartClick() {
    sidebarService.openSidebar(<EditSidebar chart={this.getVisibleChart()}
      availableDimensions={this.props.availableDimensions}
      onSave={this.onChartUpdate} />);
  }

  /**
   * @param {Chart} chart
   */
  onChartCreate(chart) {
    this.setState((state) => ({
      charts: state.charts.concat(chart),
      selectedId: chart.id,
    }));
  }

  /**
   * @param {Chart} chart
   */
  onChartUpdate(chart) {
    this.setState((state) => ({
      charts: state.charts.map((currentChart) => {
        if (currentChart.id === chart.id) {
          return chart;
        }
        return currentChart;
      }),
    }));
  }

  onDeleteChart(id) {
    this.setState((state) => {
      const updatedCharts = state.charts.filter((chart) => chart.id !== id);
      return {
        charts: updatedCharts,
        selectedId: updatedCharts[0].id,
      };
    });
  }

  /**
   *
   * @return {Chart}
   */
  getVisibleChart() {
    return this.state.charts.find((chart) => chart.id === this.state.selectedId);
  }

  getChildLevel() {
    const visibleChart = this.getVisibleChart();
    if (visibleChart !== undefined) {
      const filters = [...this.props.filters, ...this.state.childrenFilters];
      return (
        <InsightLevel parent={visibleChart}
          availableDimensions={this.props.availableDimensions}
          userHasChartEditPermissions={this.props.userHasChartEditPermissions}
          updateError={this.props.updateError}
          filters={filters}
          dataSource={this.props.dataSource}
          tableConfig={visibleChart.getTableConfig()} />
      );
    }
    return null;
  }

  getChartContainer() {
    if (!this.state.selectedId) {
      return null;
    }
    if (this.state.selectedId === 'table-view') {
      return (
        <TableViewContainer filters={this.props.filters}
          parent={this.props.parent}
          config={this.props.tableConfig} />
      );
    }
    const visibleChart = this.getVisibleChart();

    return (
      <ChartContainer chart={visibleChart}
        userHasChartEditPermissions={this.props.userHasChartEditPermissions}
        isLoading={this.state.isLoading}
        filters={this.props.filters}
        updateChildrenFilters={this.updateChildrenFilters}
        onEditChartClick={this.onEditChartClick} />
    );
  }

  updateSelected(id) {
    this.setState({
      selectedId: id,
      childrenFilters: [],
    });
  }

  updateChildrenFilters(filters) {
    this.setState({ childrenFilters: filters });
  }

  /**
   * Return true if this level must display a table view.
   * @return {boolean}
   */
  hasTableView() {
    return this.props.tableConfig !== null;
  }

  loadCharts() {
    const options = {
      data_source: this.props.dataSource,
      has_parent: this.props.parent !== null,
    };
    if (this.props.parent) {
      options.parent = this.props.parent.id;
    }
    this.setState({ isLoading: true });
    ChartModel
      .list(options)
      .done((charts) => {
        const parsedCharts = charts.map(Chart.fromJSON);
        const firstChartId = charts.length ? charts[0].id : null;

        this.setState({
          charts: parsedCharts,
          isLoading: false,
          selectedId: this.hasTableView() ? 'table-view' : firstChartId,
        });
      })
      .fail((message, code) => this.props.updateError(InsightLevel.getError(code)));
  }

  render() {
    return (
      <div className="wethod-insight-level">
        <div className="wethod-insight-level__header">
          <ChartPicker charts={this.state.charts}
            selectedId={this.state.selectedId}
            onClick={this.updateSelected}
            withTableView={this.hasTableView()}
            userHasChartEditPermissions={this.props.userHasChartEditPermissions}
            onDeleteChart={this.onDeleteChart} />
          <Actions onAddChartClick={this.onAddChartClick}
            canCreate={this.props.userHasChartEditPermissions} />
        </div>
        <ShowIf condition={this.state.selectedId !== null}>
          <div>
            {this.getChartContainer()}
            {this.getChildLevel()}
          </div>
        </ShowIf>
      </div>
    );
  }
}

InsightLevel.ERROR_UNAUTHORIZED = 'error-unauthorized';
InsightLevel.ERROR_UNKNOWN = 'error-unknown';

InsightLevel.defaultProps = {
  filters: [],
  parent: null,
  tableConfig: null,
  userHasChartEditPermissions: false,
  availableDimensions: [],
};

InsightLevel.propTypes = {
  updateError: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(ChartConfigFilter)),
  parent: PropTypes.instanceOf(Chart),
  dataSource: PropTypes.oneOf(Chart.AVAILABLE_DATA_SOURCES).isRequired,
  tableConfig: PropTypes.instanceOf(ChartConfigTable),
  userHasChartEditPermissions: PropTypes.bool,
  availableDimensions: PropTypes.arrayOf(PropTypes.instanceOf(DimensionSelectItem)),
};

module.exports = InsightLevel;
