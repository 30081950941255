const React = require('react');
const PropTypes = require('prop-types');
const AuthorizationModel = require('../../../../models/Authorization');
const SectionHeader = require('../../SectionHeader/components/SectionHeader.react');
const SidebarManager = require('../../sidebar/SidebarManager/SidebarManager.react');
const ModalManager = require('../../Modal/ModalManager/ModalManager.react');
const InsightLevel = require('./InsightLevel.react');
const ErrorBody = require('../../SectionError/SectionError.react');
const Loader = require('../../../../apps/core/modules/budget/components/SectionLoader.react');
const DimensionSelectItem = require('../../Chart/ChartSidebar/models/DimensionSelectItem');

class InsightsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartsPermissions: {},
      isLoading: false,
      error: null,
    };

    this.updateError = this.updateError.bind(this);
  }

  componentDidMount() {
    this.loadChartsPermissions();
  }

  getClassName() {
    return `wethod-section-body wethod-section-insights ${this.props.className}`;
  }

  loadChartsPermissions() {
    this.setState({ isLoading: true });
    AuthorizationModel
      .getPermissions('charts')
      .done((permissions) => {
        this.setState({
          chartsPermissions: permissions,
          isLoading: false,
        });
      });
  }

  updateError(error) {
    this.setState({ error });
  }

  userHasChartEditPermissions() {
    return this.state.chartsPermissions.edit === true;
  }

  userHasChartViewPermissions() {
    return this.state.chartsPermissions.view === true;
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }
    if (this.state.error === InsightLevel.ERROR_UNAUTHORIZED
      || !this.userHasChartViewPermissions()) {
      return <ErrorBody message="You are not allowed to be here!" />;
    }
    if (this.state.error === InsightLevel.ERROR_UNKNOWN) {
      return <ErrorBody message="Ops! Something went wrong :(" />;
    }
    return (
      <div className={this.getClassName()}>
        <SectionHeader
          current_section={this.props.title}
          helper_url={this.props.helperUrl}
          big />
        <SidebarManager />
        <ModalManager />
        <InsightLevel parent={null}
          availableDimensions={this.props.availableDimensions}
          userHasChartEditPermissions={this.userHasChartEditPermissions()}
          dataSource={this.props.dataSource}
          updateError={this.updateError} />
      </div>
    );
  }
}

InsightsSection.defaultProps = {
  className: '',
};

InsightsSection.propTypes = {
  className: PropTypes.string,
  dataSource: PropTypes.string.isRequired,
  helperUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  availableDimensions: PropTypes.arrayOf(PropTypes.instanceOf(DimensionSelectItem)).isRequired,
};

module.exports = InsightsSection;
