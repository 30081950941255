const React = require('react');
const PropTypes = require('prop-types');
const Chart = require('../../../Chart/Chart/Chart.react');
const imageSrc = require('./images/chart-empty-state.svg');
const T = require('../../../Typography/Typography.react');

const InsightLevelChartEmptyState = ({
  chartTitle, chartDescription, message, canEdit,
  onEditChartClick, isLoading,
}) => (
  <Chart title={chartTitle}
    description={chartDescription}
    canEdit={canEdit}
    isLoading={isLoading}
    onEditChartClick={onEditChartClick}>
    <div className="wethod-insight-level__chart-empty-state">
      <div className="wethod-insight-level__chart-empty-state-content">
        <div className="wethod-insight-level__chart-empty-state-message">
          <T size={T.SIZES.PX12} weight={T.WEIGHTS.SEMIBOLD} uppercase>{message}</T>
        </div>
        <img className="wethod-insight-level__chart-empty-state-image" src={imageSrc} alt="chart empty state" />
      </div>
    </div>
  </Chart>
);

InsightLevelChartEmptyState.defaultProps = {
  onEditChartClick: null,
  canEdit: false,
};

InsightLevelChartEmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
  chartDescription: PropTypes.string.isRequired,
  onEditChartClick: PropTypes.func,
  canEdit: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

module.exports = InsightLevelChartEmptyState;
