const React = require('react');
const Header = require('../SubsectionHeader.react');
const Row = require('../SettingsRow.react');
const Typography = require('../../../../../../../common/react/Typography/Typography.react');
const BasicTextField = require('../../../../../../../common/react/inputs/TextField/BasicTextField/BasicTextField.react');

const WonLostBot = ({
  winLossProbabilityThreshold,
  winLossEmailAdddress,
  updateWinEmailAddress,
  updateLossEmailAddress,
  isSavingWinEmail,
  isSavingLossEmail,
}) => {
  const feedbackWinEmail = isSavingWinEmail ? 'Saving...' : null;
  const feedbackLossEmail = isSavingLossEmail ? 'Saving...' : null;
  function handleOnBlur(e) {
    const { id } = e.target;
    const { value } = e.target;
    if (id === 'win-email') {
      updateWinEmailAddress(value);
    } else {
      updateLossEmailAddress(value);
    }
  }

  return (
    <div className="bottomSpace">
      <Header subtitle="Project Won/Lost Bot"
        id="won-lost-bot"
        description="" />
      <Row feedback={feedbackWinEmail}>
        <div style={{ width: '100%' }}>
          <Typography component={Typography.COMPONENTS.SPAN}>
            If the probability of a projects reaches {
            winLossProbabilityThreshold.winProbabilityThreshold
          }% then send a notification to:
          </Typography>
        </div>
        <div style={{ width: '100%' }}>
          <BasicTextField value={winLossEmailAdddress.winEmail.address}
            onBlur={handleOnBlur}
            placeholder="team@yourdomain.com"
            id="win-email"
            label="win-email"
            name="win-email" />
        </div>
      </Row>
      <Row feedback={feedbackLossEmail}>
        <div style={{ width: '100%' }}>
          <Typography component={Typography.COMPONENTS.SPAN}>
            If the probability of a project goes down to 0 then send a notification to:
          </Typography>
        </div>
        <div style={{ width: '100%' }}>
          <BasicTextField value={winLossEmailAdddress.lossEmail.address}
            onBlur={handleOnBlur}
            placeholder="team@yourdomain.com"
            id="loss-email"
            label="loss-email"
            name="loss-email" />
        </div>
      </Row>
    </div>
  );
};

module.exports = WonLostBot;
