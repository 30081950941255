const { connect } = require('react-redux');
const Component = require('../../components/opportunities/AddItemButton.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});
const mapDispatchToProps = (dispatch) => ({
  onClick: (winProbabilityThreshold) => dispatch(actions.addOpportunity(winProbabilityThreshold)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
