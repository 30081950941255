const moment = require('moment');
const ChartConfigFilterTime = require('./ChartConfigFilterTime');
const ChartConfigFilter = require('./ChartConfigFilter');

class ChartConfigFilterTimeRange extends ChartConfigFilterTime {
  /**
   * @param {Date} min
   * @param {Date} max
   */
  constructor(min, max) {
    super(ChartConfigFilter.TYPE_RANGE);
    this.min = min;
    this.max = max;
  }

  isValid() {
    const validMin = this.min !== null && this.min !== undefined && this.min instanceof Date;
    const validMax = this.max !== null && this.max !== undefined && this.max instanceof Date;
    return validMin && validMax;
  }

  /**
   * @param data
   * @return {ChartConfigFilterTimeRange}
   */
  static fromJSON(data) {
    const min = new Date(data.min);
    const max = new Date(data.max);
    return new ChartConfigFilterTimeRange(min, max);
  }

  /**
   * Return attributes to serialize when calling JSON.stringify on this object.
   */
  toJSON() {
    return {
      target: this.target,
      type: this.type,
      min: moment(this.min).format('YYYY-MM'),
      max: moment(this.max).format('YYYY-MM'),
    };
  }
}

module.exports = ChartConfigFilterTimeRange;
