/* eslint-disable react/sort-comp,react/no-did-update-set-state,react/no-access-state-in-setstate,
 class-methods-use-this */
const React = require('react');
const Table = require('../../../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../../../common/react/Table2/TableBody.react');
const TableSummaryRow = require('../../../../../../../../common/react/Table2/TableSummaryRow.react');
const TableFooter = require('../../../../../../../../common/react/Table2/TableFooter.react');
const Project = require('./Project.react');
const FormatService = require('../../../../../../../../services/FormatService');

module.exports = class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 0,
      page: 0,
      filtered: [],
      expanded: false,
      sortBy: {
        col: 'name',
        order: 'asc',
      },
    };

    this.listMinHeight = 300;
  }

  handleSort(col, order) {
    this.setState({
      sortBy: {
        col,
        order,
      },
    });
  }

  getSort(col) {
    const currentSort = this.state.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  loadMore(size, page) {
    this.setState({
      page,
      size,
    });
  }

  hasMore() {
    return this.state.filtered < this.props.projects || this.props.isWaiting;
  }

  compare(a, b) {
    let valueA = parseInt(a[this.state.sortBy.col]);
    let valueB = parseInt(b[this.state.sortBy.col]);

    if (Number.isNaN(valueA) || Number.isNaN(valueB)) {
      valueA = _.unescape(a[this.state.sortBy.col]);
      valueB = _.unescape(b[this.state.sortBy.col]);
    }

    if (valueA > valueB) {
      return this.state.sortBy.order === 'asc' ? 1 : -1;
    }
    if (valueA < valueB) {
      return this.state.sortBy.order === 'asc' ? -1 : 1;
    }
    return 0;
  }

  componentDidUpdate(prevProps, prevState) {
    const sortChanged = this.state.sortBy.col !== prevState.sortBy.col
      || this.state.sortBy.order !== prevState.sortBy.order;
    const hasLoaded = prevProps.isWaiting && !this.props.isWaiting;
    const filterChanged = prevProps.timeFilter.frame !== this.props.timeFilter.frame
      || prevProps.timeFilter.period !== this.props.timeFilter.period;
    if (hasLoaded || prevState.page !== this.state.page || sortChanged || filterChanged) {
      this.setState({
        filtered: this.props.projects.sort(this.compare.bind(this))
          .slice(0, this.state.page * this.state.size),
      });
    }
  }

  getProjects() {
    return this.state.filtered.map((project) => (
      <Project
        key={project.id}
        {...project}
        hasFeedback={this.props.hasFeedback}
        timeFilter={this.props.timeFilter}
        isValueAsUnit={this.props.isValueAsUnit} />
    ));
  }

  getTotalValue() {
    return this.formatValue(this.props.projects
      .reduce((sum, project) => sum + project.estimate, 0));
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  getFooter() {
    const hasEnoughProjects = this.props.projects.length > 4;
    if (hasEnoughProjects) {
      return (
        <TableFooter expanded={this.state.expanded}
          toggleExpanded={this.toggleExpanded.bind(this)} />
      );
    }
    return null;
  }

  getFeedbackColumnHeader() {
    return this.props.hasFeedback ? 'Won/lost feedback' : '';
  }

  /**
   * Format the project value as unit or as K according to the company settings.
   * @param value
   * @returns {string}
   */
  formatValue(value) {
    if (this.props.isValueAsUnit) {
      return FormatService.formatDecimalNumber(value, false, 2, 2);
    }
    return `${numeral(value / 1000).format('0,0.0')} K`;
  }

  render() {
    return (
      <div className="client-won-projects client-content__main-section">
        <h2 className="client-content__main-title">{this.props.title}</h2>
        <div className="client-content__list"
          ref={(ref) => this.listRef = ref}>
          <Table
            columns={2}
            minHeight={this.listMinHeight}
            maxHeight={500}
            footer={this.getFooter()}
            expanded={this.state.expanded}>
            <TableHead>
              <TableRow>
                <TableCell
                  className="client-projects__column--name"
                  onSort={this.handleSort.bind(this)}
                  sort={this.getSort('name')}
                  name="name">
                  Project
                </TableCell>
                <TableCell className="client-projects__column--feedback"
                  name="feedback">
                  {this.getFeedbackColumnHeader()}
                </TableCell>
                <TableCell
                  className="client-projects__column--value"
                  onSort={this.handleSort.bind(this)}
                  sort={this.getSort('estimate')}
                  name="estimate">
                  Value
                </TableCell>
                <TableCell
                  className="client-projects__column--probability"
                  onSort={this.handleSort.bind(this)}
                  sort={this.getSort('probability')}
                  name="probability">
                  Probability
                </TableCell>
                <TableCell className="client-projects__column--actions"
                  name="actions" />
              </TableRow>
              <TableSummaryRow>
                <TableCell className="client-projects__column--name"
                  name="name" />
                <TableCell className="client-projects__column--feedback"
                  name="feedback" />
                <TableCell
                  className="client-projects__column--value"
                  name="value">
                  {this.getTotalValue()}
                </TableCell>
                <TableCell className="client-projects__column--probability"
                  name="probability" />
                <TableCell className="client-projects__column--actions"
                  name="actions" />
              </TableSummaryRow>
            </TableHead>
            <TableBody
              hasMore={this.hasMore()}
              loadMore={this.loadMore.bind(this)}
              empty={(
                <span>
                  There's nothing here! What about
                  <a href="/#pipeline/projects"> starting a new project</a>?
                </span>
              )}>
              {this.getProjects()}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
};
