const ChartConfigFilter = require('./ChartConfigFilter');

/**
 * @abstract
 */
class ChartConfigFilterAccount extends ChartConfigFilter {
  constructor(type) {
    super(ChartConfigFilterAccount.TARGET, type);
  }
}

ChartConfigFilterAccount.TARGET = 'account';

module.exports = ChartConfigFilterAccount;
