const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;

const roundWithDecimals = (value) => (value ? Math.round(value * 100) / 100 : value);

const hasCurrencies = (order) => order && order.currencies && order.currencies.length;

const getMasterCurrency = (order) => {
  if (!hasCurrencies(order)) {
    return null;
  }
  const masterCurrency = order.currencies.filter((curr) => curr.is_master === true);
  return masterCurrency.length ? masterCurrency[0] : null;
};

const getCurrencyByCode = (order, code) => {
  if (!hasCurrencies(order)) {
    return null;
  }
  const currency = order.currencies.filter((curr) => curr.code === code);
  return currency.length ? currency[0] : null;
};

const isOrderMulticurrency = (order) => {
  if (!hasCurrencies(order)) {
    return false;
  }
  return order.currencies.length > 1 || getMasterCurrency(order) === null;
};

const getCurrencyRateByCode = (order, code) => {
  if (!hasCurrencies(order)) {
    return null;
  }
  const currency = getCurrencyByCode(order, code);
  return currency ? currency.rate : null;
};

const getConversionRate = (order) => {
  if (!hasCurrencies(order)) {
    return null;
  }
  if (order.exchange_rate != null) {
    return order.exchange_rate;
  }
  return getCurrencyRateByCode(order, order.currency);
};

const getConvertedValue = (order) => {
  if (!isOrderMulticurrency(order) || !order.currency) {
    return roundWithDecimals(order.value);
  }
  const conversionRate = getConversionRate(order);
  const convertedValue = conversionRate ? order.value * conversionRate : order.value;
  return roundWithDecimals(convertedValue);
};

const getFormattedConvertedValue = (order, precision = 2, minPrecision = 0) => (
  formatNumber(getConvertedValue(order), false, precision, minPrecision)
);

const getMasterConvertedValue = (order, value) => {
  if (!isOrderMulticurrency(order) || !order.currency) {
    return value;
  }
  const conversionRate = getConversionRate(order);
  return conversionRate ? value / conversionRate : value;
};

const getCurrencyCode = (currency) => (currency ? currency.code : '');

const getCurrencyName = (currency) => (currency ? currency.name : '');

const getSelectedCurrency = (order) => (
  order.currency ? order.currency : getCurrencyCode(getMasterCurrency(order))
);

module.exports = {
  isOrderMulticurrency,
  hasCurrencies,
  getMasterCurrency,
  getCurrencyByCode,
  getCurrencyRateByCode,
  getConversionRate,
  getConvertedValue,
  getFormattedConvertedValue,
  getMasterConvertedValue,
  getCurrencyCode,
  getCurrencyName,
  getSelectedCurrency,
};
