'use strict';

Wethod.module('DashboardApp', function (DashboardApp, Wethod, Backbone, Marionette, $, _) {
  var chart = function (duration, tentativeProd, prod, tentativeValue, value) {
    return {
      chart: {
        backgroundColor: 'transparent',
        type: 'solidgauge',
      },
      title: {
        text: ' ',
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        positioner: function () {
          return {
            x: 150,
            y: 100,
          };
        },
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
        }],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          borderWidth: '8px',
          borderRadius: 0,
          dataLabels: {
            enabled: false,
          },
          linecap: 'square',
          stickyTracking: false,
        },
      },
      series: [
        {
          name: '',
          borderColor: '#F3F3F3',
          tooltip: {
            pointFormat: '',
          },
          data: [{
            color: '#F3F3F3',
            radius: '100%',
            innerRadius: '0%',
            y: duration,
          }],
        }, {
          name: 'PRODUCT',
          borderColor: 'rgba(78,216,141,0.4)',
          tooltip: {
            pointFormat: '',
          },
          data: [{
            color: 'rgba(78,216,141,0.4)',
            radius: '95%',
            innerRadius: '95%',
            y: tentativeProd,
          }],
        }, {
          name: 'INVOICED',
          borderColor: 'rgba(78,216,141,1)',
          tooltip: {
            pointFormat: '',
          },
          data: [{
            color: 'rgba(78,216,141,1)',
            radius: '95%',
            innerRadius: '95%',
            y: prod,
          }],
        }, {
          name: 'PROJECTS',
          borderColor: 'rgba(72,165,244,0.4)',
          tooltip: {
            pointFormat: '',
          },
          data: [{
            color: 'rgba(72,165,244,0.4)',
            radius: '84%',
            innerRadius: '84%',
            y: tentativeValue,
          }],
        }, {
          name: 'PROJECTS > 90 %',
          borderColor: 'rgba(72,165,244,1)',
          tooltip: {
            pointFormat: '',
          },
          data: [{
            color: 'rgba(72,165,244,1)',
            radius: '84%',
            innerRadius: '84%',
            y: value,
          }],
        },
      ],
    };
  };

  this.WidgetSalesFunnelItemView = Marionette.ItemView.extend({
    className: 'relative',
    _template: '#widgetSalesFunnelTemplate',
    ui: {
      targetRegionEl: '[data-region="target"]',
      targetInputEl: '[data-action="targetValue"]',
      chartRegion: '[data-region="chartRegion"]',
      mdlInput: '[data-region="mdl-input"]',

      showFilterEl: '[data-action="showFilterTooltip"]',
      showFilterLabel: '[data-region="filterSel"]',

      tooltipEl: '[data-region="filterTooltip"]',
      tooltipFilterEl: '[data-action="selectFilter"]',

      filterTypeLabel: '[data-region="filterType"]',
      wrapTooltipHintEl: '[data-region="wrapFilterTooltipHint"]',
      wrapInputHintEl: '[data-region="wrapInputHints"]',
      inputHintEl: '[data-action="inputHint"]',

      settingsRegionEl: '[data-region="widgetSettings"]',

      // settings
      settingsEl: '[data-action="settingsWidget"]',
      // save
      saveEl: '[data-action="saveWidget"]',
      // save feedback
      saveFeedbackEl: '[data-region="widgetSaveFeedback"]',
      // delete
      deleteEl: '[data-action="deleteWidget"]',

      companyOverviewMessageEl: '[data-region="companyOverviewMassage"]',
      clientMessageEl: '[data-region="clientMessage"]',
    },
    events: {
      'change @ui.targetInputEl': 'changeTargetValue',

      'click @ui.targetInputEl': 'selsectTargetValue',
      'touchstart @ui.targetInputEl': 'selsectTargetValue',

      'click @ui.showFilterEl': 'showFilterTooltip',
      'touchstart @ui.showFilterEl': 'showFilterTooltip',

      'click @ui.tooltipFilterEl': 'selectFilter',
      'touchstart @ui.tooltipFilterEl': 'selectFilter',

      'click @ui.inputHintEl': 'showHitsList',
      'touchstart @ui.inputHintEl': 'showHitsList',

      'keyup @ui.inputHintEl': 'showHitsList',

      'click @ui.settingsEl': 'showSettings',
      'touchstart @ui.settingsEl': 'showSettings',

      'click @ui.saveEl': 'saveWidget',
      'touchstart @ui.saveEl': 'saveWidget',

      'click @ui.deleteEl': 'deleteWidget',
      'touchstart @ui.deleteEl': 'deleteWidget',
    },
    initialize: function (options) {
      this.options = options;

      this.options.target = options.model.get('config').target || 0;
      var type = this.model.get('config').active_filter;
      if (type && type.name === 'client') {
        this.model.get('config').target *= 1000;
      }
      this.options.filterType = options.model.get('config').active_filter.name || null;
      this.options.filterValue = options.model.get('config').active_filter.value || null;

      _.bindAll(this, 'template');
    },
    template: function (model) {
      var template = $(this._template).html();
      var durationPerc;
      var tentativeProdPerc;
      var prodPerc;
      var tentativeValuePerc;
      var
        vevaluePerc;

      // duration
      durationPerc = ((52 - model.time_to_go) / 52) * 100;
      this.options.durationPerc = durationPerc;

      // product
      tentativeProdPerc = (((model.product
        .outstanding + model.product.won) / 1000) / model.config.target) * 100;
      prodPerc = ((model.product.won / 1000) / model.config.target) * 100;

      tentativeProdPerc = (tentativeProdPerc < 0) ? 0 : parseFloat(tentativeProdPerc.toFixed(1));
      prodPerc = (prodPerc < 0) ? 0 : parseFloat(prodPerc.toFixed(1));

      this.options.tentativeProdPerc = tentativeProdPerc;
      this.options.prodPerc = prodPerc;

      // project
      tentativeValuePerc = (((model.projects
        .outstanding + model.projects.won) / 1000) / model.config.target) * 100;
      vevaluePerc = ((model.projects.won / 1000) / model.config.target) * 100;

      tentativeValuePerc = (tentativeValuePerc > 100) ? 100
        : parseFloat(tentativeValuePerc.toFixed(1));
      vevaluePerc = (vevaluePerc > 100) ? 100 : parseFloat(vevaluePerc.toFixed(1));

      this.options.tentativeValuePerc = tentativeValuePerc;
      this.options.vevaluePerc = vevaluePerc;

      // link
      model.pipelineForecastLink = '#reports/revenue-pipeline';
      model.pipelineProductionLink = '#reports/production-value';

      // total
      model.projectsTotal = model.projects.outstanding + model.projects.won;
      model.prodTotal = model.product.outstanding + model.product.won;

      return _.template(template)(model);
    },
    onRender: function () {
      var width = this.options.widgetView.$el.width();
      // resize chart region
      this.ui.chartRegion.css({ width: width });

      if (this.options.new) {
        this.ui.settingsRegionEl.addClass('isVisible');
      } else {
        this.ui.chartRegion.highcharts(chart(this.options.durationPerc,
          this.options.tentativeProdPerc, this.options.prodPerc,
          this.options.tentativeValuePerc, this.options.vevaluePerc));
      }

      var activeFilterName = this.model.get('config').active_filter.name;
      if (activeFilterName === 'company overview') {
        this.ui.targetRegionEl.hide();
        this.showCompanyOverviewMessage();
      } else if (activeFilterName === 'client') {
        this.ui.targetRegionEl.hide();
        this.showClientMessage();
      }

      _.each(this.ui.mdlInput, function (input) {
        componentHandler.upgradeElement(input);
      });

      var dispQuantity = numeral(this.options.target).format('0,0');
      this.ui.targetInputEl.val(dispQuantity);
      this.ui.mdlInput.addClass('is-dirty');
    },
    selsectTargetValue: function (e) {
      e.currentTarget.select();
    },
    changeTargetValue: function (e) {
      var value = numeral(e.target.value).format('0,0');
      value = parseFloat(numeral().unformat(value));
      this.options.target = value;

      var dispQuantity = numeral(value).format('0,0');
      this.ui.targetInputEl.val(dispQuantity);
    },
    showFilterTooltip: function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.ui.showFilterEl.hasClass('disabled')) {
        this.ui.tooltipEl.addClass('isVisible');
        Wethod.onHtmlClick(this.ui.tooltipEl.selector, 'isVisible', 'remove');
      }
    },
    selectFilter: function (e) {
      e.preventDefault();

      if (this.options.filterType !== e.target.dataset.value) {
        this.ui.showFilterLabel.text(e.target.dataset.value);
        this.ui.tooltipEl.removeClass('isVisible');
        this.ui.inputHintEl.val('');
        this.ui.wrapInputHintEl.find('[data-region="mdl-input"]').removeClass('is-dirty');

        var filter = e.target.dataset.value;
        if (filter === 'company overview') {
          this.ui.wrapInputHintEl.removeClass('isVisible');
          this.ui.saveEl.show();
          this.ui.targetRegionEl.hide();
          this.hideClientMessage();
          this.showCompanyOverviewMessage();
        } else if (filter === 'client') {
          this.ui.wrapInputHintEl.addClass('isVisible');
          this.ui.filterTypeLabel.text(e.target.dataset.value);
          this.ui.saveEl.show();
          this.ui.targetRegionEl.hide();
          this.hideCompanyOverviewMessage();
          this.showClientMessage();
          this.ui.saveEl.hide();
        } else if (filter === 'me') {
          this.ui.wrapInputHintEl.removeClass('isVisible');
          this.ui.targetRegionEl.show();
          this.hideCompanyOverviewMessage();
          this.hideClientMessage();
          this.ui.saveEl.show();
        } else {
          this.ui.wrapInputHintEl.addClass('isVisible');
          this.ui.filterTypeLabel.text(e.target.dataset.value);
          this.ui.saveEl.hide();
          this.ui.targetRegionEl.show();
          this.hideCompanyOverviewMessage();
          this.hideClientMessage();
        }

        this.options.filterValue = null;
        this.options.filterType = e.target.dataset.value;
      }
    },
    showHitsList: function (e) {
      e.stopPropagation();

      if (!this.ui.inputHintEl.hasClass('disabled')) {
        if (e.type === 'click') {
          e.currentTarget.select();
        }

        if (e.target.value === '') {
          this.ui.saveEl.hide();
        }

        if (e.target.value === '' && e.type === 'click') {
          this.ui.wrapTooltipHintEl.addClass('isVisible');
          DashboardApp.dashboardAutocomplete(this, this.options.filterType, '');
        }

        if (e.type === 'keyup') {
          var value = e.target.value.trim();
          this.ui.wrapTooltipHintEl.addClass('isVisible');
          DashboardApp.dashboardAutocomplete(this, this.options.filterType, value);
        }

        // Hide select when click on 'html'
        Wethod.onHtmlClick(this.ui.wrapTooltipHintEl.selector, 'isVisible', 'remove');
      }
    },
    showSettings: function (e) {
      e.preventDefault();
      this.ui.settingsRegionEl.addClass('isVisible');
      this.ui.deleteEl.show();
      // reset
      this.ui.saveFeedbackEl.hide();
      this.ui.saveEl.removeClass('disabled');
      this.ui.showFilterEl.removeClass('disabled');
      this.ui.inputHintEl.removeClass('disabled');
      this.ui.targetInputEl.removeClass('disabled');
    },
    saveWidget: function (e) {
      e.preventDefault();

      if (!this.ui.saveEl.hasClass('disabled')) {
        this.ui.saveEl.addClass('disabled');
        this.ui.showFilterEl.addClass('disabled');
        this.ui.inputHintEl.addClass('disabled');
        this.ui.targetInputEl.addClass('disabled');
        this.ui.deleteEl.hide();
        this.ui.saveFeedbackEl.show();

        var data = {
          target: this.options.target,
          filterType: this.options.filterType,
          filterValue: this.options.filterValue,
        };

        DashboardApp.saveSalesFunnel(this, data);
      }
    },
    deleteWidget: function (e) {
      e.preventDefault();
      if (!this.ui.deleteEl.hasClass('disabled')) {
        this.ui.deleteEl.addClass('disabled');
        DashboardApp.deleteSalesFunnel(this);
      }
    },
    showCompanyOverviewMessage: function () {
      this.ui.companyOverviewMessageEl.removeClass('hidden');
    },
    showClientMessage: function () {
      this.ui.clientMessageEl.removeClass('hidden');
    },
    hideCompanyOverviewMessage: function () {
      this.ui.companyOverviewMessageEl.addClass('hidden');
    },
    hideClientMessage: function () {
      this.ui.clientMessageEl.addClass('hidden');
    },
  });
});
