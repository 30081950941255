const React = require('react');
const PropTypes = require('prop-types');

const ChartSidebarStepStatus = ({ type }) => {
  const baseClassName = 'wethod-chart-sidebar__step-status';
  return (
    <span className={`${baseClassName} ${baseClassName}--${type}`} />
  );
};

ChartSidebarStepStatus.propTypes = {
  type: PropTypes.oneOf(['error', 'complete']).isRequired,
};

module.exports = ChartSidebarStepStatus;
