/* eslint-disable jsx-a11y/role-has-required-aria-props */
const React = require('react');
const PropTypes = require('prop-types');

const SelectItem = ({ value, className, children, onClick, disabled }) => {
  const getClassName = () => {
    let name = `wethod-menu__item ${className}`;
    if (disabled) {
      name += ' wethod-menu__item--disabled';
    }
    return name;
  };

  function handleClick(e) {
    if (disabled) {
      e.stopPropagation();
    } else {
      onClick(e);
    }
  }

  return (
    <li tabIndex="-1"
      role="option"
      onKeyPress={onClick}
      className={getClassName()}
      value={value}
      onClick={handleClick}>
      {children}
    </li>
  );
};

SelectItem.defaultProps = {
  className: '',
  onClick: null,
  disabled: false,
  children: null,
};

SelectItem.propTypes = {
  /**
   * Item content.
   */
  children: PropTypes.node,
  /**
   * A value which uniquely identifies this item.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Additional class given to the item
   */
  className: PropTypes.string,
  onClick: PropTypes.func,
  /**
   * If true, the item is disabled and cannot be selected.
   */
  disabled: PropTypes.bool,
};

module.exports = SelectItem;
