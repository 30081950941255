const React = require('react');
const WonLostModal = require('../../containers/modals/WonLostFeedbackModal');
const CannotEditModal = require('./CannotEditFeedbackModal.react');

const EditFeedbackModal = ({ data, onCloseClick, winProbabilityThreshold }) => {
  const isLost = data.project && data.project.probability === 0;
  const isWon = data.project && data.project.probability >= winProbabilityThreshold;
  const canEditFeedback = isLost || isWon;

  return canEditFeedback
    ? <WonLostModal data={data} onCloseClick={onCloseClick} isWon={isWon} />
    : (
      <CannotEditModal onCloseClick={onCloseClick}
        winProbabilityThreshold={winProbabilityThreshold} />
    );
};

module.exports = EditFeedbackModal;
