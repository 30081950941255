const React = require('react');
const ChartConfig = require('../Chart/models/ChartConfig');
const BarChart = require('../BarChart/BarChart.react');

/**
 * Return the right Chart based on given config.
 *
 * @param {ChartConfig} config
 * @param {BarChartPoint[]} points
 * @param {boolean} isLoading
 * @param {function} onPointClick
 * @param onEditChartClick
 * @param {BarChartPoint} selectedPoint
 * @return {JSX.Element}
 * @constructor
 */
const ChartBuilder = ({
  config, points, isLoading, onPointClick, canEdit,
  onEditChartClick, selectedPoint = null,
}) => {
  const { type } = config;

  switch (type) {
    case ChartConfig.TYPE_BAR:
    case ChartConfig.TYPE_STACKED_BAR:
      return (
        <BarChart xAxisLabel={config.dimensions.x.key}
          onEditChartClick={onEditChartClick}
          xAxisType={config.getDimensionType('x')}
          yAxisType={config.getDimensionType('y')}
          type={type}
          selectedPoint={selectedPoint}
          yAxisLabel={config.dimensions.y.key}
          colorAxisLabel={config.dimensions.color?.key}
          points={points}
          isLoading={isLoading}
          title={config.name}
          description={config.description}
          canEdit={canEdit}
          onPointClick={onPointClick} />
      );
    default:
      throw new Error(`"${type}" is not a valid Chart type`);
  }
};

module.exports = ChartBuilder;
