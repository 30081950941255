const React = require('react');
const BusinessUnitService = require('../../../../../../services/BusinessUnitService');

const AddItemButton = ({ onClick, winProbabilityThreshold }) => {
  const businessUnitService = new BusinessUnitService();

  function handleClick() {
    onClick(businessUnitService.getDefaultForItem(), winProbabilityThreshold);
  }

  return (
    <button type="button"
      className="wethod-button"
      onClick={handleClick}>
      Add project
    </button>
  );
};

module.exports = AddItemButton;
