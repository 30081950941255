const React = require('react');
const Contacts = require('../containers/Contacts');
const WonProjects = require('../containers/WonProjects');
const OutstandingProjects = require('../containers/OutstandingProjects');
const LostProjects = require('../containers/LostProjects');
const Info = require('../containers/Info');
const InvoicesWidget = require('../containers/InvoicesWidget');
const RevenueClockWidget = require('../containers/RevenueClockWidget');
const Kpi = require('../containers/Kpi/Kpi');
const Modal = require('../containers/Modal');
const SectionHeader = require('../../../../../../../../common/react/SectionHeader/components/SectionHeader.react');

module.exports = class Company extends React.Component {
  static getWonProjectsTitle(winProbabilityThreshold) {
    return (
      <span>
        Won projects {' '}
        <span
          className="client-content__main-description">
          (Probability higher than {winProbabilityThreshold}%)
        </span>
      </span>
    );
  }

  static getLostProjectsTitle() {
    return (
      <span>
        Lost projects {' '}
        <span
          className="client-content__main-description">
          (Probability equal to 0%)
        </span>
      </span>
    );
  }

  static getOutstandingProjectsTitle(winProbabilityThreshold) {
    return (
      <span>
        Outstanding projects {' '}
        <span className="client-content__main-description">(Probability between 10% and {winProbabilityThreshold}%)</span>
      </span>
    );
  }

  componentDidMount() {
    this.props.getRevenues(this.props.id);
    this.props.getRevenuesTargets(this.props.id);
    this.props.getProjects(this.props.id);
  }

  render() {
    return (
      <div className="client-company wethod-section-body">
        <SectionHeader
          current_section="Client Details"
          preview_anchor_id="Client Details"
          helper_url="finance/index/#clients"
          bookmark_fragment={`client/${this.props.id}/details`} />
        <Info />
        <div className="client-content">
          <div className="client-content__aside">
            <div className="client-widget">
              <RevenueClockWidget />
            </div>
            <div className="client-widget">
              <InvoicesWidget />
            </div>
          </div>
          <div className="client-content__main">
            <Kpi />
            <WonProjects
              title={Company.getWonProjectsTitle(this.props.winProbabilityThreshold)}
              hasFeedback
              isValueAsUnit={this.props.isValueAsUnit} />
            <LostProjects
              title={Company.getLostProjectsTitle()}
              hasFeedback
              isValueAsUnit={this.props.isValueAsUnit} />
            <OutstandingProjects
              title={Company.getOutstandingProjectsTitle(this.props.winProbabilityThreshold)}
              isValueAsUnit={this.props.isValueAsUnit} />
            <Contacts />
          </div>
        </div>
        <Modal />
      </div>
    );
  }
};
