const React = require('react');
const Modal = require('../../../../../../common/react/modal2/Modal.react');
const Table = require('../../../../../../common/react/Table2/Table2.react');
const TableRow = require('../../../../../../common/react/Table2/TableRow.react');
const TableCell = require('../../../../../../common/react/Table2/TableCell.react');
const TableHead = require('../../../../../../common/react/Table2/TableHead.react');
const TableBody = require('../../../../../../common/react/Table2/TableBody.react');
const Escaper = require('../../../../../../common/react/formatters/Escaper.react');
const NumberFormatter = require('../../../../../../common/react/formatters/NumberFormatter.react');
const InputMetadataManager = require('../../../../../../common/react/InputMetadataManager/InputMetadataManager.react');
const PropertyMetadataService = require('../../../../../../services/PropertyMetadataService/PropertyMetadataService');

const LinkedProjectsModal = ({ billingGroup, masterProject, onClose, onRevoke }) => {
  // eslint-disable-next-line no-underscore-dangle
  const metadataService = new PropertyMetadataService(masterProject._fields || []);

  function loadMore() {}

  function handleRevoke(project) {
    onRevoke(project, masterProject, billingGroup.id);
  }

  function getProjects() {
    return billingGroup && billingGroup.linked_projects ? billingGroup.linked_projects.map(
      (project) => (
        <LinkedProject key={project.id}
          project={project}
          billingGroup={billingGroup}
          onRevoke={handleRevoke}
          metadataService={metadataService} />
      ),
    ) : [];
  }

  function getBody() {
    return (
      <Table columns={3} maxHeight={300}>
        <TableHead>
          <TableRow className="invoice-plan-linked-projects__row">
            <TableCell name="name" className="invoice-plan-linked-projects__column-name">Name</TableCell>
            <TableCell name="fnp" className="invoice-plan-linked-projects__column-fnp">Final net value</TableCell>
            <TableCell name="action" className="invoice-plan-linked-projects__column-action" />
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={false}
          loadMore={loadMore}
          empty="No linked projects yet">
          {getProjects()}
        </TableBody>
      </Table>
    );
  }

  return (
    <Modal title="Linked projects" onCancelClick={onClose} className="invoice-plan__modal">
      {getBody()}
    </Modal>
  );
};

const LinkedProject = ({ project, onRevoke, metadataService }) => {
  const canEdit = metadataService.getCanEdit('invoices');
  const revokeButtonDisabledClass = !canEdit ? 'disabled' : '';

  function handleRevoke() {
    onRevoke(project);
  }

  const estimate = project.final_net_value * 1000;

  return (
    <TableRow className="invoice-plan-linked-projects__row">
      <TableCell name="name" className="invoice-plan-linked-projects__column-name">
        <Escaper>{project.name}</Escaper>
      </TableCell>
      <TableCell name="fnp" className="invoice-plan-linked-projects__column-fnp">
        <NumberFormatter precision={2}>{estimate}</NumberFormatter>
      </TableCell>
      <TableCell name="action" className="invoice-plan-linked-projects__column-action">
        <InputMetadataManager canEdit name="invoices" metadataService={metadataService}>
          <button type="button" className={`wethod-button ${revokeButtonDisabledClass}`} onClick={handleRevoke}>Revoke</button>
        </InputMetadataManager>
      </TableCell>
    </TableRow>
  );
};

module.exports = LinkedProjectsModal;
