const { connect } = require('react-redux');
const Actions = require('../components/Actions.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  isSaving: state.isSaving,
  isLoading: state.isLoadingCompanies || state.isLoadingEmployees,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

const mapDispatchToProps = (dispatch) => ({
  addOpportunity: (winProbabilityThreshold) => dispatch(actions
    .addNewOpportunity(winProbabilityThreshold)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Actions);
