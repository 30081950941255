const React = require('react');
const PrimarySection = require('./Price/PricePrimarySection.react');
const SecondarySection = require('./Price/PriceSecondarySection.react');
const HeaderBox = require('./HeaderBox/HeaderBox.react');
const NumericField = require('../../../../../../common/react/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const PrimarySectionAmount = require('./Price/PricePrimarySectionAmount.react');
const MathService = require('../../../../../../services/MathService');

const SuggestedPrice = ({
  price,
  totalCosts,
  externalCosts,
}) => {
  const formattedPrice = MathService.round(price);
  const grossMarginValue = formattedPrice - externalCosts;
  const grossMarginPercentage = formattedPrice ? (grossMarginValue / formattedPrice) * 100 : 0;
  const contributionMarginValue = formattedPrice - totalCosts;
  const contributionMarginPercentage = formattedPrice
    ? (contributionMarginValue / formattedPrice) * 100 : 0;

  const label = 'Suggested Price';

  return (
    <HeaderBox className="wethod-budget-header-box--price">
      <PrimarySection label="Suggested Price">
        <PrimarySectionAmount label={label}>
          <NumericField value={price}
            precision={2}
            minPrecision={2}
            readOnly
            label={label} />
        </PrimarySectionAmount>
      </PrimarySection>
      <SecondarySection grossMarginValue={grossMarginValue}
        grossMarginPercentage={grossMarginPercentage}
        contributionMarginValue={contributionMarginValue}
        contributionMarginPercentage={contributionMarginPercentage}
        grossMarginTooltip="Difference between Suggested Price and Ext. Costs"
        constributionMarginTooltip="Difference between Suggested Price and all costs (Costs and Ext. Costs)" />
    </HeaderBox>
  );
};

module.exports = SuggestedPrice;
