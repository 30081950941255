module.exports.GET_TIMESHEETS_REQUEST = 'GET_TIMESHEETS_REQUEST';
module.exports.GET_TIMESHEETS_SUCCESS = 'GET_TIMESHEETS_SUCCESS';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';
module.exports.SHOW_MODAL = 'SHOW_MODAL';

module.exports.CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

module.exports.SHOW_ADVANCED_SEARCH = 'SHOW_ADVANCED_SEARCH';

module.exports.APPLY_ADVANCED_SEARCH = 'APPLY_ADVANCED_SEARCH';

module.exports.GET_TIMESHEETS_TO_TRANSFER_REQUEST = 'GET_TIMESHEETS_TO_TRANSFER_REQUEST';
module.exports.GET_TIMESHEETS_TO_TRANSFER_SUCCESS = 'GET_TIMESHEETS_TO_TRANSFER_SUCCESS';

module.exports.GET_TIMESHEETS_TOTAL_HOURS_SUCCESS = 'GET_TIMESHEETS_TOTAL_HOURS_SUCCESS';

module.exports.RESET_TIMESHEETS_TO_TRANSFER = 'RESET_TIMESHEETS_TO_TRANSFER';

module.exports.TRANSFER_HOURS_REQUEST = 'TRANSFER_HOURS_REQUEST';
module.exports.TRANSFER_HOURS_SUCCESS = 'TRANSFER_HOURS_SUCCESS';
module.exports.TRANSFER_HOURS_ERROR = 'TRANSFER_HOURS_ERROR';

module.exports.SELECT_ALL_TRANSFER = 'SELECT_ALL_TRANSFER';
module.exports.SELECT_TIMESHEET_TO_TRANSFER = 'SELECT_TIMESHEET_TO_TRANSFER';

module.exports.UPDATE_HOURS_TO_TRANSFER = 'UPDATE_HOURS_TO_TRANSFER';

module.exports.GET_FROZEN_PERIOD_REQUEST = 'GET_FROZEN_PERIOD_REQUEST';
module.exports.GET_FROZEN_PERIOD_SUCCESS = 'GET_FROZEN_PERIOD_SUCCESS';
module.exports.GET_FROZEN_PERIOD_ERROR = 'GET_FROZEN_PERIOD_ERROR';
