const React = require('react');

const OpportunitySidebarDescription = ({ winProbabilityThreshold }) => (
  <span>
    An opportunity has a probability between 0% and { winProbabilityThreshold }%.{' '}
    Otherwise create a new project from <a href="/#pipeline/projects">Project list</a>
  </span>
);

module.exports = OpportunitySidebarDescription;
