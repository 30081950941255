const React = require('react');
const PropTypes = require('prop-types');
const Options = require('./ChartSidebarDimensionOptions.react');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const DimensionDistinctGrouping = require('../../Chart/models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimensionDistinctGrouping');
const ClusterOption = require('./ChartSidebarDimensionClusterGroupingOption.react');
const DimensionClusterGrouping = require('../../Chart/models/ChartConfigDimension/ChartConfigJobOrderCategoryDimension/ChartConfigJobOrderCategoryDimensionClusterGrouping');
const GroupingValue = require('../../Chart/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimensionGroupingValue');
const JobOrderCategoryModel = require('../../../../../models/JobOrderCategory');

class ChartSidebarDimensionJobOrderCategoryOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { availableCategories: [] };

    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  componentDidMount() {
    JobOrderCategoryModel
      .list()
      .done((availableCategories) => this.setState({
        availableCategories: availableCategories.map((category) => category.name),
      }));
  }

  handleTypeChange(e) {
    const selectedType = e.target.value;
    switch (selectedType) {
      case DimensionClusterGrouping.TYPE:
        this.props.onChangeType(new DimensionClusterGrouping([]));
        break;
      case DimensionDistinctGrouping.TYPE:
        this.props.onChangeType(new DimensionDistinctGrouping());
        break;
      default:
        throw new Error(`${selectedType} is not a valid job order category dimension type`);
    }
  }

  render() {
    return (
      <Options>
        <RadioGroup name="joc-dimension-grouping" onChange={this.handleTypeChange} value={this.props.type}>
          <Radio value={DimensionDistinctGrouping.TYPE} label="Show all" />
          <ClusterOption value={DimensionClusterGrouping.TYPE}
            updateErrors={this.props.updateErrors}
            getError={this.props.getError}
            availableValues={this.state.availableCategories}
            clusters={this.props.values}
            dimensionLabel="JOC"
            onChange={this.props.onChangeValues} />
        </RadioGroup>
      </Options>
    );
  }
}

ChartSidebarDimensionJobOrderCategoryOptions.defaultProps = {};

ChartSidebarDimensionJobOrderCategoryOptions.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.instanceOf(GroupingValue)).isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeValues: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionJobOrderCategoryOptions;
