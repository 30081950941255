/* eslint-disable react/sort-comp,react/prop-types,class-methods-use-this */
const React = require('react');
const Body = require('./Body.react');
const Loading = require('./Loading.react');
const Model = require('../models/RevenueClockWidget');
const RevenuesTargetsModel = require('../models/RevenuesTargets');

module.exports = class RevenueClock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: true,
    };
  }

  getTarget(targets) {
    const year = moment().year();
    return targets.reduce((sum, target) => {
      if (year === target.year) {
        return sum + target.first_quarter + target.second_quarter + target.third_quarter
          + target.fourth_quarter;
      }
      return sum;
    }, 0);
  }

  componentDidMount() {
    if (this.props.config) {
      const model = new Model(this.props.config);

      if (this.props.config.filter === 'client') {
        const revenuesTargetsModel = new RevenuesTargetsModel({ id: this.props.config.value });
        $.when(model.getModel(), revenuesTargetsModel.getModel())
          .done((data, revenuesTargets) => this.setState({
            projects: data.projects,
            product: data.product,
            weeksToGo: data.time_to_go,
            target: this.getTarget(revenuesTargets) * 1000,
            waiting: false,
          }));
      } else {
        $.when(model.getModel()).done((data) => this.setState({
          projects: data.projects,
          product: data.product,
          waiting: false,
        }));
      }
    }
  }

  getRevenuePipeline() {
    return this.state.projects.outstanding + this.state.projects.won;
  }

  getProductionValue() {
    return this.state.product.outstanding + this.state.product.won;
  }

  getBody() {
    if (!this.props.config) {
      return 'configure!';
    }
    if (this.state.waiting) {
      return <Loading />;
    }
    return (
      <Body weeksToGo={this.state.weeksToGo}
        target={this.state.target}
        revenuePipeline={this.getRevenuePipeline()}
        productionValue={this.getProductionValue()} />
    );
  }

  render() {
    return (
      <div className="wethod-widget widget-revenue-clock">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">Revenue clock</span>
        </div>
        <div className="wethod-widget__body widget-revenue-clock__body">{this.getBody()}</div>
      </div>
    );
  }
};
