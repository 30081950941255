const { connect } = require('react-redux');
const Component = require('../../components/Kpi/Kpi.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  revenues: state.revenues,
  isWaitingForRevenues: state.isWaitingForRevenues,
  isWaitingForProjects: state.isWaitingForProjects,
  timeFilter: state.timeFilter,
  projects: state.projects
    .filter((project) => project.probability >= state
      .winLossProbabilityThreshold.winProbabilityThreshold),
  projectsInFrame: state.filteredProjects
    .filter((project) => project.probability >= state
      .winLossProbabilityThreshold.winProbabilityThreshold),
});

const mapDispatchToProps = (dispatch) => ({
  updateTimeFilter: (filter) => dispatch(actions.updateTimeFilter(filter)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
