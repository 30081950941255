const React = require('react');
const format = require('../../../../../services/FormatService').formatDecimalNumber;

const Total = ({ value = 0, label, secondValue, feedback, error, currency }) => {
  const getError = () => (error ? <span className="invoice-plan__total-error">{error}</span> : null);

  const getFeedback = () => (feedback
    ? <span className="invoice-plan__total-feedback">{feedback}</span>
    : null);

  const getTotalValue = () => {
    const total = format(value, false, 2, 2);
    const currencyCode = currency ? ` ${currency}` : '';
    if (secondValue != null) {
      const secondTotal = format(secondValue, false, 2, 2);

      return error
        ? (
          <span className="invoice-plan__total-value">
            <span className="invoice-plan__total-error">{total}</span> / {secondTotal}{currencyCode}
          </span>
        )
        : <span className="invoice-plan__total-value">{total} / {secondTotal}{currencyCode}</span>;
    }
    return <span className="invoice-plan__total-value">{total}{currencyCode}</span>;
  };

  const getStyle = () => {
    let style = 'invoice-plan__total';
    if (secondValue != null) style += ' invoice-plan__total--big';
    if (error) style += ' invoice-plan__total--error';
    return style;
  };

  return (
    <div className={getStyle()}>
      <span className="invoice-plan__total-label">{label}</span>
      {getTotalValue()}
      {getFeedback()}
      {getError()}
    </div>
  );
};

module.exports = Total;
