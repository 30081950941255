const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('./StackedBarChartIcon.react');
const TypeOption = require('./ChartSidebarTypeOption.react');

const ChartSidebarStackedBarTypeOption = ({ id, name, onClick, checked, value }) => (
  <TypeOption name={name} icon={<Icon />} id={id} subtitle="Best for visualizing amounts and trends" title="Stacked Chart" value={value} onClick={onClick} checked={checked} />
);

ChartSidebarStackedBarTypeOption.defaultProps = {
  id: null,
  onClick: null,
  name: null,
  checked: false,
};

ChartSidebarStackedBarTypeOption.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

module.exports = ChartSidebarStackedBarTypeOption;
