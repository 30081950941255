/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types,react/no-did-update-set-state,react/sort-comp,
 react/no-unused-state */
const React = require('react');
const Cleave = require('cleave.js/react');

/**
 * An enhanced numeric input with:
 *
 * VALIDATION
 * Pass a comma separated string of "constraints" that will be checked at any blur. Pass an
 * "onValidation" callback to get validation errors. Supported constraints: integer, required.
 *
 * LIFECYCLE HOOKS
 * onChange, onValidate, onBlur, onFocus.
 *
 * OTHER PROPS
 * value, name, placeholder, formatOptions, disabled
 *
 * EDIT VALUE FROM PARENT
 * Just pass a new "value" to reset the input. This let you use this component by embedding it into
 * another.
 *
 * OVERRIDE OR EDIT FORMAT OPTIONS
 * Just pass a formatOptions props that will be merged with the default/integer one.
 *
 * @type {module.NumericInput}
 */
module.exports = class NumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getValue(this.props.value),
      constraints: this.props.constraints ? this.props.constraints.split(',') : [],
      isValid: false,
      placeholder: this.props.placeholder,
    };

    this.errors = [];
    this.state.isValid = this.isValid();

    this.defaultFormatOptions = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: this.props.precision ? this.props.precision : 1,
      numeralDecimalMark: ',',
      delimiter: '.',
      stripLeadingZeroes: true,
    };

    this.integerFormatOptions = {
      ...this.defaultFormatOptions,
      numeralDecimalScale: 0,
    };
  }

  getValue(value) {
    if (this.props.hideZero) {
      if (parseFloat(value) === 0) {
        return '';
      }
    }
    if (this.props.minPrecision) {
      return parseFloat(value).toFixed(this.props.minPrecision);
    }
    return value;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      if (!this.props.disabled) {
        this.cleave.setRawValue(this.getValue(nextProps.value));
      }
      this.setState({
        value: this.getValue(nextProps.value),
        isValid: this.isValid(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) this.setState({ isValid: this.isValid() });
  }

  required() {
    return this.state.value != null && this.state.value.toString().trim() !== '';
  }

  integer() {
    return Number.isInteger(parseFloat(this.state.value)) || this.state.value === '';
  }

  isValid() {
    this.errors = this.validate();
    if (this.props.onValidate) this.props.onValidate(this.props.name, this.errors);
    return this.errors.length === 0;
  }

  validate() {
    const errors = [];
    for (let i = 0; i < this.state.constraints.length; i++) {
      if (!this[this.state.constraints[i]]()) errors.push(this.state.constraints[i]);
    }
    return errors;
  }

  afterChange() {
    if (this.props.onChange) this.props.onChange(this.props.name, this.state.value);
  }

  handleChange(e) {
    if (this.state.value !== e.target.rawValue) {
      this.setState({ value: this.getValue(e.target.rawValue) }, this.afterChange.bind(this));
    }
  }

  needsPlaceholder() {
    return this.state.value === '';
  }

  handleBlur() {
    if (this.needsPlaceholder()) this.setState({ placeholder: this.props.placeholder });
    if (this.props.onBlur) this.props.onBlur(this.state.value);
  }

  blur() {
    this.input.blur();
  }

  handleFocus() {
    this.setState({ placeholder: '' });
    if (this.props.onFocus) this.props.onFocus();
  }

  isRequired() {
    return this.state.constraints.indexOf('required') !== -1;
  }

  isInteger() {
    return this.state.constraints.indexOf('integer') !== -1;
  }

  getClassName() {
    let name = 'wethod-input';
    if (this.isRequired()) name += ' wethod-input--required';
    if (this.props.disabled) name += ' disabled';
    return name;
  }

  handleInit(cleave) {
    this.cleave = cleave;
    cleave.setRawValue(this.getValue(this.state.value));
    this.setState({ input: cleave });
  }

  getFormatProps() {
    const { formatOptions } = this.props;
    if (typeof formatOptions === 'object' && formatOptions !== null) {
      return formatOptions;
    }
    return null;
  }

  getOptions() {
    let options = this.defaultFormatOptions;
    if (this.isInteger()) options = this.integerFormatOptions;
    const formatProps = this.getFormatProps();
    if (formatProps !== null) {
      options = { ...options, ...formatProps };
    }
    return options;
  }

  getInput() {
    if (this.props.disabled) {
      if (this.state.value) return (<span>{this.state.value}</span>);
      return (<span>&#160;</span>);
    }
    return (
      <Cleave options={this.getOptions()}
        htmlRef={(ref) => this.input = ref}
        placeholder={this.state.placeholder}
        onInit={this.handleInit.bind(this)}
        onChange={this.handleChange.bind(this)}
        onKeyDown={this.props.onKeyUp}
        onBlur={this.handleBlur.bind(this)}
        onFocus={this.handleFocus.bind(this)} />
    );
  }

  render() {
    return (
      <span className={this.getClassName()}>
        {this.getInput()}
      </span>
    );
  }
};
