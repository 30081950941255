'use strict';

/* eslint-disable no-underscore-dangle,camelcase */

Wethod.module('ReportApp.People', function (People, Wethod, Backbone, Marionette, $, _) {
  // STRUCTURE
  this.StructureLayoutView = Marionette.LayoutView.extend({
    el: '[data-region="appBody"]',
    template: '#peopleReportStructureTemplate',
    className: 'fluid',
    regions: {
      headerSection: '[data-region="reportSectionHeader"]',
      head: '[data-region="head"]',
      navigate: '[data-region="navigate"]',
      details: '[data-region="details"]',
      body: '[data-region="reportBody"]',
      projectTypeList: '[data-region="projectTypeList"]',
      modal: '[data-region="appDialog"]',
    },
    ui: {
      body: '[data-region="reportBody"]',
      header: '.peopleHeader',
      headerSection: '[data-region="reportSectionHeader"]',
      projectTypeList: '[data-region="projectTypeList"]',
      filterInternalEl: '[data-action="showOnlyInternal"]',
      filterAllEl: '[data-action="showAll"]',
      filterExternalEl: '[data-action="showOnlyExternal"]',
      exportEl: '[data-action="export"]',
    },
    events: {
      'click @ui.filterInternalEl': function (e) {
        this.filterEmployee(e, 'internal');
      },
      'click @ui.filterAllEl': function (e) {
        this.filterEmployee(e, 'all');
      },
      'click @ui.filterExternalEl': function (e) {
        this.filterEmployee(e, 'external');
      },
      'click @ui.exportEl': 'exportModule',
    },
    initialize: function () {
      dispatcher.on('app:banner:closed', this.setListHeight.bind(this));
    },
    setListHeight: function () {
      var notBodyHeight = Wethod.getHeaderHeight() + this.ui.projectTypeList.outerHeight(true)
        + this.ui.header.outerHeight(true) + this.ui.headerSection.outerHeight(true) + 55;
      $('.reportPeopleListRow').css('max-height', 'calc(100vh - ' + notBodyHeight + 'px)');
    },
    filterEmployee: function (e, levelFilter) {
      e.preventDefault();
      e.stopPropagation();

      var selElement = this.$el.find('.sel');
      selElement.removeClass('sel');

      var target = $(e.target);
      target.addClass('sel');

      People.filterLevelTypeEmployee(levelFilter);
    },
    exportModule: function (e) {
      e.preventDefault();
      People.showModalExport();
    },
  });

  this.LoadingMoreDataTemplateView = Marionette.ItemView.extend({
    className: 'grid clear',
    template: '#loadingMoreDataTemplate',
  });

  // Change Week
  this.ChangeWeekItemView = Marionette.ItemView.extend({
    className: '',
    _template: '#peopleReportChangeWeekTemplate',
    ui: {
      weekLabel: '[data-region="weekLabel"]',
      previousWeek: '[data-action="previousWeek"]',
      nextWeek: '[data-action="nextWeek"]',
    },
    events: {
      'click @ui.previousWeek': function (e) {
        this.changeWeek(e, 'previous');
      },
      'click @ui.nextWeek': function (e) {
        this.changeWeek(e, 'next');
      },
    },
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function (serializedModel) {
      var that = this;
      var template = $(that._template).html();
      var model = serializedModel;

      model.start = that.options.start;
      model.end = that.options.end;
      return _.template(template)(model);
    },
    changeWeek: function (e, action) {
      e.preventDefault();
      if (!this.$el.hasClass('disabled')) {
        People.changeWeek(action, this);
        $('[data-region="details"]').html('');
      }
    },
  });

  this.EmployeeRowDetailView = Marionette.ItemView.extend({
    className: 'reportPeopleDetails',
    template: '#peopleReportEmployeeDetailTemplate',
    initialize: function () {
      var timesheetPermission = Wethod.ReportApp.getTimesheetPermission('edit_other');
      this.model.set('timesheetPermission', timesheetPermission);
      this.model.set('date', People.monday);
    },
    onRender: function () {
      this.$el.css({ 'margin-top': $('[data-region="projectTypeList"]').height() });
    },
  });

  // LISTA UTENTI
  this.EmployeeRowView = Marionette.ItemView.extend({
    className: 'reportPeopleRow',
    _template: '#peopleReportEmployeeRowTemplate',
    ui: {
      employee: '[data-action="showEmployeeDetail"]',
    },
    events: {
      'click @ui.employee': 'showDetail',
    },
    initialize: function (options) {
      this.options = options;
      _.bindAll(this, 'template');
    },
    template: function (serialized_model) {
      var that = this;
      var template = $(that._template).html();
      var model = serialized_model;

      model.displayName = Wethod.Utility.displayName(model.name, model.surname);

      return _.template(template)(model);
    },
    showDetail: function (e) {
      e.preventDefault();
      People.showEmployeeDetail(this);
    },
  });
  this.EmployeeRowsCollectionView = Marionette.CollectionView.extend({
    childView: People.EmployeeRowView,
    className: 'reportPeopleListRow',
    onDomRefresh: function () {
      var maxHeight = $(window).height() - 60 - 32 - $('.peopleHeader').outerHeight() - 60;
      this.$el.css({ 'max-height': maxHeight });
    },
  });

  // LISTA PROJECT TYPE
  this.ProjectTypeRowView = Marionette.ItemView.extend({
    className: 'projectTypeRow',
    template: '#peopleProjectTypeTemplate',
    initialize: function (options) {
      this.options = options;
    },
  });
  this.ProjectTypeRowsCollectionView = Marionette.CollectionView.extend({
    childView: People.ProjectTypeRowView,
  });

  // EXPORT
  var modalW = 350;
  var modalH = 500;
  this.ModalExportLayoutView = Marionette.LayoutView.extend({
    template: '#peopleReportExportModalTemplate',
    className: 'modalWrapper',
    selectedOption: 'timesheet',
    selectedDate: null,
    regions: {
      body: '[data-region="peopleReportExportBody"]',
    },
    ui: {
      modalEL: '.modalStructure',
      timesheetButton: '[data-action="showTimesheetExport"]',
      wagesButton: '[data-action="showWagesExport"]',
      foodStampsButton: '[data-action="showFoodStampsExport"]',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      messageEl: '[data-message="statusModalAction"]',
    },
    events: {
      'click @ui.timesheetButton': 'showTimesheetExportBody',
      'click @ui.wagesButton': 'showWagesExportBody',
      'click @ui.foodStampsButton': 'showFoodStampsExportBody',
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
    },
    initialize: function () {
      dispatcher.on('export:date:change', this.onDateChange.bind(this));
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    switchExportType: function (e) {
      e.preventDefault();
      e.stopPropagation();

      var selElement = this.$el.find('.sel');
      selElement.removeClass('sel');

      var target = $(e.target);
      target.addClass('sel');
    },
    showTimesheetExportBody: function (e) {
      this.switchExportType(e);

      this.selectedOption = 'timesheet';

      People.showModalTimesheetExport(this);
    },
    showWagesExportBody: function (e) {
      this.switchExportType(e);

      this.selectedOption = 'wages';

      People.showModalWagesExport(this);
    },
    showFoodStampsExportBody: function (e) {
      this.switchExportType(e);

      this.selectedOption = 'food-stamps';

      People.showModalFoodStampsExport(this);
    },
    onDateChange: function (date) {
      this.selectedDate = date;
    },
    doOkAction: function (e) {
      e.preventDefault();

      if (!this.ui.actionButton.hasClass('disabled')) {
        this.ui.actionButton.addClass('disabled');
        this.ui.cancelButton.addClass('disabled');
        this.ui.messageEl.text('Sending');

        People.doModuleExport(this, this.selectedOption, this.selectedDate);
      }
    },
    cancelAction: function (e) {
      e.preventDefault();
      if (!this.ui.cancelButton.hasClass('disabled')) {
        this.destroy();
      }
    },
  });

  this.ModalExportTimesheetBodyLayoutView = Marionette.LayoutView.extend({
    template: '#peopleReportExportTimesheetTemplate',
    _selectedMonth: moment(),
    regions: {
      monthPicker: '[data-region="monthPicker"]',
    },
    ui: {
      selectedMonth: '[data-action="selectedMonth"]',
      monthPicker: '[data-region="monthPicker"]',
    },
    events: {
      'click @ui.selectedMonth': 'openMonthPicker',
    },
    onRender: function () {
      this.selectDate(this._selectedMonth);
    },
    // Set the selected date and show the label
    selectDate: function (date) {
      this._selectedMonth = moment(date);
      this.ui.selectedMonth.val(this._selectedMonth.format('MMMM YYYY'));

      dispatcher.trigger('export:date:change', this._selectedMonth.format('YYYY-MM-DD'));
    },
    openMonthPicker: function () {
      var yearStart = this._selectedMonth.format('YYYY');
      var monthSelector = new Wethod.View.MonthPicker.YearsView({ start: yearStart });
      this.getRegion('monthPicker').show(monthSelector);
      this.ui.monthPicker.addClass('isVisible');

      var monthStart = this._selectedMonth.format('MM');
      this.getRegion('monthPicker').currentView.scrollYears(30 * (13 * 10 + (monthStart - 0.5)));

      this.getRegion('monthPicker').currentView.on('monthPicker:month:selected', function (value) {
        this.selectDate(value);
        this.getRegion('monthPicker').empty();
        this.ui.monthPicker.removeClass('isVisible');
      }.bind(this));
    },
  });

  this.ModalExportWagesBodyLayoutView = Marionette.LayoutView.extend({
    template: '#peopleReportExportWagesTemplate',
    _selectedMonth: moment(),
    regions: {
      monthPicker: '[data-region="monthPicker"]',
    },
    ui: {
      selectedMonth: '[data-action="selectedMonth"]',
      monthPicker: '[data-region="monthPicker"]',
    },
    events: {
      'click @ui.selectedMonth': 'openMonthPicker',
    },
    onRender: function () {
      this.selectDate(this._selectedMonth);
    },
    // Set the selected date and show the label
    selectDate: function (date) {
      this._selectedMonth = moment(date);
      this.ui.selectedMonth.val(this._selectedMonth.format('MMMM YYYY'));

      dispatcher.trigger('export:date:change', this._selectedMonth.format('YYYY-MM-DD'));
    },
    openMonthPicker: function () {
      var yearStart = this._selectedMonth.format('YYYY');
      var monthSelector = new Wethod.View.MonthPicker.YearsView({ start: yearStart });
      this.getRegion('monthPicker').show(monthSelector);
      this.ui.monthPicker.addClass('isVisible');

      var monthStart = this._selectedMonth.format('MM');
      this.getRegion('monthPicker').currentView.scrollYears(30 * (13 * 10 + (monthStart - 0.5)));

      this.getRegion('monthPicker').currentView.on('monthPicker:month:selected', function (value) {
        this.selectDate(value);
        this.getRegion('monthPicker').empty();
        this.ui.monthPicker.removeClass('isVisible');
      }.bind(this));
    },
  });

  this.ModalExportFoodStampsBodyLayoutView = Marionette.LayoutView.extend({
    template: '#peopleReportExportFoodStampsTemplate',
    _selectedMonth: moment(),
    regions: {
      monthPicker: '[data-region="monthPicker"]',
    },
    ui: {
      selectedMonth: '[data-action="selectedMonth"]',
      monthPicker: '[data-region="monthPicker"]',
    },
    events: {
      'click @ui.selectedMonth': 'openMonthPicker',
    },
    onRender: function () {
      this.selectDate(this._selectedMonth);
    },
    // Set the selected date and show the label
    selectDate: function (date) {
      this._selectedMonth = moment(date);
      this.ui.selectedMonth.val(this._selectedMonth.format('MMMM YYYY'));

      dispatcher.trigger('export:date:change', this._selectedMonth.format('YYYY-MM-DD'));
    },
    openMonthPicker: function () {
      var yearStart = this._selectedMonth.format('YYYY');
      var monthSelector = new Wethod.View.MonthPicker.YearsView({ start: yearStart });
      this.getRegion('monthPicker').show(monthSelector);
      this.ui.monthPicker.addClass('isVisible');

      var monthStart = this._selectedMonth.format('MM');
      this.getRegion('monthPicker').currentView.scrollYears(30 * (13 * 10 + (monthStart - 0.5)));

      this.getRegion('monthPicker').currentView.on('monthPicker:month:selected', function (value) {
        this.selectDate(value);
        this.getRegion('monthPicker').empty();
        this.ui.monthPicker.removeClass('isVisible');
      }.bind(this));
    },
  });

  // ERROR MODAL
  this.ModalErrorItemView = Marionette.ItemView.extend({
    template: '#errorModalTemplate',
    className: 'modalWrapper',
    ui: {
      modalEL: '.modalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
    },
    events: {
      'click @ui.cancelButton': 'cancelAction',
    },
    onRender: function () {
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });
});
