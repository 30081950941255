const React = require('react');
const PropTypes = require('prop-types');
const Chart = require('../Chart/Chart.react');
const Plot = require('./BarChartPlot.react');
const Legend = require('../ChartLegend/ChartLegend.react');
const BarChartPoint = require('./models/BarChartPoint');
const ShowIf = require('../../ShowIf/ShowIf.react');
const ChartConfig = require('../Chart/models/ChartConfig');

require('./style.scss');

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = { legendEntries: [] };

    this.setLegendEntries = this.setLegendEntries.bind(this);
  }

  /**
   *
   * @param entries {ChartLegendItem[]}
   */
  setLegendEntries(entries) {
    this.setState({ legendEntries: entries });
  }

  render() {
    return (
      <Chart title={this.props.title}
        description={this.props.description}
        isLoading={this.props.isLoading}
        canEdit={this.props.canEdit}
        onEditChartClick={this.props.onEditChartClick}>
        <Plot yAxisLabel={this.props.yAxisLabel}
          selectedPoint={this.props.selectedPoint}
          yAxisType={this.props.yAxisType}
          xAxisType={this.props.xAxisType}
          type={this.props.type}
          xAxisLabel={this.props.xAxisLabel}
          points={this.props.points}
          updateLegend={this.setLegendEntries}
          onPointClick={this.props.onPointClick} />
        <ShowIf condition={this.props.colorAxisLabel !== ''}>
          <Legend title={this.props.colorAxisLabel} items={this.state.legendEntries} />
        </ShowIf>
      </Chart>
    );
  }
}

BarChart.defaultProps = {
  title: '',
  description: '',
  colorAxisLabel: '',
  isLoading: false,
  xAxisType: 'string',
  yAxisType: 'string',
  selectedPoint: null,
  onEditChartClick: null,
  canEdit: false,
};

BarChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  xAxisLabel: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  colorAxisLabel: PropTypes.string,
  points: PropTypes.arrayOf(PropTypes.instanceOf(BarChartPoint)).isRequired,
  isLoading: PropTypes.bool,
  onPointClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf([ChartConfig.TYPE_BAR, ChartConfig.TYPE_STACKED_BAR]).isRequired,
  xAxisType: PropTypes.string,
  yAxisType: PropTypes.string,
  selectedPoint: PropTypes.instanceOf(BarChartPoint),
  onEditChartClick: PropTypes.func,
  canEdit: PropTypes.bool,
};

module.exports = BarChart;
