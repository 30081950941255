const React = require('react');
const PropTypes = require('prop-types');
const isEqual = require('react-fast-compare');
const Tab = require('./InsightLevelChartTab.react');
const Chart = require('../../../Chart/Chart/models/Chart');
const MoreButton = require('./SeeMoreButton.react');
const ChartPickerTab = require('../../../Chart/Chart/models/ChartPickerTab');

/**
 * Inline list of chart tabs, clicking on a tab selects the related chart.
 * If there's no enough space to show all charts inline, a "See more" button is displayed containing
 * all the charts not visible inline.
 */
class InsightLevelChartPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenTabs: [], // List of tabs are not displayed inline
    };

    this.ref = null;
    this.moreButtonRef = null;
    this.tabRefs = {};

    this.setRef = this.setRef.bind(this);
    this.setMoreButtonRef = this.setMoreButtonRef.bind(this);
    this.setTabRef = this.setTabRef.bind(this);
    this.getHiddenTabs = this.getHiddenTabs.bind(this);
    this.setTableViewTabRef = this.setTableViewTabRef.bind(this);
  }

  componentDidMount() {
    this.setState({ hiddenTabs: this.getHiddenTabs() });
  }

  componentDidUpdate(prevProps, prevState) {
    const changedHiddenCharts = !isEqual(prevState.hiddenTabs, this.state.hiddenTabs);
    const changedCharts = !isEqual(prevProps.charts, this.props.charts);
    const changedSelectedChart = prevProps.selectedId !== this.props.selectedId;
    if (changedCharts || changedSelectedChart) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hiddenTabs: prevState.hiddenTabs.length === 0 ? this.getHiddenTabs() : [] });
    }
    if (changedHiddenCharts) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hiddenTabs: this.getHiddenTabs() });
    }
  }

  /**
   * Return list of charts which tabs cannot be displayed inline due to lack of space.
   * @return {ChartPickerTab[]}
   */
  getHiddenTabs() {
    const hiddenTabs = [...this.state.hiddenTabs];
    if (this.ref) {
      const containerScrollWidth = this.ref.scrollWidth;
      // Space available to show tabs
      let containerAvailableWidth = this.ref.clientWidth;
      // If container needs scroll
      if (containerScrollWidth > containerAvailableWidth) {
        // Tabs space decreases because we have to make room for the currently selected chart
        containerAvailableWidth -= this.tabRefs[this.props.selectedId]
          ? this.tabRefs[this.props.selectedId].offsetWidth : 0;
        // Keep track of all tabs which does not fit into container
        this.getVisibleTabs().forEach((tab) => {
          // Currently selected chart is always visible
          if (tab.chartId !== this.props.selectedId) {
            const chartWidth = this.tabRefs[tab.chartId].offsetWidth;
            containerAvailableWidth -= chartWidth;
            if (containerAvailableWidth < 0) {
              hiddenTabs.push(tab);
            }
          }
        });
      }
    }
    return hiddenTabs;
  }

  /**
   * Return list of charts which tabs can be displayed inline because they fit into the available
   * space.
   * @return {ChartPickerTab[]}
   */
  getVisibleTabs() {
    const hiddenTabsIds = this.state.hiddenTabs.map((tab) => tab.chartId);
    return this.getChartTabs()
      .filter((tab) => !hiddenTabsIds.includes(tab.chartId));
  }

  /**
   * Return list of tabs to display.
   * @return {InsightLevelChartTab[]}
   */
  getTabs() {
    return this.getVisibleTabs()
      .map((tab) => (
        <Tab key={tab.chartId}
          setRef={this.setTabRef}
          id={tab.chartId}
          title={tab.title}
          onClick={this.props.onClick}
          selected={tab.isSelected}
          onDeleteChart={this.props.onDeleteChart}
          canDelete={tab.canDeleteChart} />
      ));
  }

  /**
   * @return {ChartPickerTab[]}
   */
  getChartTabs() {
    let chartTabs = this.props.charts
      .map((chart) => new ChartPickerTab(
        chart.config.name,
        chart.id,
        this.props.userHasChartEditPermissions && !chart.isDefault,
        this.props.selectedId === chart.id,
      ));
    if (this.props.withTableView) {
      const tableViewTab = new ChartPickerTab('Table view', 'table-view', false, this.props.selectedId === 'table-view');
      chartTabs = [tableViewTab].concat(chartTabs);
    }
    return chartTabs;
  }

  /**
   * Save reference to HTML tab element picker.
   * @param el
   */
  setRef(el) {
    this.ref = el;
  }

  /**
   * Save reference to HTML "See more" button.
   * @param el
   */
  setMoreButtonRef(el) {
    this.moreButtonRef = el;
  }

  /**
   * Save reference to HTML tab element related to chart with given ID.
   * @param {integer} id Chart ID
   * @param el
   */
  setTabRef(id, el) {
    this.tabRefs[id] = el;
  }

  /**
   * Save reference to HTML table view tab element.
   * @param el
   */
  setTableViewTabRef(el) {
    this.tableViewTabRef = el;
  }

  render() {
    return (
      <div className="wethod-insight-level__chart-picker-container">
        <ul className="wethod-insight-level__chart-picker" role="tablist" ref={this.setRef}>
          {this.getTabs()}
        </ul>
        <MoreButton setRef={this.setMoreButtonRef}
          tabs={this.state.hiddenTabs}
          onOptionClick={this.props.onClick} />
      </div>
    );
  }
}

InsightLevelChartPicker.defaultProps = {
  selectedId: null,
  withTableView: false,
  userHasChartEditPermissions: false,
};

InsightLevelChartPicker.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.instanceOf(Chart)).isRequired,
  /**
   * ID of the currently selected chart.
   */
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
  onDeleteChart: PropTypes.func.isRequired,
  withTableView: PropTypes.bool,
  userHasChartEditPermissions: PropTypes.bool,
};

module.exports = InsightLevelChartPicker;
