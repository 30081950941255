const React = require('react');
const Task = require('../Task.react');
const PrimaryInfo = require('../TaskPrimaryInfo.react');
const Name = require('../TaskName.react');
const PercentageCell = require('../../Cell/PercentageCell.react');
const Levels = require('./IntercompanyTaskLevels.react');
const ExternalCosts = require('../../Cell/ExternalCostCell.react');
const DaysCell = require('../../Cell/DaysCell.react');
const MoreOptions = require('../../MoreOptionsButton/MoreOptionsButton.react');
const MoreOptionsMenuItem = require('../../MoreOptionsButton/MoreOptionsMenuItem.react');
const Price = require('../../Cell/PriceCell.react');
const Cost = require('../../Cell/CostCell.react');
const CurrencyManager = require('../../../../containers/CurrencyManager');
const IconCell = require('../../Cell/IconCell.react');

const IntercompanyTask = ({
  column,
  name,
  externalCosts,
  cost,
  price,
  editable,
  id,
  updateTaskInfo,
  currency,
  deleteTask,
  levels,
  intercompany,
  previous,
  onInviteSupplierClick,
  onIntercompanyMessageClick,
  uid,
  deleted,
  created,
  areaEnabled,
}) => {
  function saveName(e) {
    updateTaskInfo(id, { name: e.target.value });
  }

  function saveTaskValues(changes) {
    updateTaskInfo(id, changes);
  }

  function saveExternalCosts(value, currencyCode) {
    saveTaskValues({ external_cost: value, currency: currencyCode });
  }

  function onInviteClick() {
    onInviteSupplierClick({ uid, id }, intercompany.supplier);
  }

  function onLabelClick() {
    onIntercompanyMessageClick(uid, intercompany);
  }

  if (column === 'left') {
    return (
      <Task exists={created} existed={deleted}>
        <PrimaryInfo>
          <Name editable={editable} save={saveName} id={`task-${id}-name`}>{name}</Name>
          <PercentageCell />
        </PrimaryInfo>
      </Task>
    );
  }
  if (column === 'center') {
    return (
      <Task exists={created} existed={deleted}>
        <PrimaryInfo>
          <Levels budgetIsEditable={editable || !areaEnabled}
            onInviteClick={onInviteClick}
            items={levels}
            isSent={intercompany.sent}
            isConnected={intercompany.connected}
            onLabelClick={onLabelClick}
            supplier={intercompany.supplier} />
        </PrimaryInfo>
      </Task>
    );
  }
  return (
    <Task exists={created} existed={deleted}>
      <PrimaryInfo>
        <CurrencyManager>
          <ExternalCosts save={saveExternalCosts}
            currencyCode={currency}
            editable={editable}
            value={externalCosts}
            previousValue={previous.external_cost}
            id={`task-${id}-external-cost`} />
        </CurrencyManager>
        <PercentageCell />
        <DaysCell />
        <Cost>{cost}</Cost>
        <Price>{price}</Price>
        <IconCell />
        <MoreOptions editable={editable}>
          <MoreOptionsMenuItem onClick={deleteTask} showIf={editable}>Delete</MoreOptionsMenuItem>
        </MoreOptions>
      </PrimaryInfo>
    </Task>
  );
};

module.exports = IntercompanyTask;
