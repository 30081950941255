const { connect } = require('react-redux');
const Component = require('../../components/sidebar/ItemSidebar.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  waitingFor: state.waitingFor,
  isPipedriveIntegrationEnabled: state.isPipedriveIntegrationEnabled,
  projectMetadata: state.projectMetadata,
  winProbabilityThreshold: state.winLossProbabilityThreshold.winProbabilityThreshold,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(actions.closeSidebar()),
  onDelete: (item) => dispatch(actions.showConfirmDeleteModal(item)),
  onArchive: (item, archive) => dispatch(actions.showConfirmArchiveModal(item, archive)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
