const { connect } = require('react-redux');
const Component = require('../../components/won-lost-bot/WonLostBot.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  winLossProbabilityThreshold: state.winLossProbabilityThreshold,
  winLossEmailAdddress: state.winLossEmailAdddress,
  isSavingWinEmail: state.waitingFor.some((key) => key === 'save-win-email-address'),
  isSavingLossEmail: state.waitingFor.some((key) => key === 'save-loss-email-address'),
});

const mapDispatchToProps = (dispatch) => ({
  updateWinEmailAddress:
    (value) => {
      dispatch(actions.updateWinEmailAddress(value));
    },
  updateLossEmailAddress:
    (value) => {
      dispatch(actions.updateLossEmailAddress(value));
    },
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
