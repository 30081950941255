const React = require('react');
const NumericInput = require('../../../../../../../common/react/material-input/SidebarNumericInput.react');
const Menu = require('../../../../../../../common/react/Menu/ListMenu/ListMenu.react');
const MenuItem = require('../../../../../../../common/react/Menu/ListMenu/MenuItem.react');
const formatNumber = require('../../../../../../../services/FormatService').formatDecimalNumber;
const IconButton = require('../../../../../../../common/react/TooltipFixed/IconButtonTooltip.react');
const Multicurrency = require('../../services/multicurrencyService');
const ValueInput = require('./OrderValueInput.react');

module.exports = class MulticurrencyValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showRateBox: false,
    };

    this.masterCurrency = Multicurrency.getCurrencyCode(Multicurrency
      .getMasterCurrency(this.props.order));
  }

  componentDidMount() {
    const orderCurrency = Multicurrency.getSelectedCurrency(this.props.order);
    /**
     * Update the order currency when the selected one is not available for the selected project,
     * using the first available currency
     */
    if (!Multicurrency.getCurrencyByCode(this.props.order, orderCurrency)
      && Multicurrency.hasCurrencies(this.props.order)) {
      const currency = Multicurrency.getCurrencyCode(this.props.order.currencies[0]);
      this.handleCurrencyChange(currency);
    }
  }

  componentDidUpdate(prevProps) {
    const editModeClosed = !prevProps.readOnly && this.props.readOnly;
    if (editModeClosed) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showRateBox: false });
    }
  }

  handleRateChange(name, value) {
    let parsedRate = parseFloat(value);
    parsedRate = !Number.isNaN(parsedRate) ? parsedRate : null;
    const prevRate = this.getConversionRate();
    if (parsedRate !== prevRate) {
      this.changeExchangeRate(parsedRate);

      const newOrder = {
        ...this.props.order,
        exchange_rate: parsedRate,
      };

      // Get value converted in previous selected currency
      const oldConvertedValue = Multicurrency.getConvertedValue(this.props.order);

      this.changeValue(newOrder, oldConvertedValue);
    }
  }

  handleCurrencyChange(code) {
    const currencyCode = code === this.masterCurrency ? null : code; // Reset order currency when selecting master currency
    if (this.props.order.currency !== currencyCode) {
      this.props.onChange('currency', code);

      let newOrder = {
        ...this.props.order,
        currency: currencyCode,
      };

      if (currencyCode == null) { // Reset exchange_rate when master currency is selected
        newOrder = {
          ...newOrder,
          exchange_rate: null,
        };
        this.changeExchangeRate(null);
      }

      // Get value converted in previous selected currency
      const oldConvertedValue = Multicurrency.getConvertedValue(this.props.order);

      this.changeValue(newOrder, oldConvertedValue);
    }
  }

  handleValueChange(e) {
    this.changeValue(this.props.order, e.target.value);
  }

  getManualRateButton() {
    if (!this.isSelectedCurrencyMaster()) {
      return (
        <IconButton label="Set exchange rate" onClick={this.toggleRateBox.bind(this)}>
          <span className="wethod-icon wethod-icon-multicurrency wethod-icon-multicurrency--black" />
        </IconButton>
      );
    }
    // Hide button when the order is in master currency
    return null;
  }

  getConversionRate() {
    return Multicurrency.getConversionRate(this.props.order);
  }

  getRateBox() {
    if (this.state.showRateBox && !this.isSelectedCurrencyMaster()) {
      return (
        <div className="material-input-box orders__multicurrency-rate-box">
          <div className="material-input-box__title">
            Currency exchange rate
          </div>
          <p className="material-input-box__content">
            Manually set the exchange rate for this order's value.
          </p>
          <div className="material-input-box__content orders__multicurrency-rate-row">
            <span>1 {this.masterCurrency} is </span>
            <NumericInput name="rate"
              formatOptions={{ numeralDecimalScale: 10 }}
              value={this.getConversionRate()}
              onChange={this.handleRateChange.bind(this)}
              onValidate={this.props.onValidate} />
            <span>{this.props.order.currency}</span>
          </div>
        </div>
      );
    }
    return null;
  }

  getCurrencies() {
    return this.props.order.currencies
      .map((currency) => (
        <MenuItem key={currency.code}
          onClick={this.handleCurrencyChange.bind(this, currency.code)}>
          {currency.code} - {currency.name}
        </MenuItem>
      ));
  }

  getHelperText() {
    if (!this.isSelectedCurrencyMaster()) { // Show conversion rate and value converted in master currency
      const { value } = this.props.order;
      const { currency } = this.props.order;

      const conversionRate = formatNumber(this.getConversionRate(), false, 2);
      const formattedValue = formatNumber(value, false, 2);

      const conversionRateLabel = `(1 ${this.masterCurrency} = ${conversionRate} ${currency})`;
      const valueLabel = value ? `${formattedValue} ${this.masterCurrency} ` : ''; // Show converted value, if not empty

      return `${valueLabel}${conversionRateLabel}`;
    }
    // When the selected currency is master, there is no conversion to show
    return null;
  }

  getCurrencyButtons() {
    if (this.props.readOnly) {
      return null;
    }
    return (
      <div style={{ display: 'inline-flex' }}>
        <button type="button"
          ref={(el) => this.currenciesButton = el}
          onClick={this.showMenu.bind(this)}
          className="wethod-button orders__multicurrency-button">
          {Multicurrency.getSelectedCurrency(this.props.order)}
          <Menu open={this.state.showMenu}
            anchorEl={this.currenciesButton}
            onClose={this.hideMenu.bind(this)}
            bottom>
            {this.getCurrencies()}
          </Menu>
        </button>
        {this.getManualRateButton()}
      </div>
    );
  }

  isSelectedCurrencyMaster() {
    return !this.props.order.currency || this.props.order.currency === this.masterCurrency;
  }

  showMenu() {
    if (!this.state.showMenu) {
      this.setState({ showMenu: true });
    }
  }

  hideMenu() {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  }

  toggleRateBox() {
    if (this.state.showRateBox) {
      this.setState({ showRateBox: false });
    } else {
      this.setState({ showRateBox: true });
    }
  }

  // Update the exchange_rate of the order
  changeExchangeRate(rate) {
    this.props.onChange('exchange_rate', rate);
  }

  changeValue(order, newValue) {
    // The value of the order is always converted in master currency
    const masterConvertedValue = Multicurrency.getMasterConvertedValue(order, newValue);
    this.props.onValueChange('value', masterConvertedValue);
  }

  render() {
    return (
      <div>
        <div className="wethod-sidebar__multiple-input-row orders__multicurrency-row">
          <ValueInput name={this.props.name}
            label="Value"
            prefix={this.props.readOnly ? this.props.order.currency : null}
            error={this.props.error}
            value={Multicurrency.getConvertedValue(this.props.order)} // Visible value is converted in the selected currency
            helperText={this.getHelperText()}
            updateErrors={this.props.updateErrors}
            readOnly={this.props.readOnly}
            onFocus={this.props.onFocus}
            onChange={this.handleValueChange.bind(this)} />
          {this.getCurrencyButtons()}
        </div>
        {this.getRateBox()}
      </div>
    );
  }
};
