const React = require('react');
const constants = require('../constants');

/**
 * Handle the item save, checking:
 * whether the item needs to be updated or created,
 * whether the item needs the won/lost feedback to be saved.
 *
 * @param children
 * @param item
 */
class SaveManager extends React.Component {
  /**
   * Return item project with the given reason why
   * @param item
   * @param reasonWhy
   * @returns {{reason_why}|*}
   */
  static resetReasonWhy(item, reasonWhy) {
    if (item) {
      let updatedProject = item.project ? { ...item.project } : {};
      updatedProject = { ...updatedProject, reason_why: reasonWhy };

      return updatedProject;
    }
    return item;
  }

  /**
   * Check whether the given item should be moved to another section,
   * given its current and previous probability.
   * (Item with probability >= 90 should be in the project section, the others in the opportunity section).
   * @param item
   * @param oldItem
   * @param section
   * @param winProbabilityThreshold
   * @returns {*|false|boolean}
   */
  static shouldBeMoved(item, oldItem, section, winProbabilityThreshold) {
    if (item && item.project) {
      const oldProbability = oldItem.project.probability;
      const newProbability = item.project.probability;

      const isItemNew = SaveManager.isNew(item);

      const shouldBeMovedToProjects = section === constants.TYPE_OPPORTUNITIES
        && SaveManager.isWon(newProbability, oldProbability, isItemNew, winProbabilityThreshold);

      const shouldBeMovedToOpportunities = section === constants.TYPE_PROJECTS
        && SaveManager
          .isOpportunity(newProbability, oldProbability, isItemNew, winProbabilityThreshold);

      return shouldBeMovedToProjects || shouldBeMovedToOpportunities;
    }

    return false;
  }

  /**
   * Check whether the given item needs to request feedback,
   * given its current and previous probability and its project type settings.
   * @param item
   * @param oldItem
   * @param winProbabilityThreshold
   * @returns {boolean|*}
   */
  static needsFeedback(item, oldItem, winProbabilityThreshold) {
    if (item && item.project && item.project_type) {
      const oldProbability = oldItem.project.probability;
      const newProbability = item.project.probability;

      const isItemNew = SaveManager.isNew(item);

      const needsFeedback = SaveManager.isFeedbackRequired(item.project_type);
      const isWon = SaveManager.isWon(newProbability, oldProbability, isItemNew,
        winProbabilityThreshold);
      const isLost = SaveManager.isLost(newProbability, oldProbability, isItemNew);

      return (needsFeedback && (isWon || isLost));
    }

    return false;
  }

  /**
   * Check whether the given item should reset the reason why,
   * given its current probability.
   * (Item which are not won or lost should not have a reason why).
   * @param item
   * @param winProbabilityThreshold
   * @returns {boolean}
   */
  static needsReasonWhyReset(item, winProbabilityThreshold) {
    if (item && item.project && item.project_type) {
      const newProbability = item.project.probability;

      const isWon = newProbability >= winProbabilityThreshold;
      const isLost = newProbability === 0;

      const hasReasonWhy = item.project.reason_why != null;

      return (!isLost && !isWon && hasReasonWhy);
    }

    return false;
  }

  /**
   * Check whether the project needs to set a default value for the list of employees in whitelist.
   * This is necessary when the user enables the whitelist, and it's still empty.
   *
   * @param whitelistEnabled
   * @param oldItem
   * @returns {boolean}
   */
  static needsDefaultWhitelistedEmployees(whitelistEnabled, oldItem) {
    const oldWhitelist = oldItem.project.whitelisted_employee;
    const wasWhitelistEnabled = oldItem.project.timesheet_whitelist;

    const hasEnabledWhitelist = !wasWhitelistEnabled && whitelistEnabled;
    const hasEmptyWhitelist = oldWhitelist.length === 0;

    return hasEnabledWhitelist && hasEmptyWhitelist;
  }

  /**
   * Check whether the given project type requires the won/lost feedback
   * @param projectType
   * @returns {boolean}
   */
  static isFeedbackRequired(projectType) {
    return projectType.won_lost_feedback
      || projectType.won_lost_feedback_required;
  }

  /**
   * Check whether the given probability turns and item in a won project.
   *
   * @param newProbability
   * @param oldProbability
   * @param isItemNew
   * @param winProbabilityThreshold
   * @returns {boolean}
   */
  static isWon(newProbability, oldProbability, isItemNew, winProbabilityThreshold) {
    return newProbability >= winProbabilityThreshold
      && (oldProbability < winProbabilityThreshold || isItemNew);
  }

  /**
   * Check whether the given probability turns and item in a lost opportunity.
   *
   * @param newProbability
   * @param oldProbability
   * @param isItemNew
   * @returns {boolean}
   */
  static isLost(newProbability, oldProbability, isItemNew) {
    return newProbability === 0 && (oldProbability > 0 || isItemNew);
  }

  /**
   * Check whether the given probability turns and item in an opportunity.
   *
   * @param newProbability
   * @param oldProbability
   * @param isItemNew
   * @param winProbabilityThreshold
   * @returns {boolean}
   */
  static isOpportunity(newProbability, oldProbability, isItemNew, winProbabilityThreshold) {
    return newProbability < winProbabilityThreshold
      && (oldProbability >= winProbabilityThreshold || isItemNew);
  }

  /**
   * Check whether the given item is new and not yet saved.
   * @param item
   * @returns {boolean}
   */
  static isNew(item) {
    return !item || !item.project.id;
  }

  /**
   * Return the default list of employees that should be in whitelist.
   * This includes the pm and account, when already selected in the given project.
   * @param item
   * @returns {*[]}
   */
  static getDefaultWhitelistedEmployees(item) {
    const whitelisted = [];

    const { pm, account } = item;

    const pmId = pm ? pm.id : null;
    const accountId = account ? account.id : null;

    if (pmId) {
      whitelisted.push(pm);
    }

    if (accountId && accountId !== pmId) {
      whitelisted.push(account);
    }

    return whitelisted;
  }

  /**
   * Return the correct probability given the item stage.
   * @param stage
   * @param oldStage
   * @param oldProbability
   * @param winProbabilityThreshold
   * @returns {number|*}
   */
  static getProbabilityFromStage(stage, oldStage, oldProbability, winProbabilityThreshold) {
    if (stage.is_won) { // Probability should be >= 90
      if (oldProbability >= winProbabilityThreshold) return oldProbability;
      return winProbabilityThreshold;
    }
    if (stage.is_lost) { // Probability should be 0
      return 0;
    }
    if (oldStage.is_won) { // Probability should be 75
      return 75;
    }
    if (oldStage.is_lost) { // Probability should be 10
      return 10;
    }
    // Leave probability unchanged
    return oldProbability;
  }

  /**
   * Return the item changes with the correct probability, given the item stage.
   * @param stage
   * @param oldItem
   * @param winProbabilityThreshold
   * @returns {{opportunity_status, project: (*&{probability: (number|*)})}}
   */
  static formatStageChanges(stage, oldItem, winProbabilityThreshold) {
    const oldStage = oldItem.opportunity_status;
    const oldProbability = oldItem.project.probability;
    const newProbability = SaveManager
      .getProbabilityFromStage(stage, oldStage, oldProbability, winProbabilityThreshold);

    return {
      opportunity_status: stage,
      project: {
        probability: newProbability,
      },
    };
  }

  /**
   * Return a list of ids given a list of objects, each one containing the id.
   * @param {Array} list
   */
  static formatIdListChanges(list) {
    if (list && list.length) {
      return list.map((element) => element.id);
    }
    return [];
  }

  /**
   * Return the item changes with the whitelist toggle and default list.
   * The default list is set with pm and account when the user enables the whitelist toggle and
   * the list of whitelisted employees is still empty.
   * @param whitelistEnabled
   * @param oldItem
   * @returns {{opportunity_status: (*|T|null), project: (*&{probability})}}
   */
  static formatWhitelistChanges(whitelistEnabled, oldItem) {
    const formattedChanges = {
      project: {
        timesheet_whitelist: whitelistEnabled,
      },
    };

    const needsDefaultWhitelistedEmployees = SaveManager
      .needsDefaultWhitelistedEmployees(whitelistEnabled, oldItem);

    if (needsDefaultWhitelistedEmployees) {
      formattedChanges.project.whitelisted_employee = SaveManager
        .getDefaultWhitelistedEmployees(oldItem);
    }

    return formattedChanges;
  }

  /**
   * Return the value as K when it was in unit
   * @param value
   * @returns {number}
   */
  static formatValueAsK(value) {
    return value / 1000;
  }

  /**
   * Check whether the given item has a budget.
   * @param item
   * @returns {*}
   */
  static hasBudget(item) {
    return item && item.budget && item.budget.id;
  }

  /**
   * Return the changes formatted to be saved.
   * @param changes
   * @param updatedItem
   * @returns {{}}
   */
  static formatItem(changes, updatedItem) {
    const item = {};

    const hasBudget = SaveManager.hasBudget(updatedItem);
    const isArchived = updatedItem.project.archived;

    Object.keys(changes).forEach((key) => {
      let formattedKey;
      const value = changes[key];
      let formattedValue;
      switch (key) {
        case 'project_type':
        case 'project_label':
        case 'client':
        case 'pm':
        case 'account':
          formattedKey = `id_${key}`;
          formattedValue = value.id;
          item[formattedKey] = formattedValue;
          break;
        case 'opportunity_status':
        case 'risk':
        case 'customer':
          formattedKey = `${key}_id`;
          formattedValue = value.id;
          item[formattedKey] = formattedValue;
          break;
        case 'project':
          // set the item attribute for each value in the project object
          Object.keys(value).forEach((projectKey) => {
            formattedKey = projectKey;
            switch (projectKey) {
              case 'estimate':
                if (hasBudget) {
                  break;
                } else {
                  formattedValue = value[projectKey];
                  item[formattedKey] = formattedValue;
                  break;
                }
              case 'probability':
                if (isArchived) {
                  break;
                } else {
                  formattedValue = value[projectKey];
                  item[formattedKey] = formattedValue;
                  break;
                }
              case 'timesheet_whitelist':
                formattedValue = value[projectKey];
                item[formattedKey] = formattedValue;
                break;
              case 'whitelisted_employee':
                formattedValue = SaveManager.formatIdListChanges(value.whitelisted_employee);
                item[formattedKey] = formattedValue;
                break;
              // Ignored fields
              case 'visibility':
              case 'archived':
              case 'archived_date':
              case 'id':
                break;
              default:
                formattedValue = value[projectKey];
                item[formattedKey] = formattedValue;
                break;
            }
          });
          break;
        case 'business_unit':
          formattedValue = parseInt(value);
          formattedValue = Number.isNaN(formattedValue) ? null : formattedValue;
          item.business_unit_id = formattedValue;
          break;
        case 'budget':
          break;
        case '_fields':
          break;
        default:
          item[key] = value;
          break;
      }
    });
    return item;
  }

  /**
   * Return the item merging the given changes.
   * @param changes
   * @param oldItem
   * @returns {*}
   */
  static getUpdatedItem(changes, oldItem) {
    const updatedItem = {
      ...oldItem,
      ...changes,
      project: changes.project ? { ...oldItem.project, ...changes.project } : oldItem.project,
    };
    return updatedItem;
  }

  /**
   * Check whether the given item is included in the list of items that are waiting to save the reason why
   * @param itemsList
   * @param itemId
   * @returns {boolean|*}
   */
  static isItemSavingFeedback(itemsList, itemId) {
    if (!itemsList || !itemId) return false;

    return itemsList.some((item) => item === itemId);
  }

  constructor(props) {
    super(props);

    this.state = {
      needsSave: false, // Whether the item is waiting for the reason why to be saved
      toSave: null, // Item and metadata waiting to be saved
    };

    this.onSave = this.onSave.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formatJocChanges = this.formatJocChanges.bind(this);
    this.formatProbabilityChanges = this.formatProbabilityChanges.bind(this);
    this.formatValueChanges = this.formatValueChanges.bind(this);
  }

  /**
   * Create or update the item and its metadata.
   * @param changes
   * @param updatedItem
   * @param metadataToCreate
   * @param metadataToUpdate
   * @param metadataToDelete
   * @param shouldBeMoved
   */
  handleSave(changes, updatedItem, metadataToCreate, metadataToUpdate,
    metadataToDelete, shouldBeMoved) {
    if (this.state.needsSave) {
      this.setState({ needsSave: false });
    }

    if (SaveManager.isNew(this.props.item)) {
      const formattedChanges = SaveManager.formatItem(updatedItem, updatedItem);
      this.props.createItem(updatedItem, formattedChanges, metadataToCreate,
        metadataToUpdate, metadataToDelete);
    } else {
      const { id } = this.props.item.project;

      const formattedChanges = SaveManager.formatItem(changes, updatedItem);

      this.props.saveItem(id, formattedChanges, metadataToCreate, metadataToUpdate,
        metadataToDelete, shouldBeMoved);
    }
  }

  /**
   * When the item has a reason why selected to be saved,
   * handle the pending save of the updated item
   * @param projectId
   * @param reasonId
   */
  handleFeedbackSaveCallback(projectId, reasonId) {
    const {
      changes, metadataToCreate,
      metadataToUpdate, metadataToDelete,
      shouldBeMoved,
    } = { ...this.state.toSave };

    changes.project = SaveManager.resetReasonWhy(changes, reasonId);

    const updatedItem = SaveManager.getUpdatedItem(changes, this.props.item);

    this.handleSave(changes, updatedItem, metadataToCreate,
      metadataToUpdate, metadataToDelete, shouldBeMoved);

    // Close the modal of won/lost feedback
    this.props.hideModal();
  }

  /**
   * Handle the item saving and creation, along with the metadata to update.
   * When it is required, handle the won/lost feedback request before saving the item.
   * @param {object} changes
   * @param {array} metadataToCreate
   * @param {array} metadataToUpdate
   * @param {array} metadataToDelete
   */
  onSave(changes, metadataToCreate = [], metadataToUpdate = [], metadataToDelete = []) {
    const updatedItem = SaveManager.getUpdatedItem(changes, this.props.item);
    const changesToSave = changes;

    const shouldBeMoved = SaveManager.shouldBeMoved(updatedItem, this.props.item, this.props.type,
      this.props.winProbabilityThreshold);

    if (SaveManager.needsFeedback(updatedItem, this.props.item,
      this.props.winProbabilityThreshold)) {
      this.setState({
        needsSave: true,
        toSave: {
          changes, metadataToCreate, metadataToUpdate, metadataToDelete, shouldBeMoved,
        },
      });
      this.props.showEditFeedbackModal(
        updatedItem.project,
        this.handleFeedbackSaveCallback.bind(this),
      );
    } else {
      if (SaveManager.needsReasonWhyReset(updatedItem, this.props.winProbabilityThreshold)) {
        changesToSave.project = SaveManager.resetReasonWhy(changes, null);
      }

      this.handleSave(changesToSave, updatedItem, metadataToCreate,
        metadataToUpdate, metadataToDelete, shouldBeMoved);
    }
  }

  /**
   * Return the stage with the given attribute.
   * @param attribute
   * @returns {T|null}
   */
  getStageWithAttribute(attribute) {
    const filteredStage = this.props.stages.filter((stage) => stage[attribute]);
    return filteredStage && filteredStage.length > 0 ? filteredStage[0] : null;
  }

  /**
   * Return the correct stage given the item probability.
   * @param probability
   * @param oldProbability
   * @param oldStage
   * @param isStageEnabled
   * @returns {*|T|null}
   */
  getStageFromProbability(probability, oldProbability, oldStage, isStageEnabled) {
    if (!isStageEnabled) { // When stage is not enabled, it is always set as default
      return this.getStageWithAttribute('is_default');
    }

    if (probability >= this.props.winProbabilityThreshold) { // Stage should be won
      return this.getStageWithAttribute('is_won');
    }
    if (probability === 0) { // Stage should be lost
      return this.getStageWithAttribute('is_lost');
    }
    if (oldProbability === 0 || oldProbability >= this.props.winProbabilityThreshold) { // Stage should be default
      return this.getStageWithAttribute('is_default');
    }
    // Leave stage unchanged
    return oldStage;
  }

  /**
   * Return the correct stage given the item job order category.
   * @param isStageEnabled
   * @param wasStageEnabled
   * @param oldStage
   * @param oldProbability
   * @returns {T|null|*}
   */
  getStageFromJoc(isStageEnabled, wasStageEnabled, oldStage, oldProbability) {
    if ((isStageEnabled && wasStageEnabled) || (!isStageEnabled && !wasStageEnabled)) {
      return oldStage;
    }

    if (!wasStageEnabled) {
      if (oldProbability >= this.props.winProbabilityThreshold) { // Stage should be won
        return this.getStageWithAttribute('is_won');
      }
      if (oldProbability === 0) { // Stage should be lost
        return this.getStageWithAttribute('is_lost');
      }
    }
    return this.getStageWithAttribute('is_default');
  }

  /**
   * Return the item changes with the correct stage, given the item probability.
   * @param probability
   * @param oldItem
   * @returns {{opportunity_status: (*|T|null), project: (*&{probability})}}
   */
  formatProbabilityChanges(probability, oldItem) {
    const oldProbability = oldItem.project.probability;
    const oldStage = oldItem.opportunity_status;
    const isStageEnabled = oldItem.project_type.track_opportunity_status;
    const newStage = this
      .getStageFromProbability(probability, oldProbability, oldStage, isStageEnabled);

    return {
      opportunity_status: newStage,
      project: {
        probability,
      },
    };
  }

  /**
   * Return the item changes with the correct stage, given the item job order category.
   * @param joc
   * @param oldItem
   * @returns {{project_type, opportunity_status: (T|*|null)}}
   */
  formatJocChanges(joc, oldItem) {
    const isStageEnabled = joc.track_opportunity_status;
    const wasStageEnabled = oldItem.project_type.track_opportunity_status;
    const oldStage = oldItem.opportunity_status;
    const oldProbability = oldItem.project.probability;
    const newStage = this
      .getStageFromJoc(isStageEnabled, wasStageEnabled, oldStage, oldProbability);

    return {
      project_type: joc,
      opportunity_status: newStage,
    };
  }

  /**
   * Return the properly formatted value as K
   * @param value
   * @returns {*}
   */
  formatValueChanges(value) {
    const formattedValue = this.props.isValueAsUnit ? SaveManager.formatValueAsK(value) : value;
    return {
      project: {
        estimate: formattedValue,
      },
    };
  }

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props,
      onSave: this.onSave,
      formatJocChanges: this.formatJocChanges,
      formatProbabilityChanges: this.formatProbabilityChanges,
      formatStageChanges: SaveManager.formatStageChanges,
      formatWhitelistChanges: SaveManager.formatWhitelistChanges,
      formatValueChanges: this.formatValueChanges,
    });
  }
}

module.exports = SaveManager;
