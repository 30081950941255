// const WinLossProbabilityThresholdMock = require('./__mocks__/WinLossProbabilityThreshold');
const request = require('./Request');

const WinLossProbabilityThreshold = {
  get() {
    // return WinLossProbabilityThresholdMock.get();
    return request({
      method: 'get',
      url: '/settings/pipeline/win-probability-threshold',
    });
  },
};

module.exports = WinLossProbabilityThreshold;
