const React = require('react');
const DaysCell = require('./DaysCell.react');
const NumberFormatter = require('../../../../../../../common/react/formatters/NumberFormatter.react');
const Delta = require('../NumericDelta.react');

const ManDaysCell = ({ children, className, previousValue = null, label = '' }) => (
  <DaysCell className={className} label={label}>
    <Delta previousValue={previousValue} actualValue={children} decimalPlaces={1} />
    <NumberFormatter precision={1} hideZero>
      {children}
    </NumberFormatter>
  </DaysCell>
);

module.exports = ManDaysCell;
