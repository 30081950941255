/**
 * Utility for using feature flags.
 */
class FeatureService {
  /**
   * @param {string[]} enabledFeatures list of current company enabled feature-flag keys
   */
  constructor(enabledFeatures) {
    this.enabledFeatures = enabledFeatures;
  }

  /**
   * Return if feature identified by the given name is enabled for current company.
   * @param {string} key
   * @return {boolean}
   */
  isEnabled(key) {
    return this.enabledFeatures.includes(key);
  }
}

FeatureService.FEATURE_FLAG_WEEKLY_TIMESHEET_DAILY_MODE = 'weekly-timesheet-daily-mode';
FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK = 'jakala-features-november-block';
FeatureService.PUBLIC_API = 'public-api';
FeatureService.NEW_INSIGHTS_REVENUES = 'new-insights-revenues';
FeatureService.NEW_INSIGHTS_GROSS_MARGIN = 'new-insights-gross-margin';
FeatureService.EXTERNAL_COST_SUSPENDED = 'external-cost-suspended';
FeatureService.PIVOT_WEEKLY_TIMESHEET_EXPORT = 'pivot-weekly-timesheet-export';

module.exports = FeatureService;
